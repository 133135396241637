import axios from "axios";
import store from "../stores/store";
import {SESSION_TIMEOUT} from '../actions/auth-actions/types'
import { message } from "antd";

var token = localStorage.getItem("domicilier_token");

const axiosInstance = axios.create({
  baseURL: process.env.MIX_API_URL,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.MIX_APP_URL
  },
});

export const setAuthToken = (config) => {
  if (token) {
  //applying token
  config.headers.Authorization = `Bearer ${token}`
  } else {
  //deleting the token from header
    delete config.headers.Authorization;
  }
}

const responseHandler = response => {
  return response;
};

const errorHandler = error => {
  if (token && error.response.status == 401) {
    message.error({
      content: 'Votre session a expirée.',
      className: 'error-message',
      duration: 3
    });
    store.dispatch({type: SESSION_TIMEOUT});
  }else{
    return Promise.reject(error);
  }
};

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  setAuthToken(config);
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
