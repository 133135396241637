import React from "react";
import { Menu, Dropdown, Badge, Button } from "antd";
import { useHistory } from "react-router-dom";
import RingtoneSmallIcon from "../../svg-components/icons/RingtoneSmallIncon";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import DropDownNotificationCard from "./DropDownNotificationCard";
import {changeNotifStatus} from "../../actions/notifications-actions/actions";
import {EMPTY_NOTIFICATIONS} from "../../actions/notifications-actions/types";

const NavbarNotificationDropDown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const goToAll = async () => {
    await dispatch({type: EMPTY_NOTIFICATIONS});
    history.push('/notifications')
  }
  const newUnseenNotifications = useSelector(
    (state: RootState) => state.notificationsReducer.newUnseenNotifications
  );
  const totalNew = useSelector(
    (state: RootState) => state.notificationsReducer.totalNew
  );
  const changeStatus = (visible) => {
    if(visible && totalNew > 0){
      dispatch(changeNotifStatus());
    }
  }
  const menu = (
    <Menu>
      {newUnseenNotifications && newUnseenNotifications.length > 0 ? (
        <>
          {newUnseenNotifications.map((notification, i) => (
            <Menu.Item key={notification.id}>
              <DropDownNotificationCard
                notification={notification}
              />
            </Menu.Item>
          ))}
          <Menu.Divider />
          <Menu.Item key="all">
            <Button onClick={goToAll} className={"link-to-all-notifications"}>Toutes les notifications</Button>
          </Menu.Item>
        </>
      ) : (
        <>
        <em style={{color: "#bfbfbf", textAlign: "center", display: "block"}}>Aucune notification</em>
        <Menu.Divider />
        <Menu.Item key="all">
          <Button onClick={goToAll} className={"link-to-all-notifications"}>Toutes les notifications</Button>
        </Menu.Item>
        </>
      )}
    </Menu>
  );
  return (
    <Dropdown placement={"bottomCenter"} overlay={menu} trigger={["click"]} overlayClassName={"notification-dropdown"} onVisibleChange={changeStatus}>
      <a
        className="ant-dropdown-link btn-icon-navbar"
        onClick={(e) => e.preventDefault()}
      >
        <Badge count={totalNew > 5 ? '+5' : totalNew}>
          <div className={"icon-border"}>
            <RingtoneSmallIcon />
          </div>
        </Badge>
      </a>
    </Dropdown>
  );
};

export default NavbarNotificationDropDown;
