import React from "react";
import "./squared-radio-btn.scss";

interface SquaredRadioBtnInterfaceTypes {
  label: string;
  name: string;
  text: string;
  icon: React.FunctionComponentElement<any>;
  defaultChecked: boolean;
  value: string;

}

const SquaredRadioBtn: React.FC<SquaredRadioBtnInterfaceTypes> = ({
                                                                    label,
                                                                    name,
                                                                    text,
                                                                    icon,
                                                                    defaultChecked,
                                                                    value
                                                                  }) => {
  return (
    <label className="squared-radio-btn">
      <input
        defaultChecked={defaultChecked}
        name={name}
        id={"#" + label}
        type="radio"
        value={value}
      />
      <div className="checkmark">
        <div className="svg-icon-container">{icon}</div>
        <div className="text">{text}</div>
      </div>
    </label>
  );
};

export default SquaredRadioBtn;
