export const GET_SERVICE_BUNDLE_REQUEST = "GET_SERVICE_BUNDLE_REQUEST ";
export const GET_SERVICE_BUNDLE_SUCCESS = "GET_SERVICE_BUNDLE_SUCCESS ";
export const GET_SERVICE_BUNDLE_FAILURE = "GET_SERVICE_BUNDLE_FAILURE ";

export const ADD_SERVICE_PHONE_REQUEST = "ADD_SERVICE_PHONE_REQUEST ";
export const ADD_SERVICE_PHONE_SUCCESS = "ADD_SERVICE_PHONE_SUCCESS ";
export const ADD_SERVICE_PHONE_FAILURE = "ADD_SERVICE_PHONE_FAILURE ";

export const ADD_SERVICE_MEETING_ROOM_REQUEST = "ADD_SERVICE_MEETING_ROOM_REQUEST ";
export const ADD_SERVICE_MEETING_ROOM_SUCCESS = "ADD_SERVICE_MEETING_ROOM_SUCCESS ";
export const ADD_SERVICE_MEETING_ROOM_FAILURE = "ADD_SERVICE_MEETING_ROOM_FAILURE ";


export const GET_SERVICE_PACKS_REQUEST = "GET_SERVICE_PACKS_REQUEST ";
export const GET_SERVICE_PACKS_SUCCESS = "GET_SERVICE_PACKS_SUCCESS ";
export const GET_SERVICE_PACKS_FAILURE = "GET_SERVICE_PACKS_FAILURE ";

export const GET_SERVICE_SPACES_REQUEST = "GET_SERVICE_SPACES_REQUEST ";
export const GET_SERVICE_SPACES_SUCCESS = "GET_SERVICE_SPACES_SUCCESS ";
export const GET_SERVICE_SPACES_FAILURE = "GET_SERVICE_SPACES_FAILURE ";

export const GET_SERVICE_PHONES_REQUEST = "GET_SERVICE_PHONES_REQUEST ";
export const GET_SERVICE_PHONES_SUCCESS = "GET_SERVICE_PHONES_SUCCESS ";
export const GET_SERVICE_PHONES_FAILURE = "GET_SERVICE_PHONES_FAILURE ";

export const GET_ADDRESSES_REQUEST = "GET_ADDRESSES_REQUEST ";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS ";
export const GET_ADDRESSES_FAILURE = "GET_ADDRESSES_FAILURE ";

export const GET_ADDRESS_REQUEST = "GET_ADDRESS_REQUEST ";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS ";
export const GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE ";

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILURE = "GET_REVIEWS_FAILURE";

export const GET_ADDRESS_REVIEWS_REQUEST = "GET_ADDRESS_REVIEWS_REQUEST";
export const GET_ADDRESS_REVIEWS_SUCCESS = "GET_ADDRESS_REVIEWS_SUCCESS";
export const GET_ADDRESS_REVIEWS_FAILURE = "GET_ADDRESS_REVIEWS_FAILURE";

export const GET_FAQS_REQUEST = "GET_FAQS_REQUEST";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAILURE = "GET_FAQS_FAILURE";


export const GET_JOBS_REQUEST = "GET_JOBS_REQUEST";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAILURE = "GET_JOBS_FAILURE";

export const GET_JOB_REQUEST = "GET_JOB_REQUEST";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAILURE = "GET_JOB_FAILURE";

export const GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST";
export const GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS";
export const GET_PROMOTIONS_FAILURE = "GET_PROMOTIONS_FAILURE";

export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";
