import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCart, emptyCart } from "../../actions/cart-actions/actions";
import { RootState } from "../../reducers";
import "./pack-choice-card.scss";
import PackPromoCounter from '../pack-promo-counter-components/PackPromoCounter';
import HouseSmallIcon from "../../svg-components/icons/HouseSmallIcon";
import ScannerIconInCard from "../../svg-components/icons/ScannerIconInCard";
import FlyingArrowMessageIcon from "../../svg-components/icons/FlyingArrowMessageIcon";
import HouseBigIcon from "../../svg-components/icons/HouseBigIcon";

interface packChoiceCardIProps {
  offer: any;
  title: string;
  description: string;
  animDelay: string;
  price: number;
  promo: any;
  defaultSelected: boolean
}

const PackChoiceCard: React.FC<packChoiceCardIProps> = ({ offer, defaultSelected, title, description, price, promo, animDelay }) => {
  const [btnClass, setBtnClass] = useState('pack-choice-card');
  const [topContainerClass, setTopContainerClass] = useState('top-container');
  const dispatch = useDispatch();
  const cart = useSelector(
    (state: RootState) => state.cartReducer.cart
  );
  useEffect(() => {
    if (promo) {
      setTopContainerClass('top-container promo');
    } else {
      setTopContainerClass('top-container');
    }
  }, [promo]);
  const handleSelectedPack = (e, offer) => {
    if(!defaultSelected){
      const addedPack = {
        id: offer.service_id,
        name: offer.service.name,
        priceHt: promo ? price - (price * promo.value / 100) : price
      };
      const isInCart = cart.packs.find(element => element.id === addedPack.id);
      if (isInCart) {
        setBtnClass('pack-choice-card');
        cart.packs = cart.packs.filter((element) => element.id !== addedPack.id);
      } else {
        setBtnClass('pack-choice-card selected');
        cart.packs.push(addedPack);
      }
      dispatch(updateCart(cart));
    }
  }
  useEffect(() => {
    if(defaultSelected){
      const addedPack = {
        id: offer.id,
        name: offer.service.name,
        priceHt: promo ? price - (price * promo.value / 100) : price
      };
      const isInCart = cart.packs.find(element => element.id === addedPack.id);
      if (!isInCart) {
        setBtnClass('pack-choice-card selected');
        cart.packs.push(addedPack);
        dispatch(updateCart(cart));
      }
    }
  }, [defaultSelected])
  useEffect(() => {
    if (cart.packs.length > 0) {
      const isInCart = cart.packs.find(element => element.id === offer.id);
      if (isInCart) {
        setBtnClass('pack-choice-card selected');
        if (defaultSelected) setBtnClass('pack-choice-card selected disabled');
      } else {
        setBtnClass('pack-choice-card');
      }
    }
  }, [cart.packs]);
  const getIcon = (offer) => {
    let icon = <HouseBigIcon/>
    if(offer.service.icon){
      icon = <img src={process.env.MIX_STORAGE_URL + offer.service.icon} alt="icon" title={offer.service.name}/>
    }else{
      if(title.includes("Numérisation") || title.includes("Scan")){
        icon = <ScannerIconInCard/>
      }
      if(title.includes("Rexpédition")){
        icon = <FlyingArrowMessageIcon/>
      }
    }
    return icon
  }
  return (
    <button style={{ animationDelay: animDelay }} className={btnClass} onClick={(e) => handleSelectedPack(e, offer)}>
      {promo ? (
        <div className="promo-tag">
          <p>
            {promo.value}% <br />
            promo
          </p>
        </div>
      ) : null
      }
      <div className="filler-blue" />
      <div className={topContainerClass}>
        <div className="service-icon" title={title}>
          {getIcon(offer)}
        </div>
        <div>
          <h2 className="title marged-top-h">{title}</h2>
          <h3 className="silver-sub-title">{description}</h3>
        </div>
      </div>
      {promo ? (
        <div style={{ width: '100%' }}>
          <div className="bottom-container">
            <h3 className="price">
              <span>{price.toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt HT/mois</span>
              {(price - (price * promo.value / 100)).toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt HT/mois
            </h3>
          </div>
          <PackPromoCounter
            promoExpiryDate={promo.expiry_date}
          />
        </div>
      ) : (
        <div className="bottom-container">
          <h3 className="price">{price.toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt HT/mois</h3>
        </div>
      )}
    </button>
  );
};

export default PackChoiceCard;
