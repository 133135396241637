import React, { useEffect, useState } from "react";
import "../account-verification-page.scss";
import { Alert, Form, Input, Spin, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "../../../../actions/auth-actions/actions";
import { RootState } from "../../../../reducers";
import { Link, useParams, Redirect } from "react-router-dom";
import Success from "../../../../svg-components/Success";

const EmailVerficationPage = ({ history }: any) => {
  const dispatch = useDispatch();
  const {token}: any = useParams();
  const [isValid, setIsValid] = useState(null);
  const useMountEffect = (fun) => useEffect(fun, [])
  const emailIsVerified = useSelector(
    (state: RootState) => state.authReducer.emailIsVerified
  );
  useEffect(() => {
    document.title = `Vérifier mon email | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/verify/email`);
    }
  })
  const verifyLink = async (values) => {
    await dispatch(verifyEmail(values));
  }
  useMountEffect(() => {
    verifyLink({ token: token });
  });
  useEffect(() => {
    if(emailIsVerified){
      setIsValid(true);
    }else{
      setIsValid(false);
    }
  }, [emailIsVerified])
  return (
    <div className={"auth-page"}>
      <div className="max-width-container">
        <div className="container-center">
          {isValid ? isValid === true ? (
            <Alert
              message="Compte vérifié !"
              description={
                <div>
                  <p>Votre compte a été vérifié. <br /> Vous pouvez maintenant accéder à votre dashboard.</p>
                  <Link to="/dashboard">Obtenir ma domiciliation</Link>
                </div>
              }
              type="success"
              icon={<Success />}
              showIcon
            />
          ) :  (
            <Alert
              message="Lien invalide ou expiré !"
              description={
                <div>
                  <p>Le lien est invalide ou a expiré.</p>
                </div>
              }
              type="error"
              showIcon
            />
          ) : null}
        </div>
      </div >
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div >
  );
};

export default EmailVerficationPage;
