import React from "react";

const PdfSmallIcon = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 7.11705C17 5.94696 16.5444 4.84693 15.7171 4.01963L12.9804 1.28295C12.1531 0.455611 11.0531 0 9.88302 0H9.88299H4.94625C4.79754 0 4.67704 0.120534 4.67704 0.26921C4.67704 0.417886 4.79754 0.53842 4.94625 0.53842H9.27752C10.402 0.53842 11.3168 1.45323 11.3168 2.57767V3.98269C11.3168 4.9204 12.0796 5.68327 13.0173 5.68327H14.4224C15.5445 5.68327 16.4577 6.59435 16.4615 7.71563V16.6774C16.4615 17.3182 15.9402 17.8396 15.2996 17.8396H3.54047C3.01016 17.8396 2.55247 17.4864 2.41743 16.977L2.40591 15.3164H11.1372C12.3224 15.3164 13.2866 14.3522 13.2866 13.167V12.585C13.2866 12.4363 13.1661 12.3158 13.0173 12.3158C12.8686 12.3158 12.7481 12.4363 12.7481 12.585V13.167C12.7481 14.0553 12.0255 14.7779 11.1372 14.7779H1.48672C0.963844 14.7779 0.53842 14.3525 0.53842 13.8296V8.00282C0.795964 8.21668 1.12655 8.34547 1.48672 8.34547H11.1371C12.0254 8.34547 12.7481 9.06814 12.7481 9.95643V11.5081C12.7481 11.6568 12.8686 11.7773 13.0173 11.7773C13.166 11.7773 13.2865 11.6568 13.2865 11.5081V9.95643C13.2865 8.77126 12.3223 7.80705 11.1371 7.80705H2.37849V1.70069C2.37849 1.05983 2.89971 0.53842 3.5404 0.53842H3.86959C4.0183 0.53842 4.1388 0.417886 4.1388 0.26921C4.1388 0.120534 4.01826 0 3.86959 0H3.54043C2.60287 0 1.8401 0.762941 1.8401 1.70069V5.37203H1.48672C0.666923 5.37203 0 6.03899 0 6.85875V13.8296C0 14.6494 0.666923 15.3164 1.48672 15.3164H1.86742L1.87919 17.0131C1.87934 17.0335 1.88181 17.0539 1.88655 17.0738C2.07005 17.8417 2.75014 18.3781 3.54043 18.3781H15.2995C16.2371 18.3781 16.9999 17.6151 16.9999 16.6774L17 7.11705ZM1.48672 5.91045H1.8401V7.80705H1.48672C0.963844 7.80705 0.53842 7.38163 0.53842 6.85875C0.53842 6.33588 0.963808 5.91045 1.48672 5.91045ZM14.4223 5.14485H13.0173C12.3765 5.14485 11.8551 4.62352 11.8551 3.98269V2.57767C11.8551 1.85015 11.5523 1.19217 11.0659 0.723026C11.6381 0.907381 12.1623 1.22631 12.5997 1.66365L15.3364 4.40033C15.7737 4.83767 16.0926 5.36187 16.277 5.93407C15.8078 5.44773 15.1498 5.14485 14.4223 5.14485Z"
        fill="#3240FF"
      />
      <path
        d="M10.8422 10.1141C10.9909 10.1141 11.1114 9.99357 11.1114 9.84489C11.1114 9.69622 10.9909 9.57568 10.8422 9.57568H9.45427C9.30556 9.57568 9.18506 9.69622 9.18506 9.84489V13.2694C9.18506 13.4181 9.30556 13.5386 9.45427 13.5386C9.60298 13.5386 9.72348 13.4181 9.72348 13.2694V11.7939H10.732C10.8807 11.7939 11.0012 11.6734 11.0012 11.5247C11.0012 11.3761 10.8807 11.2555 10.732 11.2555H9.72348V10.1141H10.8422Z"
        fill="#3240FF"
      />
      <path
        d="M5.92448 9.57568C5.85302 9.57568 5.78446 9.60411 5.73392 9.65472C5.68345 9.70533 5.65513 9.77393 5.65527 9.84543V9.84837V9.84848V13.2138C5.65527 13.2348 5.65789 13.2551 5.66245 13.2747C5.66382 13.3445 5.69214 13.4112 5.74174 13.4605C5.79218 13.5106 5.86038 13.5386 5.93141 13.5386H5.93242C5.96167 13.5385 6.65221 13.5359 6.92745 13.5311C7.40521 13.5227 7.83056 13.3063 8.12529 12.9217C8.39687 12.5672 8.54648 12.0826 8.54648 11.5572C8.54648 10.372 7.88254 9.57572 6.89436 9.57572H5.92448V9.57568ZM8.00806 11.5572C8.00806 12.3912 7.55984 12.9815 6.91805 12.9927C6.75433 12.9956 6.43171 12.9977 6.19969 12.999C6.19836 12.6062 6.19613 11.8871 6.19613 11.5534C6.19613 11.2741 6.19488 10.5211 6.19412 10.1141H6.89432C7.43278 10.1141 8.00806 10.4932 8.00806 11.5572Z"
        fill="#3240FF"
      />
      <path
        d="M2.99312 9.55057C2.92158 9.55057 2.85299 9.57904 2.80245 9.62972C2.75227 9.68004 2.72413 9.74817 2.72395 9.81921C2.72395 9.81942 2.72388 9.8196 2.72388 9.81978V13.2695C2.72388 13.4182 2.84438 13.5388 2.99309 13.5388C3.1418 13.5388 3.2623 13.4182 3.2623 13.2695V12.0388C3.48571 12.0376 3.79558 12.036 3.95366 12.036C4.64941 12.036 5.21543 11.4785 5.21543 10.7933C5.21543 10.108 4.64937 9.55054 3.95366 9.55054H2.99312V9.55057ZM4.67705 10.7933C4.67705 11.1751 4.34578 11.4976 3.9537 11.4976C3.79648 11.4976 3.49091 11.4992 3.2675 11.5004C3.26628 11.271 3.26474 10.9537 3.26474 10.7933C3.26474 10.658 3.26388 10.3276 3.26316 10.089H3.9537C4.34578 10.089 4.67705 10.4115 4.67705 10.7933Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default PdfSmallIcon;
