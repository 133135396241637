import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import App from "./App";
import store from "./stores/store";
import { createBrowserHistory } from "history";
import axiosInstance from "./config/axios-instance";
import { connectTheUser, getAuthUser } from "./actions/auth-actions/actions";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RootState } from "./reducers";
import { BrowserRouter as Router } from "react-router-dom";
// RCE CSS
import 'react-chat-elements/dist/main.css';
import ScrollToTop from "./tools/ScrollToTop";
import BounceLoader from "react-spinners/BounceLoader";
import SetAuth from "./components/auth/SetAuth";
export const history = createBrowserHistory();

const capitalize = require('capitalize')

declare global {
  interface Window {
     capitalize: any;
  }
}

window.capitalize = capitalize || {};

let Capitalize = window.capitalize;

const token = localStorage.getItem("domicilier_token");
if (token) {
  store.dispatch(connectTheUser(token));
}
store.subscribe(() => {
  const reduxSubs = store.getState();
  if (reduxSubs.authReducer.token) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${reduxSubs.authReducer.token}`;
    axiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${reduxSubs.authReducer.token}`;
  }
});
const WrappedApp = ({ children }: any) => {
  const dispatch = useDispatch();
  const { isLoadingUser } = useSelector(
    (state: RootState) => state.authReducer
  );
  useEffect(() => {
    if (token) {
      dispatch(getAuthUser());
    }
  }, [token]);
  return (
    <>
      {isLoadingUser ? (
        <div className="app-loading-container">
            <BounceLoader color={"#0BFFDB"} loading size={60} />
            <h1>Chargement...</h1>
        </div>
      ) : (
        children
      )}
    </>
  );
};

if (document.getElementById("root")) {
  ReactDOM.render(
    <Provider store={store}>
      <WrappedApp>
        <Router>
          <SetAuth/>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
      </WrappedApp>
    </Provider>,
    document.getElementById("root")
  );
}
