import {
  GET_DASHBOARD_INFO_FAILURE,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_DASHBOARD_DIRECTORY_FAILURE,
  GET_DASHBOARD_DIRECTORY_REQUEST,
  GET_DASHBOARD_DIRECTORY_SUCCESS,
  GET_DASHBOARD_CONTACTS_FAILURE,
  GET_DASHBOARD_CONTACTS_REQUEST,
  GET_DASHBOARD_CONTACTS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_DASHBOARD_BILLS_FAILURE,
  GET_DASHBOARD_BILLS_REQUEST,
  GET_DASHBOARD_BILLS_SUCCESS,
  GET_DASHBOARD_LATEST_BILLS_FAILURE,
  GET_DASHBOARD_LATEST_BILLS_REQUEST,
  GET_DASHBOARD_LATEST_BILLS_SUCCESS,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_FAILURE,
} from "./types";
import DashboardServices from "./services";
import {message} from "antd";

export function getSubscriptions() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_SUBSCRIPTIONS_REQUEST
    });
    try {
      const response = await DashboardServices.getSubscriptionsRequest();
      await dispatch({
        type: GET_SUBSCRIPTIONS_SUCCESS,
        payload: {
          subscriptions: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_SUBSCRIPTIONS_FAILURE,
      });
    }
  };
}

export function getBills(page: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_BILLS_REQUEST
    });
    try {
      const response = await DashboardServices.getBillsRequest(page);
      await dispatch({
        type: GET_DASHBOARD_BILLS_SUCCESS,
        payload: {
          bills: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_BILLS_FAILURE,
      });
    }
  };
}

export function getLatestBills() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_LATEST_BILLS_REQUEST
    });
    try {
      const response = await DashboardServices.getLatestBillsRequest();
      await dispatch({
        type: GET_DASHBOARD_LATEST_BILLS_SUCCESS,
        payload: {
          bills: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_LATEST_BILLS_FAILURE,
      });
    }
  };
}

export function getDirectory() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_DIRECTORY_REQUEST
    });
    try {
      const response = await DashboardServices.getDirectoryRequest();
      await dispatch({
        type: GET_DASHBOARD_DIRECTORY_SUCCESS,
        payload: {
          directory: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_DIRECTORY_FAILURE,
      });
    }
  };
}

export function getContacts() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_CONTACTS_REQUEST
    });
    try {
      const response = await DashboardServices.getContactsRequest();
      await dispatch({
        type: GET_DASHBOARD_CONTACTS_SUCCESS,
        payload: {
          contacts: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_CONTACTS_FAILURE,
      });
    }
  };
}

export function removeContact(data: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: REMOVE_CONTACT_REQUEST
    });
    try {
      const response = await DashboardServices.removeContactRequest(data);
      await dispatch({
        type: REMOVE_CONTACT_SUCCESS,
        payload: {
          contacts: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: REMOVE_CONTACT_FAILURE,
      });
    }
  };
}

export function getInfos() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_INFO_REQUEST
    });
    try {
      const response = await DashboardServices.getInfosRequest();
      await dispatch({
        type: GET_DASHBOARD_INFO_SUCCESS,
        payload: {
          infos: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_INFO_FAILURE,
      });
    }
  };
}

export function getInfosMonthly() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_INFO_REQUEST
    });
    try {
      const response = await DashboardServices.getInfosMonthlyRequest();
      await dispatch({
        type: GET_DASHBOARD_INFO_SUCCESS,
        payload: {
          infos: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_INFO_FAILURE,
      });
    }
  };
}

export function getInfosYearly() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DASHBOARD_INFO_REQUEST
    });
    try {
      const response = await DashboardServices.getInfosYearlyRequest();
      await dispatch({
        type: GET_DASHBOARD_INFO_SUCCESS,
        payload: {
          infos: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_INFO_FAILURE,
      });
    }
  };
}

export function addContact(data:any) {
  return async (dispatch: any) => {
    await dispatch({
      type: ADD_CONTACT_REQUEST,
    });
    try {
      const response = await DashboardServices.addContact(data);
      await dispatch({
        type: ADD_CONTACT_SUCCESS,
        payload: {
          contacts: response.data,
        },
      });
    } catch (e) {
      dispatch({
        type: ADD_CONTACT_ERROR,
      });
    }
  };
}

export function search(data:any) {
  return async (dispatch: any) => {
    try {
      const response = await DashboardServices.search(data);
      return response.data
    } catch (e) {
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}
