import React, {useEffect, useState} from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import { useHistory, Redirect } from "react-router-dom";
import { getMyContract } from "../../../actions/profile-actions/actions";
import { Modal, Spin, Button, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import $ from "jquery";
import Success from "../../../svg-components/Success";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface DownloadContractModalProps {
  visible: boolean,
  abort: any
}

const DownloadContractModal : React.FC<DownloadContractModalProps> = ({visible, abort}:any) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const contract = useSelector((state: RootState) => state.profileReducer.contract);
  useEffect(() => {
    if (visible) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [visible]);
  const handleDownload = async (e) => {
    if((profile.ngsign_contract || (contract && contract.status == "SIGNED"))){
      if(profile.can_download){
        await dispatch(getMyContract())
      }else{
        message.warning({
          content: 'Vous devez payer votre facture afin de pouvoir télécharger votre contrat !',
          className: 'warning-message',
          duration: 3
        });
      }
    }else{
      history.push('/profile');
    }
  }

  return (
    <Modal
      centered
      visible={visible}
      onCancel={abort}
      style={{ top: 20 }}
      footer={<div></div>}
      wrapClassName={"download-contract-modal"}
      width={"45%"}
    >
      <div className={"download-contract-modal-content"}>
        <Success/>
        <h2>Votre contrat a été signé avec succès !</h2>
        <div className={"buttons-wrapper"}>
          <button type="button" className="blue-btn" onClick={(e) => handleDownload(e)}>Télécharger le contrat</button>
          <Button onClick={abort} className={"back-btn"}>Retour</Button>
        </div>
      </div>
    </Modal>
  );
}
export default DownloadContractModal;
