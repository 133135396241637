import { combineReducers } from "redux";
import { authReducer } from "./auth-reducer";
import { cartReducer } from "./cart-reducer";
import { uiReducer } from "./ui-reducer";
import { profileReducer } from "./profile-reducer";
import { serivcesReducer } from "./services-reducer";
import { blogReducer } from "./blog-reducer";
import { mailsReducer } from "./mails-reducer";
import { dashboardReducer } from "./dashboard-reducer";
import { discussionsReducer } from "./discussions-reducer";
import { notificationsReducer } from "./notifications-reducer";

const RootReducer = combineReducers({
  authReducer,
  cartReducer,
  profileReducer,
  serivcesReducer,
  mailsReducer,
  dashboardReducer,
  uiReducer,
  discussionsReducer,
  blogReducer,
  notificationsReducer
});

export default RootReducer;
export type RootState = ReturnType<typeof RootReducer>;
