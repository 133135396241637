import React from "react";

const LocalisationIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.943 14.1953C22.2197 18.5353 18.7839 21.9711 14.4439 22.6944V20.8635C17.7893 20.1854 20.434 17.5407 21.1121 14.1953H22.943Z" fill="white"/>
        <path d="M11.0307 20.8635V22.6944C6.69078 21.9711 3.25497 18.5353 2.53165 14.1953H4.36257C5.04069 17.5407 7.68535 20.1854 11.0307 20.8635Z" fill="white"/>
        <path d="M11.0307 2.28296V4.11388C7.68535 4.8146 5.04069 7.45927 4.36257 10.7821H2.53165C3.25497 6.44209 6.69078 3.00629 11.0307 2.28296Z" fill="white"/>
        <path d="M22.943 10.7821H21.1121C20.434 7.43667 17.7893 4.792 14.4439 4.11388V2.28296C18.7839 3.00629 22.2197 6.44209 22.943 10.7821Z" fill="white"/>
        <path d="M13.7432 0H11.7314V6.41953H13.7432V0Z" fill="white"/>
        <path d="M13.7432 18.5806H11.7314V25.0001H13.7432V18.5806Z" fill="white"/>
        <path d="M0 11.4812L0 13.4929L6.41953 13.4929L6.41953 11.4812L0 11.4812Z" fill="white"/>
        <path d="M18.5804 11.4812L18.5804 13.4929L25 13.4929L25 11.4812L18.5804 11.4812Z" fill="white"/>
        <path d="M12.5 14.851C13.7983 14.851 14.8508 13.7985 14.8508 12.5002C14.8508 11.2019 13.7983 10.1494 12.5 10.1494C11.2017 10.1494 10.1492 11.2019 10.1492 12.5002C10.1492 13.7985 11.2017 14.851 12.5 14.851Z" fill="white"/>
    </svg>
  );
};

export default LocalisationIcon;
