import { Reducer, AnyAction } from "redux";
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_NEW_UNSEEN_NOTIFICATIONS_REQUEST,
  GET_NEW_UNSEEN_NOTIFICATIONS_SUCCESS,
  GET_NEW_UNSEEN_NOTIFICATIONS_FAILURE,
  CHANGE_NOTIF_STATUS_REQUEST,
  CHANGE_NOTIF_STATUS_SUCCESS,
  CHANGE_NOTIF_STATUS_FAILURE,
  EMPTY_NOTIFICATIONS
} from "../actions/notifications-actions/types";

const INITIAL_STATE: any = {
  notifications: null,
  filtredNotifications: null,
  currentNotification: null,
  isLoadingNotifications: false,
  isLoadingCurrentNotification: false,
  newUnseenNotifications: null,
  totalNew: 0,
  total: 0,
  lastPage: 1,
};

export const notificationsReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): any => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoadingNotifications: true
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoadingNotifications: false,
        notifications: state.notifications ? [...state.notifications, ...action.payload.notifications] : action.payload.notifications,
        total: action.payload.total,
        lastPage: action.payload.lastPage,
      };
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoadingNotifications: false,
      };
    case EMPTY_NOTIFICATIONS:
      return {
        ...state,
        notifications: null,
      };

    case GET_NEW_UNSEEN_NOTIFICATIONS_REQUEST:
      return {
        ...state,
      };
    case GET_NEW_UNSEEN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        newUnseenNotifications: action.payload.newUnseenNotifications,
        totalNew: action.payload.totalNew,
      };
    case GET_NEW_UNSEEN_NOTIFICATIONS_FAILURE:
      return {
        ...state,
      };

    case CHANGE_NOTIF_STATUS_REQUEST:
      return {
        ...state,
      };
    case CHANGE_NOTIF_STATUS_SUCCESS:
      return {
        ...state,
        newUnseenNotifications: action.payload.newUnseenNotifications,
        totalNew: action.payload.totalNew,
      };
    case CHANGE_NOTIF_STATUS_FAILURE:
      return {
        ...state,
      };

    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoadingCurrentNotification: true
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        currentNotification: action.payload.notification,
        isLoadingCurrentNotification: false
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoadingCurrentNotification: false
      };
    default:
      return state;
  }
};

