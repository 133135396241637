import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_NEW_UNSEEN_NOTIFICATIONS_REQUEST,
  GET_NEW_UNSEEN_NOTIFICATIONS_SUCCESS,
  GET_NEW_UNSEEN_NOTIFICATIONS_FAILURE,
  CHANGE_NOTIF_STATUS_REQUEST,
  CHANGE_NOTIF_STATUS_SUCCESS,
  CHANGE_NOTIF_STATUS_FAILURE,
} from "./types";
import {message} from "antd";
import NotificationsServices from "./services";


export function getNotifications(page: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_NOTIFICATIONS_REQUEST,
    });
    try {
      const response = await NotificationsServices.getNotificationsRequest(page);
      await dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications: response.data.data,
          total: response.data.total,
          lastPage: response.data.last_page,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_NOTIFICATIONS_FAILURE,
      });
    }
  };
}

export function filterNotifications(data:any) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_NOTIFICATIONS_REQUEST,
    });
    try {
      const response = await NotificationsServices.filterNotificationsRequest(data);
      await dispatch({
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications: response.data.data,
          total: response.data.total,
          lastPage: response.data.last_page,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_NOTIFICATIONS_FAILURE,
      });
    }
  };
}


export function getNewUnseenNotif() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_NEW_UNSEEN_NOTIFICATIONS_REQUEST,
    });
    try {
      const response = await NotificationsServices.getNewUnseenNotifRequest();
      await dispatch({
        type: GET_NEW_UNSEEN_NOTIFICATIONS_SUCCESS,
        payload: {
          newUnseenNotifications: response.data.notifications,
          totalNew: response.data.total,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_NEW_UNSEEN_NOTIFICATIONS_FAILURE,
      });
    }
  };
}

export function changeNotifStatus() {
  return async (dispatch: any) => {
    await dispatch({
      type: CHANGE_NOTIF_STATUS_REQUEST,
    });
    try {
      const response = await NotificationsServices.changeNotifStatusRequest();
      await dispatch({
        type: CHANGE_NOTIF_STATUS_SUCCESS,
        payload: {
          newUnseenNotifications: response.data.notifications,
          totalNew: response.data.total,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: CHANGE_NOTIF_STATUS_FAILURE,
      });
    }
  };
}


export function getNotification(id: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_NOTIFICATION_REQUEST,
    });
    try {
      const response = await NotificationsServices.getNotificationRequest(id);
      await dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: {
          notification: response.data,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_NOTIFICATION_FAILURE,
      });
    }
  };
}
