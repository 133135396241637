export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS ";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE ";

export const GET_CONTRACT_REQUEST = "GET_CONTRACT_REQUEST";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILURE = "GET_CONTRACT_FAILURE";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const SIGN_CONTRACT_REQUEST = "SIGN_CONTRACT_REQUEST";
export const SIGN_CONTRACT_SUCCESS = "SIGN_CONTRACT_SUCCESS";
export const SIGN_CONTRACT_FAILURE = "SIGN_CONTRACT_FAILURE";

export const UPDATE_BILL_REQUEST = "UPDATE_BILL_REQUEST";
export const UPDATE_BILL_SUCCESS = "UPDATE_BILL_SUCCESS";
export const UPDATE_BILL_FAILURE = "UPDATE_BILL_FAILURE";

export const INITIATE_PAYMENT_REQUEST = "INITIATE_PAYMENT_REQUEST";
export const INITIATE_PAYMENT_FAILURE = "INITIATE_PAYMENT_FAILURE";
export const INITIATE_PAYMENT_SUCCESS = "INITIATE_PAYMENT_SUCCESS";

export const APPLY_COUPON_REQUEST = "APPLY_COUPON_REQUEST";
export const APPLY_COUPON_FAILURE = "APPLY_COUPON_FAILURE";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";

export const CHECK_PAYMENT_REQUEST = "CHECK_PAYMENT_REQUEST";
export const CHECK_PAYMENT_FAILURE = "CHECK_PAYMENT_FAILURE";
export const CHECK_PAYMENT_SUCCESS = "CHECK_PAYMENT_SUCCESS";

export const DELETE_FILE = "DELETE_FILE";

export const CLEAR_BILL = "CLEAR_BILL";

export const SET_BILL = "SET_BILL";

export const SUBSCRIBE_DOMICILIATION_REQUEST = "SUBSCRIBE_DOMICILIATION_REQUEST";
export const SUBSCRIBE_DOMICILIATION_SUCCESS = "SUBSCRIBE_DOMICILIATION_SUCCESS";
export const SUBSCRIBE_DOMICILIATION_FAILURE = "SUBSCRIBE_DOMICILIATION_FAILURE";

export const SUBSCRIBE_PHONE_SOLUTION_REQUEST = "SUBSCRIBE_PHONE_SOLUTION_REQUEST";
export const SUBSCRIBE_PHONE_SOLUTION_SUCCESS = "SUBSCRIBE_PHONE_SOLUTION_SUCCESS";
export const SUBSCRIBE_PHONE_SOLUTION_FAILURE = "SUBSCRIBE_PHONE_SOLUTION_FAILURE";

export const SUBSCRIBE_MEETING_SOLUTION_REQUEST = "SUBSCRIBE_MEETING_SOLUTION_REQUEST";
export const SUBSCRIBE_MEETING_SOLUTION_SUCCESS = "SUBSCRIBE_MEETING_SOLUTION_SUCCESS";
export const SUBSCRIBE_MEETING_SOLUTION_FAILURE = "SUBSCRIBE_MEETING_SOLUTION_FAILURE";

export const GET_CONTRACT_STATUS_REQUEST = "GET_CONTRACT_STATUS_REQUEST";
export const GET_CONTRACT_STATUS_SUCCESS = "GET_CONTRACT_STATUS_SUCCESS";
export const GET_CONTRACT_STATUS_FAILURE = "GET_CONTRACT_STATUS_FAILURE";

export const GET_BILL_REQUEST = "GET_BILL_REQUEST";
export const GET_BILL_SUCCESS = "GET_BILL_SUCCESS";
export const GET_BILL_FAILURE = "GET_BILL_FAILURE";
