import React, {useEffect, useState} from "react";
import { Row, Col, Form, Button, message } from "antd";
import { RootState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import {CloudDownloadOutlined} from "@ant-design/icons";
import CheckSmallGreenIcon from "../../../svg-components/icons/CheckSmallGreeenIcon";
import DownloadContractModal from "./DownloadContractModal";
import { useHistory } from "react-router-dom";

const DownloadContract = (props) => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [hasChanges, setHasChanges] = useState(false);
    const profile = useSelector((state: RootState) => state.profileReducer.profile);
    const contract = useSelector((state: RootState) => state.profileReducer.contract);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const useMountEffect = (fun) => useEffect(fun, [])

    useMountEffect(() =>{
      if(history.location.pathname == "/profile/contract"){
        setIsModalVisible(true);
      }
    })
    useEffect(() => {
      if(props.isSubmited){
        form.submit()
      }
    }, [props.isSubmited])
    const onFormFinish = (values) => {
      if(values){
        values.progress = 60
        props.onSubmit(hasChanges, values)
      }
    }
    const cancel = () => {
      if(history.location.pathname == "/profile/contract"){
        history.push('/profile');
      }
      setIsModalVisible(false)
    }
    return (
        <Form
            name="basic"
            className={"form-profile download-contract-page"}
            form={form}
            onFinish={onFormFinish}
        >
            <Row style={{ width: "100%" }}>
                <Col lg={12} xs={24}>
                    <Row style={{ width: "100%", maxWidth: "40rem" }}>
                        <Col xs={24} md={24} lg={24}>
                          <Button className={"upload-icon-btn"} onClick={() => setIsModalVisible(true)}  style={{marginBottom: "3rem"}}>
                              {profile.ngsign_contract || (contract && contract.status == "SIGNED") ? (
                                <div style={{marginLeft: "0.5rem"}}>
                                  <CheckSmallGreenIcon />
                                </div>
                              ) : null}
                              <div className={"text"}>
                                CONTRAT SIGNE NGSIGN
                              </div>
                              <div className="svg-icon-container">
                                <Button style={{padding: 0}}>
                                  <CloudDownloadOutlined />
                                </Button>
                              </div>
                          </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <DownloadContractModal
              visible={isModalVisible}
              abort={cancel}
            />
        </Form>
    );
};

export default DownloadContract;
