import * as React from "react";
import { Col, Form, Input, Row } from "antd";
import SquaredRadioBtn from "../../squared-radio-btn/SquaredRadioBtn";
import CreationToolIconBig from "../../../svg-components/CreationToolIconBig";
import BuildingBigIcon from "../../../svg-components/BuildingBigIcon";

interface SignupStepOneTypes {
  callBackFn: any;
}

export const SignupStepOne: React.FC<SignupStepOneTypes> = ({ callBackFn }) => {
  const submitFunction = (values: any) => {
    callBackFn(values)
  }
  return (
    <Form
      id={"signup-form-step-1"}
      name="signup-form-step-1"
      onFinish={submitFunction}
      initialValues={{ remember: true }}>
      <Row>
        <h1 className={"white-title"}>Bienvenue chez domicilier.tn</h1>
      </Row>
      <div className="separtor-small-white" />
      <Row>
        <h2 className={"white-sub-title"}>
          Nous allons vous accompagner dans votre domiciliation
        </h2>
      </Row>
      <Row>
        <h2 className={"white-sub-title marged-bottom-h3"}>
          Pour commencer, quel est votre <strong>Nom & Prénom?</strong>
        </h2>
      </Row>
      <Row style={{marginTop: "2rem"}}>
        <Row>
          <Col xxl={12} md={12} xs={24}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Tapez votre Nom SVP!",
                },
              ]}
            >
              <Input placeholder={"Nom"} />
            </Form.Item>
          </Col>
          <Col xxl={12} md={12} xs={24}>
            <Form.Item
              name="surname"
              rules={[
                {
                  required: true,
                  message: "Tapez votre prénom SVP!",
                },
              ]}
            >
              <Input placeholder={"Prénom"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <h2 className={"white-sub-title marged-top-h2"}>
            Quelle est la situation qui decrit plus{" "}
            <strong>vos besoins? </strong>
          </h2>
        </Row>
        <Row style={{marginTop: "2rem"}}>
          <Form.Item
            name="need_situation_type"
            rules={[
              {
                required: true,
                message: "Votre besoin SVP!",
              },
            ]}
          >
            <div className={"flex-radio-btns-group-signup marged-top-h3"}>
              <Col>
                <SquaredRadioBtn
                  defaultChecked={false}
                  icon={<CreationToolIconBig />}
                  text={"Création"}
                  label={"creation-btn-radio-signup"}
                  name={"signup-radio-need"}
                  value={"Création"}
                />
              </Col>
              <div className="margin-top" />
              <Col>
                <SquaredRadioBtn
                  value={"Transfert de siège"}
                  defaultChecked={false}
                  icon={<BuildingBigIcon />}
                  text={"Transfert de siège"}
                  label={"transfer-siege-btn-radio-signup"}
                  name={"signup-radio-need"}
                />
              </Col>
            </div>
          </Form.Item>
        </Row>
        <Row style={{ width: "100%", marginTop: "2rem" }} gutter={[24, 24]}>
            <Col xs={24} md={6}>
              <button
                className={"auth-submit-btn-white "}
                type="submit"
              >
                Suivant
              </button>
            </Col>
        </Row>
      </Row>
    </Form>
  );
};
