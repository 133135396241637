import React, { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { Menu, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth-actions/actions";
import { UserOutlined } from '@ant-design/icons';

const NavbarUserDropDown = () => {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => state.authReducer.user
  );
  const logOut = () => {
    dispatch(logout());
  }
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link
          style={{
            textAlign: "center",
          }}
          to={"/profile"}
        >
          Mon profil
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link
          style={{
            textAlign: "center",
          }}
          to={"/settings/confidentiality"}
        >
          Paramètres
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <button
          className={"logout-button"}
          onClick={logOut}
        >
          Se déconnecter
        </button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown placement={"bottomCenter"} overlay={menu} trigger={["click"]} overlayClassName={"user-dropdown"}>
      <a
        className="ant-dropdown-link btn-avatar-navbar"
        onClick={(e) => e.preventDefault()}
      >
        <Avatar size={40} icon={<UserOutlined/>} src={user && user.avatar ? process.env.MIX_STORAGE_URL + user.avatar : null } />
      </a>
    </Dropdown>
  );
};

export default NavbarUserDropDown;
