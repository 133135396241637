import * as React from "react";
import { useEffect, useState } from "react";
import "./messages-page.scss";
import { useSelector, useDispatch } from "react-redux";
import { Steps, Row, Col, Button, Spin } from "antd";
import { RootState } from "../../reducers";
import { LoadingOutlined } from '@ant-design/icons';
import ChatComponent from '../../components/messages-components/ChatComponent';
import DiscussionsList from '../../components/messages-components/DiscussionsList';
import NewMessageModal from '../../components/messages-components/NewMessageModal';
import { getDiscussion, deleteDiscussion, startDiscussion, getNewUnseen } from "../../actions/discussions-actions/actions";
import $ from "jquery";

const { Step } = Steps;
const customDot = (dot: any, { status, index }: any) => <></>;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MessagesPage = (props) => {
  const dispatch = useDispatch();
  const useMountEffect = (fun) => useEffect(fun, [])
  const [isOpen, setIsOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeDiscussionId, setActiveDiscussionId] = useState(null);
  const [chatEntranceAnimation, setChatEntranceAnimation] = useState('');

  useEffect(() => {
    document.title = `Mes messages | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/messages`);
    }
  })
  const fetchDiscussion = async () => {
     await dispatch(getDiscussion(activeDiscussionId))
  }
  const setActiveDiscussion = (discussionId) => {
    setActiveDiscussionId(discussionId);
    setChatEntranceAnimation('slide-fwd-center');
    setIsOpen(true);
    $('.messages-page .discussions-container .left').addClass('collapsed')
  }
  const deleteCurrentDiscussion = () => {
    if(activeDiscussionId){
      dispatch(deleteDiscussion(activeDiscussionId));
      setIsOpen(false);
      $(`#discussion-${activeDiscussionId}`).fadeOut( "slow" );
    }
  }
  const submitNewMessage = async (messageTo) => {
    await dispatch(startDiscussion(messageTo));
    setIsModalVisible(false);
    setChatEntranceAnimation('slide-fwd-center');
    setIsOpen(true);
    $('.messages-page .discussions-container .left').addClass('collapsed')
  }
  useEffect(() => {
    if(activeDiscussionId){
      fetchDiscussion();
    }
  }, [activeDiscussionId])
  useEffect(() => {
    if(props.location.state && props.location.state.discussionId){
      setActiveDiscussion(props.location.state.discussionId);
      setTimeout(() => {
        dispatch(getNewUnseen());
        $(`#discussion-${props.location.state.discussionId}`).click();
      }, 2000)
    }
  }, [])
  return (
    <div className={"messages-page"}>
        <div className={"header"}>
          <h1>Mes Messages</h1>
          <Button className={"new-message-btn"} onClick={() => setIsModalVisible(!isModalVisible)}>
            Nouvelle réclamation
          </Button>
        </div>
        <div className={"discussions-container"}>
            <DiscussionsList
              setActiveDiscussion={setActiveDiscussion}
            />
            <ChatComponent
              isOpen={isOpen}
              animation={chatEntranceAnimation}
              onClickDelete={deleteCurrentDiscussion}
            />
        </div>
        <NewMessageModal
          visible={isModalVisible}
          onSubmitNewMessage={submitNewMessage}
          abort={() => setIsModalVisible(false)}
        />
    </div>
  );
};

export default MessagesPage;
