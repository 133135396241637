import * as React from "react";
import { useEffect, useState } from "react";
import "./notifications-page.scss";
import { useSelector, useDispatch } from "react-redux";
import { Steps, Row, Col, Button, Spin } from "antd";
import { RootState } from "../../reducers";
import { LoadingOutlined } from '@ant-design/icons';
import NotificationsList from '../../components/notifications-components/NotificationsList';
import { getNotification, getNewUnseenNotif } from "../../actions/notifications-actions/actions";
import $ from "jquery";

const { Step } = Steps;
const customDot = (dot: any, { status, index }: any) => <></>;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const NotificationsPage = (props) => {
  useEffect(() => {
    document.title = `Mes notifications | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/notifications`);
    }
  })
  return (
    <div className={"notifications-page"}>
        <div className={"header"}>
          <h1>Mes Notifications</h1>
        </div>
        <div className={"notifications-container"}>
            <NotificationsList/>
        </div>
    </div>
  );
};

export default NotificationsPage;
