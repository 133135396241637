import React from "react";

const BuildingBigIcon = () => {
  return (
    <svg viewBox="0 0 75 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M72.989 78.2697H70.7137V6.82583C70.7137 6.07168 70.1024 5.4607 69.3486 5.4607H43.8654C43.1114 5.4607 42.5002 6.07168 42.5002 6.82583V12.7415H39.77V6.82583C39.77 6.07168 39.1587 5.4607 38.4048 5.4607H32.489V1.36513C32.489 0.61098 31.8777 0 31.1239 0C30.37 0 29.7587 0.61098 29.7587 1.36513V5.4607H23.843C23.089 5.4607 22.4779 6.07168 22.4779 6.82583V12.7415H18.3823C17.6283 12.7415 17.0172 13.3525 17.0172 14.1067V25.483H5.64081C4.88682 25.483 4.27568 26.094 4.27568 26.8482V78.2696H2.00008C1.24609 78.2696 0.634949 78.8806 0.634949 79.6347C0.634949 80.3889 1.24625 81 2.00008 81H72.989C73.743 81 74.3541 80.389 74.3541 79.6349C74.3541 78.8807 73.7428 78.2697 72.989 78.2697ZM25.208 8.19097H37.0394V12.7415H25.208V8.19097ZM20.6575 78.2697H19.7473V75.9945C19.7473 75.2403 19.136 74.6293 18.3822 74.6293C17.6283 74.6293 17.017 75.2403 17.017 75.9945V78.2697H16.1069V73.7192H20.6575V78.2697ZM29.7586 43.2303V45.5788V78.2697H23.3877V72.354C23.3877 71.5999 22.7764 70.9889 22.0226 70.9889H14.7418C13.9878 70.9889 13.3766 71.5999 13.3766 72.354V78.2697H7.00579V28.2135H29.7586V43.2303ZM31.1237 25.4832H19.7473V15.4719H42.5001V41.8651H32.489V37.3146H36.5846C37.3386 37.3146 37.9497 36.7036 37.9497 35.9494C37.9497 35.1953 37.3384 34.5843 36.5846 34.5843H32.489V30.0338H36.5846C37.3386 30.0338 37.9497 29.4228 37.9497 28.6686C37.9497 27.9145 37.3384 27.3035 36.5846 27.3035H32.489V26.8485C32.489 26.0942 31.8777 25.4832 31.1237 25.4832ZM55.2418 78.2697H32.489V45.5788V44.5956H55.2418V78.2697ZM67.9833 78.2697H57.972V43.2303V41.41C57.972 40.6558 57.3607 40.0448 56.6069 40.0448C55.8531 40.0448 55.2418 40.6558 55.2418 41.41V41.865H52.5115V41.41C52.5115 40.6558 51.9002 40.0448 51.1464 40.0448C50.3925 40.0448 49.7812 40.6558 49.7812 41.41V41.865H45.2307V14.1068V8.19097H67.9835V78.2697H67.9833Z"
        fill="#3240FF"
      />
      <path
        d="M38.4047 75.5396C39.1587 75.5396 39.7698 74.9283 39.7698 74.1745V48.6914C39.7698 47.9373 39.1585 47.3263 38.4047 47.3263C37.6508 47.3263 37.0396 47.9373 37.0396 48.6914V74.1746C37.0396 74.9285 37.6507 75.5396 38.4047 75.5396Z"
        fill="#3240FF"
      />
      <path
        d="M43.8654 75.5396C44.6194 75.5396 45.2305 74.9283 45.2305 74.1745V48.6914C45.2305 47.9373 44.6192 47.3263 43.8654 47.3263C43.1115 47.3263 42.5002 47.9373 42.5002 48.6914V74.1746C42.5001 74.9285 43.1114 75.5396 43.8654 75.5396Z"
        fill="#3240FF"
      />
      <path
        d="M49.3259 75.5396C50.0799 75.5396 50.6911 74.9283 50.6911 74.1745V48.6914C50.6911 47.9373 50.0798 47.3263 49.3259 47.3263C48.5721 47.3263 47.9608 47.9373 47.9608 48.6914V74.1746C47.9608 74.9285 48.5721 75.5396 49.3259 75.5396Z"
        fill="#3240FF"
      />
      <path
        d="M51.1462 17.2921C51.9002 17.2921 52.5113 16.6811 52.5113 15.9269V14.1068C52.5113 13.3526 51.9 12.7417 51.1462 12.7417C50.3924 12.7417 49.7811 13.3526 49.7811 14.1068V15.9271C49.7811 16.6811 50.3922 17.2921 51.1462 17.2921Z"
        fill="#3240FF"
      />
      <path
        d="M56.6069 17.2921C57.3609 17.2921 57.972 16.6811 57.972 15.9269V14.1068C57.972 13.3526 57.3607 12.7417 56.6069 12.7417C55.8531 12.7417 55.2418 13.3526 55.2418 14.1068V15.9271C55.2418 16.6811 55.8529 17.2921 56.6069 17.2921Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 17.2921C62.8216 17.2921 63.4327 16.6811 63.4327 15.9269V14.1068C63.4327 13.3526 62.8214 12.7417 62.0676 12.7417C61.3138 12.7417 60.7025 13.3526 60.7025 14.1068V15.9271C60.7025 16.6811 61.3136 17.2921 62.0676 17.2921Z"
        fill="#3240FF"
      />
      <path
        d="M51.1462 26.3932C51.9002 26.3932 52.5113 25.7822 52.5113 25.028V23.2078C52.5113 22.4536 51.9 21.8426 51.1462 21.8426C50.3924 21.8426 49.7811 22.4536 49.7811 23.2078V25.028C49.7811 25.7822 50.3922 26.3932 51.1462 26.3932Z"
        fill="#3240FF"
      />
      <path
        d="M56.6069 26.3932C57.3609 26.3932 57.972 25.7822 57.972 25.028V23.2078C57.972 22.4536 57.3607 21.8426 56.6069 21.8426C55.8531 21.8426 55.2418 22.4536 55.2418 23.2078V25.028C55.2418 25.7822 55.8529 26.3932 56.6069 26.3932Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 26.3932C62.8216 26.3932 63.4327 25.7822 63.4327 25.028V23.2078C63.4327 22.4536 62.8214 21.8426 62.0676 21.8426C61.3138 21.8426 60.7025 22.4536 60.7025 23.2078V25.028C60.7025 25.7822 61.3136 26.3932 62.0676 26.3932Z"
        fill="#3240FF"
      />
      <path
        d="M51.1462 35.4944C51.9002 35.4944 52.5113 34.8835 52.5113 34.1293V32.309C52.5113 31.5549 51.9 30.9439 51.1462 30.9439C50.3924 30.9439 49.7811 31.5549 49.7811 32.309V34.1293C49.7811 34.8833 50.3922 35.4944 51.1462 35.4944Z"
        fill="#3240FF"
      />
      <path
        d="M56.6069 35.4944C57.3609 35.4944 57.972 34.8835 57.972 34.1293V32.309C57.972 31.5549 57.3607 30.9439 56.6069 30.9439C55.8531 30.9439 55.2418 31.5549 55.2418 32.309V34.1293C55.2418 34.8833 55.8529 35.4944 56.6069 35.4944Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 35.4944C62.8216 35.4944 63.4327 34.8835 63.4327 34.1293V32.309C63.4327 31.5549 62.8214 30.9439 62.0676 30.9439C61.3138 30.9439 60.7025 31.5549 60.7025 32.309V34.1293C60.7025 34.8833 61.3136 35.4944 62.0676 35.4944Z"
        fill="#3240FF"
      />
      <path
        d="M12.9215 39.1348C13.6755 39.1348 14.2866 38.5239 14.2866 37.7697V35.9494C14.2866 35.1953 13.6753 34.5843 12.9215 34.5843C12.1676 34.5843 11.5563 35.1953 11.5563 35.9494V37.7697C11.5563 38.5237 12.1676 39.1348 12.9215 39.1348Z"
        fill="#3240FF"
      />
      <path
        d="M18.3822 39.1348C19.1362 39.1348 19.7473 38.5239 19.7473 37.7697V35.9494C19.7473 35.1953 19.136 34.5843 18.3822 34.5843C17.6283 34.5843 17.017 35.1953 17.017 35.9494V37.7697C17.017 38.5237 17.6282 39.1348 18.3822 39.1348Z"
        fill="#3240FF"
      />
      <path
        d="M23.8429 39.1348C24.5969 39.1348 25.208 38.5239 25.208 37.7697V35.9494C25.208 35.1953 24.5967 34.5843 23.8429 34.5843C23.089 34.5843 22.4777 35.1953 22.4777 35.9494V37.7697C22.4777 38.5237 23.0889 39.1348 23.8429 39.1348Z"
        fill="#3240FF"
      />
      <path
        d="M12.9215 48.236C13.6755 48.236 14.2866 47.625 14.2866 46.8708V45.0506C14.2866 44.2964 13.6753 43.6854 12.9215 43.6854C12.1676 43.6854 11.5563 44.2964 11.5563 45.0506V46.8708C11.5563 47.6248 12.1676 48.236 12.9215 48.236Z"
        fill="#3240FF"
      />
      <path
        d="M18.3822 48.236C19.1362 48.236 19.7473 47.625 19.7473 46.8708V45.0506C19.7473 44.2964 19.136 43.6854 18.3822 43.6854C17.6283 43.6854 17.017 44.2964 17.017 45.0506V46.8708C17.017 47.6248 17.6282 48.236 18.3822 48.236Z"
        fill="#3240FF"
      />
      <path
        d="M23.8429 48.236C24.5969 48.236 25.208 47.625 25.208 46.8708V45.0506C25.208 44.2964 24.5967 43.6854 23.8429 43.6854C23.089 43.6854 22.4777 44.2964 22.4777 45.0506V46.8708C22.4777 47.6248 23.0889 48.236 23.8429 48.236Z"
        fill="#3240FF"
      />
      <path
        d="M12.9215 57.3371C13.6755 57.3371 14.2866 56.7261 14.2866 55.9719V54.1516C14.2866 53.3975 13.6753 52.7865 12.9215 52.7865C12.1676 52.7865 11.5563 53.3975 11.5563 54.1516V55.9719C11.5563 56.7261 12.1676 57.3371 12.9215 57.3371Z"
        fill="#3240FF"
      />
      <path
        d="M18.3822 57.3371C19.1362 57.3371 19.7473 56.7261 19.7473 55.9719V54.1516C19.7473 53.3975 19.136 52.7865 18.3822 52.7865C17.6283 52.7865 17.017 53.3975 17.017 54.1516V55.9719C17.017 56.7261 17.6282 57.3371 18.3822 57.3371Z"
        fill="#3240FF"
      />
      <path
        d="M23.8429 57.3371C24.5969 57.3371 25.208 56.7261 25.208 55.9719V54.1516C25.208 53.3975 24.5967 52.7865 23.8429 52.7865C23.089 52.7865 22.4777 53.3975 22.4777 54.1516V55.9719C22.4777 56.7261 23.0889 57.3371 23.8429 57.3371Z"
        fill="#3240FF"
      />
      <path
        d="M12.9215 66.4382C13.6755 66.4382 14.2866 65.8272 14.2866 65.0731V63.2528C14.2866 62.4986 13.6753 61.8876 12.9215 61.8876C12.1676 61.8876 11.5563 62.4986 11.5563 63.2528V65.0731C11.5563 65.8272 12.1676 66.4382 12.9215 66.4382Z"
        fill="#3240FF"
      />
      <path
        d="M18.3822 66.4382C19.1362 66.4382 19.7473 65.8272 19.7473 65.0731V63.2528C19.7473 62.4986 19.136 61.8876 18.3822 61.8876C17.6283 61.8876 17.017 62.4986 17.017 63.2528V65.0731C17.017 65.8272 17.6282 66.4382 18.3822 66.4382Z"
        fill="#3240FF"
      />
      <path
        d="M23.8429 66.4382C24.5969 66.4382 25.208 65.8272 25.208 65.0731V63.2528C25.208 62.4986 24.5967 61.8876 23.8429 61.8876C23.089 61.8876 22.4777 62.4986 22.4777 63.2528V65.0731C22.4777 65.8272 23.0889 66.4382 23.8429 66.4382Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 44.5956C62.8216 44.5956 63.4327 43.9846 63.4327 43.2304V41.4101C63.4327 40.656 62.8214 40.045 62.0676 40.045C61.3138 40.045 60.7025 40.656 60.7025 41.4101V43.2304C60.7025 43.9844 61.3136 44.5956 62.0676 44.5956Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 53.6967C62.8216 53.6967 63.4327 53.0857 63.4327 52.3315V50.5113C63.4327 49.7571 62.8214 49.1461 62.0676 49.1461C61.3138 49.1461 60.7025 49.7571 60.7025 50.5113V52.3315C60.7025 53.0855 61.3136 53.6967 62.0676 53.6967Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 62.7977C62.8216 62.7977 63.4327 62.1868 63.4327 61.4326V59.6123C63.4327 58.8582 62.8214 58.2472 62.0676 58.2472C61.3138 58.2472 60.7025 58.8582 60.7025 59.6123V61.4326C60.7025 62.1866 61.3136 62.7977 62.0676 62.7977Z"
        fill="#3240FF"
      />
      <path
        d="M62.0676 71.8989C62.8216 71.8989 63.4327 71.2879 63.4327 70.5337V68.7135C63.4327 67.9593 62.8214 67.3483 62.0676 67.3483C61.3138 67.3483 60.7025 67.9593 60.7025 68.7135V70.5337C60.7025 71.2877 61.3136 71.8989 62.0676 71.8989Z"
        fill="#3240FF"
      />
      <path
        d="M37.9495 21.3876C37.9495 20.6334 37.3382 20.0225 36.5844 20.0225H25.663C24.909 20.0225 24.2979 20.6334 24.2979 21.3876C24.2979 22.1417 24.9091 22.7527 25.663 22.7527H36.5844C37.3384 22.7527 37.9495 22.1417 37.9495 21.3876Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default BuildingBigIcon;
