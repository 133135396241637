import React from "react";

const FastSmallMessageIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6239 5.00166L9.99776 0.375535C9.49705 -0.125178 8.6823 -0.125178 8.18159 0.375535L0.96208 7.59508C0.711738 7.84545 0.586553 8.1743 0.586553 8.50316C0.586553 8.83202 0.711738 9.16088 0.96208 9.41125L2.12279 10.572L0.848291 11.8465C0.791074 11.9037 0.76248 11.9787 0.76248 12.0536C0.76248 12.1286 0.791074 12.2036 0.848291 12.2608C0.962695 12.3752 1.1482 12.3752 1.26261 12.2608L2.53711 10.9863L3.06794 11.5171L0.0858105 14.4993C0.0285937 14.5565 0 14.6315 0 14.7065C0 14.7814 0.0285937 14.8564 0.0858105 14.9136C0.200215 15.028 0.385723 15.028 0.500156 14.9136L3.48231 11.9315L5.58817 14.0373C6.08892 14.538 6.90363 14.538 7.40435 14.0373L14.6239 6.8178C14.8664 6.57528 15 6.25275 15 5.90974C15 5.56671 14.8664 5.24421 14.6239 5.00166ZM6.28339 13.7936C6.18062 13.7609 6.08385 13.7044 6.00246 13.623L1.3764 8.99693C1.19385 8.81435 1.13432 8.55516 1.19657 8.32205H6.28339V13.7936ZM5.22439 7.73611L6.09313 6.86737C6.20754 6.75297 6.20757 6.56746 6.09313 6.45305C5.9787 6.33865 5.79322 6.33862 5.67882 6.45305L4.39573 7.73611H3.54217L6.19078 5.0875C6.30516 4.97309 6.30519 4.78759 6.19078 4.67315C6.07638 4.55875 5.89087 4.55875 5.77644 4.67315L2.71354 7.73611H1.64971L8.32263 1.06316V7.03782C8.32263 7.42287 8.00938 7.73611 7.62434 7.73611H5.22439ZM14.2096 6.40351L6.99003 13.623C6.95279 13.6603 6.91213 13.6919 6.86936 13.719V8.32205H7.62436C8.33247 8.32205 8.90859 7.74595 8.90859 7.03782V6.28281H12.2667L10.5714 7.97816C10.5142 8.03535 10.4856 8.11035 10.4856 8.18532C10.4856 8.26032 10.5142 8.33526 10.5714 8.39248C10.6858 8.50688 10.8713 8.50688 10.9857 8.39248L13.0952 6.28304C13.0952 6.28299 13.0953 6.2829 13.0954 6.28284H14.3061C14.2787 6.32599 14.2466 6.36651 14.2096 6.40351ZM8.90856 5.69687V0.610056C9.14171 0.5478 9.4009 0.607332 9.58345 0.78988L14.2095 5.41597C14.2896 5.49598 14.3476 5.59205 14.381 5.69687H8.90856Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default FastSmallMessageIcon;
