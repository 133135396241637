import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "../../reducers";

const SetAuth = () => {
  const { isLoadingUser, isLoggedIn } = useSelector(
    (state: RootState) => state.authReducer
  );
  const [iframeIsLoaded, setIsLoaded] = useState(false);
  const postCrossDomainMessage = (msg) => {
    var ifrm = document.getElementById('ifr');
    ifrm = ifrm.contentWindow || ifrm.contentDocument.document || ifrm.contentDocument;
    ifrm.postMessage(msg, "*");
  }

  useEffect(() => {
    if(iframeIsLoaded){
      var postMsg = {isLoggedIn: isLoggedIn};
      postCrossDomainMessage(JSON.stringify(postMsg));
    }
  }, [isLoggedIn, iframeIsLoaded])
  return (
    <iframe style={{display: "none"}} onLoad={(e) => setIsLoaded(true)} src={process.env.MIX_SITE_URL+ "/get-auth.html"} id="ifr"></iframe>
  );
};

export default SetAuth;
