import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import "./landing-navbar.scss";
import Logo from "../../svg-components/Logo";
import { NavLink, Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Affix, Drawer } from "antd";

const { Header } = Layout;
const LandingNavbar = ({ history }: any) => {
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  );
  const [drawerMobileOpened, setDrawerMobileOpened] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    history.listen((location: any) => {
      setTimeout(() => {
        if (drawerMobileOpened) {
          setDrawerMobileOpened(false);
        }
      }, 400);
    });
  }, [history]);
  return (
    <>
      <Affix>
        <Header
          className={isScrolled ? "landing-navbar is_scrolled" : "landing-navbar"}
        >
          <div className="max-width-container">
            <div className="flex-navbar">
              <a href={process.env.MIX_SITE_URL}>
                <div className="logo-landing">
                  <Logo />
                </div>
              </a>

              {isLoggedIn ? (
                <div className="auth-btns">
                  <NavLink
                    className={"landing-auth-btn"}
                    activeClassName={"active"}
                    to={"/"}
                    exact
                  >
                    Dashboard
                  </NavLink>
                </div>
              ) : (
                <div className="auth-btns">
                  <NavLink
                    className={"landing-auth-btn"}
                    activeClassName={"active"}
                    to={"/login"}
                    exact
                  >
                    Sign in
                  </NavLink>
                  <NavLink
                    className={"landing-auth-btn"}
                    activeClassName={"active"}
                    to={"/signup"}
                    exact
                  >
                    Je me domicile
                  </NavLink>
                </div>
              )}

              <button
                onClick={() => setDrawerMobileOpened(!drawerMobileOpened)}
                className={"menu-drawer-icon"}
              >
                <MenuOutlined />
              </button>
            </div>
          </div>
        </Header>
        <Drawer
          placement="right"
          width={"80%"}
          closeIcon={<CloseOutlined />}
          onClose={() => setDrawerMobileOpened(false)}
          className={"drawer-mobile-landing"}
          visible={drawerMobileOpened}
          title={""}
        >
          {isLoggedIn ? (
            <div className="auth-btns">
              <NavLink
                className={"landing-auth-btn"}
                activeClassName={"active"}
                to={"/dashboard"}
                exact
              >
                Dashboard
              </NavLink>
            </div>
          ) : (
            <div className="auth-btns">
              <NavLink
                className={"landing-auth-btn"}
                activeClassName={"active"}
                to={"/login"}
                exact
              >
                Sign in
              </NavLink>
              <NavLink
                className={"landing-auth-btn"}
                activeClassName={"active"}
                to={"/signup"}
                exact
              >
                Je me domicile
              </NavLink>
            </div>
          )}
        </Drawer>
      </Affix>
    </>
  );
};

export default withRouter(LandingNavbar);
