import React, { useEffect, useState } from "react";
import { Table, Row, Col, Menu, Dropdown, DatePicker, Select, Form, Button, Spin, Badge } from "antd";
import "./mails-list.scss";
import DownloadSmallIcon from "../../../svg-components/icons/DownloadSmallIcon";
import CalendarSmallIcon from "../../../svg-components/icons/CalendarSmallIcon"
import FilterValidation from "../../../svg-components/FilterValidation"
import { useDispatch, useSelector } from "react-redux";
import { getMails, filterMails, exportMails, getMail } from "../../../actions/mails-actions/actions";
import { RootState } from "../../../reducers";
import $ from "jquery";
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

let lastIndex: 0;
const updateIndex = () => {
  lastIndex++
  return lastIndex
}
const { RangePicker } = DatePicker;

const rowSelection = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === "Disabled User", // Column configuration not to be checked
    name: record.name,
  }),
};

const categoryOptions = [{ value: 'Contrat' }, { value: 'Banque' }, { value: 'Fournisseur' }, { value: 'Administratif' }];
const statusOptions = [{ value: 'Courrier collecté' }, { value: 'Réexpédié' }, { value: 'Retrait effectué' }, { value: 'Mise à disposition en cours' }, { value: 'Disponible en retrait' }, { value: 'Numérisation en cours' }];

// Component Validation Filter
const Logo = () => {
  return (<div className="validation-filter-container">
    <button className={"validation-filter"} type="submit" style={{ cursor: "pointer" }}>
      <FilterValidation />
    </button>
  </div>
  )
}
// handle select category abbreviation
const handleCategoryabbreviation = () => {
  let categoryChoice1 = null;
  let categoryChoice2 = null;
  let categoryChoice3 = null;
  let categoryChoice4 = null;
  $(document).ready(() => {
    const selectionItem = $(".ant-select-selection-item");
    for (var i = 0; i < selectionItem.length; i++) {
      if (selectionItem[i].innerText === "Contrat") {
        categoryChoice1 = selectionItem[i].innerHTML = "CT";
      } else if (selectionItem[i].innerText === "Banque") {
        categoryChoice2 = selectionItem[i].innerHTML = "BK";
      } else if (selectionItem[i].innerText === "Fournisseur") {
        categoryChoice3 = selectionItem[i].innerHTML = "FR";
      } else if (selectionItem[i].innerText === "Administratif") {
        categoryChoice4 = selectionItem[i].innerHTML = "AD";
      }
    }
  });
};
// handle select status abbreviation
const handleStatusabbreviation = () => {
  let statusChoice1 = null;
  let statusChoice2 = null;
  let statusChoice3 = null;
  let statusChoice4 = null;
  let statusChoice5 = null;
  let statusChoice6 = null;
  $(document).ready(() => {
    const selectionItem = $(".ant-select-selection-item");
    for (var i = 0; i < selectionItem.length; i++) {
      if (selectionItem[i].innerText === "Courrier collecté") {
        statusChoice1 = selectionItem[i].innerHTML = "CC";
      } else if (selectionItem[i].innerText === "Réexpédié") {
        statusChoice2 = selectionItem[i].innerHTML = "R";
      } else if (selectionItem[i].innerText === "Retrait effectué") {
        statusChoice3 = selectionItem[i].innerHTML = "RE";
      } else if (selectionItem[i].innerText === "Mise à disposition en cours") {
        statusChoice4 = selectionItem[i].innerHTML = "MD";
      } else if (selectionItem[i].innerText === "Disponible en retrait") {
        statusChoice5 = selectionItem[i].innerHTML = "DR";
      } else if (selectionItem[i].innerText === "Numérisation en cours") {
        statusChoice6 = selectionItem[i].innerHTML = "NC";
      }
    }
  });
};

// Component Parent
const MailsList = () => {
  const [selectionType] = useState("checkbox");
  const [StartDate, setStartDate] = useState("Start Date");
  const [EndDate, setEndDate] = useState("End Date");
  const [StartDateStr, setStartDateStr] = useState("Start Date");
  const [EndDateStr, setEndDateStr] = useState("End Date");
  const [mailsList, setMailsList] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [page, setPage] = useState(1);
  const [isSearching, setIsSearching] = useState(false);
  const [filter, setFilter] = useState(null);
  const dispatch = useDispatch();
  const mails = useSelector(
    (state: RootState) => state.mailsReducer.mails
  );
  const isLoadingMails = useSelector(
    (state: RootState) => state.mailsReducer.isLoadingMails
  );
  const downloadMail = async (e, mail) => {
    await dispatch(getMail(mail.id));
  }
  const columns: any = [
    {
      title: "Expéditeur",
      dataIndex: "sender",
      key: "sender",
    },
    {
      title: "Date de réception",
      dataIndex: "date",
      key: "date",
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => (new Date(a.date)) > (new Date(b.date)),
      // sortDirections: ['descend', 'ascend'],
      render: (text: string) => (<span>{moment(text).format('DD/MM/YYYY à HH:mm')}</span>),
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Etat",
      dataIndex: "status",
      key: "status",
      responsive: ['md'],
    },
    {
      title: "Lu",
      dataIndex: "seen",
      key: "seen",
      responsive: ['md'],
      render: (text: any, record: any) => (
        <>
          <Badge offset={[5, 0]} status={record.seen ? "success" : "error"} title={record.seen ? "Lu" : "Non Lu"} />
        </>
      )
    },
    {
      title: "",
      dataIndex: "file",
      key: "file",
      responsive: ['md'],
      render: (text: any, record: any) => (
        <>
          {record.file ? (
            <button onClick={(e) => downloadMail(e, record)} className={"table-download-icon-btn"}>
              <DownloadSmallIcon />
            </button>
          ) : (
            <Button className={"table-download-icon-btn disabled"} >
              <DownloadSmallIcon />
            </Button>
          )
          }
        </>
      ),
    },
  ];
  const format = "DD-MM-YYYY";
  const onFinish = (values: any) => {
    setPage(1);
    if (values.startDate || values.endDate || values.category || values.status) {
      values.page = page;
      values.startDate ? values.startDate = values.startDate.format("YYYY-MM-DD") : null;
      values.endDate ? values.endDate = values.endDate.format("YYYY-MM-DD") : null;
      setIsSearching(true);
      setFilter(values);
    } else {
      setIsSearching(false);
      setFilter({ page: page });
    }
  }
  const onStartChange = (date, dateString) => {
    let startDate = new Date(date);
    let startDateString = startDate.toDateString();
    let startDateStringSlice = startDateString.slice(4);
    setStartDate(dateString);
    setStartDateStr(startDateStringSlice);
  }
  const onEndChange = (date, dateString) => {
    let endDate = new Date(date);
    let endDateString = endDate.toDateString();
    let endDateStringSlice = endDateString.slice(4);
    setEndDate(dateString);
    setEndDateStr(endDateStringSlice);
  }
  const fetchMails = () => {
    if (isSearching) {
      dispatch(filterMails(filter));
    } else {
      dispatch(getMails(page));
    }
  }
  useEffect((): any => {
    fetchMails();
  }, [filter])
  useEffect(() => {
    setMailsList([]);
    if (mails && mails.data && mails.data.length > 0) {
      mails.data.map((mail, i) => {
        setMailsList(prevState => ([...prevState, { ...mail, key: mail.id.toString() }]))
      })
    }
  }, [mails])

  const handlePage = (current) => {
    setPage(current);
    setFilter((prevFilter) => ({ ...prevFilter, page: current }));
  }
  const handleExport = async (e, type) => {
    e.preventDefault();
    if (mailsList.length > 0) {
      await dispatch(exportMails({ type: type, list: filter }))
    }
  }
  const tableLoading = {
    spinning: isLoadingMails,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  const menu = (
    <Menu className={"exportation-types"}>
      <Menu.Item key="0">
        <a href="" style={{ cursor: mailsList.length > 0 ? "pointer" : "not-allowed" }} onClick={(e) => handleExport(e, 'pdf')} target="_blank">Format PDF</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="" style={{ cursor: mailsList.length > 0 ? "pointer" : "not-allowed" }} onClick={(e) => handleExport(e, 'xlsx')} target="_blank">Microsoft Excel</a>
      </Menu.Item>
      <Menu.Item key="2">
        <a href="" style={{ cursor: mailsList.length > 0 ? "pointer" : "not-allowed" }} onClick={(e) => handleExport(e, 'csv')} target="_blank">Excel CSV</a>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="mails-list">
      <Row style={{ width: "100%" }}>
        <Col xs={24} md={24} lg={24}>
          <div className="header-mail">
            <h1 className={"title"}>Mes Courriers</h1>
            <Dropdown overlay={menu} trigger={["click"]}>
              <button className="dropdown-blue-btn-with-icon" >
                <div className="text">Exporter la selection</div>
                <div className="svg-container">
                  <DownOutlined />
                </div>
              </button>
            </Dropdown>
          </div>
          <Form onFinish={onFinish} >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} md={12} lg={5}>
                <Form.Item
                  name="startDate"
                >
                  <DatePicker
                    size={"large"}
                    format={format}
                    suffixIcon={<CalendarSmallIcon />}
                    className={"start"}
                    placeholder={'Date début'}
                    allowClear={false}
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    showToday={false}
                    onChange={onStartChange}
                  >
                  </DatePicker>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12} lg={5}>
                <Form.Item
                  name="endDate"
                >
                  <DatePicker
                    size={"large"}
                    format={format}
                    suffixIcon={<CalendarSmallIcon />}
                    placeholder={'Date fin'}
                    allowClear={false}
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    showToday={false}
                    onChange={onEndChange}
                  >
                  </DatePicker>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12} lg={5}>
                <Form.Item
                  name="category"
                >
                  <Select
                    mode="multiple"
                    placeholder={"Catégorie"}
                    options={categoryOptions}
                    style={{ width: "100%" }}
                    showSearch={false}
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    showArrow
                    onSelect={handleCategoryabbreviation}
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={12} lg={6}>
                <Form.Item
                  name="status"
                >
                  <Select
                    mode="multiple"
                    placeholder={"Etat"}
                    options={statusOptions}
                    showSearch={false}
                    style={{ width: "100%" }}
                    dropdownStyle={{ color: 'white' }}
                    showArrow
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    onSelect={handleStatusabbreviation}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} md={2} lg={2}>
                <Logo />
              </Col>
            </Row>
          </Form>
          <Table
            rowKey={"id"}
            loading={tableLoading}
            size={"large"}
            // rowSelection={{
            //   //@ts-ignore
            //   type: selectionType,
            //   ...rowSelection,
            // }}
            pagination={{
              defaultCurrent: page,
              defaultPageSize: 4,
              total: mails.total ? mails.total : 0,
              onChange: handlePage
            }}
            locale={{ emptyText: <em style={{ color: "#bfbfbf" }}>Vide</em> }}
            columns={columns}
            dataSource={mailsList}
          />
        </Col>
      </Row>
    </div>
  );
};

export default MailsList;
