/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";


function changePackChoosedRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/update-chosed-pack",
    data: body,
  });
}

function logoutRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/logout",
    data: null,
  });
}

function signinRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "signin",
    data: body,
  });
}

function signupRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "signup",
    data: body,
  });
}

function getAuthUserRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/user",
  });
}

function updateNotifRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/user/notification",
    data: body,
  });
}

function updateParsRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/user/update",
    data: body,
  });
}

function sendVerificationCodeRequest(id :number) {
  return axiosInstance({
    method: "get",
    url: `auth/user/send-code`,
  });
}

function sendVerificationEmailRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: `auth/user/send-email`,
  });
}

function verifyPhoneRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/user/verify/phone",
    data: body,
  });
}

function verifyEmailRequest(body: any) {
  return axiosInstance({
    method: "get",
    url: `user/verify/email/${body.token}`,
  });
}

function resetPasswordRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "user/password/reset",
    data: body,
  });
}

function checkReinitialisationLinkRequest(code: any) {
  return axiosInstance({
    method: "get",
    url: `user/password/reset/${code}`,
  });
}

function changePasswordRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: `user/new-password`,
    data: body,
  });
}

const AuthServices = {
  signinRequest,
  signupRequest,
  logoutRequest,
  getAuthUserRequest,
  changePackChoosedRequest,
  updateNotifRequest,
  updateParsRequest,
  sendVerificationCodeRequest,
  verifyPhoneRequest,
  sendVerificationEmailRequest,
  verifyEmailRequest,
  resetPasswordRequest,
  checkReinitialisationLinkRequest,
  changePasswordRequest
};

export default AuthServices;
