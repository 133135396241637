import {
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  EMPTY_CART_REQUEST,
  EMPTY_CART_SUCCESS
} from "./types";

export const updateCart = (data: any) => {
  return async (dispatch: any) => {
    await dispatch({ type: UPDATE_CART_REQUEST });
    await dispatch({
      type: UPDATE_CART_SUCCESS, payload: {
        cart: data
      }
    });
  };
};
export const emptyCart = () => {
  return async (dispatch: any) => {
    await dispatch({ type: EMPTY_CART_REQUEST });
    await dispatch({
      type: EMPTY_CART_SUCCESS
    });
  };
};
