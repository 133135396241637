import React from "react";
import { Dropdown, Menu, Badge, Button } from "antd";
import { useHistory } from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import MessageSmallIcon from "../../svg-components/icons/MessageSmallIcon";
import DropDownDiscussionCard from "./DropDownDiscussionCard";
import { EMPTY_DISCUSSIONS } from "../../actions/discussions-actions/types";

const NavbarMessagesDropDown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const goToAll = async () => {
    await dispatch({ type: EMPTY_DISCUSSIONS });
    history.push('/messages')
  }
  const newUnseenDiscussions = useSelector(
    (state: RootState) => state.discussionsReducer.newUnseenDiscussions
  );
  const totalNew = useSelector(
    (state: RootState) => state.discussionsReducer.totalNew
  );
  const menu = (
    <Menu>
      {newUnseenDiscussions && newUnseenDiscussions.length > 0 ? (
        <>
          {newUnseenDiscussions.map((discussion, i) => (
            <Menu.Item key={discussion.id}>
              <DropDownDiscussionCard
                discussion={discussion}
              />
            </Menu.Item>
          ))}
          <Menu.Divider />
          <Menu.Item key="all">
            <Button onClick={goToAll} className={"link-to-all-messages"}>Tous les messages</Button>
          </Menu.Item>
        </>
      ) : (
        <>
          <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucun message</em>
          <Menu.Divider />
          <Menu.Item key="all">
            <Button onClick={goToAll} className={"link-to-all-messages"}>Tous les messages</Button>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  return (
    <Dropdown placement={"bottomCenter"} overlay={menu} trigger={["click"]} overlayClassName={"messages-dropdown"}>
      <a
        className="ant-dropdown-link btn-icon-navbar"
        onClick={(e) => e.preventDefault()}
      >
        <Badge count={totalNew > 5 ? '+5' : totalNew}>
          <div className={"icon-border"}>
            <MessageSmallIcon />
          </div>
        </Badge>
      </a>
    </Dropdown>
  );
};

export default NavbarMessagesDropDown;
