import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, message } from "antd";
import UploadFile from "../upload-file-modal/UploadFile";
import { RootState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../actions/profile-actions/actions";

const OfficialDocumentsForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm()
  const [hasChanges, setHasChanges] = useState(false);
  const profile = useSelector((state: RootState) => state.profileReducer.profile);

  const onUpdateFiles = (name, value) => {
    if (value) {
      form.setFieldsValue({ [name]: value })
      setHasChanges(true)
    }
  }
  useEffect(() => {
    if (props.isSubmited) {
      form.submit()
      props.unSubmit();
    }
  }, [props.isSubmited])
  const onFormFinish = (values) => {
    if (!values.patent && !values.national_entre_reg) {
      message.warn('Veuillez ajouter votre Patente et/ou Registre national des entreprises', 3)
    }else{
      values.progress = 100
      props.onSubmit(hasChanges, values)
    }
  }
  return (
    <Form
      name="basic"
      className={"form-profile"}
      form={form}
      onFinish={onFormFinish}
    >
        <Form.Item
          name="patent"
          initialValue={profile ? profile.patent : null}
        >
          <UploadFile name="patent" docName={'Patente'} parentSetFileName={(value) => onUpdateFiles('patent', value)} />
        </Form.Item>
        <Form.Item
          name="national_entre_reg"
          initialValue={profile ? profile.national_entre_reg : null}
        >
          <UploadFile name="national_entre_reg" docName={'Registre national des entreprises'} parentSetFileName={(value) => onUpdateFiles('national_entre_reg', value)} />
        </Form.Item>
    </Form>
  );
};

export default OfficialDocumentsForm;
