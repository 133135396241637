import React, { useEffect } from "react";
import "../auth-pages.scss";
import "./signup-page.scss";
import SignUpForm from "../../../components/auth/SignUpForm";

const SignupPage = (props) => {
  const email = sessionStorage.getItem('email');
  useEffect(() => {
    document.title = `Je me domicile | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/signup`);
    }
  })
  return (
    <div className={"auth-page signup-page"}>
      <div className="max-width-container">
        <div className="container-center">{<SignUpForm email={email ?? null}/>}</div>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div>
  );
};

export default SignupPage;
