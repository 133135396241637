import {AnyAction, Reducer} from "redux";
import {
  GET_DASHBOARD_INFO_FAILURE,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_DASHBOARD_CONTACTS_FAILURE,
  GET_DASHBOARD_CONTACTS_REQUEST,
  GET_DASHBOARD_CONTACTS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_DASHBOARD_BILLS_FAILURE,
  GET_DASHBOARD_BILLS_REQUEST,
  GET_DASHBOARD_BILLS_SUCCESS,
  GET_DASHBOARD_LATEST_BILLS_FAILURE,
  GET_DASHBOARD_LATEST_BILLS_REQUEST,
  GET_DASHBOARD_LATEST_BILLS_SUCCESS,
  ADD_CONTACT_REQUEST,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_FAILURE,
  } from "../actions/dashboard-actions/types";

  const INITIAL_STATE: any = {
    infos: [],
    bills: [],
    subscriptions: [],
    latestBills: [],
    contacts: null,
    isLoadingSubscriptions: false,
    isLoadingBills: false,
    isLoadingContacts: false,
  };

  export const dashboardReducer: Reducer<any, AnyAction> = (
    state: any = INITIAL_STATE,
    action
  ): boolean => {
    switch (action.type) {
      case GET_DASHBOARD_INFO_REQUEST:
        return {
          ...state,
        };
      case GET_DASHBOARD_INFO_SUCCESS:
        return {
          ...state,
          infos: action.payload.infos,
        };
      case GET_DASHBOARD_INFO_FAILURE:
        return {
          ...state,
        };
      // LOAD SUBSCRIPTIONS
        case GET_SUBSCRIPTIONS_REQUEST:
          return {
            ...state,
            isLoadingSubscriptions: true
          };
        case GET_SUBSCRIPTIONS_SUCCESS:
          return {
            ...state,
            subscriptions: action.payload.subscriptions,
            isLoadingSubscriptions: false
          };
        case GET_SUBSCRIPTIONS_FAILURE:
          return {
            ...state,
            isLoadingSubscriptions: false

          };
      // LOAD BILLS
        case GET_DASHBOARD_BILLS_REQUEST:
          return {
            ...state,
            isLoadingBills: true
          };
        case GET_DASHBOARD_BILLS_SUCCESS:
          return {
            ...state,
            bills: action.payload.bills,
            isLoadingBills: false
          };
        case GET_DASHBOARD_BILLS_FAILURE:
          return {
            ...state,
            isLoadingBills: false

          };
        case GET_DASHBOARD_LATEST_BILLS_REQUEST:
          return {
            ...state,
            isLoadingBills: true
          };
        case GET_DASHBOARD_LATEST_BILLS_SUCCESS:
          return {
            ...state,
            latestBills: action.payload.bills,
            isLoadingBills: false

          };
        case GET_DASHBOARD_LATEST_BILLS_FAILURE:
          return {
            ...state,
            isLoadingBills: false
          };
        // LOAD CONTACTS
        case GET_DASHBOARD_CONTACTS_REQUEST:
          return {
            ...state,
            isLoadingContacts: true
          };
        case GET_DASHBOARD_CONTACTS_SUCCESS:
          return {
            ...state,
            contacts: action.payload.contacts,
            isLoadingContacts: false
          };
        case GET_DASHBOARD_CONTACTS_FAILURE:
          return {
            ...state,
            isLoadingContacts: false
          };
        case ADD_CONTACT_REQUEST:
          return {
            ...state,
            isLoadingContacts: true
          };
        case ADD_CONTACT_SUCCESS:
          return {
            ...state,
            contacts: action.payload.contacts,
            isLoadingContacts: false
          };
        case ADD_CONTACT_ERROR:
          return {
            ...state,
            isLoadingContacts: false
          };
        case REMOVE_CONTACT_REQUEST:
          return {
            ...state,
            isLoadingContacts: true
          };
        case REMOVE_CONTACT_SUCCESS:
          return {
            ...state,
            contacts: action.payload.contacts,
            isLoadingContacts: false
          };
        case REMOVE_CONTACT_FAILURE:
          return {
            ...state,
            isLoadingContacts: false
          };
      default:
        return state;
    }
  };
