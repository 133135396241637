import React, {Component, useState, useEffect} from 'react'
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { List, Badge, Avatar, Skeleton } from 'antd';
import $ from 'jquery';
import UrgentNotifIcon from '../../svg-components/icons/notifications/UrgentNotifIcon';
import ReminderNotifIcon from '../../svg-components/icons/notifications/ReminderNotifIcon';
import NormalNotifIcon from '../../svg-components/icons/notifications/NormalNotifIcon';
import SeenNotifIcon from '../../svg-components/icons/notifications/SeenNotifIcon';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
import { useHistory } from 'react-router';
moment.locale('fr')

interface NotificationCardProps {
  notification: any;
}

const NotificationCard : React.FC<NotificationCardProps> = ({notification}) =>  {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const isLoading = useSelector((state: RootState) => state.notificationsReducer.isLoadingNotifications);
  const [isSelected, setIsSelected] = useState(false);
  const [hasUnseen, setHasUnseen] = useState(false);

  const onSelectNotification = (notification) => {
    $('.notification-wrapper').removeClass('selected')
    setIsSelected(true);
    setHasUnseen(false)
    if(notification.content.includes('courriel')){
      history.push('/mails');
    }
  }
  const getPriority = () => {
    let prefix = null;
    switch (notification.priority) {
      case "urgent":
        prefix = "Urgent : "
        break;
      case "reminder":
        prefix = "Rappel : "
        break;
      default:
        prefix = ""
        break;
    }
    return prefix
  }
  const getIcon = () => {
    let icon = null;
    switch (notification.priority) {
      case "urgent":
        icon = <UrgentNotifIcon/>
        break;
      case "reminder":
        icon = <ReminderNotifIcon/>
        break;
      default:
        icon = <NormalNotifIcon/>
        break;
    }
    return icon
  }
  return (
    <List.Item className={isSelected ? "notification-wrapper selected" : "notification-wrapper"} onClick={() => onSelectNotification(notification)} id={`notification-${notification.id}`}>
      <Skeleton avatar title={false} loading={isLoading} active>
        <div className={"notification-body"}>
          <div className={"notification-content-wrapper"}>
            <div className={"notifiaction-icon"}>
              {getIcon()}
            </div>
            <div>
              <div className={"notification-content"}>
                {notification.priority !== "normal" ? (
                  <span>{getPriority()}</span>
                ) : null}
                <div className={"notification-content-body"} dangerouslySetInnerHTML={{ __html: notification.content }} ></div>
              </div>
              <div className={"notification-date"}>
                {moment(notification.created_at).fromNow(true)}
              </div>
            </div>
          </div>
          <div className={"notification-status"}>
            <SeenNotifIcon/>
              {/* {notification.status == "seen" ? (
                ) : (
                <SeenNotifIcon/>
              )} */}
          </div>
        </div>
      </Skeleton>
    </List.Item>
  )
}
export default NotificationCard;
