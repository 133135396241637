/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getProfileRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/profile",
    data: null,
  });
}

function deleteFileRequest(file: string) {
  return axiosInstance({
    method: "post",
    url: "auth/delete-file",
    data: {file : file},
  });
}

function getMyContractRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/profile/contract/get",
    responseType: 'blob',
    data: null,
  });
}

function getBillRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: `auth/bills/get/${id}`,
    responseType: 'blob',
  });
}

function updateProfile(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/profile",
    data: body,
  });
}

function getContractStatusRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/profile/contract/check",
  });
}

function updateBillRequest(body: any) {
  return axiosInstance({
    method: "put",
    url: `auth/bills/${body.bill_id}`,
    data: body,
  });
}

// PAYMENT

function getPaymentToken(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/payment/initiate",
    data: body,
  });
}

function applyCouponRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/payment/coupon",
    data: body,
  });
}

function getPaymentStatus(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/payment/checkStatus",
    data: body,
  });
}

// END PAYMENT

function subscribeDomiciliationRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/profile/subscribe/domiciliation",
    data: body,
  });
}

function subscribePhoneSolution(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/profile/subscribe/phone-solution",
    data: body,
  });
}

function subscribeMeetingSolution(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/profile/subscribe/meeting-solution",
    data: body,
  });
}

const ProfileServices = {
  getProfileRequest,
  getContractStatusRequest,
  getMyContractRequest,
  updateBillRequest,
  getPaymentToken,
  getPaymentStatus,
  applyCouponRequest,
  subscribeDomiciliationRequest,
  subscribePhoneSolution,
  subscribeMeetingSolution,
  updateProfile,
  deleteFileRequest,
  getBillRequest
};

export default ProfileServices;
