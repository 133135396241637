/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";


function getDiscussionsRequest(page: number) {
  return axiosInstance({
    method: "get",
    url: "auth/discussion",
    params: {
      page: page
    }
  });
}

function filterDiscussionsRequest(data: any) {
  return axiosInstance({
    method: "get",
    url: "auth/discussion/search/get",
    params: data
  });
}

function getTeamRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/discussion/team/get",
  });
}

function getNewUnseenRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/discussion/messages/unseen",
  });
}

function getDiscussionRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: `auth/discussion/${id}`,
  });
}

function startDiscussionRequest(discussionWith: number) {
  return axiosInstance({
    method: "post",
    url: `auth/discussion`,
    data: {to: discussionWith}
  });
}

function deleteDiscussionRequest(id: number) {
  return axiosInstance({
    method: "delete",
    url: `auth/discussion/${id}`,
  });
}

function sendMessageRequest(data: any, id: number) {
  return axiosInstance({
    method: "post",
    url: `auth/discussion/${id}/message/add`,
    data: data
  });
}

function getAttachmentRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: "auth/discussions/attachment/get/" + id,
    responseType: 'blob',
  });
}

const DiscussionsServices = {
  getDiscussionsRequest,
  filterDiscussionsRequest,
  getDiscussionRequest,
  getTeamRequest,
  getNewUnseenRequest,
  deleteDiscussionRequest,
  startDiscussionRequest,
  sendMessageRequest,
  getAttachmentRequest
};

export default DiscussionsServices;
