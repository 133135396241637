import React, {useEffect, useState} from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import {addContact} from "../../../actions/dashboard-actions/actions";
import { RootState } from "../../../reducers";
import "./add-contact-modal.scss";
import { Link, Redirect } from "react-router-dom";
import { Modal, Spin, Button, Radio, Input, Form } from 'antd';
import CloseRed from "../../../svg-components/icons/CloseRed";
import { LoadingOutlined } from '@ant-design/icons';
import $ from "jquery";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AddContactModal = ({history, visible, contactType, abort}:any) => {
  const dispatch = useDispatch();
  const isLoadingContacts = useSelector(
    (state: RootState) => state.dashboardReducer.isLoadingContacts
  );
  const [form] = Form.useForm();
  useEffect(() => {
    if (visible) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [visible])
  const closeModal = () => {
    abort()
    form.resetFields();
  }
  const addContactForm = () => {
    form.submit();
  }
  const onFormFinish = async (values) => {
    values.contactType = contactType;
    const res = await dispatch(addContact(values));
    closeModal();
  }

  return (
    <Modal
      title={contactType === 'rep' ? (
        <h1 className={"menu-title"}>Ajouter un contact</h1>
      ) : (
        <h1 className={"menu-title"}>Ajouter un contact de confiance</h1>
      )}
      centered
      visible={visible}
      onOk={addContactForm}
      onCancel={closeModal}
      width={"80%"}
      style={{ top: 20 }}
      closeIcon={<CloseRed/>}
      footer={[
        <Button onClick={closeModal} className="white-btn">Annuler</Button>,
        <Button onClick={addContactForm} className="blue-btn" type={"primary"}>
          {isLoadingContacts ? (
            <Spin indicator={antIcon} style={{color: "#3240FF" }}/>
          ) : "Ajouter"}
        </Button>
      ]}
    >
      <div className={"add-contact-modal"}>
        {contactType === 'trusted' ? (
            <div className={"header-menu"}>
              <div className={"sub-header-menu"}>
                <p className={"service-text"} >Ajouter une personne de confiance. Si vous n'étes pas disponible, celle-ci aura l'autorisation de venir retirer votre courrier</p>
              </div>
            </div>
        ) : (
            <div className={"header-menu"}>
              <div className={"sub-header-menu"}>
                <p className={"service-text"}>Ajouter un contact avec lesquel vous souhaitez partager du courrier</p>
              </div>
            </div>
        )}
        <Form
          name="basic"
          className={"form-contact"}
          onFinish={onFormFinish}
          form={form}
        >
          <div className="label-input">{"Nom & Prénom"}</div>
          <Form.Item
            name="fullname"
            rules={[{ required: true, message: "Tapez le nom et le prénom!" }]}
          >
            <Input placeholder={"Saisir le nom et prénom de la personne"}/>
          </Form.Item>
          <div className="label-input">{"Catégorie"}</div>
          <Form.Item
            name="category"
            rules={[{ required: true, message: "Tapez la catégorie!" }]}
          >
            <Input placeholder="Saisir la catégorie de la personne"/>
          </Form.Item>
          <div className="label-input">{"Email"}</div>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Tapez une adresse email!" },
              { type: 'email', message: "Tapez une adresse email valide!" }
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder="Saisir l'adresse email de la personne"/>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
export default AddContactModal;
