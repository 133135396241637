function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import TextMessage from './TextMessage';
import EmojiMessage from './EmojiMessage';
import FileMessage from './FileMessage';
import seen from './../../assets/seen.svg';
import unseen from './../../assets/unseen.svg';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')

var Message = function (_Component) {
  _inherits(Message, _Component);

  function Message() {
    _classCallCheck(this, Message);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  Message.prototype._renderMessageOfType = function _renderMessageOfType(type) {
    switch (type) {
      case 'text':
        return React.createElement(TextMessage, { message: this.props.message, onDelete: this.props.onDelete });
      case 'emoji':
        return React.createElement(EmojiMessage, this.props.message);
      case 'file':
        return React.createElement(FileMessage, { onDelete: this.props.onDelete, message: this.props.message, onDownload: this.props.onDownload });
    }
  };

  Message.prototype.render = function render() {
    var contentClassList = ["sc-message--content", this.props.message.author === "me" ? "sent" : "received"];
    return React.createElement(
      'div',
      { className: 'sc-message' },
      React.createElement('div', { className: 'sc-message--content-wrapper'},
        React.createElement(
          'div',
          { className: contentClassList.join(" ") },
          (this.props.message.author === "me" ?
            null
           :
          React.createElement('div', { className: 'sc-message--avatar', style: {
            backgroundImage: 'url(' + this.props.message.avatar_url + ')'
            }})
          ),
          this._renderMessageOfType(this.props.message.type),
        ),
        React.createElement('div', { className: 'sc-message--status' },
        this.props.message.author === "me" && this.props.message.status == "unseen" ?
        React.createElement('img', { className: 'sc-message--status-unseen', src: unseen, alt: 'unseen icon' },
        )
        : this.props.message.author === "me" && this.props.message.status == "seen" ?
        React.createElement('img', { className: 'sc-message--status-seen', src: seen, alt: 'seen icon' },
        )
        : ""
        ),
      ),
      React.createElement('div', { className: this.props.message.author === "me" ? "sc-message--date sent" : "sc-message--date received"},
      "",
      moment(this.props.message.date).fromNow()
      )
    );
  };

  return Message;
}(Component);

export default Message;
