import React, {useEffect, useState} from "react";
import { Spin, Col, Form, Button, message } from "antd";
import UploadFile from "../upload-file-modal/UploadFile";
import { RootState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SignContract = (props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.profileReducer.isLoadingProfile);
  const contract = useSelector((state: RootState) => state.profileReducer.contract);
  const useMountEffect = (fun) => useEffect(fun, [])

  const signContract = () => {
    props.onSubmit(true, {sign: true})
    props.unSubmit();
  }
  return (
    <div className={"sign-btn-wrapper"}>
      <Button onClick={signContract}  className={contract && contract.domi_has_signed ? "sign-contract-btn" : "sign-contract-btn disabled"} disabled={!contract || !contract.domi_has_signed}>
        {isLoading && props.isSigningRequest ? (
          <Spin indicator={antIcon} />
        ) :
        "Signer le contrat"
        }
      </Button>
    </div>
  )
};

export default SignContract;
