import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import "./my-services-page.scss";
import { Col, Row } from "antd";
import MyServicesCardDash from "../../components/my-services-dashboard-cards/MyServicesCardDash";
import ServiceBannerWithImg from "../../components/my-services-components/ServiceBannerWithImg";
import CallCenterIcon from "../../svg-components/CallCenterIcon";
import MettingLogo from "../../svg-components/MeetingTeamIcon";
import ServiceStandardCustomized from "../../components/my-services-components/service-item-card-big/ServiceStandardCustomized";
import ServiceMeetingRoom from "../../components/my-services-components/service-item-card-big/ServiceMettingRoom";
import ServiceCallRedirection from "../../components/my-services-components/service-item-card-big/ServiceCallRedirection";
import { getAvailablePhoneSolutions } from "../../actions/services-actions/actions";

const MyServicesPage = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const [View, setView] = useState("1");
  const [Checked, setChecked] = useState("solution-telephonique");

  useEffect(() => {
    document.title = `Mes services | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/services`);
    }
  })

  const handleSol = () => {
      setChecked("solution-telephonique");
  }
  const handleMeet = () => {
      setChecked("meeting-room");
  }

  const handleStandard = () => {
    setView("1")
  }
  const handleMeeting = () => {
    setView("2")
  }
  const handleRedirection = () => {
    setView("3")
  }
  useEffect((): any => {
    if(user){
      dispatch(getAvailablePhoneSolutions(user.profile.address_infos.id));
    }
  }, [])
  useEffect(() => {
    if(props.location.state && props.location.state.type){
      if (props.location.state.type === "meeting") {
        handleMeeting();
        handleMeet();
      }else{
        handleStandard();
        handleSol();
      }
    }
  }, [])
  return (
    <div className={"my-services-page"}>
      <div className="header-services-page">
        <h1 className={"title"}>Mes Services</h1>
      </div>
      <div className="marged-top-h3" />
      <Row gutter={[16, 16]}>
        <Col className={"column-" + (Checked === "solution-telephonique" ? 'checked' : '')}>
          <MyServicesCardDash
            title={"Solution téléphonique"}
            image={<CallCenterIcon />}
            onClick={() => {
              handleStandard();
              handleSol();
            }}
          />
        </Col>
        <Col className={"column-" + (Checked === "meeting-room" ? 'checked' : '')} >
          <MyServicesCardDash
            title={"Réservation Meeting Room"}
            image={(<MettingLogo />)}
            onClick={() => {
              handleMeeting();
              handleMeet();
            }}
          />
        </Col>
        <Col >
          <ServiceBannerWithImg
            text={
              `Réservez la salle de conférence pour vos événement et vos séminaires`
            }
            isNew={true}
            onClick={() => {
              handleMeeting();
              handleMeet();
            }}
          />
        </Col>
      </Row>
      <div className="marged-top-h3" />
      {View === "1" && <ServiceStandardCustomized
        title="Solution Téléphonique"
        description="Optez pour la solution téléphonique de votre choix en quelques clics. Toutes vos options peuvent être souscrites en ligne."
        onClick1={handleStandard}
        onClick2={handleRedirection}
      />}
      {View === "2" && <ServiceMeetingRoom
        title="Réservation Meeting Room"
        description="Organiser, inviter,effectuer vos réservation et payez via notre systéme de réservation en ligne"
      />}
      {View === "3" && <ServiceCallRedirection
        title="Solution Téléphonique"
        description="Optez pour la solution téléphonique de votre choix en quelques clics. Toutes vos options peuvent être souscrites en ligne."
        onClick1={handleStandard}
        onClick2={handleRedirection}
      />}
    </div>
  );
};

export default MyServicesPage;
