import * as React from "react";
import {Col, Form, Row} from "antd";
import RadioSimpleBtn from "../../radio-simple-btn/RadioSimpleBtn";
interface SignupStepTwoTypes {
  callBackFn: any;
  goBack: any;
  username: string;
}
const SignupStepTwo: React.FC<SignupStepTwoTypes> = ({
  callBackFn,
  goBack,
  username,
}) => {
  const submitFunction = (value:any)=>{
    callBackFn(value)
  }

  return (
    <Form  id={"signup-form-step-2"} name="signup-form-step-2"
           onFinish={submitFunction}
    >
      <Row>
        <h1 className={"white-title"}>
          <strong>{username}!</strong>
        </h1>
      </Row>
      <Row>
        <h2 className={"white-sub-title"}>
          Souhaitez vous domicilier <strong>en tant que...</strong>
        </h2>
      </Row>
      <div className="separtor-small-white" />

      <Row>
        <Form.Item
          name="company_type"
          rules={[
            {
              required: true,
              message: "Votre besoin SVP!",
            },
          ]}
        >
        <Row>
          <Row className={"justify-center-on-mobile"}>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"suarl-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"SUARL"}
                defaultChecked={false}
              />
            </Col>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"sarl-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"SARL"}
                defaultChecked={false}
              />
            </Col>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"sa-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"S.A"}
                defaultChecked={false}
              />
            </Col>
          </Row>
          <Row className={"justify-center-on-mobile"}>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"association-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"Association"}
                defaultChecked={false}
              />
            </Col>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"personne-phy-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"Personne Phy."}
                defaultChecked={false}
              />
            </Col>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"syndicat-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"syndicat"}
                defaultChecked={false}
              />
            </Col>
          </Row>
          <Row className={"justify-center-on-mobile"}>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"artisant-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"artisant"}
                defaultChecked={false}
              />
            </Col>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"freelance-phy-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"freelance Phy."}
                defaultChecked={false}
              />
            </Col>
            <Col xs={24} md={8}>
              <RadioSimpleBtn
                label={"consultant-btn-radio-signup"}
                name={"type-btn-radio-signup"}
                text={"consultant"}
                defaultChecked={false}
              />
            </Col>
          </Row>
        </Row>
        </Form.Item>
        <Row
          style={{
            width: "100%",
            marginTop: "2rem"
          }}
          gutter={[24, 24]}
        >
            <Col xs={12} md={6}>
              <button
                className={"auth-submit-btn-white"}
                onClick={goBack}
                type="button"
              >
                Précédent
              </button>
            </Col>
            <Col xs={12} md={6}>
              <button
                className={"auth-submit-btn-white"}
                type="submit"
              >
                Suivant
              </button>
            </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default SignupStepTwo;
