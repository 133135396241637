import React, {useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import { Modal, Spin, Button, Select } from 'antd';
import { RootState } from "../../../../reducers";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { sendVerificationEmail } from "../../../../actions/auth-actions/actions";

const Saly = require("../../../../assets/png/Saly-6.png");


interface EmailVerificationModalProps {
  visible: boolean,
}

const EmailVerificationModal : React.FC<EmailVerificationModalProps> = ({visible}:any) => {
  const useMountEffect = (fun) => useEffect(fun, [])
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => state.authReducer.user
  );
  useEffect(() => {
    if (visible) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [visible])
  const sendEmailVerification = async () => {
    if (user && user.id) {
      await dispatch(sendVerificationEmail(user.id));
    }
  }
  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      style={{ top: 10 }}
      footer={<div></div>}
      title={<h2>Veuillez valider votre email !</h2>}
      wrapClassName={"email-verification-modal"}
      width={"50%"}
    >
      <div className={"email-verification-modal-content"}>
        <h3>{`Cher ${user && user.name ? user.name : ""}, bienvenue chez Domicilier.tn`}</h3>
        <p>Pour terminer votre inscription et commencer votre domiciliation, vous devez valider votre email. Veuillez suivre le lien reçu dans votre boîte de réception pour finaliser les démarches !</p>
        <img src={Saly} alt="img" />
        <div className={"buttons-wrapper"}>
          <Button onClick={sendEmailVerification} className={"back-btn"}>Renvoyer le mail</Button>
        </div>
      </div>
    </Modal>
  );
}
export default EmailVerificationModal;
