import React, {Component, useState, useEffect} from 'react'
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { List, Spin } from 'antd';
import { getNotifications, filterNotifications } from "../../actions/notifications-actions/actions";
import NotificationCard from "./NotificationCard";
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
import NotificationsSearchBar from './NotificationsSearchBar';
import { LoadingOutlined } from '@ant-design/icons';
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;
moment.locale('fr')


const NotificationsList = ({}) =>  {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const notifications = useSelector((state: RootState) => state.notificationsReducer.notifications);
  const filtredNotifications = useSelector((state: RootState) => state.notificationsReducer.filtredNotifications);
  const isLoadingNotifications = useSelector((state: RootState) => state.notificationsReducer.isLoadingNotifications);
  const lastPage = useSelector((state: RootState) => state.notificationsReducer.lastPage);
  const [data, setData] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [page, setPage] = useState(1);
  const [inSearchMode, setInSearchMode] = useState(false);
  const [searchData, setSearchData] = useState({key: '', filter: null});
  const listLoading = {
    spinning: isLoadingNotifications && page === 1,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  const fetchNotifications = async () => {
    if(user){
      await dispatch(getNotifications(page))
    }
  }

  const fetchFiltredNotifications = async () => {
    if(user){
      const values = {...searchData, page: page}
      await dispatch(filterNotifications(values))
    }
  }

  useEffect(() => {
    if(page <= lastPage){
      fetchNotifications();
    }
  }, [page])

  useEffect(() => {
    if(searchData && searchData.key.length > 0){
      setInSearchMode(true);
      fetchFiltredNotifications();
    }else{
      setInSearchMode(false);
      setPage(1);
    }
  }, [searchData])

  useEffect(() =>{
    if(notifications){
        setData(notifications)
    }
  }, [notifications])
  useEffect(() =>{
    if(filtredNotifications){
        setFiltredData(filtredNotifications)
    }
  }, [filtredNotifications])
  const loadMore = (e) => {
    const limit = e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop;
    const nextPage = page + 1;
      if(limit == 0){
        setPage(nextPage)
      }
  }

  return (
    <div className={"left"}>
      {/* <NotificationsSearchBar
        search={(values) => setSearchData(values)}
      /> */}
      <div className={"notifications-list-wrapper"} onScroll={loadMore}>
          <List
            loading={listLoading}
            itemLayout="horizontal"
            locale={{emptyText: <em style={{color: "#bfbfbf"}}>Aucune notification</em>}}
            dataSource={inSearchMode ? filtredData : data}
            renderItem={notification => (
              <NotificationCard
                notification={notification}
              />
            )}
          />
      </div>
    </div>
  )
}
export default NotificationsList;
