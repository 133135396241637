import { Reducer, AnyAction } from "redux";
import {
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  EMPTY_CART_REQUEST,
  EMPTY_CART_SUCCESS
} from "../actions/cart-actions/types";

const emptyCart = {
  address: {
    id: null,
    location: null,
  },
  packs: [],
  billing: {
    type: 1,
    totalHt: 0,
    totalAmount: 0,
  },
  payment: "paymee"
}

const INITIAL_STATE: any = {
  cart: JSON.parse(sessionStorage.getItem('domicilier_cart')) ? JSON.parse(sessionStorage.getItem('domicilier_cart')) : emptyCart,
  cartIsUpdating: false
}

export const cartReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): any => {
  switch (action.type) {
    case UPDATE_CART_REQUEST:
      return {
        ...state,
        cartIsUpdating: true
      };
    case UPDATE_CART_SUCCESS:
      sessionStorage.setItem("domicilier_cart", JSON.stringify(action.payload.cart));
      return {
        ...state,
        cart: {
          address: action.payload.cart.address,
          packs: action.payload.cart.packs,
          billing: action.payload.cart.billing,
        },
        cartIsUpdating: false
      };
    case EMPTY_CART_REQUEST:
      return {
        ...state,
        cartIsUpdating: true
      }
    case EMPTY_CART_SUCCESS:
      sessionStorage.removeItem('domicilier_cart');
      return {
        ...state,
        cart: {
          address: {
            id: null,
            location: null,
          },
          packs: [],
          billing: {
            type: 1,
            totalHt: 0,
            totalAmount: 0,
          },
        },
        cartIsUpdating: false
      }
    default:
      return state;
  }
};

