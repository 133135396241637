import React, { useEffect, useState } from "react";
import "./stats-dashboard-cards-container.scss";
import StatDashboardCardItem from "./StatDashboardCardItem";
import ArrowMessageIcon from "../../svg-components/ArrowMessageIcon";
import BigHome from "../../svg-components/BigHome";
import TeamMeetingPeople from "../../svg-components/TeamMeetingPeople";
import { Row, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDirectory, getContacts, getInfos, getInfosMonthly, getInfosYearly, getBills } from "../../actions/dashboard-actions/actions";
import { RootState } from "../../reducers";
import PhoneIcon from "../../svg-components/icons/PhoneIcon";

const StatsDashboardCardsContainer = () => {
  const dispatch = useDispatch();
  const infos = useSelector(
    (state: RootState) => state.dashboardReducer.infos
  );
  return (
    <div className={"stats-dashboard-cards-container"}>
      <div className="header-part">
        <h1 className="title">STATISTIQUES</h1>
        <Radio.Group
          className={"select-period-radion-btns"}
          defaultValue={"week"}
        >
          <Radio.Button defaultChecked={true} value="week" onClick={() => dispatch(getInfos())}>
            Semaine
          </Radio.Button>
          <Radio.Button defaultChecked={false} value="month" onClick={() => dispatch(getInfosMonthly())}>
            Mois
          </Radio.Button>
          <Radio.Button defaultChecked={false} value="year" onClick={() => dispatch(getInfosYearly())}>
            Année
          </Radio.Button>
        </Radio.Group>
      </div>
      <Row gutter={[10, 10]}>
        <StatDashboardCardItem
          title={"Courriers expédiés"}
          icon={<ArrowMessageIcon />}
          counter={infos.mails_exp}
        />
        <StatDashboardCardItem
          title={"Appelles traités"}
          icon={<PhoneIcon />}
          counter={infos.treated_calls}
        />
        <StatDashboardCardItem
          title={"Réunions organisés"}
          icon={<TeamMeetingPeople />}
          counter={infos.meetings}
        />
      </Row>
    </div>
  );
};

export default StatsDashboardCardsContainer;
