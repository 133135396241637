import React from "react";

const ShareSmallIcon = () => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29345 3.48003C9.29345 3.76111 9.3364 4.03216 9.4161 4.28697L4.65354 7.23841C4.16995 6.78167 3.51766 6.50166 2.79998 6.50166C1.30881 6.50166 0.0999756 7.71049 0.0999756 9.20166C0.0999756 10.6928 1.30881 11.9017 2.79998 11.9017C3.49232 11.9017 4.12379 11.6411 4.60168 11.2126L9.27253 14.0711C9.20077 14.314 9.16226 14.5712 9.16226 14.8373C9.16226 16.3285 10.3711 17.5373 11.8623 17.5373C13.3534 17.5373 14.5623 16.3285 14.5623 14.8373C14.5623 13.3462 13.3534 12.1374 11.8623 12.1374C11.1426 12.1374 10.4887 12.4189 10.0047 12.8779L5.36734 10.0398C5.45343 9.77599 5.49998 9.49426 5.49998 9.20166C5.49998 8.93358 5.46091 8.67463 5.38815 8.43018L10.1671 5.46861C10.6478 5.91035 11.2891 6.18003 11.9934 6.18003C13.4846 6.18003 14.6934 4.9712 14.6934 3.48003C14.6934 1.98886 13.4846 0.780029 11.9934 0.780029C10.5023 0.780029 9.29345 1.98886 9.29345 3.48003ZM13.2934 3.48003C13.2934 4.198 12.7114 4.78003 11.9934 4.78003C11.2755 4.78003 10.6934 4.198 10.6934 3.48003C10.6934 2.76206 11.2755 2.18003 11.9934 2.18003C12.7114 2.18003 13.2934 2.76206 13.2934 3.48003ZM11.8623 16.1374C12.5802 16.1374 13.1623 15.5553 13.1623 14.8374C13.1623 14.1194 12.5802 13.5374 11.8623 13.5374C11.1443 13.5374 10.5623 14.1194 10.5623 14.8374C10.5623 15.5553 11.1443 16.1374 11.8623 16.1374ZM4.09998 9.20166C4.09998 9.91963 3.51795 10.5017 2.79998 10.5017C2.08201 10.5017 1.49998 9.91963 1.49998 9.20166C1.49998 8.48369 2.08201 7.90166 2.79998 7.90166C3.51795 7.90166 4.09998 8.48369 4.09998 9.20166Z"
        fill="white"
      />
    </svg>
  );
};

export default ShareSmallIcon;
