import React from "react";

const MoreDotsSmallIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 10.6193 2.11929 9.5 3.5 9.5C4.88071 9.5 6 10.6193 6 12C6 13.3807 4.88071 14.5 3.5 14.5C2.11929 14.5 1 13.3807 1 12ZM9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12ZM20.5 9.5C19.1193 9.5 18 10.6193 18 12C18 13.3807 19.1193 14.5 20.5 14.5C21.8807 14.5 23 13.3807 23 12C23 10.6193 21.8807 9.5 20.5 9.5Z"
          fill="#2A3F58"
        />
      </g>
    </svg>
  );
};

export default MoreDotsSmallIcon;
