import { Reducer, AnyAction } from "redux";
import {
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_PARAMETERS_REQUEST,
  UPDATE_PARAMETERS_SUCCESS,
  UPDATE_PARAMETERS_FAILURE,
  LOGOUT_REQUEST,
  DISCONNECT_THE_USER,
  CONNECT_THE_USER,
  GET_AUTH_REQUEST,
  GET_AUTH_SUCCESS,
  GET_AUTH_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHECK_LINK_REQUEST,
  CHECK_LINK_SUCCESS,
  CHECK_LINK_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_CODE_ERROR,
  SEND_VERIFICATION_CODE_REQUEST,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_ERROR,
  SEND_VERIFICATION_EMAIL_REQUEST,
  PHONE_VERIFICATION_SUCCESS,
  PHONE_VERIFICATION_ERROR,
  PHONE_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  EMAIL_VERIFICATION_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  CHANGE_PACK_CHOOSED,
  SESSION_TIMEOUT
} from "../actions/auth-actions/types";

const INITIAL_STATE: any = {
  user: null,
  isLoggedIn: false,
  isLoadingUser: false,
  isLoading: false,
  token: null,
  billNum: null,
  status: null,
  cart: [],
  choosedAPack: false,
  phoneIsVerified: false,
  emailIsVerified: false,
  isVerified: false,
  passwordChangeStatus: false,
  linkValidity: false,
  isCheckingLink: false,
  isSendingRequest: false,
};

export const authReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): boolean => {
  switch (action.type) {
    case CHANGE_PACK_CHOOSED:
      return {
        ...state,
        user: action.payload.user,
        choosedAPack: action.payload.choosedAPack == 1 ? true : false
      };
      case GET_AUTH_REQUEST:
        return {
          ...state,
          isLoadingUser: true,
        };
      case GET_AUTH_SUCCESS:
        return {
          ...state,
          user: action.payload.user,
          isLoadingUser: false,
          isLoggedIn: true,
          choosedAPack: action.payload.user && action.payload.user.pack_choosed == 1 ? true : false,
          phoneIsVerified: action.payload.user && action.payload.user.phone_verified_at,
          emailIsVerified: action.payload.user && action.payload.user.email_verified_at,
          isVerified: action.payload.user && action.payload.user.email_verified_at && action.payload.user.phone_verified_at ? true : false,
        };

      case GET_AUTH_FAILURE:
        return {
          ...state,
          user: null,
          isLoggedIn: false,
          isLoadingUser: false,
          isLoading: false,
          token: null,
          choosedAPack: false,
          phoneIsVerified: false,
          emailIsVerified: false,
          isVerified: false,
        };

    // Sign in
    case SIGNIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SIGNIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
        token: action.payload.access_token,
        choosedAPack: action.payload.user.pack_choosed,
        phoneIsVerified: action.payload.user && action.payload.user.phone_verified_at,
        emailIsVerified: action.payload.user && action.payload.user.email_verified_at,
        isVerified: action.payload.user && action.payload.user.email_verified_at && action.payload.user.phone_verified_at ? true : false,
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        isLoading: false
      };
      case SESSION_TIMEOUT:
        localStorage.removeItem('domicilier_token');
        return {
          ...state,
          user: null,
          isLoggedIn: false,
          token: null,
          choosedAPack: false,
          phoneIsVerified: false,
          emailIsVerified: false,
          isVerified: false,
        };
    // UPDATE NOTIFICATION
    case UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
      }

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isSendingRequest: false,
        user: action.payload.user,
        phoneIsVerified: action.payload.user && action.payload.user.phone_verified_at,
        emailIsVerified: action.payload.user && action.payload.user.email_verified_at,
        isVerified: action.payload.user && action.payload.user.email_verified_at && action.payload.user.phone_verified_at ? true : false,
      };
    case UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        isSendingRequest: false
      }
    // UPDATE NAME AND PASSWORD
    case UPDATE_PARAMETERS_REQUEST:
      return {
        ...state,
        isSendingRequest: true
      }
    case UPDATE_PARAMETERS_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isSendingRequest: false,
      };
    case UPDATE_PARAMETERS_FAILURE:
      return {
        ...state,
        isSendingRequest: false,
      };
    // Password reinitialisation
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSendingRequest: false,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isSendingRequest: false,
      };
    case CHECK_LINK_REQUEST:
      return {
        ...state,
        isCheckingLink: true,
      };

    case CHECK_LINK_SUCCESS:
      return {
        ...state,
        isCheckingLink: false,
        linkValidity: action.payload.linkValidity
      };
    case CHECK_LINK_ERROR:
      return {
        ...state,
        isCheckingLink: false,
        linkValidity: false
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSendingRequest: false,
        passwordChangeStatus: true,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isSendingRequest: false,
        passwordChangeStatus : false
      };

    // PHONE VERIFICATION
    case SEND_VERIFICATION_CODE_REQUEST:
      return state;

    case SEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
      };
    case SEND_VERIFICATION_CODE_ERROR:
      return state;

    case PHONE_VERIFICATION_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
      };

    case PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        isSendingRequest: false,
        user: action.payload.user,
        phoneIsVerified: action.payload.user.phone_verified_at,
        isVerified: action.payload.user && action.payload.user.email_verified_at && action.payload.user.phone_verified_at ? true : false,
      };
    case PHONE_VERIFICATION_ERROR:
      return {
        ...state,
        isSendingRequest: false,
      };
    // EMAIL VERIFICATION
    case SEND_VERIFICATION_EMAIL_REQUEST:
      return state;

    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return state;

    case EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
      };

    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isSendingRequest: false,
        user: action.payload.user,
        isLoggedIn: true,
        choosedAPack: action.payload.user && action.payload.user.pack_choosed == 1 ? true : false,
        phoneIsVerified: action.payload.user && action.payload.user.phone_verified_at,
        emailIsVerified: action.payload.user && action.payload.user.email_verified_at,
        isVerified: action.payload.user && action.payload.user.email_verified_at && action.payload.user.phone_verified_at ? true : false,
      };
    case EMAIL_VERIFICATION_ERROR:
      return {
        ...state,
        isSendingRequest: false,
      };

    //Sign up
    case SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
        token: action.payload.access_token,
        choosedAPack: action.payload.user.pack_choosed,
        isVerified: action.payload.user && action.payload.user.email_verified_at && action.payload.user.phone_verified_at ? true : false,
        isLoading: false,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    // Logout

    case LOGOUT_REQUEST:
      return state;

    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        token: null,
      };
    case LOGOUT_FAILURE:
      return state;

    // Connect & disconnect user ( no interaction with the server )
    case DISCONNECT_THE_USER:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        token: null,
      };

    case CONNECT_THE_USER:
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token
      };
    default:
      return state;
  }
};
