import * as React from "react";
import { useEffect, useState } from "react";
import "./navbar.scss";
import { Layout } from "antd";
import NavbarUserDropDown from "./NavbarUserDropDown";
import NavbarNotificationDropDown from "./NavbarNotificationDropDown";
import NavbarMessagesDropDown from "./NavbarMessagesDropDown";
import NavbarSearchBar from "./NavbarSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { OPEN_DASHBOARD_SIDER, CLOSE_DASHBOARD_SIDER } from "../../actions/ui-actions/types";
import { MenuOutlined } from "@ant-design/icons";
import DrawerMenu from "./DrawerMenu";
import { Link } from "react-router-dom";
import Logo from "../../svg-components/Logo";

const { Header } = Layout;

const Navbar = (props) => {
  const dispatch = useDispatch();
  const dashboardSiderOpened = useSelector(
    (state: RootState) => state.uiReducer.dashboardSiderOpened
  );

  const useMountEffect = (fun) => useEffect(fun, [])

  const handleSiderStatus = async () => {
    if (dashboardSiderOpened) {
      await dispatch({ type: CLOSE_DASHBOARD_SIDER });
    } else {
      await dispatch({ type: OPEN_DASHBOARD_SIDER });
    }
  };
  return (
    <Header className={"navbar"}>
      <div className="max-width-container">
        <div className="navbar-container" style={{justifyContent: props.withSidebar ? "flex-end" : "space-between"}}>
          <DrawerMenu />
          <button className={"mobile-sider-btn"} onClick={handleSiderStatus}>
            <MenuOutlined />
          </button>
          {props.withSidebar ? null : (
            <a href={process.env.MIX_SITE_URL} className="logo-landing-link">
              <div className="logo-landing">
                <Logo />
              </div>
            </a>
          )}
          <div className={"right"}>
            <NavbarSearchBar />
            <div className="icons-notifications-container">
              <NavbarMessagesDropDown />
              <NavbarNotificationDropDown />
            </div>
            <NavbarUserDropDown />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default Navbar;
