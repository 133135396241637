import React, { Component, useState, useEffect } from 'react'
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { List, Badge, Avatar, Skeleton } from 'antd';
import FileSmall from '../../svg-components/icons/FileSmall';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
import { EMPTY_DISCUSSIONS } from "../../actions/discussions-actions/types";
import { Link, useHistory } from 'react-router-dom';
moment.locale('fr')

interface DropDownDiscussionCardProps {
  discussion: any;
}

const DropDownDiscussionCard: React.FC<DropDownDiscussionCardProps> = ({ discussion }) => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const isLoading = useSelector((state: RootState) => state.discussionsReducer.isLoadingDiscussions);
  const totalNew = useSelector(
    (state: RootState) => state.discussionsReducer.totalNew
  );
  const [hasUnseen, setHasUnseen] = useState(false);
  const useMountEffect = (fun) => useEffect(fun, [])

  const goToDiscussion = async (e) => {
    e.preventDefault();
    await dispatch({ type: EMPTY_DISCUSSIONS });
    history.push({
      pathname: "/messages",
      state: { discussionId: discussion.id }
    })
  }
  const getDiscussionLastMessage = (discussion) => {
    let lastMessage = null;
    if (discussion.messages.length > 0) {
      lastMessage = discussion.messages[discussion.messages.length - 1];
    }
    return lastMessage
  }

  const getDiscussionUnseenMessagesNumber = (discussion) => {
    let unseenMessages = [];
    if (discussion.messages.length > 0) {
      unseenMessages = discussion.messages.filter((message) => message.status === "unseen" && message.user_id !== user.id)
    }
    return unseenMessages.length
  }

  useEffect(() => {
    if (discussion) {
      const unseenNumber = getDiscussionUnseenMessagesNumber(discussion);
      if (unseenNumber > 0) {
        setHasUnseen(true)
      } else {
        setHasUnseen(false)
      }
    }
  }, [discussion])

  return (
    <Link
      to={{
        pathname: "/messages",
      }}
      onClick={goToDiscussion}
    >
      <Skeleton avatar title={false} loading={isLoading} active>
        <div className={hasUnseen ? "dropdown-discussion-wrapper has-unseen" : "dropdown-discussion-wrapper"}>
          <div className={"discussion-header"}>
            <Avatar src={discussion.support.user.avatar ? process.env.MIX_STORAGE_URL + discussion.support.user.avatar : ""} />
            <div className={"from-info"}>
              <div className={"from-name"}>{window.capitalize(discussion.support.user.full_name)}</div>
            </div>
          </div>
          <div className={"discussion-preview"}>
            <div className={"discussion-preview-content"}>
              {getDiscussionLastMessage(discussion) ?
                getDiscussionLastMessage(discussion).content ? (
                  <div className={"text"}>{getDiscussionLastMessage(discussion).content}</div>
                ) : (
                  <div className={"file"}><FileSmall />{getDiscussionLastMessage(discussion).shared_files[0].file_name}
                  </div>
                )
                : null}
              <small className={"updated-at"}>il y a {moment(discussion.updated_at).fromNow(true)}</small>
            </div>
          </div>
        </div>
      </Skeleton>
    </Link>
  )
}
export default DropDownDiscussionCard;
