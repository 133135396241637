import React, {useEffect, useState} from "react";
import "./pack-promo-counter.scss";
import {useDispatch, useSelector} from "react-redux";
import {changePackChoosed} from "../../actions/auth-actions/actions";
import {RootState} from "../../reducers";

interface PackPromoCounterProps {
  promoExpiryDate: string;
}
function doubleDigitsFormat(params:any) {
  if (params < 10)  params = '0'+params;
  return params;

};
const calculateTimeLeft = (expiryDate) => {
  let difference = +new Date(expiryDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      jr: (Math.floor(difference / (1000 * 60 * 60 * 24))),
      hr: Math.floor((difference / (1000 * 60 * 60)) % 24),
      min: Math.floor((difference / 1000 / 60) % 60),
      sec: Math.floor((difference / 1000) % 60)
  };
}

return timeLeft;

}
const PackPromoCounter: React.FC<PackPromoCounterProps> = ({promoExpiryDate}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(promoExpiryDate));
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(promoExpiryDate));
    }, 1000);
    return () => clearTimeout(timer);
  });
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {

    timerComponents.push(
      <span className="promo-countdown-item" key={interval}>
        {doubleDigitsFormat(timeLeft[interval])}
          <br />
        {interval}
      </span>
    );
  });
  return (
    <div className="promo-countdown-wrapper">
        {timerComponents.length ? timerComponents : "Cette promotion a maintenant pris fin"}
    </div>
  )
};

export default PackPromoCounter;
