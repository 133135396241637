export const GET_DASHBOARD_INFO_REQUEST = "GET_DASHBOARD_INFO_REQUEST ";
export const GET_DASHBOARD_INFO_SUCCESS = "GET_DASHBOARD_INFO_SUCCESS ";
export const GET_DASHBOARD_INFO_FAILURE = "GET_DASHBOARD_INFO_FAILURE ";

export const GET_DASHBOARD_DIRECTORY_REQUEST = "GET_DASHBOARD_DIRECTORY_REQUEST ";
export const GET_DASHBOARD_DIRECTORY_SUCCESS = "GET_DASHBOARD_DIRECTORY_SUCCESS ";
export const GET_DASHBOARD_DIRECTORY_FAILURE = "GET_DASHBOARD_DIRECTORY_FAILURE ";

export const GET_DASHBOARD_CONTACTS_REQUEST = "GET_DASHBOARD_CONTACTS_REQUEST ";
export const GET_DASHBOARD_CONTACTS_SUCCESS = "GET_DASHBOARD_CONTACTS_SUCCESS ";
export const GET_DASHBOARD_CONTACTS_FAILURE = "GET_DASHBOARD_CONTACTS_FAILURE ";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const GET_DASHBOARD_BILLS_REQUEST = "GET_DASHBOARD_BILLS_REQUEST ";
export const GET_DASHBOARD_BILLS_SUCCESS = "GET_DASHBOARD_BILLS_SUCCESS ";
export const GET_DASHBOARD_BILLS_FAILURE = "GET_DASHBOARD_BILLS_FAILURE ";

export const GET_DASHBOARD_LATEST_BILLS_REQUEST = "GET_DASHBOARD_LATEST_BILLS_REQUEST ";
export const GET_DASHBOARD_LATEST_BILLS_SUCCESS = "GET_DASHBOARD_LATEST_BILLS_SUCCESS ";
export const GET_DASHBOARD_LATEST_BILLS_FAILURE = "GET_DASHBOARD_LATEST_BILLS_FAILURE ";

export const ADD_CONTACT_REQUEST = "ADD_CONTACT_REQUEST ";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS ";
export const ADD_CONTACT_ERROR = "ADD_CONTACT_ERROR ";

export const REMOVE_CONTACT_REQUEST = "REMOVE_CONTACT_REQUEST ";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS ";
export const REMOVE_CONTACT_FAILURE = "REMOVE_CONTACT_FAILURE ";
