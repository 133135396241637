import React from "react";

const TeamMeetingPeople = () => {
  return (
    <svg viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3002 4.6336C14.173 4.25783 13.7654 4.05624 13.3894 4.18331C10.544 5.14594 8.00957 7.02732 6.25293 9.4807C6.02205 9.80329 6.09629 10.2521 6.41888 10.4831C6.54559 10.5738 6.69174 10.6174 6.83648 10.6174C7.06033 10.6174 7.281 10.513 7.42116 10.3171C9.00411 8.10631 11.2872 6.4113 13.8499 5.54425C14.2258 5.41711 14.4274 5.00937 14.3002 4.6336Z"
        fill="white"
      />
      <path
        d="M19.7013 0.723857C19.2703 0.263778 18.6616 0 18.0313 0C17.4009 0 16.7922 0.263848 16.3612 0.723857C15.9301 1.18387 15.7064 1.80848 15.7475 2.4376L15.8626 4.20171C15.8626 4.20178 15.8626 4.20178 15.8626 4.20178C15.9081 4.89929 16.2764 5.53729 16.8477 5.90848C17.2072 6.14211 17.6193 6.25882 18.0313 6.25882C18.4434 6.25882 18.8555 6.14204 19.2149 5.90855C19.7861 5.53736 20.1544 4.89936 20.1999 4.20178L20.315 2.4376C20.3561 1.80855 20.1324 1.18394 19.7013 0.723857ZM18.8815 2.34414L18.7664 4.10817C18.7503 4.35575 18.6254 4.57832 18.4323 4.70377C18.1886 4.86203 17.874 4.86189 17.6304 4.70377C17.4373 4.57832 17.3125 4.35561 17.2963 4.10817L17.1812 2.34414C17.1658 2.10656 17.2468 1.87997 17.4097 1.70621C17.5724 1.53252 17.7932 1.4368 18.0313 1.4368C18.2693 1.4368 18.49 1.53252 18.6528 1.70621C18.8158 1.87997 18.8969 2.10656 18.8815 2.34414Z"
        fill="white"
      />
      <path
        d="M23.7132 10.4778L23.5136 9.37603C23.2512 7.92762 22.1037 6.77164 20.6582 6.49941C20.4399 6.45835 20.2168 6.4375 19.9949 6.4375H19.3938C19.2033 6.4375 19.0206 6.51322 18.8858 6.64789C18.6576 6.8761 18.3542 7.00182 18.0313 7.00182C17.7084 7.00182 17.405 6.8761 17.1768 6.64789C17.042 6.51322 16.8593 6.4375 16.6688 6.4375H16.0677C15.8458 6.4375 15.6227 6.45835 15.4044 6.49941C13.9589 6.7715 12.8114 7.92755 12.5489 9.37603L12.3494 10.4778C12.3114 10.6873 12.3684 10.9029 12.5049 11.0663C12.6414 11.2297 12.8434 11.3243 13.0563 11.3243H23.0064C23.2193 11.3243 23.4212 11.2298 23.5577 11.0663C23.6941 10.9029 23.7511 10.6874 23.7132 10.4778ZM13.9166 9.88746L13.9628 9.63206C14.1198 8.76558 14.8061 8.07405 15.6705 7.91128C15.8012 7.88662 15.9349 7.87416 16.0679 7.87416H16.3976C16.8617 8.23999 17.4313 8.43855 18.0314 8.43855C18.6314 8.43855 19.201 8.24006 19.6651 7.87416H19.9949C20.1278 7.87416 20.2615 7.88662 20.3923 7.91128C21.2567 8.07405 21.9429 8.76558 22.1 9.63206L22.1462 9.88746H13.9166Z"
        fill="white"
      />
      <path
        d="M19.7013 25.3996C19.2703 24.9396 18.6616 24.6758 18.0313 24.6758C17.4009 24.6758 16.7922 24.9396 16.3612 25.3996C15.9301 25.8597 15.7064 26.4843 15.7475 27.1134L15.8626 28.8776C15.9081 29.5751 16.2764 30.2131 16.8476 30.5843C17.2072 30.8179 17.6192 30.9346 18.0313 30.9346C18.4433 30.9346 18.8555 30.8178 19.2149 30.5843C19.7861 30.2131 20.1544 29.5751 20.1999 28.8775L20.315 27.1133C20.3561 26.4843 20.1324 25.8597 19.7013 25.3996ZM18.8815 27.0198L18.7664 28.7839C18.7503 29.0314 18.6254 29.254 18.4322 29.3794C18.1886 29.5377 17.874 29.5377 17.6304 29.3794C17.4373 29.254 17.3125 29.0313 17.2963 28.7839L17.1812 27.0198C17.1658 26.7822 17.2468 26.5556 17.4097 26.3819C17.5724 26.2082 17.7932 26.1125 18.0313 26.1125C18.2693 26.1125 18.4901 26.2082 18.6528 26.3819C18.8158 26.5556 18.8969 26.7822 18.8815 27.0198Z"
        fill="white"
      />
      <path
        d="M23.7131 35.1536L23.5136 34.0518C23.2511 32.6034 22.1036 31.4474 20.6581 31.1752C20.44 31.1341 20.2169 31.1133 19.9948 31.1133H19.3937C19.2032 31.1133 19.0205 31.189 18.8857 31.3237C18.4145 31.7949 17.648 31.7949 17.1768 31.3237C17.042 31.189 16.8593 31.1133 16.6688 31.1133H16.0677C15.846 31.1133 15.6229 31.1341 15.4044 31.1752C13.9589 31.4474 12.8114 32.6034 12.5489 34.0519L12.3494 35.1537C12.3114 35.3632 12.3684 35.5788 12.5049 35.7422C12.6414 35.9056 12.8434 36 13.0563 36H23.0064C23.2193 36 23.4212 35.9056 23.5576 35.742C23.694 35.5787 23.751 35.3632 23.7131 35.1536ZM13.9166 34.5633L13.9627 34.308C14.1198 33.4415 14.806 32.75 15.6702 32.5872C15.8011 32.5625 15.9348 32.5501 16.0677 32.5501H16.3979C17.353 33.3009 18.7098 33.3009 19.6647 32.5501H19.9949C20.1279 32.5501 20.2617 32.5625 20.3923 32.5871C21.2568 32.7499 21.943 33.4414 22.1 34.3079L22.1463 34.5633H13.9166Z"
        fill="white"
      />
      <path
        d="M7.36375 13.0618C6.93269 12.6017 6.324 12.3379 5.69361 12.3379C5.06322 12.3379 4.45438 12.6017 4.02339 13.0618C3.5924 13.5218 3.36877 14.1464 3.40983 14.7754L3.52492 16.5395C3.57043 17.237 3.93866 17.8751 4.50995 18.2463C4.86952 18.4799 5.28164 18.5966 5.69368 18.5966C6.10572 18.5966 6.5179 18.4799 6.87726 18.2464C7.44856 17.8752 7.81679 17.2371 7.86222 16.5397L7.97731 14.7755C8.0183 14.1465 7.79467 13.5218 7.36375 13.0618ZM6.54375 14.6819L6.42866 16.4461C6.4126 16.6936 6.28772 16.9161 6.09459 17.0415C5.85096 17.1998 5.53633 17.1998 5.29276 17.0415C5.09963 16.916 4.97482 16.6934 4.95862 16.4461L4.84353 14.6819C4.82804 14.4443 4.90918 14.2177 5.07195 14.044C5.23473 13.8703 5.45554 13.7745 5.69361 13.7745C5.93168 13.7745 6.15242 13.8703 6.31519 14.044C6.47797 14.2177 6.55925 14.4443 6.54375 14.6819Z"
        fill="white"
      />
      <path
        d="M11.3752 22.8157L11.1757 21.7139C10.9132 20.2655 9.76573 19.1095 8.3202 18.8373C8.10206 18.7962 7.87892 18.7754 7.65684 18.7754H7.05575C6.8653 18.7754 6.68266 18.8511 6.54785 18.9858C6.07664 19.457 5.3101 19.4571 4.83889 18.9858C4.70415 18.8511 4.52144 18.7754 4.33092 18.7754H3.72976C3.50803 18.7754 3.28482 18.7962 3.06654 18.8373C1.62101 19.1095 0.473493 20.2655 0.211053 21.7139L0.011512 22.8157C-0.0264523 23.0252 0.0305294 23.2408 0.166961 23.4042C0.303534 23.5676 0.50547 23.6621 0.718394 23.6621H10.6685C10.8814 23.6621 11.0833 23.5676 11.2197 23.4042C11.3562 23.2408 11.4132 23.0253 11.3752 22.8157ZM1.57875 22.2254L1.62496 21.97C1.78203 21.1036 2.46827 20.412 3.33265 20.2492C3.46337 20.2246 3.59706 20.2121 3.72997 20.2121H4.05989C4.52398 20.578 5.09358 20.7764 5.69362 20.7764C6.29365 20.7764 6.86325 20.578 7.32735 20.2121H7.65712C7.79003 20.2121 7.92372 20.2246 8.05445 20.2492C8.91889 20.412 9.60507 21.1036 9.76213 21.97L9.80841 22.2254H1.57875Z"
        fill="white"
      />
      <path
        d="M32.0392 13.0617C31.6081 12.6017 30.9994 12.3379 30.3691 12.3379C29.7387 12.3379 29.1299 12.6017 28.6989 13.0618C28.2679 13.5218 28.0443 14.1465 28.0854 14.7755L28.2005 16.5396C28.246 17.2371 28.6141 17.8752 29.1854 18.2464C29.5451 18.48 29.957 18.5967 30.3691 18.5967C30.7811 18.5967 31.1934 18.4799 31.5527 18.2464C32.124 17.8752 32.4923 17.2371 32.5378 16.5396L32.6528 14.7754C32.6939 14.1464 32.4702 13.5218 32.0392 13.0617ZM31.2193 14.6819L31.1042 16.4461C31.0881 16.6935 30.9632 16.9161 30.7701 17.0415C30.5266 17.1997 30.2119 17.1998 29.9682 17.0415C29.7752 16.9161 29.6504 16.6935 29.6342 16.4461L29.5191 14.6819C29.5036 14.4443 29.5848 14.2177 29.7475 14.044C29.9102 13.8703 30.131 13.7746 30.3691 13.7746C30.6072 13.7746 30.828 13.8703 30.9907 14.044C31.1536 14.2177 31.2347 14.4443 31.2193 14.6819Z"
        fill="white"
      />
      <path
        d="M36.051 22.8157L35.8515 21.7139C35.589 20.2655 34.4415 19.1095 32.996 18.8373C32.7777 18.7962 32.5546 18.7754 32.3327 18.7754H31.7315C31.5411 18.7754 31.3584 18.8511 31.2236 18.9858C30.7524 19.457 29.9859 19.4571 29.5147 18.9858C29.3799 18.8511 29.1972 18.7754 29.0067 18.7754H28.4056C28.1837 18.7754 27.9605 18.7962 27.7424 18.8373C26.2968 19.1095 25.1493 20.2655 24.8868 21.7139L24.6873 22.8157C24.6493 23.0252 24.7063 23.2408 24.8427 23.4042C24.9793 23.5676 25.1813 23.6621 25.3942 23.6621H35.3443C35.5572 23.6621 35.7591 23.5676 35.8955 23.4042C36.032 23.2408 36.0889 23.0253 36.051 22.8157ZM26.2545 22.2254L26.3007 21.97C26.4577 21.1036 27.144 20.412 28.0085 20.2492C28.1392 20.2246 28.2728 20.2121 28.4058 20.2121H28.7355C29.1996 20.578 29.7692 20.7764 30.3693 20.7764C30.9693 20.7764 31.5389 20.578 32.003 20.2121H32.3328C32.4657 20.2121 32.5994 20.2246 32.7302 20.2492C33.5946 20.412 34.2808 21.1036 34.4378 21.97L34.4841 22.2254H26.2545Z"
        fill="white"
      />
      <path
        d="M29.8318 9.48061C28.5951 7.75341 26.9633 6.29309 25.1124 5.25756C24.7664 5.06393 24.3285 5.18747 24.1348 5.53373C23.941 5.87999 24.0648 6.31767 24.411 6.51143C26.0786 7.44455 27.5491 8.76055 28.6636 10.317C28.8038 10.513 29.0244 10.6173 29.2484 10.6173C29.393 10.6173 29.5392 10.5737 29.6658 10.4829C29.9886 10.2519 30.0627 9.80313 29.8318 9.48061Z"
        fill="white"
      />
      <path
        d="M23.0658 4.31443C22.9437 4.26943 22.819 4.22526 22.6952 4.18342C22.3193 4.05608 21.9117 4.25787 21.7844 4.63364C21.6573 5.00941 21.8589 5.41716 22.2347 5.54436C22.3461 5.58205 22.4584 5.62177 22.5683 5.66241C22.6504 5.6927 22.7344 5.70707 22.817 5.70707C23.1093 5.70707 23.384 5.52739 23.4909 5.2372C23.6284 4.86495 23.438 4.45185 23.0658 4.31443Z"
        fill="white"
      />
      <path
        d="M13.8499 29.7068C11.2872 28.8399 9.00416 27.1449 7.42121 24.934C7.19019 24.6115 6.74145 24.5372 6.41893 24.7681C6.09627 24.9991 6.02203 25.4479 6.25298 25.7705C8.00962 28.2239 10.5441 30.1052 13.3894 31.0679C13.4658 31.0936 13.5434 31.106 13.6197 31.106C13.9193 31.106 14.1989 30.9171 14.3002 30.6176C14.4273 30.2418 14.2258 29.834 13.8499 29.7068Z"
        fill="white"
      />
      <path
        d="M29.6657 24.7681C29.3431 24.5371 28.8943 24.6115 28.6634 24.934C27.0805 27.1448 24.7974 28.8398 22.2347 29.7068C21.8589 29.8339 21.6573 30.2416 21.7844 30.6175C21.8857 30.917 22.1653 31.1058 22.4649 31.1058C22.5412 31.1058 22.6189 31.0935 22.6951 31.0678C25.5817 30.0912 28.0494 28.2594 29.8316 25.7704C30.0625 25.4478 29.9884 24.999 29.6657 24.7681Z"
        fill="white"
      />
    </svg>
  );
};

export default TeamMeetingPeople;
