import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BlueCardDashboardWelcome from "../blue-card-dashboard-welcome/BlueCardDashboardWelcome";
import TransparentDashboardCard from "../transparent-dashboard-card/TransparentDashboardCard";
import HouseSmallIcon from "../../svg-components/icons/HouseSmallIcon";
import FastSmallMessageIcon from "../../svg-components/icons/FastSmallMessageIcon";
import PdfSmallIcon from "../../svg-components/icons/PdfSmallIcon";
import { RootState } from "../../reducers";
import { useDispatch, useSelector } from "react-redux";
import "./dashboard-page-cards-container.scss";
import { Col, Row } from "antd";

const DashboardPageCardsContainer = () => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const latestBills = useSelector((state: RootState) => state.dashboardReducer.latestBills);
  return (
    <>
      <BlueCardDashboardWelcome
        username={user && user.surname ? user.surname : ""}
        percentage={profile && profile.progress ? profile.progress : ""}
        stepsValidated={profile && profile.progress ? profile.progress / 100 * 4 : 0}
      />
      <TransparentDashboardCard title={"Mes Abonnements"}>
        {profile && profile.subscriptions.length > 0 ? (
          <Row align="middle" gutter={[8, 8]} style={{width: "100%"}} className="subscriptions-container">
            <Col xs={24} md={4}>
              <HouseSmallIcon />
            </Col>
            <Col xs={24} md={20} className={"subscriptions-wrapper"}>
              {profile.subscriptions.map((sub, i) => (
                <div key={sub.id} className={"subscription-wrapper"}>
                  <span className="subscription-name">{sub.name}</span>
                  <span style={{display: "block", textAlign: "right"}}>{sub.price} DT / mois</span>
                </div>
              ))}
            </Col>
          </Row>
        ) : (
          <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucun abonnement</em>
        )}
        <div className="row" style={{ alignItems: "baseline" }}>
          <strong style={{ marginRight: "0.5rem" }}>Adresse:</strong>
          <div className="text-black">
            {profile && profile.address_infos ? profile.address_infos.full_location : null}
          </div>
        </div>
        <div className="row">
          <div className="left">
            <FastSmallMessageIcon />
          </div>
          <div className="right">Gratuit</div>
        </div>
        <button className={"light-blue-btn"} id={"setting-bills"} style={{ marginBottom: "-4rem" }}>
          <span><Link to={"/settings/subscriptions"}>Mes Abonnements</Link></span>
        </button>
      </TransparentDashboardCard>
      <div style={{ margin: "4rem auto" }}>
        <TransparentDashboardCard title={"Mes Factures"}>
          <div className="row">
            {latestBills && latestBills.length > 0 ? (
              <>
                <div className="left">
                  <PdfSmallIcon />
                </div>
                <div className={"invoices-wrapper"}>
                  {latestBills.map((bill, index) => (
                    <div key={bill.id}>
                      <strong>{bill.object} : </strong>
                      <span>{bill.bill_num}</span>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <em style={{ color: "#bfbfbf", textAlign: "center", display: "block" }}>Aucune facture</em>
            )}
          </div>
          <button className={"light-blue-btn"} id={"setting-bills"} style={{ marginBottom: "-4rem" }}>
            <span><Link to={"/settings/bill"}>Mes Factures</Link></span>
          </button>
        </TransparentDashboardCard>
      </div>

    </>
  );
};

export default DashboardPageCardsContainer;
