export const GET_MAILS_REQUEST = "GET_MAILS_REQUEST";
export const GET_MAILS_SUCCESS = "GET_MAILS_SUCCESS";
export const GET_MAILS_FAILURE = "GET_MAILS_FAILURE";

export const GET_MAIL_REQUEST = "GET_MAIL_REQUEST";
export const GET_MAIL_SUCCESS = "GET_MAIL_SUCCESS";
export const GET_MAIL_FAILURE = "GET_MAIL_FAILURE";


export const GET_NEW_MAILS_REQUEST = "GET_NEW__MAILS_REQUEST";
export const GET_NEW_MAILS_SUCCESS = "GET_NEW_MAILS_SUCCESS";
export const GET_NEW_MAILS_FAILURE = "GET_NEW_MAILS_FAILURE";

export const EXPORT_MAILS_REQUEST = "EXPORT_MAILS_REQUEST";
export const EXPORT_MAILS_SUCCESS = "EXPORT_MAILS_SUCCESS";
export const EXPORT_MAILS_FAILURE = "EXPORT_MAILS_FAILURE";
