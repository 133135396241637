import React from "react";

const GraphSmallIcon = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 0.5C18.3807 0.5 19.5 1.61929 19.5 3C19.5 4.38071 18.3807 5.5 17 5.5C16.8416 5.5 16.6867 5.48528 16.5365 5.45713L14.7185 8.18426C15.1998 8.63992 15.5 9.2849 15.5 10C15.5 11.3807 14.3807 12.5 13 12.5C11.6193 12.5 10.5 11.3807 10.5 10C10.5 9.6454 10.5738 9.30804 10.707 9.00245L8.12909 6.23111C7.78994 6.40308 7.40629 6.5 7 6.5C6.84164 6.5 6.68673 6.48528 6.53655 6.45712L4.71846 9.18425C5.19976 9.63992 5.5 10.2849 5.5 11C5.5 12.3807 4.38071 13.5 3 13.5C1.61929 13.5 0.5 12.3807 0.5 11C0.5 9.61929 1.61929 8.5 3 8.5C3.15835 8.5 3.31327 8.51472 3.46345 8.54288L5.28154 5.81574C4.80024 5.36008 4.5 4.7151 4.5 4C4.5 2.61929 5.61929 1.5 7 1.5C8.38071 1.5 9.5 2.61929 9.5 4C9.5 4.46548 9.37279 4.90124 9.15123 5.27442L11.6123 7.9202C12.0093 7.65477 12.4866 7.5 13 7.5C13.1584 7.5 13.3133 7.51472 13.4635 7.54288L15.2815 4.81574C14.8002 4.36008 14.5 3.7151 14.5 3C14.5 1.61929 15.6193 0.5 17 0.5ZM17 1.9C16.3925 1.9 15.9 2.39249 15.9 3C15.9 3.60751 16.3925 4.1 17 4.1C17.6075 4.1 18.1 3.60751 18.1 3C18.1 2.39249 17.6075 1.9 17 1.9ZM11.9 10C11.9 9.39249 12.3925 8.9 13 8.9C13.6075 8.9 14.1 9.39249 14.1 10C14.1 10.6075 13.6075 11.1 13 11.1C12.3925 11.1 11.9 10.6075 11.9 10ZM7 2.9C6.39249 2.9 5.9 3.39249 5.9 4C5.9 4.60751 6.39249 5.1 7 5.1C7.60751 5.1 8.1 4.60751 8.1 4C8.1 3.39249 7.60751 2.9 7 2.9ZM1.9 11C1.9 10.3925 2.39249 9.9 3 9.9C3.60751 9.9 4.1 10.3925 4.1 11C4.1 11.6075 3.60751 12.1 3 12.1C2.39249 12.1 1.9 11.6075 1.9 11Z"
        fill="white"
      />
    </svg>
  );
};

export default GraphSmallIcon;
