import React from "react";
import "./my-services-dashboard-cards.scss";
import { Col, Row } from "antd";
import { withRouter, useHistory } from "react-router";
import MyServicesCardDash from "./MyServicesCardDash";
import CallCenterLogo from "../../svg-components/CallCenterIcon"
import MettingLogo from "../../svg-components/MeetingTeamIcon"

const MyServicesDashboardCards = (props) => {
  const history : any = useHistory();
  const onClickService = (e, type) => {
    history.push({
      pathname: "/services",
      state: { type: type }
    })
  }
  return (
    <div className={"my-services-dashboard"}>
      <h1 className={"title"}>MES SERVICES</h1>
      <div className={"my-services-dashboard-cards"}>
        <Row gutter={[10, 10]}>
          <Col md={24} sm={24} xs={24} xl={12} lg={12} xxl={12}>
            <MyServicesCardDash
              title={"Solution Téléphonique"}
              image={<CallCenterLogo/>}
              onClick={(e) => onClickService(e, 'call')}
            />
          </Col>
          <Col md={24} sm={24} xs={24} xl={12} lg={12} xxl={12}>
            <MyServicesCardDash
              title={"Réservation Meeting room"}
              image={<MettingLogo/>}
              onClick={(e) => onClickService(e, 'meeting')}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(MyServicesDashboardCards);
