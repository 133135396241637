import React from "react";
import { Link } from "react-router-dom";

interface Interface {
  text: string;
  path: string;
  id: string;
}
const TealButton = ({ text, path, id }: Interface) => {
  return (
    <button className={"light-blue-btn"}>
      <span><Link to={path}>{text}</Link></span>
    </button>
  );
};

export default TealButton;
