import React from "react";

const CloseRed = () => {
    return (
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35Z" fill="#FD005C"/>
<path d="M24.4814 22.926L13.074 11.5186L10.9999 13.5927L22.4073 25.0001L24.4814 22.926Z" fill="white"/>
<path d="M22.9259 10.9999L11 22.9258L13.0741 24.9999L25 13.074L22.9259 10.9999Z" fill="white"/>
</svg>
    );
};

export default CloseRed;
