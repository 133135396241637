import React, { useEffect } from 'react';
import "./settings.scss";
import { Row, Col } from "antd";
import MenuSettingsSider from "../../components/settings-components/settings-sider/SettingsSideBar";

const SettingsPage = () => {
  useEffect(() => {
    document.title = `Mes Paramétres | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/settings`);
    }
  })
  return (
    <div className="settings-list">
      <Row gutter={[10, 10]}>
        <Col xs={24} md={24} lg={24}>
          <div className="header-settings">
            <h1 className={"title"}>Mes Paramétres</h1>
          </div>
        </Col>
        <Col xs={24} md={24} lg={24}>
          <MenuSettingsSider />
        </Col>
      </Row>
    </div>
  );
};

export default SettingsPage;
