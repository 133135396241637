import React, {Component, useState, useEffect} from 'react'
import './messages-components.scss';
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { List, Spin } from 'antd';
import { getDiscussions, filterDiscussions } from "../../actions/discussions-actions/actions";
import DiscussionCard from "./DiscussionsCard";
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')
import MessagesSearchBar from './MessagesSearchBar';
import { LoadingOutlined } from '@ant-design/icons';
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

interface DiscussionsListProps {
  setActiveDiscussion: any,
}

const DiscussionsList: React.FC<DiscussionsListProps> = ({setActiveDiscussion}) =>  {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const discussions = useSelector((state: RootState) => state.discussionsReducer.discussions);
  const filtredDiscussions = useSelector((state: RootState) => state.discussionsReducer.filtredDiscussions);
  const isLoadingDiscussions = useSelector((state: RootState) => state.discussionsReducer.isLoadingDiscussions);
  const total = useSelector((state: RootState) => state.discussionsReducer.total);
  const lastPage = useSelector((state: RootState) => state.discussionsReducer.lastPage);
  const [data, setData] = useState([]);
  const [filtredData, setFiltredData] = useState([]);
  const [page, setPage] = useState(1);
  const [inSearchMode, setInSearchMode] = useState(false);
  const [searchData, setSearchData] = useState({key: '', filter: null});
  const useMountEffect = (fun) => useEffect(fun, [])

  const fetchDiscussions = async () => {
    if(user){
      await dispatch(getDiscussions(page))
    }
  }

  const fetchFiltredDiscussions = async () => {
    if(user){
      const values = {...searchData, page: page}
      await dispatch(filterDiscussions(values))
    }
  }

  useEffect(() => {
    if(page <= lastPage){
      fetchDiscussions();
    }
  }, [page])

  useEffect(() => {
    if(searchData.key.length > 0){
      setInSearchMode(true);
      fetchFiltredDiscussions();
    }else{
      setInSearchMode(false);
      setPage(1);
    }
  }, [searchData.key])

  useEffect(() =>{
    if(discussions){
        setData(discussions)
    }
  }, [discussions])
  useEffect(() =>{
    if(filtredDiscussions && filtredDiscussions.data){
      setFiltredData(filtredDiscussions.data)
    }
  }, [filtredDiscussions])
  const loadMore = (e) => {
    if(data.length <= total){
      const limit = e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop;
      if(limit < 5){
        const nextPage = page + 1;
        setPage(nextPage)
      }
    }
  }
  const listLoading = {
    spinning: isLoadingDiscussions && page === 1,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  const setSelectedDiscussion = (selectedDiscussionId) => {
    setActiveDiscussion(selectedDiscussionId);
  }

  return (
    <div className={"left"}>
      <MessagesSearchBar
        search={(values) => setSearchData(values)}
      />
      <div className={"discussions-list-wrapper"} onScroll={loadMore}>
          <List
            loading={listLoading}
            itemLayout="horizontal"
            locale={{emptyText: <em style={{color: "#bfbfbf"}}>Aucun message</em>}}
            dataSource={inSearchMode ? filtredData : data}
            renderItem={discussion => (
              <DiscussionCard
                discussion={discussion}
                setSelectedDiscussion={setSelectedDiscussion}
              />
            )}
          />
      </div>
    </div>
  )
}
export default DiscussionsList;
