import React, { useEffect, useState } from "react";
import "./card-blue-checkout.scss";
import CheckoutItem from "./CheckoutItem";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { getPaymentToken, applyCoupon } from "../../actions/profile-actions/actions";
import { updateCart, emptyCart } from "../../actions/cart-actions/actions";
import { Button, Spin, Input, Col, Row } from 'antd';
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import $ from "jquery";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const DROIT_TIMBRE: number = Number(process.env.MIX_DROIT_TIMBRE) ? Number(process.env.MIX_DROIT_TIMBRE) : 0.6;
const CardBlueCheckout = ({ payment, isVisiblePayBtn, withCouponField }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [coupon, setCoupon] = useState(null);
  const [btnContent, setBtnContent] = useState(true);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const cart = useSelector(
    (state: RootState) => state.cartReducer.cart
  );
  const cartIsUpdating = useSelector(
    (state: RootState) => state.cartReducer.cartIsUpdating
  );
  const isSendingRequest = useSelector(
    (state: RootState) => state.profileReducer.isSendingRequest
  );
  const isApplyingCoupon = useSelector(
    (state: RootState) => state.profileReducer.isApplyingCoupon
  );
  const couponApplied = useSelector(
    (state: RootState) => state.profileReducer.couponApplied
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const initiatePayBill = async () => {
    if (bill) {
      dispatch(getPaymentToken({ bill_id: bill.id, method: payment}));
    }
  }
  useEffect(() => {
    if (bill?.total_ttc) {
      let packs = [];
      bill.lines.map((item, i) => packs.push({ id: item.subscription.offer_id, name: item.name, priceHt: item.price_ht }))
      const newCart = {
        address: {
          id: cart.address.id,
          location: cart.address.location,
        },
        packs: packs,
        billing: {
          type: bill.billing_type,
          totalHt: bill.total_ht,
          totalAmount: bill.total_ttc
        }
      }
      dispatch(updateCart(newCart));
    }
  }, [bill]);
  const apply = () => {
    if (bill && coupon) {
      dispatch(applyCoupon({ bill_id: bill.id, coupon: coupon }))
    }
  }
  const toggleBtn = () => {
    $("#coupon-section").toggle("slow");
    setBtnContent(!btnContent);
  }
  return (
    <div className={'card-blue-checkout'}>
      <h2 className={'white-title'}>Ma commande</h2>
      <div>
        {cartIsUpdating ? (
          <Spin indicator={antIcon} style={{ color: "#fff", marginTop: "10%" }} />
        ) : cart && cart.packs.length > 0 ? (
          <>
            {cart.packs.map((pack, index) => (
              <CheckoutItem
                key={index}
                title={pack.name + (cart.billing.type !== 0 ? " /mois" : " /h") || null}
                description={pack.name === "Domiciliation" ? cart.address.full_location : null}
                price={pack.priceHt || null}
              />
            )
            )}
            <CheckoutItem
              title={"Total HT"}
              description="Vous pouvez récupérer la TVA"
              price={cart.billing.totalHt}
            />
            <CheckoutItem
              title="Total TTC à payer*"
              description={null}
              price={cart.billing.totalAmount}
            />
            <small style={{ paddingTop: "2rem", color: "#fff" }}><sup>*</sup>{DROIT_TIMBRE.toLocaleString('fr-FR', { minimumFractionDigits: 3 })} Dt droit de timbre.</small>
          </>
        ) : (
          <em style={{ color: "#fff", textAlign: "center", display: "block", marginTop: "10%" }}>Votre panier est vide</em>
        )}
      </div>
      {withCouponField && isVisiblePayBtn && !couponApplied ? (
        <>
          <div className="coupon-wrapper" id="coupon-section" style={{ display: "none" }}>
            <Row align="middle">
              <Col xs={24} md={16}>
                <label htmlFor="coupon">Veuillez introduire votre coupon</label>
                <Input
                  placeholder={"---------------"}
                  size="large"
                  type="text"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
                >
                </Input>
              </Col>
              <Col xs={24} md={8}>
                <Button type="primary" className={coupon ? "btn apply-coupon" : "btn apply-coupon disabled"} onClick={apply} disabled={!coupon}>
                  {isApplyingCoupon ?
                    (<Spin indicator={antIcon} />)
                    : "Appliquer"
                  }
                </Button>
              </Col>
            </Row>
          </div>
          <Button type="text" className="btn show-coupon-field" id="coupon-btn" onClick={toggleBtn}>{btnContent ? "Vous avez un code Coupon ?" : "Annuler"}</Button>
        </>
      ) : null}
      {isVisiblePayBtn ? (
        <Button className="light-blue-btn" onClick={initiatePayBill}>{isSendingRequest ? (<Spin indicator={antIcon} />) : payment == "paymee" ? "Payer maintenant" : "Valider"}</Button>
      ) : null}
    </div>
  );
};

export default CardBlueCheckout;

