import * as React from "react";
import { useEffect, useState } from "react";
import "./profile-page.scss";
import { useSelector, useDispatch } from "react-redux";
import BlueProgressCard from "../../components/profile-components/blue-progress-card/BlueProgressCard";
import { Steps, Row, Col, Button, Spin, Skeleton } from "antd";
import StepProfileBtnItem from "../../components/profile-components/step-profile-btn-item/StepProfileBtnItem";
import MyInfosProfileBlock from "../../components/profile-components/my-infos-profile-block/MyInfosProfileBlock";
import UserInfosForm from "../../components/profile-components/user-infos-form/UserInfosForm";
import { RootState } from "../../reducers";
import FilesUploadFormStepTwo from "../../components/profile-components/files-upload-form-step-two/FilesUploadFormStepTwo";
import OfficialDocumentsForm from "../../components/profile-components/official-documents-form/OfficialDocumentsForm";
import ProcurationFormPostal from "../../components/profile-components/procuration-form-postal/ProcurationFormPostal";
import DownloadContract from "../../components/profile-components/download-contract/DownloadContract";
import SignContract from "../../components/profile-components/sign-contract/SignContract";
import { getProfile, updateProfile, getContractStatus } from "../../actions/profile-actions/actions";
import { LoadingOutlined } from '@ant-design/icons';
import ProfileDoneModal from "../../components/profile-components/profile-done-modal/ProfileDoneModal";
import WomanData from "../../svg-components/WomanData";

const { Step } = Steps;
const customDot = (dot: any, { status, index }: any) => <></>;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ProfilePage = (props) => {
  const dispatch = useDispatch();
  const useMountEffect = (fun) => useEffect(fun, [])
  const user = useSelector((state: RootState) => state.authReducer.user);
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const isCheckingStatus = useSelector((state: RootState) => state.profileReducer.isCheckingStatus);
  const contract = useSelector((state: RootState) => state.profileReducer.contract);
  const isLoading = useSelector((state: RootState) => state.profileReducer.isLoadingProfile);
  const signURL = useSelector((state: RootState) => state.profileReducer.signURL);
  const [SelectedStep, setSelectedStep] = useState(1);
  const [isSigning, setIsSigning] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [fromSubmit, setFromSubmit] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTerminated, setIsTerminated] = useState(false);

  useEffect(() => {
    document.title = `Mon Profil | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/profile`);
    }
  })

  useEffect(() => {
    if (profile && profile.progress) {
      const step = (profile.progress + 25) / 100 * 4;
      setSelectedStep(step);
      if (step > 4) {
        setSelectedStep(1);
      }
    }
    if (profile && profile.progress >= 50 && props.location.pathname == "/profile/contract") {
      setSelectedStep(3);
    }
  }, [profile])

  useEffect(() => {
    if(SelectedStep == 3 && !profile.ngsign_contract){
      dispatch(getContractStatus());
    }
  }, [SelectedStep])

  useMountEffect(() => {
    if (user) {
      dispatch(getProfile());
    }
  })
  const onClickStep = (progress) => {
    if (progress - profile.progress <= 25) {
      setSelectedStep(progress / 100 * 4)
    }
  }
  const onClickBack = () => {
    setSelectedStep(SelectedStep - 1);
  }
  const onClickNext = () => {
    setFromSubmit(true)
  }
  // const nextStep = (values) => {
  //   if (profile.progress >= values.progress) {
  //     if (SelectedStep + 1 <= 4) {
  //       setSelectedStep(SelectedStep + 1);
  //     } else {
  //       setSelectedStep(1);
  //     }
  //   }
  // }
  const updateProfileData = async (hasChanges, values) => {
    setFromSubmit(false)
    if (hasChanges) {
      setIsSendingRequest(true);
      if (values.progress <= profile.progress) {
        values.progress = profile.progress
      }
      if (values.sign) {
        setIsSigning(true);
      } else {
        setIsSigning(false);
      }
      const res = await dispatch(updateProfile(values));
      if (values.progress == 100) {
        setIsTerminated(true);
      }
      // nextStep(values);
    } else {
      if (SelectedStep + 1 <= 4) {
        setSelectedStep(SelectedStep + 1);
      } else {
        setSelectedStep(1);
      }
    }
  }
  useEffect(() => {
    if (signURL) {
      window.open(signURL, "_self");
    }
  }, [signURL])
  useEffect(() => {
    if(profile && profile.progress == 100 && isTerminated){
      setIsModalVisible(true);
      setIsTerminated(false);
    }
  }, [profile, isTerminated])

  return (
    profile ? (
      <div className={"profile-page"}>
        <div>
          <BlueProgressCard
            username={user && user.surname ? user.surname : ""}
            percentage={profile && profile.progress ? profile.progress : ""}
            step={profile && profile.progress ? profile.progress / 100 * 4 : 0}
          />
          <Steps current={SelectedStep} progressDot={customDot}>
            <Step
              title={
                <StepProfileBtnItem
                  text={"Mes coordonnées"}
                  isDone={profile && profile.progress >= 25}
                  isCurrent={SelectedStep === 1}
                  onClickCallback={() => onClickStep(25)}
                />
              }
            />
            <Step
              title={
                <StepProfileBtnItem
                  text={"Mes pièces justificatives"}
                  isDone={profile && profile.progress >= 50}
                  isCurrent={SelectedStep === 2}
                  onClickCallback={() => onClickStep(50)}
                />
              }
            />
            <Step
              title={
                <StepProfileBtnItem
                  text={isCheckingStatus ? (
                    <Spin indicator={antIcon} />
                  ) : profile.ngsign_contract || (contract && contract.status == "SIGNED") ? "Télécharger mon contrat" : "Signer mon contrat"
                }
                  isDone={profile && profile.progress >= 75}
                  isCurrent={SelectedStep === 3}
                  onClickCallback={() => onClickStep(75)}
                  isButton
                  lightBlue
                />
              }
            />
            <Step
              title={
                <StepProfileBtnItem
                  text={"Mes documents officiels"}
                  isDone={profile && profile.progress >= 100}
                  isCurrent={SelectedStep === 4}
                  onClickCallback={() => onClickStep(100)}
                />
              }
            />
            {/* <Step
          title={
            <StepProfileBtnItem
              text={"Procuration postale"}
              isDone={profile && profile.progress >= 100}
              isCurrent={SelectedStep === 5}
              onClickCallback={() => onClickStep(100)}
            />
          }
        /> */}
          </Steps>
          {SelectedStep === 4 ? (
            <Row gutter={[24, 24]} style={{ width: "100%" }}>
              <Col xs={24} md={14}>
                <MyInfosProfileBlock currentStep={SelectedStep} />
                <OfficialDocumentsForm unSubmit={() => setFromSubmit(false)} onSubmit={updateProfileData} isSubmited={fromSubmit} />
              </Col>
              <Col  xs={24} md={10}>
                <div className="img-svg-container">
                  <WomanData />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <MyInfosProfileBlock currentStep={SelectedStep} />
              {SelectedStep === 1 ? <UserInfosForm unSubmit={() => setFromSubmit(false)} onSubmit={updateProfileData} isSubmited={fromSubmit} /> : null}
              {SelectedStep === 2 ? <FilesUploadFormStepTwo unSubmit={() => setFromSubmit(false)} onSubmit={updateProfileData} isSubmited={fromSubmit} /> : null}
              {SelectedStep === 3 ? isCheckingStatus ? (
                <div style={{height: 100, width: 50}}></div>
              ) : profile.ngsign_contract || (contract && contract.status == "SIGNED") ? (
                <DownloadContract unSubmit={() => setFromSubmit(false)} onSubmit={updateProfileData} isSubmited={fromSubmit} />
              ) : (
                <SignContract unSubmit={() => setFromSubmit(false)} onSubmit={updateProfileData} isSubmited={fromSubmit} isSigningRequest={isSigning} />
              ) : null}
            </>
          )}
        </div>
        <Row gutter={[8, 8]} className={"navigation-buttons-wrapper"}>
          {SelectedStep > 1 && SelectedStep <= 4 ? (
            <Col xs={24} md={12} lg={12}>
              <Button
                size={"large"}
                className={"bordered-blue-button"}
                type="primary"
                onClick={onClickBack}
              >
                <span style={{ marginRight: "3px" }}>&#60;</span> Précédent
              </Button>
            </Col>
          ) : null}
          {SelectedStep <= 4 ? (
            <Col xs={24} md={12} lg={12}>
              <Button
                size={"large"}
                className={"bordered-blue-button"}
                type="primary"
                htmlType="submit"
                onClick={onClickNext}
              >
                {isLoading && !isSigning && isSendingRequest ? (
                  <Spin indicator={antIcon} style={{ color: "#ffffff" }} />
                ) : (
                  <span>Suivant <span style={{ marginLeft: "1px" }}>&#62;</span></span>
                )}
              </Button>
            </Col>
          ) : null}
        </Row>
        <ProfileDoneModal
          visible={isModalVisible}
          abort={() => setIsModalVisible(false)}
        />
      </div>
    ) : (
      <div className={"profile-page"}>
        <Skeleton active />
      </div>
    )
  );
};

export default ProfilePage;
