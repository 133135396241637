import React, {Component, useState, useEffect} from 'react'
import './messages-components.scss';
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { List, Badge, Avatar, Skeleton } from 'antd';
import FileSmall from '../../svg-components/icons/FileSmall';
import $ from 'jquery';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')

interface DiscussionsCardProps {
  discussion: any;
  setSelectedDiscussion: any;
}

const DiscussionsCard : React.FC<DiscussionsCardProps> = ({discussion, setSelectedDiscussion}) =>  {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const isLoadingDiscussions = useSelector((state: RootState) => state.discussionsReducer.isLoadingDiscussions);
  const [isSelected, setIsSelected] = useState(false);
  const [hasUnseen, setHasUnseen] = useState(false);
  const useMountEffect = (fun) => useEffect(fun, [])

  const onSelectDiscussion = (discussionId) => {
    $('.discussion-wrapper').removeClass('selected')
    setIsSelected(true);
    setSelectedDiscussion(discussionId);
    setHasUnseen(false)
  }

  const getDiscussionLastMessage = (discussion) => {
    let lastMessage = null;
    if(discussion.messages.length > 0){
      lastMessage = discussion.messages[discussion.messages.length - 1];
    }
    return lastMessage
  }

  const getDiscussionUnseenMessagesNumber = (discussion) => {
    let unseenMessages = [];
    if(discussion.messages.length > 0){
      unseenMessages = discussion.messages.filter((message) => message.status === "unseen" && message.user_id !== user.id)
    }
    return unseenMessages.length
  }

  useMountEffect(() => {
    if(discussion){
      const unseenNumber = getDiscussionUnseenMessagesNumber(discussion);
      if(unseenNumber > 0){
        setHasUnseen(true)
      }
    }
  })

  return (
    <List.Item className={isSelected ? "discussion-wrapper selected" : "discussion-wrapper"} onClick={() => onSelectDiscussion(discussion.id)} id={`discussion-${discussion.id}`}>
      <Skeleton avatar title={false} loading={isLoadingDiscussions} active>
        <List.Item.Meta
          avatar={
            <Avatar src={discussion.support.user.avatar ? process.env.MIX_STORAGE_URL + discussion.support.user.avatar : ""} />
          }
          title={
            <div className={"discussion-header"}>
              <div className={"from-info"}>
                <div className={"from-name"}>{window.capitalize(discussion.support.user.full_name)}</div>
                <div className={"from-last-active"}>en ligne il y a {moment(discussion.support.user.last_active_at).fromNow(true)}</div>
              </div>
              <small className={"updated-at"}>{moment(discussion.updated_at).fromNow(true)}</small>
            </div>
          }
          description={
            <div className={"discussion-preview"}>
              <div className={"discussion-preview-content"}>
                {getDiscussionLastMessage(discussion) ?
                  getDiscussionLastMessage(discussion).content ? (
                    <div className={"text"}>{getDiscussionLastMessage(discussion).content}</div>
                    ) : (
                    <div className={"file"}><FileSmall/>{getDiscussionLastMessage(discussion).shared_files[0].file_name}
                    </div>
                  )
                : null}
              </div>
              <Badge count={hasUnseen ? getDiscussionUnseenMessagesNumber(discussion) : 0} className="unseen-messages-badge"/>
            </div>
          }
        />
      </Skeleton>
    </List.Item>
  )
}
export default DiscussionsCard;
