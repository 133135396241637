import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useParams,
  useRouteMatch,
  useLocation,
  Link,
  withRouter
} from "react-router-dom";
import { Layout, Menu } from "antd";
import "./sidebar.scss";
import SignalSmallIcon from "../../svg-components/icons/SignalSmallIcon";
import GraphSmallIcon from "../../svg-components/icons/GraphSmallIcon";
import LettreSmallIcon from "../../svg-components/icons/LettreSmallIcon";
import ShareSmallIcon from "../../svg-components/icons/ShareSmallIcon";
import SettingsSmallIcon from "../../svg-components/icons/SetttingsSmallIcon";
import Logo from "../../svg-components/Logo";

const { Sider } = Layout;
interface InterfaceMenuInSiderTypes {
  history: any;
}
export const MenuIndrawerSider: React.FC<InterfaceMenuInSiderTypes> = ({
  history,
}) => {
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const handleSelected = ({ item, key, keyPath, selectedKeys, domEvent }: any) => {
    setSelectedMenu(key)
  }
  useEffect(() => {
      switch (history.location.pathname) {
        case "/profile":
          setSelectedMenu('profile')
          break;
        case "/profile/contract":
          setSelectedMenu('profile')
          break;
        case "/mails":
          setSelectedMenu('mails')
          break;
        case "/services":
          setSelectedMenu('services')
          break;
        case "/settings/confidentiality":
          setSelectedMenu('settings')
          break;
        case "/settings/notification":
          setSelectedMenu('settings')
          break;
        case "/settings/repertory":
          setSelectedMenu('settings')
          break;
        case "/settings/bill":
          setSelectedMenu('settings')
          break;
        case "/settings/download":
          setSelectedMenu('settings')
          break;
        case "/settings/subscriptions":
          setSelectedMenu('settings')
          break;
        case "/notifications":
          setSelectedMenu('notifications')
          break;
        case "/messages":
          setSelectedMenu('messages')
          break;

        default:
          setSelectedMenu('dashboard')
          break;
      }
  }, [history.location])
  return (
    <Menu
      theme="light"
      defaultSelectedKeys={[selectedMenu]}
      selectedKeys={[selectedMenu]}
      mode="inline"
      onSelect={handleSelected}
    >
      <Menu.Item
        key="dashboard"
        className={"sidebard-menu-link"}
        icon={<SignalSmallIcon />}
      >
        <Link to={"/"}>Tableau de bord</Link>
      </Menu.Item>
      <Menu.Item
        key="profile"
        className={"sidebard-menu-link"}
        icon={<GraphSmallIcon />}
      >
        <Link to={"/profile"}>Profil</Link>
      </Menu.Item>
      <Menu.Item
        key="mails"
        className={"sidebard-menu-link"}
        icon={<LettreSmallIcon />}
      >
        <Link to={"/mails"}>Mes Courriers</Link>
      </Menu.Item>
      <Menu.Item
        key="services"
        className={"sidebard-menu-link"}
        icon={<ShareSmallIcon />}
      >
        <Link to={"/services"}>Mes Services</Link>
      </Menu.Item>
      <Menu.Item
        key="settings"
        className={"sidebard-menu-link"}
        icon={<SettingsSmallIcon />}
      >
        <Link to={"/settings/confidentiality"}>Paramètres</Link>
      </Menu.Item>
    </Menu>
  );
};

const Sidebar = ({ history }: any) => {
  return (
    <Sider
      width={260}
      className={"sidebar"}
      breakpoint="lg"
      collapsible={true}
      collapsedWidth="0"
      trigger={null}
    >
      <a href={process.env.MIX_SITE_URL}>
        <div className="sidebar-logo-container">
          <Logo/>
          {/* <SmallLogo /> */}
        </div>
      </a>
      <MenuIndrawerSider history={history} />
    </Sider>
  );
};

export default withRouter(Sidebar);
