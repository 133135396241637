import React, { useState } from 'react';
import "../../pages/settings-page/settings.scss";
import { Input, Space, Switch, Button, Row, Col, Form } from 'antd';
import EditSmallIcon from "../../svg-components/icons/EditSmallIcon";
import { useDispatch, useSelector } from "react-redux";
import { updateNotif } from "../../actions/auth-actions/actions";
import { RootState } from "../../reducers";
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'
import { useForm } from 'antd/lib/form/Form';

const services = [
  { field: "noti_mail", title: "Courrier", text: "Je souhaite recevoir des mails de notification concernant mes courriers" },
  { field: "noti_support", title: "Support", text: "Je souhaite recevoir des mails de notification concernant mes demandes au service support" },
  { field: "noti_marketing", title: "Marketing", text: "Je souahite recevoir des mails d'actualité de la part de Domicilier.tn" },
]
const suffix = (
  <EditSmallIcon
  />
);

const DisabledEmailEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  return (
    <Space>
      <Input
        placeholder={"Tapez votre E-mail"}
        className="edited-email"
        size="large"
        type="text"
        addonAfter={suffix}
        disabled={true}
        value={user ? user.email : null}
        style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
      >
      </Input>
      <Button htmlType="submit" className={"edit-btn"} onClick={props.onClick}>éditer</Button>
    </Space>
  )
}
const EnabledEmailEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const dispatch = useDispatch();
  const getData = (data: any) => {
    if (data.email && data.email.length > 0) {
      dispatch(updateNotif(data));
    }
    props.onClick();
  }
  return (
    <Form onFinish={getData}>
      <Form.Item
        style={{ width: "100%", marginBottom: "0" }}
        name="email"
        rules={[
          { required: false, message: "Saisissez votre e-mail" },
          { type: "email", message: "Saisissez une adresse e-mail valide" },
        ]}
        initialValue={user ? user.email : null}
      >
        <Space>
          <Input
            placeholder={"Tapez votre nouvel E-mail"}
            className={"edit-input"}
            size="large"
            type="text"
            style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
          >
          </Input>
          <Button htmlType="submit" className={"save-btn"} >Enregistrer</Button>
          <Button htmlType="button" className={"edit-btn"} onClick={() => props.onClick()}>Annuler</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

const DisabledPhoneEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  return (
    <Space>
      <Input
        placeholder={"Tapez votre numéro de téléphone"}
        className="edited-email"
        size="large"
        type="text"
        addonAfter={suffix}
        disabled={true}
        value={user ? user.phone : null}
        style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
      >
      </Input>
      <Button htmlType="submit" className={"edit-btn"} onClick={props.onClick}>éditer</Button>
    </Space>
  )
}
const EnabledPhoneEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const dispatch = useDispatch();
  const [form] = useForm();
  const getData = (data: any) => {
    if (data.phone && data.phone.length > 0) {
      dispatch(updateNotif(data));
    }
    props.onClick();
  }
  return (
    <Form onFinish={getData} form={form}>
      <Form.Item
        style={{ width: "100%", marginBottom: "0" }}
        name="phone"
        rules={[
          {
            required: true,
            message: "Vous devez saisir un numéro de téléphone",
          },
          {
            validator: (rule: any, value: string, cb: (msg?: string) => void) => {
              value && isValidPhoneNumber(value) === false ? cb("Vous devez saisir un numéro de téléphone valide") : cb();
            }
          },
        ]}
        validateTrigger="onBlur"
        initialValue={user ? user.phone : null}
      >
        <Space>
          <PhoneInput
            country={"tn"}
            regions={['north-africa', 'europe']}
            localization={fr}
            prefix={"+"}
            placeholder="Téléphone"
            inputClass="phone-input"
          />
          <Button htmlType="submit" className={"save-btn"} >Enregistrer</Button>
          <Button htmlType="button" className={"edit-btn"} onClick={() => props.onClick()}>Annuler</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

const Services = (props) => {
  const [Checked, setChecked] = useState(props.toggled);
  const dispatch = useDispatch();
  const handleSwitcher = () => {
    let data = `{"${props.field}":"${!Checked}"}`;
    dispatch(updateNotif(data));
    setChecked(!Checked);
  }
  return (
  <div className={"services-information"}>
    <h3 className={"service-title"}>{props.title}</h3>
    <Space align='baseline' style={{marginBottom: "1rem"}}>
      <p className={"service-text"}>{props.text}</p>
      <Switch onClick={handleSwitcher} checked={Checked}></Switch>
    </Space>
  </div>)
}

const NotificationPage = () => {
  const [DisabledEmail, setDisabledEmail] = useState(true);
  const [DisabledPhone, setDisabledPhone] = useState(true);
  const user = useSelector(
    (state: RootState) => state.authReducer.user
  );
  const handleEmailEdit = () => {
    setDisabledEmail(!DisabledEmail);
  }
  const handlePhoneEdit = () => {
    setDisabledPhone(!DisabledPhone);
  }
  return (
    <div>
      <div className={"header-menu"}>
        <h1 className={"menu-title"}>Notifications</h1>
        <p className={"service-text"}>Recever les informations nécessaires concernant votre courrier, <br></br> votre domiciliation et les actualités de votre service.</p>
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "39px" }}>
        <Col xs={24} md={4} lg={4}>
          <label>E-mail :
            <span className="redstar">*</span>
          </label>
        </Col>
        <Col xs={24} md={20} lg={20}>
          {DisabledEmail && (<DisabledEmailEdit onClick={() => {
            handleEmailEdit();
          }} />)}
          {!DisabledEmail && (<EnabledEmailEdit onClick={() => {
            handleEmailEdit();
          }} />)}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "39px" }}>
        <Col xs={24} md={4} lg={4}>
          <label>Téléphone :
            <span className="redstar">*</span>
          </label>
        </Col>
        <Col xs={24} md={20} lg={20}>
          {DisabledPhone && (<DisabledPhoneEdit onClick={() => {
            handlePhoneEdit();
          }} />)}
          {!DisabledPhone && (<EnabledPhoneEdit onClick={() => {
            handlePhoneEdit();
          }} />)}
        </Col>
      </Row>
      <div>
        {services.map((element, index) => {
          return <Services toggled={user ? user[element.field] : null} field={element.field} key={index} title={element.title} text={element.text} />
        })}
      </div>
    </div>
  );
};

export default NotificationPage;
