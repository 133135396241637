import React, {useEffect, useState} from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Link, Redirect } from "react-router-dom";
import { Modal, Spin, Button, Select } from 'antd';
import CloseRed from "../../svg-components/icons/CloseRed";
import { LoadingOutlined } from '@ant-design/icons';
import { getMyTeam } from '../../actions/discussions-actions/actions';
import DownArrow from "../../svg-components/icons/DownArrow";
import $ from "jquery";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

interface NewMessageModalProps {
  visible: boolean,
  onSubmitNewMessage: any,
  abort: any
}

const NewMessageModal : React.FC<NewMessageModalProps> = ({visible, onSubmitNewMessage, abort}:any) => {
  const dispatch = useDispatch();
  const myTeam = useSelector(
    (state: RootState) => state.discussionsReducer.myTeam
  );
  const isLoadingTeam = useSelector(
    (state: RootState) => state.discussionsReducer.isLoadingTeam
  );
  const isLoadingCurrentDiscussion = useSelector(
    (state: RootState) => state.discussionsReducer.isLoadingCurrentDiscussion
  );
  const useMountEffect = (fun) => useEffect(fun, [])
  const [VisibleModal, setVisibleModal] = useState(false);
  const [messageTo, setMessageTo] = useState(null);
  useEffect(() => {
    setVisibleModal(visible);
  }, [visible])
  useEffect(() => {
    if (VisibleModal) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [VisibleModal])
  const closeModal = () => {
    abort();
  }
  const onClickNewMessage = () => {
    if(messageTo){
      onSubmitNewMessage(messageTo);
    }
  }
  const getMemberTags = (member) => {
    let tags = [];
    if(member.type && member.type.length > 0 ){
      member.type.map((typee, i) => tags.push(<span className="support-tag" key={i}>#{typee}</span>))
    }
    return tags
  }
  useEffect(() => {
    if(VisibleModal && !myTeam){
      dispatch(getMyTeam());
    }
  }, [VisibleModal])
  return (
    <Modal
      title={<h1 className={"menu-title"}>Nouvelle réclamation</h1>}
      centered
      visible={VisibleModal}
      onOk={onClickNewMessage}
      onCancel={closeModal}
      style={{ top: 20 }}
      closeIcon={<CloseRed/>}
      footer={[
        <Button onClick={closeModal} className={"white-btn"}>Annuler</Button>,
        <Button onClick={onClickNewMessage} type={"primary"} className={"blue-btn"}>
          {isLoadingCurrentDiscussion ? (
            <Spin indicator={antIcon} style={{color: "#3240FF" }}/>
          ) : "Valider"}
        </Button>
      ]}
    >
      <div className={"new-message-modal"}>
        <Select
          size={"large"}
          onChange={(value) => setMessageTo(value)}
          value={messageTo}
          placeholder={"Sélectionner un support"}
          style={{width: "100%"}}
        >
          {myTeam && myTeam.map((member, i) => (<Option key={i} value={member.user_id}>{member.user.full_name} {getMemberTags(member)}</Option>))}
        </Select>
      </div>
    </Modal>
  );
}
export default NewMessageModal;
