import React from "react";
interface RectangleRadioBtnInterfaceTypes {
  label: string;
  name: string;
  text: string;
  icon: React.FunctionComponentElement<any>;
  defaultChecked: boolean;
  onClick: () => void;
}
const RectangleRadioBtnWithIcon: React.FC<RectangleRadioBtnInterfaceTypes> = ({
  label,
  name,
  text,
  icon,
  defaultChecked,
  onClick,
}) => {
  return (
    <label className="rectangle-radio-btn">
      <input
        defaultChecked={defaultChecked}
        name={name}
        id={"#" + label}
        type="radio"
      />
      <div className="checkmark" onClick={onClick}>
        <div className="svg-icon-container">{icon}</div>
        <div className="text">{text}</div>
      </div>
    </label>
  );
};

export default RectangleRadioBtnWithIcon;
