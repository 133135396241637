import React from "react";

const NormalNotifIcon = () => {
return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="30" height="30" rx="15" fill="#3240FF" />
  <g clip-path="url(#clip0)">
    <path
      d="M20.7627 13.3446C20.6942 13.2004 20.5488 13.1084 20.3891 13.1084H16.2407L18.7516 8.85937C18.8272 8.73151 18.8283 8.57291 18.7547 8.44395C18.6811 8.31498 18.544 8.2353 18.3955 8.23535L14.8513 8.23574C14.7043 8.23579 14.5682 8.31388 14.4941 8.44091L9.84249 16.4147C9.76785 16.5426 9.76732 16.7007 9.84108 16.8291C9.91484 16.9575 10.0516 17.0367 10.1997 17.0367H14.3216L13.1211 21.8391C13.0741 22.0269 13.1639 22.2223 13.337 22.3092C13.3961 22.3387 13.4594 22.353 13.5221 22.353C13.6434 22.353 13.7621 22.2997 13.8428 22.2008L20.7096 13.7835C20.8105 13.6598 20.8312 13.4889 20.7627 13.3446Z"
      fill="white" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="14.1176" height="14.1176" fill="white" transform="translate(8.23535 8.23535)" />
    </clipPath>
  </defs>
</svg>
);
};

export default NormalNotifIcon;
