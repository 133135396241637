import React from "react";

const DownArrow = () => {
  return (
  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.5 10L0.870835 0.249999L12.1292 0.25L6.5 10Z" fill="white"/>
  </svg>
  );
};

export default DownArrow;
