import React from "react";

const CheckSmallGreenIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM10.5605 4.67021C10.9051 4.32563 11.4638 4.32562 11.8083 4.67021C12.1529 5.01479 12.1529 5.57346 11.8083 5.91804L6.817 10.9094C6.6377 11.0887 6.40042 11.1747 6.1655 11.1674C5.93072 11.1746 5.6936 11.0886 5.5144 10.9094L3.17011 8.56509C2.82553 8.22051 2.82553 7.66183 3.17011 7.31725C3.51469 6.97267 4.07337 6.97267 4.41795 7.31725L6.16571 9.06501L10.5605 4.67021Z"
        fill="#A7E521"
      />
    </svg>
  );
};

export default CheckSmallGreenIcon;
