import {
  BrowserRouter as Router,
  useParams,
  useRouteMatch,
  Link
} from "react-router-dom";
import React, {useState} from 'react';
import { Menu, Row, Col } from "antd";
import FacturePage from "../FacturePage";
import NotificationPage from "../NotificationPage";
import ConfidentialityPage from "../ConfidentialityPage";
import RepertoryPage from "../RepertoryPage";
import PaymentModal from "../PaymentModal";
// import UploadFile from '../UploadFile';
import "./settingsSider.scss";
import { useEffect } from "react";
import SubscriptionsPage from "../SubscriptionsPage";

const MenuSettingsSider = () => {
  const param : any = useParams();
  const [selectedSetting, setSelectedSetting] = useState(param.settingName ? param.settingName : 'confidentiality');
  const handleSelected = ({ item, key, keyPath, selectedKeys, domEvent }: any) => {
    setSelectedSetting(key)
  }
  return (
    <Router>
    <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 16]} justify={"space-between"}>
    <Col xs={24} md={6} lg={6}>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedSetting]}
        selectedKeys={[selectedSetting]}
        onSelect={handleSelected}
        >
        <Menu.Item
         key="confidentiality"
        >
          <Link to={"/settings/confidentiality"}>Confidentialité</Link>
        </Menu.Item>
        <Menu.Item
          key="notification"
        >
          <Link to={"/settings/notification"}>Notifications</Link>
        </Menu.Item>
        <Menu.Item
         key="repertory"
        >
          <Link to={"/settings/repertory"}>Répertoire</Link>
        </Menu.Item>
        <Menu.Item
          key="bill"
        >
          <Link to={"/settings/bill"}>Factures</Link>
        </Menu.Item>
        {/* <Menu.Item
        >
          <Link to={"/settings/paymentMethod"}>Moyens de paiements</Link>
        </Menu.Item> */}
        {/* <Menu.Item
         key="download"
        >
          <Link to={"/settings/download"}>Téléchargement</Link>
        </Menu.Item> */}
        <Menu.Item
         key="subscriptions"
        >
          <Link to={"/settings/subscriptions"}>Abonnements</Link>
        </Menu.Item>
      </Menu>
      </Col>
      <Col xs={24} md={18} lg={18}>
          {selectedSetting === "confidentiality" ? <ConfidentialityPage /> : null}
          {selectedSetting === "notification" ? <NotificationPage /> : null}
          {selectedSetting === "repertory" ? <RepertoryPage /> : null}
          {selectedSetting === "bill" ? <FacturePage /> : null}
          {selectedSetting === "subscriptions" ? <SubscriptionsPage /> : null}
          {/* {selectedSetting === "download" ? null : null} */}
      </Col>
    </Row>
    </Router>
  );
};

export default (MenuSettingsSider);

