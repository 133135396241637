import React, { useEffect, useState } from "react";
import "./step-profile-btn-item.scss";
import CheckSmallGreenIcon from "../../../svg-components/icons/CheckSmallGreeenIcon";
import ErrorSmallRedIcon from "../../../svg-components/icons/ErrorSmallRedIcon";

interface StepProfileBtnItemInterface {
  text: any;
  isDone: boolean;
  isCurrent: boolean;
  onClickCallback?: any;
  isButton?: boolean;
  lightBlue?: boolean;
}
const StepProfileBtnItem: React.FC<StepProfileBtnItemInterface> = ({
  text,
  isDone,
  isCurrent,
  onClickCallback,
  isButton,
  lightBlue,
}) => {
  const [classNameOfStep, setClassNameOfStep] = useState("step-profile-btn-item");
  useEffect(() => {
    if (isDone) {
      setClassNameOfStep("step-profile-btn-item is_done");
    }else if (isCurrent) {
      setClassNameOfStep("step-profile-btn-item is_current");
    }else{
      setClassNameOfStep("step-profile-btn-item");
    }
    if (lightBlue) {
      setClassNameOfStep(classNameOfStep + " light-blue")
    }
  }, [isDone, isCurrent]);
  return (
    <div
      className={`step-profile-btn-item-container
      ${isButton ? "is_button" : " "}
      ${isCurrent ? " is_current" : " "}
      ${isDone ? "is_done" : " "}`}
      onClick={onClickCallback}
    >
      <button
        className={classNameOfStep}
      >
        <p> {text}</p>
      </button>
      <div className="svg-icon-container">
        {isDone ? <CheckSmallGreenIcon /> : <ErrorSmallRedIcon />}
      </div>
    </div>
  );
};

//@ts-ignore
export default StepProfileBtnItem;
