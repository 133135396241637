import React from "react";
import "./services-banner-with-img.scss";
import { Row, Col } from "antd";
const Business = require("../../assets/profil/business-meeting.png")

interface PropsBannerServiceWithImgCard {
  text: string;
  isNew: boolean;
  onClick(): void;
}

const ServiceBannerWithImg: React.FC<PropsBannerServiceWithImgCard> = ({
  text,
  isNew,
  onClick
}) => {
  return (
    <div className={"service-banner-with-img-card"}>
      <Row>
        <Col xs={24} lg={8}>
          <div className="left">
            {isNew ? <div className="is-new">Nouveauté</div> : null}
            <h3>{text}</h3>
            <button onClick={onClick}>Réserver</button>
          </div>
        </Col>
        <Col xs={0} lg={16}>
          <div className="right">
            <div className="img-container">
              <img
                src={Business}
                alt="pic"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServiceBannerWithImg;
