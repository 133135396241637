import React from "react";

const ArrowMessageIcon = () => {
  return (
    <svg viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.5369 0.120649C46.2506 -0.0427495 45.8985 -0.0399956 45.6148 0.127809L28.7927 10.0753C28.3563 10.3333 28.2117 10.8962 28.4697 11.3326C28.7277 11.7689 29.2906 11.9135 29.7271 11.6556L41.7967 4.51845L19.3151 27.8452L8.13352 24.4243L22.726 15.7954C23.1624 15.5373 23.307 14.9744 23.0489 14.538C22.791 14.1017 22.228 13.9569 21.7916 14.215L5.4078 23.9032C5.09477 24.0883 4.92045 24.4407 4.9635 24.802C5.00656 25.1632 5.25872 25.4648 5.60645 25.5712L18.96 29.6565L24.8513 40.3796C24.8574 40.3907 24.8638 40.4001 24.8703 40.4084C24.9686 40.5724 25.1159 40.7063 25.2997 40.7836C25.4147 40.832 25.5356 40.8556 25.6557 40.8556C25.891 40.8556 26.1226 40.7651 26.2975 40.594L33.0715 33.9737L45.8135 37.8719C45.9016 37.8988 45.992 37.9121 46.082 37.9121C46.2762 37.9121 46.4681 37.8504 46.628 37.7321C46.862 37.559 47 37.2851 47 36.9941V0.917996C47 0.588354 46.8232 0.283955 46.5369 0.120649ZM24.9013 30.2223C24.795 30.3759 24.738 30.5582 24.738 30.745V36.3603L20.7152 29.0381L38.5165 10.5677L24.9013 30.2223ZM26.5739 37.7569V31.9859L31.0711 33.3617L26.5739 37.7569ZM45.1641 35.7533L27.1261 30.2349L45.1641 4.19551V35.7533Z"
        fill="white"
      />
      <path
        d="M15.0936 31.9065C14.735 31.5481 14.1539 31.5481 13.7953 31.9065L5.22588 40.476C4.86741 40.8344 4.86741 41.4157 5.22588 41.7742C5.40525 41.9534 5.64016 42.043 5.87507 42.043C6.10998 42.043 6.34488 41.9534 6.52416 41.7742L15.0936 33.2047C15.452 32.8464 15.452 32.2651 15.0936 31.9065Z"
        fill="white"
      />
      <path
        d="M3.67837 43.3214C3.31981 42.9632 2.73874 42.9632 2.38018 43.3215L0.26885 45.4329C-0.0896167 45.7913 -0.0896167 46.3726 0.26885 46.7311C0.448129 46.9103 0.683037 46.9999 0.917945 46.9999C1.15285 46.9999 1.38776 46.9102 1.56704 46.731L3.67837 44.6197C4.03683 44.2613 4.03683 43.68 3.67837 43.3214Z"
        fill="white"
      />
      <path
        d="M13.0947 45.3788C12.9241 45.2081 12.6872 45.1099 12.4458 45.1099C12.2034 45.1099 11.9675 45.2081 11.7968 45.3788C11.6261 45.5496 11.5278 45.7855 11.5278 46.0278C11.5278 46.2693 11.626 46.5061 11.7968 46.6768C11.9675 46.8476 12.2044 46.9458 12.4458 46.9458C12.6872 46.9458 12.9241 46.8476 13.0947 46.6768C13.2655 46.5061 13.3638 46.2693 13.3638 46.0278C13.3638 45.7855 13.2655 45.5496 13.0947 45.3788Z"
        fill="white"
      />
      <path
        d="M19.9246 38.5486C19.5663 38.1902 18.9853 38.1902 18.6264 38.5486L14.0875 43.0875C13.729 43.446 13.729 44.0273 14.0875 44.3857C14.2667 44.565 14.5016 44.6546 14.7365 44.6546C14.9715 44.6546 15.2064 44.5649 15.3856 44.3857L19.9246 39.8468C20.2831 39.4884 20.2831 38.9071 19.9246 38.5486Z"
        fill="white"
      />
      <path
        d="M35.59 38.2002C35.2314 37.8418 34.6504 37.8418 34.2918 38.2002L29.7405 42.7515C29.3821 43.1099 29.3821 43.6912 29.7405 44.0497C29.9198 44.2289 30.1547 44.3186 30.3896 44.3186C30.6245 44.3186 30.8594 44.2289 31.0387 44.0497L35.59 39.4985C35.9485 39.14 35.9485 38.5587 35.59 38.2002Z"
        fill="white"
      />
      <path
        d="M26.0245 12.5129C25.8537 12.3421 25.6169 12.2439 25.3755 12.2439C25.1341 12.2439 24.8972 12.3421 24.7265 12.5129C24.5558 12.6836 24.4575 12.9204 24.4575 13.1628C24.4575 13.4042 24.5557 13.6401 24.7265 13.8109C24.8972 13.9825 25.1341 14.0798 25.3755 14.0798C25.6169 14.0798 25.8537 13.9825 26.0245 13.8109C26.1951 13.6401 26.2935 13.4042 26.2935 13.1628C26.2935 12.9204 26.1952 12.6836 26.0245 12.5129Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowMessageIcon;
