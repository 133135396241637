import React, { useState } from "react";
import { Input, Select, Row, Col, List } from "antd";
import { useDispatch } from "react-redux";
import { search } from "../../actions/dashboard-actions/actions";
import { getMail } from "../../actions/mails-actions/actions";
const verticalLine = require("../../assets/png/avatars/VerticalLine.png");

const { Option } = Select;
const { Search } = Input;

const NavbarSearchBar = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const onSearch = async (e) => {
    if (e.target.value && e.target.value.length > 0) {
      setSearchKey(e.target.value)
      setIsSearching(true)
      const values = { key: e.target.value.trim(), date: date }
      const res = await dispatch(search(values));
      if (res) {
        setResults(res);
        setIsSearching(false)
      }
    } else {
      setSearchKey(e.target.value)
      setIsSearching(false)
    }
  }
  const options = [
    <Option key={1} value={"week"}>Semaine</Option>,
    <Option key={1} value={"month"}>Mois</Option>,
    <Option key={1} value={"year"}>Année</Option>,
  ];
  const downloadMail = async (e, mail) => {
    e.preventDefault();
    await dispatch(getMail(mail.id));
  }
  return (
    <div className={"search-bar"}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ position: "relative" }}>
        <Col>
          <Search
            placeholder="Rechercher un courrier..."
            onKeyUp={onSearch}
            onSearch={onSearch}
            enterButton
            style={{ width: 270 }}
          />
        </Col>
        <Col>
          <Select
            placeholder={"Date"}
            style={{ width: '100%' }}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onChange={setDate}
          >
            {options}
          </Select>
        </Col>
        <img className="verticalLine" src={verticalLine} />
        {results && searchKey ? (
          <List
            size="small"
            header={<div></div>}
            footer={<div></div>}
            bordered
            locale={{ emptyText: <em>Aucun courrier trouvé</em> }}
            dataSource={results}
            loading={isSearching}
            renderItem={(item: any) => (
              <List.Item>
                {item.file ? (
                  <a onClick={(e) => downloadMail(e, item)} title={"Télécharger"}>
                    <div>
                      <span>Expéditeur </span>
                      <strong>{item.sender}</strong>
                    </div>
                    <small>{item.status}</small>
                  </a>
                ) : (
                  <a href={"#"} title={item.status}>
                    <div>
                      <span>Expéditeur </span>
                      <strong>{item.sender}</strong>
                    </div>
                    <small>{item.status}</small>
                  </a>
                )}
              </List.Item>)}
          />
        ) : null}
      </Row>
    </div>
  );
};

export default NavbarSearchBar;
