import React from "react";

const Note = () => {
return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10 2.40039H9C7.89543 2.40039 7 3.29582 7 4.40039V5.00046H7.18219C5.97536 5.01003 5 5.99131 5 7.20039V18.8004C5 20.0154 5.98497 21.0004 7.2 21.0004H16.8C18.015 21.0004 19 20.0154 19 18.8004V7.20039C19 5.98536 18.015 5.00039 16.8 5.00039H12V4.40039C12 3.29582 11.1046 2.40039 10 2.40039ZM12 5.00039H7.2C7.19406 5.00039 7.18812 5.00041 7.18219 5.00046H12V5.00039ZM8.4 4.99818H10.6V4.40039C10.6 4.06902 10.3314 3.80039 10 3.80039H9C8.66863 3.80039 8.4 4.06902 8.4 4.40039V4.99818ZM7.2 6.40039C6.75817 6.40039 6.4 6.75856 6.4 7.20039V18.8004C6.4 19.2422 6.75817 19.6004 7.2 19.6004H16.8C17.2418 19.6004 17.6 19.2422 17.6 18.8004V7.20039C17.6 6.75856 17.2418 6.40039 16.8 6.40039H7.2ZM9.7 9.00039C9.3134 9.00039 9 9.31379 9 9.70039C9 10.087 9.3134 10.4004 9.7 10.4004H14.3C14.6866 10.4004 15 10.087 15 9.70039C15 9.31379 14.6866 9.00039 14.3 9.00039H9.7ZM9 12.7004C9 12.3138 9.3134 12.0004 9.7 12.0004H14.3C14.6866 12.0004 15 12.3138 15 12.7004C15 13.087 14.6866 13.4004 14.3 13.4004H9.7C9.3134 13.4004 9 13.087 9 12.7004ZM9.7 15.0004C9.3134 15.0004 9 15.3138 9 15.7004C9 16.087 9.3134 16.4004 9.7 16.4004H12.3C12.6866 16.4004 13 16.087 13 15.7004C13 15.3138 12.6866 15.0004 12.3 15.0004H9.7Z" fill="white"/>
</svg>
);
};

export default Note;
