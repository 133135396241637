import * as React from "react";
import {Col, Form, Input, Row, Spin} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface SignupStepFourTypes {
  callBackFn: any;
  goBack: any;
}

const SignupStepFour: React.FC<SignupStepFourTypes> = ({callBackFn, goBack}) => {
  const isLoading = useSelector(
    (state: RootState) => state.authReducer.isLoading
  );
  const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  const submitFunction = (values: any) => {
    callBackFn(values)
  }
  const [form] = Form.useForm()

  return (
    <Form
      id={"signup-form-step-4"}
      name="signup-form-step-4"
      onFinish={submitFunction}
      form={form}
    >
      <Row>
        <h1 className={"white-title"}>Tapez votre mot de passe!</h1>
      </Row>
      <div className="marged-bottom-h3"/>
      <Row>
        <Col xxl={12} md={12} xs={24}>
          <Form.Item
            name="password"
            rules={[
              {
                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  value && !value.match(passwordPattern) ? cb("") : cb();
                }
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input.Password placeholder={"Password"} onChange={e => form.setFieldsValue({password: e.target.value})}/>
            <div className={"password-description"}>
              Le mot de passe doit contenir 8 caractères minimum, une lettre majuscule, une lettre minuscule, un symbole, un chiffre.
            </div>
          </Form.Item>
        </Col>
      </Row>
      <div className="separtor-small-white"/>
      <Row>
        <h1 className={"white-title"}>Confirmation mot de passe</h1>
      </Row>
      <div className="marged-bottom-h3"/>
      <Row>
        <Col xxl={12} md={12} xs={24}>
          <Form.Item
            name="password_confirmation"
            rules={[
              {
                required: true,
                message: "Confirmez votre mot de passe SVP!",
              },
              {
                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  value && value !== form.getFieldValue('password') ? cb("Les mots de passe que vous avez entrés ne sont pas identiques !") : cb();
                }
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input.Password placeholder={"Confirmation"} onChange={e => form.setFieldsValue({password_confirmation: e.target.value})}/>
          </Form.Item>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "2rem"
        }}
        gutter={[24, 24]}
      >
            <Col xs={24} md={6}>
              <button
                className={"auth-submit-btn-white"}
                onClick={goBack}
                type="button"
              >
                Précédent
              </button>
            </Col>
            <Col xs={24} md={6}>
            <button
          className={"auth-submit-btn-white "}
          type="submit"
        >
          {isLoading ? (<Spin indicator={antIcon} />) : "S'inscrire"}
        </button>
            </Col>
      </Row>
    </Form>
  );
};

export default SignupStepFour;
