import React from "react";

const DeleteSmallIcon = () => {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M1.8132 19.7314C1.83659 20.2902 2.29638 20.7311 2.85554 20.7311H12.5949C13.154 20.7311 13.6138 20.2902 13.6372 19.7314L14.3327 5.04883H1.11768L1.8132 19.7314ZM9.92837 8.69572C9.92837 8.46177 10.1181 8.27202 10.3521 8.27202H11.0299C11.2638 8.27202 11.4536 8.46172 11.4536 8.69572V17.0842C11.4536 17.3182 11.2639 17.5079 11.0299 17.5079H10.3521C10.1182 17.5079 9.92837 17.3183 9.92837 17.0842V8.69572ZM6.96263 8.69572C6.96263 8.46177 7.15233 8.27202 7.38633 8.27202H8.06409C8.29799 8.27202 8.4878 8.46172 8.4878 8.69572V17.0842C8.4878 17.3182 8.29815 17.5079 8.06409 17.5079H7.38633C7.15238 17.5079 6.96263 17.3183 6.96263 17.0842V8.69572ZM3.99679 8.69572C3.99679 8.46177 4.18649 8.27202 4.42049 8.27202H5.0983C5.33225 8.27202 5.52201 8.46172 5.52201 8.69572V17.0842C5.52201 17.3182 5.33231 17.5079 5.0983 17.5079H4.42049C4.18654 17.5079 3.99679 17.3183 3.99679 17.0842V8.69572Z" fill="#3240FF" />
                <path d="M14.796 1.06792H10.3029V0.218476C10.3029 0.0978447 10.2051 0 10.0844 0H5.36623C5.2456 0 5.14781 0.0978447 5.14781 0.218476V1.06787H0.654666C0.293077 1.06787 0 1.361 0 1.72258V3.7793H15.4507V1.72264C15.4507 1.36105 15.1576 1.06792 14.796 1.06792Z" fill="#3240FF" />
            </g>
        </svg>
    );
};

export default DeleteSmallIcon;
