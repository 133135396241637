export const SESSION_TIMEOUT = "SESSION_TIMEOUT";

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const GET_AUTH_REQUEST = "GET_AUTH_REQUEST";
export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS";
export const GET_AUTH_FAILURE = "GET_AUTH_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const DISCONNECT_THE_USER = "DISCONNECT_THE_USER";
export const CONNECT_THE_USER = "CONNECT_THE_USER";

export const CHANGE_PACK_CHOOSED = "CHANGE_PACK_CHOOSED";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const CHECK_LINK_REQUEST = "CHECK_LINK_REQUEST";
export const CHECK_LINK_SUCCESS = "CHECK_LINK_SUCCESS";
export const CHECK_LINK_ERROR = "CHECK_LINK_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

export const SEND_VERIFICATION_CODE_REQUEST = "SEND_VERIFICATION_CODE_REQUEST";
export const SEND_VERIFICATION_CODE_ERROR = "SEND_VERIFICATION_CODE_ERROR";
export const SEND_VERIFICATION_CODE_SUCCESS = "SEND_VERIFICATION_CODE_SUCCESS";

export const SEND_VERIFICATION_EMAIL_REQUEST = "SEND_VERIFICATION_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_ERROR = "SEND_VERIFICATION_EMAIL_ERROR";
export const SEND_VERIFICATION_EMAIL_SUCCESS = "SEND_VERIFICATION_EMAIL_SUCCESS";

export const PHONE_VERIFICATION_REQUEST = "PHONE_VERIFICATION_REQUEST";
export const PHONE_VERIFICATION_ERROR = "PHONE_VERIFICATION_ERROR";
export const PHONE_VERIFICATION_SUCCESS = "PHONE_VERIFICATION_SUCCESS";

export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";

export const UPDATE_PARAMETERS_REQUEST = "UPDATE_PARAMETERS_REQUEST";
export const UPDATE_PARAMETERS_SUCCESS = "UPDATE_PARAMETERS_SUCCESS";
export const UPDATE_PARAMETERS_FAILURE = "UPDATE_PARAMETERS_FAILURE";
