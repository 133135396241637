import {
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  GET_AUTH_SUCCESS,
  GET_AUTH_FAILURE,
  GET_AUTH_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_PARAMETERS_REQUEST,
  UPDATE_PARAMETERS_SUCCESS,
  UPDATE_PARAMETERS_FAILURE,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_CODE_ERROR,
  SEND_VERIFICATION_CODE_REQUEST,
  SEND_VERIFICATION_EMAIL_REQUEST,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHECK_LINK_REQUEST,
  CHECK_LINK_SUCCESS,
  CHECK_LINK_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  PHONE_VERIFICATION_SUCCESS,
  PHONE_VERIFICATION_ERROR,
  PHONE_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  EMAIL_VERIFICATION_REQUEST,
  LOGOUT_REQUEST,
  CONNECT_THE_USER,
  LOGOUT_SUCCESS,
  CHANGE_PACK_CHOOSED,
  LOGOUT_FAILURE,
} from "./types";
import { message } from "antd";
import AuthServices from "./services";

export function changePackChoosed(data: any) {
  return async (dispatch: any) => {
    const response = await AuthServices.changePackChoosedRequest(data);
    await dispatch({
      type: CHANGE_PACK_CHOOSED, payload: {
        user: response.data,
        choosedAPack: response.data.pack_choosed
      }
    });
  }
}

export function getAuthUser() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_AUTH_REQUEST,
    });
    try {
      const response = await AuthServices.getAuthUserRequest();
      await dispatch({
        type: GET_AUTH_SUCCESS,
        payload: {
          user: response.data
        }
      });
    } catch (e) {
      await dispatch({
        type: GET_AUTH_FAILURE,
        payload: null
      });
    }
  };
}

export function loginUser(values: any) {
  return async (dispatch: any) => {
    await dispatch({ type: SIGNIN_REQUEST });
    try {
      const response = await AuthServices.signinRequest(values);
      localStorage.setItem("domicilier_token", response.data.access_token);
      await dispatch({ type: SIGNIN_SUCCESS, payload: response.data });
      message.success({
        content: `Bienvenue ${window.capitalize(response.data.user.surname)} !`,
        className: 'success-message',
        duration: 3
      });
    } catch (e) {
      await dispatch({ type: SIGNIN_FAILURE });
      if (e.response.status == 429) {
        message.error({
          content: 'Trois tentatives de connexion incorrectes. Veuillez réessayer après une minute',
          className: 'error-message',
          duration: 3
        });
      } else {
        message.error({
          content: "Email ou mot de passe incorrect !",
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

export function signupUser(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: SIGNUP_REQUEST });
    try {
      const response = await AuthServices.signupRequest(body);
      localStorage.setItem("domicilier_token", response.data.access_token);
      await dispatch({ type: SIGNUP_SUCCESS, payload: response.data });
    } catch (e) {
      if (e.response.data.email) {
        message.error({
          content: 'Un compte avec cette même adresse email existe déjà!',
          className: 'error-message',
          duration: 3
        });
      } else {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
      await dispatch({ type: SIGNUP_ERROR });

    }
  };
}

export function logout() {
  return async (dispatch: any) => {
    await dispatch({ type: LOGOUT_REQUEST });
    try {
      await AuthServices.logoutRequest();
      localStorage.removeItem("domicilier_token");
      await dispatch({ type: LOGOUT_SUCCESS });
      message.success({
        content: `A bientôt !`,
        className: 'success-message',
        duration: 3
      });
    } catch (e) {
      await dispatch({ type: LOGOUT_FAILURE });
    }
  };
}

export function connectTheUser(token: string) {
  return async (dispatch: any) => {
    localStorage.setItem("domicilier_token", token);
    await dispatch({
      type: CONNECT_THE_USER,
      payload: {
        token: token,
      },
    });
  };
}

export function updateNotif(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: UPDATE_NOTIFICATION_REQUEST });
    try {
      const response = await AuthServices.updateNotifRequest(body);
      await dispatch({
        type: UPDATE_NOTIFICATION_SUCCESS, payload: {
          user: response.data
        },
      });
    } catch (e) {
      await dispatch({ type: UPDATE_NOTIFICATION_ERROR });
    }
  };
}

export function updatePars(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: UPDATE_PARAMETERS_REQUEST
    });
    try {
      const response = await AuthServices.updateParsRequest(body);
      await dispatch({
        type: UPDATE_PARAMETERS_SUCCESS,
        payload: {
          user: response.data
        },
      });
    } catch (e) {
      await dispatch({
        type: UPDATE_PARAMETERS_FAILURE,
      });
    }
  };
}

export function sendVerificationCode(id: number) {
  return async (dispatch: any) => {
    await dispatch({ type: SEND_VERIFICATION_CODE_REQUEST });
    try {
      const response = await AuthServices.sendVerificationCodeRequest(id);
      if (response.data.errors) {
        await dispatch({ type: SEND_VERIFICATION_CODE_ERROR });
        message.error({
          content: response.data.errors,
          className: 'error-message',
          duration: 3
        });
      } else {
        await dispatch({
          type: SEND_VERIFICATION_CODE_SUCCESS,
        });
        message.success({
          content: response.data.message,
          className: 'success-message',
          duration: 3
        });
      }
    } catch (e) {
      await dispatch({ type: SEND_VERIFICATION_CODE_ERROR });
      if (e.response.status == 429) {
        message.error({
          content: 'Veuillez réessayer après une minute',
          className: 'error-message',
          duration: 3
        });
      } else {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

export function verifyPhone(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: PHONE_VERIFICATION_REQUEST });
    try {
      const response = await AuthServices.verifyPhoneRequest(body);
      await dispatch({
        type: PHONE_VERIFICATION_SUCCESS,
        payload: {
          user: response.data
        }
      });
    } catch (e) {
      await dispatch({ type: PHONE_VERIFICATION_ERROR });
      if (e.response.data.errors) {
        message.error({
          content: e.response.data.errors,
          className: 'error-message',
          duration: 3
        });
      } else {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

export function sendVerificationEmail(id: number) {
  return async (dispatch: any) => {
    await dispatch({ type: SEND_VERIFICATION_EMAIL_REQUEST });
    try {
      const response = await AuthServices.sendVerificationEmailRequest(id);
      await dispatch({
        type: SEND_VERIFICATION_EMAIL_SUCCESS,
      });
      message.success({
        content: response.data.message,
        className: 'success-message',
        duration: 3
      });
    } catch (e) {
      await dispatch({ type: SEND_VERIFICATION_EMAIL_ERROR });
      if (e.response.status == 429) {
        message.error({
          content: 'Veuillez réessayer après une minute',
          className: 'error-message',
          duration: 3
        });
      } else {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

export function verifyEmail(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: EMAIL_VERIFICATION_REQUEST });
    try {
      const response = await AuthServices.verifyEmailRequest(body);
      await dispatch({
        type: EMAIL_VERIFICATION_SUCCESS,
        payload: {
          user: response.data.user,
        }
      });
      await dispatch(connectTheUser(response.data.token))
    } catch (e) {
      await dispatch({ type: EMAIL_VERIFICATION_ERROR });
      if (!e.response.data.errors) {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

export function changePassword(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: CHANGE_PASSWORD_REQUEST });
    try {
      const response = await AuthServices.changePasswordRequest(body);
      await dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });
      message.success({
        content: response.data.message,
        className: 'success-message',
        duration: 3
      });
    } catch (e) {
      await dispatch({ type: CHANGE_PASSWORD_ERROR });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function checkReinitialisationLink(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: CHECK_LINK_REQUEST });
    try {
      const response = await AuthServices.checkReinitialisationLinkRequest(body);
      await dispatch({
        type: CHECK_LINK_SUCCESS,
        payload: {
          linkValidity: response.data
        }
      });
    } catch (e) {
      await dispatch({ type: CHECK_LINK_ERROR });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function resetPassword(body: any) {
  return async (dispatch: any) => {
    await dispatch({ type: RESET_PASSWORD_REQUEST });
    try {
      const response = await AuthServices.resetPasswordRequest(body);
      await dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
      message.success({
        content: response.data.message,
        className: 'success-message',
        duration: 3
      });
      return response.data
    } catch (e) {
      await dispatch({ type: RESET_PASSWORD_ERROR });
      if (e.response.data.email) {
        message.error({
          content: 'L\'adresse email saisie n\'est pas valide !',
          className: 'error-message',
          duration: 3
        });
      } else if (e.response.status == 429) {
        message.error({
          content: 'Veuillez réessayer après une minute',
          className: 'error-message',
          duration: 3
        });
      } else {
        message.error({
          content: "Adresse email ou mot de passe non valide !",
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}
