import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, DatePicker } from "antd";
import MapWithAMarker from "./MapWithMarker"
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateProfile } from "../../../actions/profile-actions/actions";
import { RootState } from "../../../reducers";
import $ from "jquery";
import moment from "moment";
import MapContainer from "../../../tools/MapContainer";

const UserInfosForm = (props) => {
  const format = "DD / MM / YYYY";
  const [form] = Form.useForm();
  const [hasChanges, setHasChanges] = useState(false);
  const profile = useSelector(
    (state: RootState) => state.profileReducer.profile
  );
  const onUpdateValues = (changedValues, allValues) => {
    if(changedValues){
      setHasChanges(true)
    }
  };
  useEffect(() => {
    if(props.isSubmited){
      form.submit()
      props.unSubmit();
    }
  }, [props.isSubmited])
  const onFormFinish = (values) => {
    values.progress = 25
    values.manager_birthdate = values.manager_birthdate.format("YYYY-MM-DD");
    delete values.address;
    props.onSubmit(hasChanges, values)
  }
  // Label Input Company Name onFocus
  const handleCompanyNameFocus = () => {
    $(".label-input-company-name").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input Company Name onBlur
  const handleCompanyNameBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-company-name").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-company-name").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  }
  // Label Input CEO Name onFocus
  const handleCeoNameFocus = () => {
    $(".label-input-ceo-name").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input CEO Name onBlur
  const handleCeoNameBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-ceo-name").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-ceo-name").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  // Label Input Adress onFocus
  const handleAdressFocus = () => {
    $(".label-input-adress").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input Adress onBlur
  const handleAdressBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-adress").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-adress").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  // Label Input Email onFocus
  const handleEmailFocus = () => {
    $(".label-input-email").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input Email onBlur
  const handleEmailBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-email").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-email").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  // Label Input Birth Date onFocus
  const handleBirthDateFocus = () => {
    $(".label-input-birth-date").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input Birth Date onBlur
  const handleBirthDateBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-birth-date").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-birth-date").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  // Label Input Reexpedition Date onFocus
  const handleReexpeditionDateFocus = () => {
    $(".label-input-reexpedition-adress").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input Reexpedition Date onBlur
  const handleReexpeditionDateBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-reexpedition-adress").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-reexpedition-adress").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  // LOAD PROFILE
  useEffect((): any => {
    if(profile){
      if (profile.entreprise_name) {
        $(".label-input-company-name").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-company-name").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
      if (profile.manager_name) {
        $(".label-input-ceo-name").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-ceo-name").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
      if (profile.address_infos) {
        $(".label-input-adress").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-adress").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
      if (profile.entreprise_email) {
        $(".label-input-email").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-email").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
      if (profile.manager_birthdate) {
        $(".label-input-birth-date").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-birth-date").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
      if (profile.address_rexp) {
        $(".label-input-reexpedition-adress").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-reexpedition-adress").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
    }
  }, [])
  return (
    <Form
      name="basic"
      className={"form-profile"}
      onFinish={onFormFinish}
      form={form}
      onValuesChange={onUpdateValues}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} md={12} lg={8}>
          <div className="label-input-company-name">Nom de société</div>
          <Form.Item
            name="entreprise_name"
            rules={[
              {
                required: true,
                message: "Tapez le nom de votre société svp!",
              },
            ]}
            initialValue={profile ? profile.entreprise_name : null}
          >
            <Input className={"company-name"} size={"large"} onBlur={handleCompanyNameBlur} onFocus={handleCompanyNameFocus} />
          </Form.Item>
          <div className="label-input-ceo-name">Nom Gérant</div>
          <Form.Item
            name="manager_name"
            rules={[{ required: true, message: "Tapez le nom du Gérant svp!" }]}
            initialValue={profile ? profile.manager_name : null}
          >
            <Input className={"ceo-name"} size={"large"} onBlur={handleCeoNameBlur} onFocus={handleCeoNameFocus} />
          </Form.Item>
          <div className="label-input-adress">Adresse de domiciliation</div>
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Tapez votre adresse svp!" }]}
            initialValue={profile && profile.address_infos ? profile.address_infos.full_location : null}
          >
            <Input disabled className={"adress"} size={"large"} onBlur={handleAdressBlur} onFocus={handleAdressFocus} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <div className="label-input-email">Email</div>
          <Form.Item
            name="entreprise_email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Tapez votre email svp!",
              },
            ]}
            initialValue={profile ? profile.entreprise_email : null}
          >
            <Input className={"email"} size={"large"} onBlur={handleEmailBlur} onFocus={handleEmailFocus} />
          </Form.Item>
          <div className="label-input-birth-date">Date de naissance</div>
          <Form.Item
            name="manager_birthdate"
            rules={[
              {
                required: true,
                type: "date",
                message: "Tapez votre date de naissance svp!",
              },
            ]}
            initialValue={profile && profile.manager_birthdate ? moment(profile.manager_birthdate) : null }
          >
            <DatePicker format={format} placeholder={""} className={"birth-date"} size={"large"} onBlur={handleBirthDateBlur} onFocus={handleBirthDateFocus}></DatePicker>
          </Form.Item>
          <div className="label-input-reexpedition-adress">Adresse de réexpédition</div>
          <Form.Item
            name="address_rexp"
            rules={[
              {
                required: true,
                message: "Tapez votre adresse de réexpédition svp!",
              },
            ]}
            initialValue={profile ? profile.address_rexp : null}
          >
            <Input className={"reexpedition-adress"} size={"large"} onBlur={handleReexpeditionDateBlur} onFocus={handleReexpeditionDateFocus} />
          </Form.Item>

        </Col>
        <Col xs={24} md={24} lg={8}>
          <Row justify={"center"}>
            <Col span={24}>
              <div style={{height: "100%"}}>
                {profile && profile.address_infos ? (
                  <MapContainer
                   address={profile && profile.address_infos ? profile.address_infos : null}
                    height={"250px"}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UserInfosForm;
