
export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const GET_NEW_UNSEEN_NOTIFICATIONS_REQUEST = "GET_NEW_UNSEEN_NOTIFICATIONS_REQUEST";
export const GET_NEW_UNSEEN_NOTIFICATIONS_SUCCESS = "GET_NEW_UNSEEN_NOTIFICATIONS_SUCCESS";
export const GET_NEW_UNSEEN_NOTIFICATIONS_FAILURE = "GET_NEW_UNSEEN_NOTIFICATIONS_FAILURE";

export const CHANGE_NOTIF_STATUS_REQUEST = "CHANGE_NOTIF_STATUS_REQUEST";
export const CHANGE_NOTIF_STATUS_SUCCESS = "CHANGE_NOTIF_STATUS_SUCCESS";
export const CHANGE_NOTIF_STATUS_FAILURE = "CHANGE_NOTIF_STATUS_FAILURE";

export const EMPTY_NOTIFICATIONS = "EMPTY_NOTIFICATIONS";
