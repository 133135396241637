import React from "react";
import { Col } from "antd";
import CountUp from 'react-countup';

interface StatDashboardCardDashType {
  title: string;
  icon: React.FunctionComponentElement<any>;
  counter: number;
}
const StatDashboardCardItem: React.FC<StatDashboardCardDashType> = ({
  title,
  counter,
  icon,
}) => {
  return (
    <Col md={12} xs={24} xl={6} lg={6} xxl={6}>
      <div className={"stat-dashboard-card-item"}>
        <div className="icon-logo">{icon}</div>
        <div className={"bottom"}>
          <div className={"counter-big-number"}><CountUp end={counter} duration={counter <= 10 ? 0.2 : 2}/></div>
          <div className={"text-title"}>{title}</div>
        </div>
      </div>
    </Col>
  );
};

export default StatDashboardCardItem;
