import React, { useEffect, useState } from "react";
import { Layout, BackTop } from "antd";
import Sidebar from '../components/sidebar/Sidebar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Navbar from "../components/navbar/Navbar";
import { RootState } from "../reducers";
import { useDispatch, useSelector } from "react-redux";
import {getNewUnseen} from "../actions/discussions-actions/actions";
import {getNewUnseenNotif} from "../actions/notifications-actions/actions";

const { Content } = Layout;

const DashboardLayout = ({ location, children, ...rest }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  );
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getNewUnseen());
      dispatch(getNewUnseenNotif());
    }
  }, [isLoggedIn])
  return (
    <Layout style={{ minHeight: "100vh" }} className={"app-container"}>
      <Sidebar />
      <Layout className="app-layout">
        <Navbar withSidebar />
        <Content
          className={"content-container"}
        >
          <TransitionGroup component={"section"} >
            <CSSTransition
              key={location.key}
              timeout={450}
              classNames="fade"
              unmountOnExit
            >
              {children}
            </CSSTransition>

          </TransitionGroup>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
