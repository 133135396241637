import React, { useEffect, useState } from "react";
import "./service-item-card-big.scss";
import { Row, Col, Form, Select, Input, Button, Spin } from "antd";
import RectangleRadioBtnWithIcon from "./RectangleRadioBtnIwthIcon";
import HeadPhonesSmallIcon from "../../../svg-components/icons/HeadPhonesSmallIcon";
import ArrowTurningSmallIcon from "../../../svg-components/icons/ArrowTurningSmallIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { updateCart, emptyCart } from "../../../actions/cart-actions/actions";
import { subscribePhoneSolution, clearBill } from "../../../actions/profile-actions/actions";
import PaimentModalContent from "../../paiement-modal/PaiementModalContent";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

interface ServiceCallRedirectionPropsInterface {
  title: string;
  description: string;
  onClick1: () => void;
  onClick2: () => void;
}
const ServiceCallRedirection: React.FC<ServiceCallRedirectionPropsInterface> = ({
  title,
  description,
  onClick1,
  onClick2,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const phones = useSelector(
    (state: RootState) => state.serivcesReducer.phones
  );
  const isLoadingSubscription = useSelector(
    (state: RootState) => state.profileReducer.isLoadingSubscription
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const [form] = Form.useForm();
  const [filtredSolutions, setFiltredSolutions] = useState([]);
  const [VisibleModal, setVisibleModal] = useState(false);
  const [isOtherReason, setIsOtherReason] = useState(false);

  const checkIsOtherReason = (value) => {
    if (value === "Autre") {
      setIsOtherReason(true)
    } else {
      setIsOtherReason(false)
      form.setFieldsValue({ absence_reason: value })
    }
  }
  const getOtherValue = (e) => {
    form.setFieldsValue({ absence_reason: e.target.value })
  }

  const onSubmit = (values) => {
    dispatch(emptyCart());
    values.packs = [values.service_id];
    values.billing_type = 1;
    dispatch(subscribePhoneSolution(values));
  }
  useEffect(() => {
    if (phones.length > 0) {
      const filtred = phones.filter((data) => data.service.phone_type === "Redirection");
      setFiltredSolutions(filtred);
    }
  }, [phones])
  const cancelPayment = async () => {
    dispatch(emptyCart());
    await dispatch(clearBill());
    form.resetFields();
    setVisibleModal(false);
  }
  useEffect(() => {
    if (bill) {
      setVisibleModal(true);
    }
  }, [bill])
  const paymentSucceeded = async () => {
    await dispatch(emptyCart());
    await dispatch(clearBill());
    setVisibleModal(false);
  }
  return (
    <div className={"service-item-card-big"}>
      <Row style={{ marginBottom: "20px" }}>
        <h1 className={"services-title"}>{title}</h1>
      </Row>
      <Row style={{ marginBottom: "14px" }}>
        <h4 className={"services-description"}>{description}</h4>
      </Row>
      <Form
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "24px" }}>
          <Col>
            <RectangleRadioBtnWithIcon
              label={"Standard personnalisé"}
              name={"phone-solu-service-radio"}
              text={`SECRÉTARIAT\nStandard personnalisé`}
              icon={<HeadPhonesSmallIcon />}
              defaultChecked={false}
              onClick={onClick1}
            />
          </Col>
          <Col>
            <RectangleRadioBtnWithIcon
              label={"Rédireger vos appels"}
              name={"phone-solu-service-radio"}
              text={`REDIRECTION\nRédireger vos appels`}
              icon={<ArrowTurningSmallIcon />}
              defaultChecked={true}
              onClick={onClick2}
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={8}>
            <Row>
              <p>
                Choissiez le forfait qui vous convient :
                <span className="redstar">*</span>
              </p>
              <Form.Item
                style={{ width: "100%" }}
                name="service_id"
                rules={[
                  { required: true, message: "Sélectionnez un Forfait SVP!" },
                ]}
              >
                <Select
                  size={"large"}
                  style={{ width: "100%" }}
                >
                  {filtredSolutions.length > 0 ?
                    filtredSolutions.map((offer, i) => (
                      <Option
                        value={offer.id}
                        title={`${offer.service.name} ${offer.active_promo ? (offer.price - offer.price * offer.active_promo.value / 100) : offer.price} Dt HT / Mois`}
                      >
                        <div>
                          {offer.service.name}
                        </div>
                        <div style={{ textAlign: "right" }}>
                          {offer.active_promo ? (offer.price - offer.price * offer.active_promo.value / 100) : offer.price} Dt HT / Mois
                        </div>
                      </Option>
                    )) : (
                      <Option
                        value={""}
                      >
                        <em>Aucune offre disponible</em>
                      </Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Row>
            <Row style={{ marginTop: "9px" }}>
              <p>
                Choisissez votre numéro de redirection :
                <span className="redstar">*</span>
              </p>
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="phone_number"
                rules={[
                  { required: true, message: "SVP inserez votre numéro!" },
                ]}
              >
                <Input size={"large"} placeholder={"Téléphone"} />
              </Form.Item>
            </Row>
          </Col>
          <Col xs={24} md={8}>
            <Row>
              <p>
                Raison de votre absence:<span className="redstar">*</span>
              </p>
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="absence_reason"
                rules={[
                  { required: true, message: "SVP inserez votre raison d'abscence!" },
                ]}
              >
                <Input.Group>
                  <Select
                    size={"large"}
                    style={{ width: "100%" }}
                    onChange={checkIsOtherReason}
                  >
                    <Option value="En vacances">En vacances</Option>
                    <Option value="Maladie">Maladie</Option>
                    <Option value="Autre">Autre</Option>
                  </Select>
                  {isOtherReason ? (
                    <Input size={"large"} style={{ marginTop: "1rem" }} placeholder={"Autre Raison..."} onChange={getOtherValue} />
                  ) : null}
                </Input.Group>
              </Form.Item>
            </Row>
            <div className="marged-top-h4" />
            <Row>
              <Form.Item
                style={{
                  width: "100%", marginBottom: "0px"
                }}
              >
                <Button
                  size={"large"}
                  className={"bordered-blue-button"}
                  type="primary"
                  htmlType="submit"
                >
                  {isLoadingSubscription ? (<Spin indicator={antIcon} />) : "Valider mon service"}
                </Button>
                <p style={{ fontSize: "1.2rem", marginTop: "1rem", fontFamily: "Poppins", lineHeight: "12px", letterSpacing: "0", textAlign: "left" }}>
                  Le paiement est effectif dès la validation de votre service,
                  il s'effectuera chaque mois à la date de souscription du
                  service.
                </p>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
      <PaimentModalContent
        visible={VisibleModal}
        abort={cancelPayment}
        paid={paymentSucceeded}
      />
    </div>
  );
};

export default ServiceCallRedirection;

