import React, { useEffect, useState } from "react";
import "../account-verification-page.scss";
import { Alert, Form, Input, Spin, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { sendVerificationCode, verifyPhone } from "../../../../actions/auth-actions/actions";
import { RootState } from "../../../../reducers";
import Countdown from 'react-countdown';
import { Link, Redirect } from "react-router-dom";
import Success from "../../../../svg-components/Success";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PhoneVerficationPage = ({ history, location }: any) => {
  const dispatch = useDispatch();
  const useMountEffect = (fun) => useEffect(fun, [])
  const user = useSelector(
    (state: RootState) => state.authReducer.user
  );
  const phoneIsVerified = useSelector(
    (state: RootState) => state.authReducer.phoneIsVerified
  );
  const emailIsVerified = useSelector(
    (state: RootState) => state.authReducer.emailIsVerified
  );
  const isSendingRequest = useSelector(
    (state: RootState) => state.authReducer.isSendingRequest
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeValue, setCodeValue] = useState(null);
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(Date.now() + 300000);
  useEffect(() => {
    document.title = `Vérifier mon numéro de téléphone | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/verify/phone`);
    }
  })
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Le code a expiré. Veuillez cliquer sur "Envoyer à nouveau".</span>
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds}</span>;
    }
  };
  const sendCode = async () => {
    if (user && user.id) {
      setTimer(Date.now() + 300000);
      await dispatch(sendVerificationCode(user.id));
    }
  }
  const submitCode = async (values) => {
    await dispatch(verifyPhone(values));
  }
  useEffect(() => {
    if (user) {
      const phoneArray = user.phone.split("");
      phoneArray[phoneArray.length - 3] = '*';
      phoneArray[phoneArray.length - 4] = '*';
      setPhoneNumber(phoneArray.join(''));
    }
  })
  const onInputKeyPress = (e) => {
    if (e.target.value.length === 6) {
      e.preventDefault();
    } else if (e.target.value.length > 6) {
      // Maximum exceeded
      e.target.value = e.target.value.substring(0, 6);
    }
  }
  const redirectTo = (e) => {
    e.preventDefault();
    if(emailIsVerified){
      history.push('/dashboard')
    }else{
      history.push('/verify')
    }
  }
  return (
    <div className={"auth-page"}>
      <div className="max-width-container">
        <div className="container-center">
          {phoneIsVerified ? (
            <Alert
              message="Numéro de téléphone vérifié !"
              description={
                <div>
                  <p>Votre numéro de téléphone a été vérifié.</p>
                  <Link to="/dashboard" onClick={redirectTo}>Obtenir ma domiciliation</Link>
                </div>
              }
              type="success"
              icon={<Success />}
              showIcon
            />
          ) :
            (
              <div className={"account-verification-page"}>
                <h1 className={"account-verification-title"}>
                  Vérifiez votre compte !
                </h1>
                <div className={"account-verification-countdown"}>
                  <Countdown
                    date={timer}
                    zeroPadTime={2}
                    renderer={renderer}
                  />
                </div>
                <div className={"account-verification-text"}>
                  {`Nous vous avons envoyé le code de vérification sur votre numéro ${phoneNumber}. Je n'ai pas reçu le code ?`}
                  <span className={"account-verification-resend"} onClick={sendCode}>Envoyer à nouveau</span>
                </div>
                <Form
                  name="verification-form"
                  className={"verification-form"}
                  onFinish={submitCode}
                  form={form}
                >
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Saisir le code!",
                      },
                      {
                        validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                          value && value.length !== 6 ? cb("Le code doit contenir 6 chiffres!") : cb();
                        }
                      },
                    ]}
                    validateTrigger="onBlur"
                  >
                    <div className={"code-input"}>
                      <Input placeholder={"------"} onKeyPress={onInputKeyPress} onChange={(e) => setCodeValue(e.target.value)} /> {codeValue && codeValue.length === 6 ? (<span className={"check-mark"}>&#10003;</span>) : null}
                    </div>
                  </Form.Item>
                  <Button className={"account-verification-btn-light"} htmlType="submit">
                    {isSendingRequest ? (<Spin indicator={antIcon} />) : "Vérifier"}
                  </Button>
                </Form>
              </div>
            )
          }
        </div>
      </div >
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div >
  );
};

export default PhoneVerficationPage;
