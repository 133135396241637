import * as React from "react";
import { Col, Form, Input, Row } from "antd";
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-input-2/lib/style.css'
import fr from 'react-phone-input-2/lang/fr.json'

interface SignupStepThreeTypes {
  emailFromHome: string
  callBackFn: any;
  goBack: any;
}

const SignupStepThree: React.FC<SignupStepThreeTypes> = ({ emailFromHome, callBackFn, goBack }) => {
  const [phoneNumber, setPhoneNumber] = React.useState(null);
  const submitFunction = (values: any) => {
    callBackFn(values)
  }
  return (
    <Form id={"signup-form-step-3"} name="signup-form-step-3"
      onFinish={submitFunction}
    >
      <Row>
        <h1 className={"white-title"}>Quelle est votre adresse email?</h1>
      </Row>
      <Row>
        <h2 className={"white-sub-title"}>
          Utile pour envoyer votre attestation
        </h2>
      </Row>
      <div className="marged-bottom-h3" />
      <Row>
        <Col xxl={12} md={12} xs={24}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Vous devez saisir une addresse email valide",
              },
            ]}
            validateTrigger="onBlur"
            initialValue={emailFromHome ? emailFromHome : null}
          >
            <Input placeholder={"Email"} />
          </Form.Item>
        </Col>
      </Row>
      <div className="separtor-small-white" />
      <Row>
        <h1 className={"white-title"}>et votre numéro de téléphone?</h1>
        <h2 className={"white-sub-title"}>
          Nous vous enverrons un sms pour valider la signature de votre
          attestation
        </h2>
      </Row>
      <div className="marged-bottom-h3" />
      <Row>
        <Col xxl={12} md={12} xs={24}>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Vous devez saisir un numéro de téléphone",
              },
              {
                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                  value && isValidPhoneNumber("+" + value) === false ? cb("Vous devez saisir un numéro de téléphone valide") : cb();
                }
              },
            ]}
            validateTrigger="onBlur"
          >
            <PhoneInput
              country={"tn"}
              regions={['north-africa', 'europe']}
              localization={fr}
              prefix={"+"}
              placeholder="Téléphone"
              inputClass="phone-input"
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "2rem"
        }}
        gutter={[24, 24]}
      >
        <Col xs={24} md={6}>
          <button
            className={"auth-submit-btn-white"}
            onClick={goBack}
            type="button"
          >
            Précédent
          </button>
        </Col>
        <Col xs={24} md={6}>
          <button
            className={"auth-submit-btn-white"}
            type="submit"
          >
            Suivant
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default SignupStepThree;
