import React, { useEffect } from 'react';
import "../../pages/settings-page/settings.scss";
import { Table, Space, Button, Spin, Col } from 'antd';
import EditSmallIcon from "../../svg-components/icons/EditSmallIcon"
import DeleteSmallIcon from "../../svg-components/icons/DeleteSmallIcon"
import { useDispatch, useSelector } from "react-redux";
import { getContacts, removeContact } from "../../actions/dashboard-actions/actions";
import { RootState } from "../../reducers";
import { useState } from 'react';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import AddContactModal from './add-contact-modal/AddContactModal';

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

const RepertoryPage = () => {
  const dispatch = useDispatch();
  const contacts = useSelector(
    (state: RootState) => state.dashboardReducer.contacts
  );
  const isLoadingContacts = useSelector(
    (state: RootState) => state.dashboardReducer.isLoadingContacts
  );
  const [repContacts, setRepContacts] = useState([]);
  const [trustedContacts, setTrustedContacts] = useState([]);
  const [contactType, setContactType] = useState('rep');
  const [visible, setVisible] = useState(false);
  const addRepContact = () => {
    setVisible(!visible);
    setContactType('rep');
  }
  const addTrustedContact = () => {
    setVisible(!visible);
    setContactType('trusted');
  }
  const removeThisContact = async (values) => {
    await dispatch(removeContact(values));
  }
  const repColumns = [
    {
      title: 'Nom & Prénom',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Catégorie',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {

      render: (text, record, index) => (
        <Space size="middle">
          <EditSmallIcon/>
          <button
            onClick={() => removeThisContact({contactType: 'rep', id: record.id})}
            className={"remove-btn"}
          >
            <DeleteSmallIcon />
          </button>
        </Space>
      ),
    },
  ];
  const trustedColumns = [
    {
      title: 'Nom & Prénom',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Catégorie',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {

      render: (text, record, index) => (
        <Space size="middle">
          <EditSmallIcon/>
          <button
            onClick={() => removeThisContact({contactType: 'trusted', id: record.id})}
            className={"remove-btn"}
          >
            <DeleteSmallIcon />
          </button>
        </Space>
      ),
    },
  ];
  const tableLoading = {
    spinning: isLoadingContacts,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  useEffect(() => {
    if(!contacts){
      dispatch(getContacts());
    }else{
      setRepContacts(contacts.rep);
      setTrustedContacts(contacts.trusted);
    }
  }, [contacts])
  return (
    <div>
      <div className={"header-menu"}>
        <h1 className={"menu-title"}>Répértoire</h1>
        <div className={"sub-header-menu"}>
          <p className={"service-text"}>Ajouter les contacts avec lesquels vous souhaitez partager du courrier</p>
          <Button className={"add-contact"} onClick={addRepContact}>Ajouter contact</Button>
        </div>
      </div>
        <div className={"body-menu"} style={{paddingBottom: "4rem"}}>
          <Table
            rowKey="id"
            dataSource={repContacts}
            columns={repColumns}
            pagination={false}
            loading={tableLoading}
            locale={{emptyText: <em style={{color: "#bfbfbf"}}>Vide</em>}}
          />
        </div>
        <div className={"header-menu"} style={{marginTop: "2rem"}}>
          <h1 className={"menu-title"}>Personnes de confiance</h1>
          <div className={"sub-header-menu"}>
          <p className={"service-text"} >Ajouter une personne de confiance. <br /> Si vous n'étes pas disponible, celle-ci aura l'autorisation de venir retirer votre courrier</p>
            <Button className={"add-contact"} onClick={addTrustedContact}>Ajouter contact de confiance</Button>
          </div>
        </div>
        <div className={"body-menu"}>
        <Table
            rowKey="id"
            dataSource={trustedContacts}
            columns={trustedColumns}
            pagination={false}
            loading={tableLoading}
            locale={{emptyText: <em style={{color: "#bfbfbf"}}>Vide</em>}}
          />
        </div>
        <AddContactModal
          visible={visible}
          abort={() => setVisible(false)}
          contactType={contactType}
        />
    </div>

  );
};

export default RepertoryPage;
