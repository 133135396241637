import React, { useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

const APIKEY = process.env.MIX_GOOGLE_MAP_API_KEY;

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
}
function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

const MapComponent: React.FC<MapProps> = ({
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
}
// const MapContainer = ({ address, height }) => {
//   const [zoom, setZoom] = React.useState(10); // initial zoom
//   const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
//     lat: 36.789532335184674,
//     lng: 10.227479896479087,
//   });
//   const [position, setPosition] = React.useState<google.maps.LatLngLiteral>({
//     lat: 36.789532335184674,
//     lng: 10.227479896479087,
//   });
//   const render = (status: Status) => {
//     return <h1>{status}</h1>;
//   };
//   useEffect(() => {
//     if (address) {
//       setPosition({ lat: Number(address.lat), lng: Number(address.long) })
//     }
//   }, [address])
//   return (
//     <Wrapper apiKey={APIKEY} render={render}>
//       <MapComponent center={center} zoom={zoom} style={{ height: height }}>
//         <Marker position={position} />
//       </MapComponent>
//     </Wrapper>
//   )
// }

const MapContainer = ({ address, height }) => {
  return (
    address ? (
      <iframe
        loading={"lazy"}
        height={height}
        style={{ width: "100%", border: "none", borderRadius: "8px" }}
        allowfullscreen
        src={address ? `https://www.google.com/maps/embed/v1/place?key=${APIKEY}&q=${address.address_line_1.replaceAll(" ", "+")},${address.address_line_2 ? address.address_line_2.replaceAll(" ", "+") + ',' : null}${address.city}+${address.state}&center=${address.lat},${address.long}&language=fr` : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.190690316755!2d10.143752115145709!3d36.837908879940876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd33e4d0ac7f0b%3A0x3ac1c05544ccdd6!2sAtomart%20design%20studio!5e0!3m2!1sfr!2stn!4v1632740773706!5m2!1sfr!2stn'
        }>
      </iframe >
    ) : null
  );
}

export default MapContainer;

