import React from "react";
import { Input, Form, Row, Col, Radio, Space, DatePicker } from "antd";
import UploadFile from "../upload-file-modal/UploadFile";
import { getProfile, updateProfile } from "../../../actions/profile-actions/actions";
import { RootState } from "../../../reducers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import $ from "jquery";
import moment from "moment";

const FilesUploadFormStepTwo = (props) => {
  const [form] = Form.useForm()
  const [hasChanges, setHasChanges] = useState(false);
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const format = "DD / MM / YYYY";

  const onUpdateFiles = (name, value) => {
    if(value){
      form.setFieldsValue({[name]: value})
      setHasChanges(true)
    }
  }
  useEffect(() => {
    if(props.isSubmited){
      form.submit()
      props.unSubmit();
    }
  }, [props.isSubmited])
  const onFormFinish = (values) => {
    if(values){
      values.id_date = values.id_date.format("YYYY-MM-DD");
      values.progress = 50
      props.onSubmit(hasChanges, values)
    }
  }
  const onUpdateValues = (changedValues, allValues) => {
    if(changedValues){
      setHasChanges(true)
    }
  };
  // Label Input ID Number onFocus
  const handleIdNumberFocus = () => {
    $(".label-input-id-number").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input ID Number Date onBlur
  const handleIdNumberBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-id-number").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-id-number").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  // Label Input ID Number onFocus
  const handleIdDateFocus = () => {
    $(".label-input-id-date").css({
      transform: "scale(0.8) translate(0.9rem, -1.8rem)"
    })
  };
  // Label Input ID Number Date onBlur
  const handleIdDateBlur = (event) => {
    if (!event.target.value) {
      $(".label-input-id-date").css({
        transform: "scale(1) translate(0, 0)"
      })
    } else {
      $(".label-input-id-date").css({
        transform: "scale(0.8) translate(0.9rem, -1.8rem)"
      })
    }
  };
  useEffect(() => {
    if (profile) {
      if (profile.id_number) {
        $(".label-input-id-number").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-id-number").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
      if (profile.id_date) {
        $(".label-input-id-date").css({
          transform: "scale(0.8) translate(0.9rem, -1.8rem)"
        })
      } else {
        $(".label-input-id-date").css({
          transform: "scale(1) translate(0, 0)"
        })
      }
    }
  }, [profile])
  return (
    <Form
      name="basic"
      className={"form-profile"}
      form={form}
      onFinish={onFormFinish}
      onValuesChange={onUpdateValues}
    >
      <Row gutter={[24, 24]} >
        <Col lg={12} xs={24}>
          <Row align="middle">
            <Col xs={24} md={24} lg={24}>
              <Form.Item
                name="manager_dom_justif"
                rules={[{ required: true, message: "Charger un document svp!" }]}
                initialValue={profile ? profile.manager_dom_justif : null}
              >
                <UploadFile name="manager_dom_justif" docName={'Justificatif de domicile du dirigeant'} parentSetFileName={(value) => onUpdateFiles('manager_dom_justif', value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={24} lg={24}>
              <Form.Item
                name="passport_cin_recto"
                rules={[{ required: true, message: "Charger un document svp!" }]}
                initialValue={profile ? profile.passport_cin_recto : null}
              >
                <UploadFile name="passport_cin_recto" docName={'Passport / CIN (Recto)'} parentSetFileName={(value) => onUpdateFiles('passport_cin_recto', value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} md={24} lg={24}>
              <Form.Item
                name="passport_cin_verso"
                rules={[{ required: form.getFieldValue('id_type') !== "passport", message: "Charger un document svp!" }]}
                initialValue={profile ? profile.passport_cin_verso : null}
              >
                <UploadFile name="passport_cin_verso" docName={'Passport / CIN (Verso)'} parentSetFileName={(value) => onUpdateFiles('passport_cin_verso', value)}/>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24}>
            {/* <div className="label-input-id">Pièce d'identité :</div> */}
            <Form.Item
              label={<div className="label-input-id">Pièce d'identité </div>}
              name="id_type"
              rules={[{ required: true, message: "Séléctionner le type de votre pièce d'identité!" }]}
              initialValue={profile && profile.id_type ? profile.id_type : null}
            >
              <Radio.Group >
                    <Space direction="horizontal">
                      <Radio value={"cin"} className="billing-type-radio">CIN</Radio>
                      <Radio value={"passport"} className="billing-type-radio">Passport</Radio>
                    </Space>
              </Radio.Group>
            </Form.Item>
          <Row >
              <div className="label-input-id-number">Numéro</div>
              <Form.Item
                name="id_number"
                rules={[{ required: true, message: "Taper le numéro de votre pièce d'identité!" }]}
                initialValue={profile && profile.id_number ? profile.id_number : null}
                style={{width: "100%"}}
              >
                <Input size={"large"} onBlur={handleIdNumberBlur} onFocus={handleIdNumberFocus}/>
              </Form.Item>
          </Row>
          <Row >
          <div className="label-input-id-date">Date de délivrance</div>
            <Form.Item
              name="id_date"
              rules={[
                {
                  required: true,
                  type: "date",
                  message: "Taper la date de délivrance de votre pièce d'identité!",
                },
              ]}
              initialValue={profile && profile.id_date ? moment(profile.id_date) : null }
              style={{width: "100%"}}
            >
              <DatePicker format={format} placeholder={""} size={"large"} onBlur={handleIdDateBlur} onFocus={handleIdDateFocus}></DatePicker>
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default FilesUploadFormStepTwo;
