import React from "react";

const UrgentNotifIcon = () => {
return (
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="32" height="32" rx="16" stroke="#DF0253" stroke-width="2" />
  <path
    d="M19.1233 19.0398L18.6227 19.5404C18.5904 19.5741 18.5515 19.6009 18.5085 19.6191C18.4655 19.6373 18.4192 19.6466 18.3725 19.6463C18.2783 19.6454 18.1883 19.6074 18.1222 19.5404L16.7072 18.1061C16.6745 18.0732 16.6486 18.0342 16.631 17.9912C16.6133 17.9483 16.6044 17.9023 16.6045 17.8559V14.5286C16.6045 14.435 16.6417 14.3452 16.7079 14.279C16.7741 14.2129 16.8639 14.1757 16.9575 14.1757H17.6634C17.7567 14.1765 17.846 14.214 17.912 14.28C17.978 14.346 18.0155 14.4353 18.0163 14.5286V17.2719C18.0161 17.3183 18.0251 17.3643 18.0427 17.4073C18.0603 17.4502 18.0863 17.4893 18.119 17.5222L19.1393 18.5425C19.2029 18.6107 19.2368 18.7013 19.2338 18.7945C19.2308 18.8877 19.1911 18.9759 19.1233 19.0398ZM17.2944 11.7051C16.1889 11.7051 15.1083 12.0329 14.1891 12.647C13.27 13.2612 12.5536 14.1341 12.1305 15.1554C11.7075 16.1768 11.5968 17.3006 11.8125 18.3848C12.0281 19.469 12.5605 20.4649 13.3421 21.2466C14.1238 22.0283 15.1197 22.5606 16.204 22.7763C17.2882 22.992 18.412 22.8813 19.4333 22.4582C20.4546 22.0352 21.3276 21.3188 21.9417 20.3996C22.5559 19.4805 22.8837 18.3998 22.8837 17.2944C22.8837 15.812 22.2948 14.3903 21.2466 13.3421C20.1984 12.2939 18.7768 11.7051 17.2944 11.7051Z"
    fill="#DF0253" />
</svg>

);
};

export default UrgentNotifIcon;
