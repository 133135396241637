import {
    GET_ADDRESSES_REQUEST,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAILURE,
    GET_ADDRESS_REQUEST,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAILURE,
    GET_JOBS_REQUEST,
    GET_JOBS_SUCCESS,
    GET_JOBS_FAILURE,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAILURE,
    GET_PROMOTIONS_REQUEST,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_FAILURE,
    GET_ADDRESS_REVIEWS_REQUEST,
    GET_ADDRESS_REVIEWS_SUCCESS,
    GET_ADDRESS_REVIEWS_FAILURE,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAILURE,
    GET_FAQS_REQUEST,
    GET_FAQS_SUCCESS,
    GET_FAQS_FAILURE,
    ADD_SERVICE_PHONE_REQUEST,
    ADD_SERVICE_PHONE_SUCCESS,
    ADD_SERVICE_PHONE_FAILURE,
    ADD_SERVICE_MEETING_ROOM_REQUEST,
    ADD_SERVICE_MEETING_ROOM_SUCCESS,
    ADD_SERVICE_MEETING_ROOM_FAILURE,
    GET_SERVICE_PACKS_REQUEST,
    GET_SERVICE_PACKS_SUCCESS,
    GET_SERVICE_PACKS_FAILURE,
    GET_SERVICE_SPACES_REQUEST,
    GET_SERVICE_SPACES_SUCCESS,
    GET_SERVICE_SPACES_FAILURE,
    GET_SERVICE_PHONES_REQUEST,
    GET_SERVICE_PHONES_SUCCESS,
    GET_SERVICE_PHONES_FAILURE,
    GET_DEPARTMENTS_REQUEST,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILURE,
  } from "./types";
  import Services from "./services";

  export function addNewServicePhoneRequest(body: any) {
    return async (dispatch: any) => {
      await dispatch({
        type: ADD_SERVICE_PHONE_REQUEST
      });
      try {
        const response = await Services.addNewServicePhoneRequest(body);
        await dispatch({
          type: ADD_SERVICE_PHONE_SUCCESS,
          payload: {
            service: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: ADD_SERVICE_PHONE_FAILURE,
        });
      }
    };
  }

  export function addNewServiceMeetingRoomRequest(body: any) {
    return async (dispatch: any) => {
      await dispatch({
        type: ADD_SERVICE_MEETING_ROOM_REQUEST
      });
      try {
        const response = await Services.addNewServiceMeetingRoomRequest(body);
        await dispatch({
          type: ADD_SERVICE_MEETING_ROOM_SUCCESS,
          payload: {
            service: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: ADD_SERVICE_MEETING_ROOM_FAILURE,
        });
      }
    };
  }

  export function getPacks(addressId) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_SERVICE_PACKS_REQUEST
      });
      try {
        const response = await Services.getAvailablePacks(addressId);
        await dispatch({
          type: GET_SERVICE_PACKS_SUCCESS,
          payload: {
            packs: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_SERVICE_PACKS_FAILURE,
          payload: {
            packs: []
          },
        });
      }
    };
  }

  export function getAddresses(filter = null) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ADDRESSES_REQUEST
      });
      try {
        const response = await Services.getAddresses(filter);
        await dispatch({
          type: GET_ADDRESSES_SUCCESS,
          payload: {
            addresses: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ADDRESSES_FAILURE,
          payload: {
            addresses: []
          },
        });
      }
    };
  }

  export function getAddress(id: number) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ADDRESS_REQUEST
      });
      try {
        const response = await Services.getAddressRequest(id);
        await dispatch({
          type: GET_ADDRESS_SUCCESS,
          payload: {
            address: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ADDRESS_FAILURE,
          payload: {
            address: {}
          },
        });
      }
    };
  }

  export function getDepartments() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_DEPARTMENTS_REQUEST
      });
      try {
        const response = await Services.getDepartmentsRequest();

        await dispatch({
          type: GET_DEPARTMENTS_SUCCESS,
          payload: {
            departments: response.data,
          },
        });
      } catch (e) {
        dispatch({
          type: GET_DEPARTMENTS_FAILURE,
        });
      }
    };
  }

  export function getJobOffers(params: any) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_JOBS_REQUEST
      });
      try {
        const response = await Services.getJobOffersRequest(params);
        await dispatch({
          type: GET_JOBS_SUCCESS,
          payload: {
            jobOffers: response.data.data,
            total: response.data.total,
            lastPage: response.data.last_page
          },
        });
      } catch (e) {
        dispatch({
          type: GET_JOBS_FAILURE,
          payload: {
            jobOffers: []
          },
        });
      }
    };
  }

  export function getJobOffer(id: number) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_JOB_REQUEST
      });
      try {
        const response = await Services.getJobOfferRequest(id);
        await dispatch({
          type: GET_JOB_SUCCESS,
          payload: {
            jobOffer: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_JOB_FAILURE,
        });
      }
    };
  }

  export function getPromotions() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_PROMOTIONS_REQUEST
      });
      try {
        const response = await Services.getPromotionsRequest();
        await dispatch({
          type: GET_PROMOTIONS_SUCCESS,
          payload: {
            promotions: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_PROMOTIONS_FAILURE,
        });
      }
    };
  }

  export function getAddressReviews(id: number) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_ADDRESS_REVIEWS_REQUEST
      });
      try {
        const response = await Services.getAddressReviewsRequest(id);
        await dispatch({
          type: GET_ADDRESS_REVIEWS_SUCCESS,
          payload: {
            reviews: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_ADDRESS_REVIEWS_FAILURE,
        });
      }
    };
  }

  export function getHomeReviews() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_REVIEWS_REQUEST
      });
      try {
        const response = await Services.getHomeReviewsRequest();
        await dispatch({
          type: GET_REVIEWS_SUCCESS,
          payload: {
            reviews: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_REVIEWS_FAILURE,
        });
      }
    };
  }

  export function getFAQs() {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_FAQS_REQUEST
      });
      try {
        const response = await Services.getFAQsRequest();
        await dispatch({
          type: GET_FAQS_SUCCESS,
          payload: {
            faqs: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_FAQS_FAILURE,
        });
      }
    };
  }

  export function getAvailableSpaces(addressId) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_SERVICE_SPACES_REQUEST
      });
      try {
        const response = await Services.getAvailableSpaces(addressId);
        await dispatch({
          type: GET_SERVICE_SPACES_SUCCESS,
          payload: {
            spaces: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_SERVICE_SPACES_FAILURE,
        });
      }
    };
  }

  export function getAvailablePhoneSolutions(addressId) {
    return async (dispatch: any) => {
      await dispatch({
        type: GET_SERVICE_PHONES_REQUEST
      });
      try {
        const response = await Services.getAvailablePhoneSolutions(addressId);
        await dispatch({
          type: GET_SERVICE_PHONES_SUCCESS,
          payload: {
            phones: response.data
          },
        });
      } catch (e) {
        dispatch({
          type: GET_SERVICE_PHONES_FAILURE,
        });
      }
    };
  }
