import React, {useEffect, useState} from "react";
import "./new-mails-dashboard-list.scss";
import { Button, Table, Spin, Badge } from "antd";
import DownloadSmallIcon from "../../svg-components/icons/DownloadSmallIcon";
import DropDownNewMailsAction from "./DropDownNewMailsActions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {getNewMails, getMail} from "../../actions/mails-actions/actions";
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

const NewMailsDashboardList = () => {
  const dispatch = useDispatch();
  const [newMailsList, setNewMailsList] = useState([]);
  const useMountEffect = (fun) => useEffect(fun, [])
  const newMails = useSelector(
    (state: RootState) => state.mailsReducer.newMails
  );
  const isLoadingNewMails = useSelector(
    (state: RootState) => state.mailsReducer.isLoadingNewMails
  );
  const columns : any = [
    {
      title: "EXPÉDITEUR",
      dataIndex: "sender",
      key: "sender",
    },
    {
      title: "Date de Réception",
      dataIndex: "date",
      key: "date",
      render: (text: string) => (<span>{moment(text).format('DD/MM/YYYY à HH:mm')}</span>),
      responsive: ['md'],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Lu",
      dataIndex: "seen",
      key: "seen",
      responsive: ['md'],
      render: (text: any, record: any) => (
        <>
          <Badge offset={[5, 0]} status={record.seen ? "success" : "error"} title={record.seen ? "Lu" : "Non Lu"} />
        </>
      )
    },
    {
      render: (text: any, record: any) => (
        <>
          {record.file ? (
            <button onClick={(e) => downloadMail(e, record)} key={"download"} className={"table-download-icon-btn"}>
              <DownloadSmallIcon />
            </button>
          ) : (
            <Button className={"table-download-icon-btn disabled"} key={"download"}>
              <DownloadSmallIcon />
            </Button>
          )
          }
        </>
      ),
    },

  ];
  const downloadMail = async (e, mail) => {
    await dispatch(getMail(mail.id));
  }
  useEffect((): any => {
    dispatch(getNewMails());
  }, []);
  useEffect(() => {
     setNewMailsList([]);
      if(newMails && newMails.length > 0){
        newMails.map((mail, i) => {
          setNewMailsList(prevState => ([...prevState, {...mail, key: mail.id}]))
        })
      }
  }, [newMails])
  const tableLoading = {
    spinning: isLoadingNewMails,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  return (
    <div className={"new-mails-dashboard-list"}>
      <div className="header-part">
        <h1 className={"title"}>MES NOUVEAUX COURRIERS</h1>
        <DropDownNewMailsAction />
      </div>
      <Table pagination={false} locale={{emptyText: <em style={{color: "#bfbfbf"}}>Vide</em>}} loading={tableLoading} columns={columns} dataSource={newMailsList} />
    </div>
  );
};

export default NewMailsDashboardList;
