import React from "react";

const Success = () => {
  return (
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25 0.208984C38.6921 0.208984 49.7917 11.3086 49.7917 25.0006C49.7917 38.6927 38.6921 49.7923 25 49.7923C11.308 49.7923 0.208344 38.6927 0.208344 25.0006C0.208344 11.3086 11.308 0.208984 25 0.208984ZM32.3668 18.1848C33.1964 17.3551 34.5416 17.3551 35.3713 18.1848C36.201 19.0144 36.201 20.3596 35.3713 21.1893L23.3531 33.2076C22.9215 33.6391 22.3505 33.8462 21.785 33.8288C21.2196 33.8462 20.6486 33.6391 20.217 33.2076L14.5724 27.563C13.7427 26.7333 13.7427 25.3881 14.5724 24.5584C15.4021 23.7287 16.7473 23.7287 17.5769 24.5584L21.785 28.7665L32.3668 18.1848Z" fill="#A7E521"/>
</svg>
  );
};

export default Success;
