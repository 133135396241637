import React, { useEffect, useState } from "react";
import { Layout, BackTop } from "antd";
import Navbar from '../components/navbar/Navbar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import CookiesModal from "../components/cookies-component/CookiesModal";
const { Content } = Layout;

const PackLayout = ({ location, children, ...rest }) => {
  return (
    <Layout style={{ minHeight: "100vh" }} className={"app-container"}>
      <Layout className="app-layout">
        {/* <CookiesModal /> */}
        <Navbar />
        <Content
          className={"content-container"}
        >
          <TransitionGroup component={"section"} >
            <CSSTransition
              key={location.key}
              timeout={450}
              classNames="fade"
              unmountOnExit
            >
              {children}
            </CSSTransition>

          </TransitionGroup>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PackLayout;
