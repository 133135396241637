/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";


function getNotificationsRequest(page: number) {
  return axiosInstance({
    method: "get",
    url: "auth/notification",
    params: {
      page: page
    }
  });
}

function filterNotificationsRequest(data: any) {
  return axiosInstance({
    method: "get",
    url: "auth/notification/search/get",
    params: data
  });
}

function getNewUnseenNotifRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/notification/unseen/get",
  });
}

function changeNotifStatusRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/notification/status/change",
  });
}

function getNotificationRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: `auth/notification/${id}`,
  });
}
const NotificationsServices = {
  getNotificationsRequest,
  filterNotificationsRequest,
  getNewUnseenNotifRequest,
  changeNotifStatusRequest,
  getNotificationRequest,
};

export default NotificationsServices;
