import React from "react";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import MoreDotsSmallIcon from "../../svg-components/icons/MoreDotsSmallIcon";

const DropDownNewMailsAction = () => {
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={"/mails"}>Tous les courriers</Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown placement={"bottomRight"} overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link btn-icon-navbar"
        onClick={(e) => e.preventDefault()}
      >
        <div className={"icon-border"}>
          <MoreDotsSmallIcon />
        </div>
      </a>
    </Dropdown>
  );
};

export default DropDownNewMailsAction;
