import React, { useEffect, useState } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import "./pack-choice-page.scss";
import PackChoiceCard from "../../components/pack-choice-components/PackChoiceCard";
import LocalisationIcon from "../../svg-components/icons/LocalisationIcon";
import DownArrow from "../../svg-components/icons/DownArrow";
import CardBlueCheckout from "../../components/card-blue-checkout/CardBlueCheckout";
import { Select, Button, Spin, Radio, Space, Modal, Row, Col } from 'antd';
import { getPacks, getAddresses } from "../../actions/services-actions/actions";
import { subscribeDomiciliation } from "../../actions/profile-actions/actions";
import { updateCart, emptyCart } from "../../actions/cart-actions/actions";
import { LoadingOutlined } from '@ant-design/icons';
import MapContainer from "../../tools/MapContainer";
import CloseRed from "../../svg-components/icons/CloseRed";
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const TVA: number = Number(process.env.MIX_TVA) / 100 ? Number(process.env.MIX_TVA) / 100 : 0.19;
const DROIT_TIMBRE: number = Number(process.env.MIX_DROIT_TIMBRE) ? Number(process.env.MIX_DROIT_TIMBRE) : 0.6;

const PackChoicePage = ({ history, location }: any) => {
  useEffect(() => {
    document.title = `Choisir mon pack de domiciliation | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", `https://app.domicilier.tn/pack-choice`);
    }
  })
  const cart = useSelector(
    (state: RootState) => state.cartReducer.cart
  );
  const allAvailablePacks = useSelector(
    (state: RootState) => state.serivcesReducer.packs
  );
  const centers = useSelector(
    (state: RootState) => state.serivcesReducer.addresses
  );
  const isLoadingPacks = useSelector(
    (state: RootState) => state.serivcesReducer.isLoadingPacks
  );
  const choosedAPack = useSelector(
    (state: RootState) => state.authReducer.choosedAPack
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const isLoadingSubscription = useSelector(
    (state: RootState) => state.profileReducer.isLoadingSubscription
  );
  const [selectedAddress, setSelectedAddress] = useState(cart.address);
  const [showInMap, setShowInMap] = useState(false);
  const dispatch = useDispatch();
  useEffect((): any => {
    dispatch(getAddresses())
  }, []);
  useEffect(() => {
    if (selectedAddress && selectedAddress.id) {
      fetchAvailablePacks(selectedAddress.id);
    }
  }, [selectedAddress])
  useEffect(() => {
    if (choosedAPack) {
      if (location.state && location.state.from) {
        history.push({
          pathname: location.state.from.pathname,
          search: location.state.from.search,
          state: location.state.from.state
        })
      } else {
        history.push("/dashboard")
      }
    }
  }, [choosedAPack])

  const fetchAvailablePacks = (addressId: number) => {
    dispatch(getPacks(addressId))
  }
  useEffect(() => {
    let total = 0;
    let amount = 0;
    cart.packs.map((pack, i) => {
      total += pack.priceHt;
    });

    switch (cart.billing.type) {
      case 1:
        amount = (total * 11);
        break;
      case 2:
        amount = (total * 21);
        break;
      default:
        amount = total;
        break;
    }
    if (amount > 0) {
      amount += amount * TVA + DROIT_TIMBRE;
    }
    let newCart = cart;
    newCart.billing = { ...newCart.billing, totalHt: total, totalAmount: amount }
    dispatch(updateCart(newCart));
  }, [cart.packs.length]);
  useEffect(() => {
    let total = 0;
    let amount = 0;
    cart.packs.map((pack, i) => {
      total += pack.priceHt;
    });

    switch (cart.billing.type) {
      case 1:
        amount = (total * 11);
        break;
      case 2:
        amount = (total * 21);
        break;
      default:
        amount = total;
        break;
    }
    if (amount > 0) {
      amount += amount * TVA + DROIT_TIMBRE;
    }
    let newCart = cart;
    newCart.billing = { ...newCart.billing, totalHt: total, totalAmount: amount }
    dispatch(updateCart(newCart));
  }, [cart.billing.type]);
  function onChange(e) {
    cart.billing.type = e.target.value;
    dispatch(updateCart(cart));
  };

  async function handleChange(value) {
    await dispatch(emptyCart());
    const address = centers.find((center, index) => center.id == value);
    setSelectedAddress(address);
  }
  useEffect(() => {
    cart.address = selectedAddress;
    dispatch(updateCart(cart));
  }, [allAvailablePacks])
  // function handleClick() {
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     let min = getDistanceFromLatLonInKm(position.coords.latitude, position.coords.longitude, centers[0].lat, centers[0].long);
  //     let nearset = 0;
  //     for (var i = 0; i < centers.length; i++) {
  //       // get nearset center from user's position
  //       let dist = getDistanceFromLatLonInKm(position.coords.latitude, position.coords.longitude, centers[i].lat, centers[i].long)
  //       if (dist <= min) {
  //         min = dist;
  //       }
  //       if (dist = min) {
  //         nearset = i;
  //       }
  //     }
  //     cart.address.id = centers[nearset].id;
  //     cart.address.location = centers[nearset].location;
  //     cart.packs = [];
  //     cart.billing.totalHt = 35;
  //     dispatch(updateCart(cart));
  //     fetchAvailablePacks(cart.address.id);
  //   },
  //     error => console.log(error.message),
  //     { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
  //   );
  // }
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
  useEffect(() => {
    if (bill) {
      history.push('/paiement');
    }
  }, [bill]);
  const getAddressInMap = () => {
    if (cart.address.id) {
      setShowInMap(true);
    }
  }
  const submitDomiSubscription = () => {
    if (cart.address.id && cart.packs.length > 0) {
      let packs = [];
      cart.packs.map((pack, i) => packs.push(pack.id));
      const body = {
        address_id: cart.address.id,
        billing_type: cart.billing.type,
        packs: packs,
      }
      dispatch(subscribeDomiciliation(body));
    }
  }
  return (
    <div style={{ position: "relative", minHeight: "calc(100vh - 7rem)" }}>
      <div className={"pack-choice-page"}>
        <div className="left">
          <h1 className="big-title">Personnalisation de votre domiciliation</h1>
          <div className="marger-bar-blue" />
          <div className="billing-wrapper">
            <Radio.Group onChange={onChange} value={cart.billing.type}>
              <Space direction="vertical">
                <Radio value={1} className="billing-type-radio">Facturation 1 an <span className="offer">(1 mois offert)</span></Radio>
                <Radio value={2} className="billing-type-radio">Facturation 2 ans <span className="offer">(3 mois offert)</span></Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className={"position-selection"}>
            <Select
              id="domicilation-center"
              size={"large"}
              onChange={handleChange}
              dropdownClassName={"select-menu"}
              suffixIcon={<DownArrow />}
              value={selectedAddress ? selectedAddress.id : null}
              placeholder={"Choisissez votre adresse"}>
              {centers && centers.length > 0 ? centers.map((center, i) => (
                <Option key={i} value={center.id}>{center.full_location} | {center.from_price ? center.from_price.toLocaleString('fr-FR', { minimumFractionDigits: 3 }) + " Dt/mois" : ""} {center.promo ? <span className="address-has-promo">Promo</span> : null}</Option>
              )) : (
                <Option key="empty" value={null}><em>Aucune adresse</em></Option>
              )}
            </Select>
            <Button
              type="primary"
              icon={<LocalisationIcon />}
              onClick={getAddressInMap}
              title="Voir sur Google Maps"
            />
          </div>
          {isLoadingPacks ? (
            <div className="spinner-container">
              <Spin indicator={LoadingOutlinedIcon} />
            </div>
          ) : (
            <Row className="cards-container" gutter={[24, 24]}>
              {
                /*
                1. We’re mapping over the available packs and creating a PackChoiceCard component for each one.
                2. We’re passing the price, the title, the description, the icon, and the promo to the PackChoiceCard component.
                3. We’re setting the animation delay to 0.1s for each card.
                4. We’re setting the promo to null if there isn’t any.
                5. We’re returning the component.
                */
                allAvailablePacks.map((offer, index) => (
                  <Col xs={24} md={8} key={index}>
                    <PackChoiceCard
                      offer={offer}
                      defaultSelected={offer.service.name == "Domiciliation"}
                      price={offer.price}
                      title={offer.service.name}
                      description={offer.service.description}
                      animDelay={`${index * 0.1}s`}
                      promo={offer.active_promo ? offer.active_promo : null}
                    />
                  </Col>
                ))
              }
            </Row>
          )}
        </div>
        <div className="right">
          <>
            <CardBlueCheckout
              payment={"paymee"}
              isVisiblePayBtn={false}
              withCouponField={false}
            />
            {(cart && cart.address && cart.packs.length > 0) ? (
              <Button className="light-blue-btn" onClick={submitDomiSubscription}>{isLoadingSubscription ? (<Spin indicator={antIcon} />) : "Etape suivante"}</Button>
            ) : null}
          </>
        </div>
        <Modal
          title=""
          centered
          visible={showInMap}
          onCancel={() => setShowInMap(false)}
          width={"80%"}
          style={{ top: 20 }}
          footer={[]}
          closeIcon={<CloseRed />}
        >
          <MapContainer
            height={600}
            address={cart.address}
          />
        </Modal>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div>

  );
};

export default PackChoicePage;
