
export const GET_DISCUSSIONS_REQUEST = "GET_DISCUSSIONS_REQUEST";
export const GET_DISCUSSIONS_SUCCESS = "GET_DISCUSSIONS_SUCCESS";
export const GET_DISCUSSIONS_FAILURE = "GET_DISCUSSIONS_FAILURE";

export const GET_FILTRED_DISCUSSIONS_REQUEST = "GET_FILTRED_DISCUSSIONS_REQUEST";
export const GET_FILTRED_DISCUSSIONS_SUCCESS = "GET_FILTRED_DISCUSSIONS_SUCCESS";
export const GET_FILTRED_DISCUSSIONS_FAILURE = "GET_FILTRED_DISCUSSIONS_FAILURE";

export const GET_DISCUSSION_REQUEST = "GET_DISCUSSION_REQUEST";
export const GET_DISCUSSION_SUCCESS = "GET_DISCUSSION_SUCCESS";
export const GET_DISCUSSION_FAILURE = "GET_DISCUSSION_FAILURE";

export const GET_ATTACHMENT_REQUEST = "GET_ATTACHMENT_REQUEST";
export const GET_ATTACHMENT_SUCCESS = "GET_ATTACHMENT_SUCCESS";
export const GET_ATTACHMENT_FAILURE = "GET_ATTACHMENT_FAILURE";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE";

export const GET_NEW_UNSEEN_REQUEST = "GET_NEW_UNSEEN_REQUEST";
export const GET_NEW_UNSEEN_SUCCESS = "GET_NEW_UNSEEN_SUCCESS";
export const GET_NEW_UNSEEN_FAILURE = "GET_NEW_UNSEEN_FAILURE";

export const START_DISCUSSION_REQUEST = "START_DISCUSSION_REQUEST";
export const START_DISCUSSION_SUCCESS = "START_DISCUSSION_SUCCESS";
export const START_DISCUSSION_FAILURE = "START_DISCUSSION_FAILURE";

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

export const DELETE_DISCUSSION_REQUEST = "DELETE_DISCUSSION_REQUEST";
export const DELETE_DISCUSSION_SUCCESS = "DELETE_DISCUSSION_SUCCESS";
export const DELETE_DISCUSSION_FAILURE = "DELETE_DISCUSSION_FAILURE";

export const EMPTY_DISCUSSIONS = "EMPTY_DISCUSSIONS";
