import React, { useEffect, useState } from "react";
import "../account-verification-page.scss";
import { Alert, Form, Input, Spin, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import Success from "../../../../svg-components/Success";
import EmailVerificationModal from "./EmailVerificationModal";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AccountVerficationPage = ({ history, location }: any) => {
  useEffect(() => {
    document.title = `Vérifier mon compte | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/verify`);
    }
  })
  const emailIsVerified = useSelector(
    (state: RootState) => state.authReducer.emailIsVerified
  );
  const phoneIsVerified = useSelector(
    (state: RootState) => state.authReducer.phoneIsVerified
  );
  useEffect(() => {
    if (!phoneIsVerified) {
      history.push('/verify/phone')
    }
  }, [phoneIsVerified])
  return (
    <div className={"auth-page"}>
      <div className="max-width-container">
        <div className="container-center">
          {emailIsVerified ? (
            <Alert
              message="Compte vérifié !"
              description={
                <div>
                  <p>Votre compte a été vérifié. <br /> Vous pouvez maintenant accéder à votre dashboard.</p>
                  <Link to="/dashboard">Obtenir ma domiciliation</Link>
                </div>
              }
              type="success"
              icon={<Success />}
              showIcon
            />
          ) : (
            <EmailVerificationModal
              visible={!emailIsVerified}
            />
          )}
        </div>
      </div >
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div >
  );
};

export default AccountVerficationPage;
