import React from "react";

const SignalSmallIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0016 2.2C17.0016 0.984974 16.0166 0 14.8016 0C13.5865 0 12.6016 0.984974 12.6016 2.2V13.8C12.6016 15.015 13.5865 16 14.8016 16C16.0166 16 17.0016 15.015 17.0016 13.8V2.2ZM14.0016 2.2C14.0016 1.75817 14.3597 1.4 14.8016 1.4C15.2434 1.4 15.6016 1.75817 15.6016 2.2V13.8C15.6016 14.2418 15.2434 14.6 14.8016 14.6C14.3597 14.6 14.0016 14.2418 14.0016 13.8V2.2ZM8.80156 6.4C8.35974 6.4 8.00156 6.75817 8.00156 7.2V13.8C8.00156 14.2418 8.35974 14.6 8.80156 14.6C9.24339 14.6 9.60156 14.2418 9.60156 13.8V7.2C9.60156 6.75817 9.24339 6.4 8.80156 6.4ZM5.00156 11.2C5.00156 9.98497 4.01659 9 2.80156 9C1.58654 9 0.601562 9.98497 0.601562 11.2V13.8C0.601562 15.015 1.58654 16 2.80156 16C4.01659 16 5.00156 15.015 5.00156 13.8V11.2ZM8.80156 5C10.0166 5 11.0016 5.98497 11.0016 7.2V13.8C11.0016 15.015 10.0166 16 8.80156 16C7.58654 16 6.60156 15.015 6.60156 13.8V7.2C6.60156 5.98497 7.58654 5 8.80156 5ZM2.00156 11.2C2.00156 10.7582 2.35973 10.4 2.80156 10.4C3.24339 10.4 3.60156 10.7582 3.60156 11.2V13.8C3.60156 14.2418 3.24339 14.6 2.80156 14.6C2.35973 14.6 2.00156 14.2418 2.00156 13.8V11.2Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default SignalSmallIcon;
