import React from "react";

const ArrowTurningSmallIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.6759 16.865L16.6115 32.9294L12.7441 29.062L14.8554 26.9507C15.069 26.7372 15.1488 26.4241 15.0636 26.1343C14.9784 25.8446 14.7418 25.6246 14.4465 25.5608L7.56821 24.072C7.29409 24.0126 7.00858 24.0966 6.81021 24.295C6.61184 24.4934 6.52792 24.7788 6.58723 25.053L8.07591 31.9312C8.1398 32.2264 8.35976 32.4631 8.64945 32.5483C8.93913 32.6336 9.25225 32.5537 9.46579 32.3401L11.5771 30.2288L16.028 34.6797C16.3501 35.0019 16.8726 35.002 17.1949 34.6797L33.8427 18.0318C34.1649 17.7097 34.1649 17.1871 33.8427 16.8649C33.5206 16.5428 32.9982 16.5428 32.6759 16.865ZM8.4711 25.9559L12.6186 26.8536L9.36875 30.1034L8.4711 25.9559Z"
        fill="#3240FF"
      />
      <path
        d="M29.3087 5.40933C29.0189 5.32415 28.706 5.40388 28.4924 5.61753L26.3811 7.72884L21.9302 3.27797C21.608 2.95581 21.0854 2.95581 20.7633 3.27797L4.11544 19.9258C3.79327 20.248 3.79327 20.7706 4.11544 21.0927C4.4376 21.4149 4.96018 21.4149 5.28234 21.0927L21.3467 5.02833L25.7976 9.4792C26.1198 9.80136 26.6423 9.80136 26.9645 9.4792L28.5894 7.85429L29.4871 12.0018L23.8607 10.7842C23.4154 10.6877 22.9762 10.9705 22.8798 11.4161C22.7833 11.8614 23.0663 12.3008 23.5116 12.397L30.39 13.8856C30.6641 13.945 30.9496 13.861 31.148 13.6627C31.3463 13.4643 31.4303 13.1789 31.3709 12.9047L29.8823 6.02643C29.8185 5.7312 29.5985 5.49451 29.3087 5.40933Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default ArrowTurningSmallIcon;
