import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import "./reset-password-page.scss";
import { Alert, Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { checkReinitialisationLink, resetPassword, changePassword } from "../../../actions/auth-actions/actions";
import { RootState } from "../../../reducers";
import { LoadingOutlined } from '@ant-design/icons';

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ResetPasswordPage = ({ history }) => {
  const dispatch = useDispatch();
  const isSendingRequest = useSelector((state: RootState) => state.authReducer.isSendingRequest);
  const isCheckingLink = useSelector((state: RootState) => state.authReducer.isCheckingLink);
  const linkValidity = useSelector((state: RootState) => state.authReducer.linkValidity);
  const passwordChangeStatus = useSelector((state: RootState) => state.authReducer.passwordChangeStatus);
  const { code }: any = useParams();
  const [emailForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  useEffect(() => {
    document.title = `Réinitialiser mon mot de passe | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/reset-password`);
    }
  })
  const submitEmail = async (values) => {
    const res : any = await dispatch(resetPassword(values));
    if(res.message){
      emailForm.resetFields();
    }
  }
  const submitPassword = async (values) => {
    values.reset_code = code;
    const res: any = await dispatch(changePassword(values));
  }
  useEffect(() => {
    if (code) {
      checkValidCode();
    }
  }, [code])
  const checkValidCode = async () => {
    const res: any = await dispatch(checkReinitialisationLink(code));
  }
  useEffect(() => {
    if(passwordChangeStatus){
      passwordForm.resetFields();
      history.push('/login')
    }
  }, [passwordChangeStatus])
  return (
    <div className={"auth-page"}>
      <div className="max-width-container">
        <div className="container-center">
          <div className={"reset-password-page"}>
            {code ? isCheckingLink ? (
              <div className="spinner-container">
                <Spin indicator={LoadingOutlinedIcon} />
              </div>
            ) : linkValidity && linkValidity.user_id ? (
              <>
                <h1 className={"reset-password-title"} >
                  Réinitialisez votre mot de passe
                </h1>
                <hr style={{ marginBottom: "5rem" }} />
                <Form
                  name="reset-password-form"
                  className={"reset-form"}
                  form={passwordForm}
                  onFinish={submitPassword}
                >
                  <div className={"password-input-wrapper"}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                            value && !value.match(passwordPattern) ? cb("") : cb();
                          }
                        },
                      ]}
                      className={"password-input"}
                      validateTrigger="onBlur"
                    >
                      <Input.Password placeholder={"Tapez votre nouveau mot de passe"} onChange={e => passwordForm.setFieldsValue({ password: e.target.value })} />
                      <div className={"password-description"}>
                        Le mot de passe doit contenir 8 caractères minimum, une lettre majuscule, une lettre minuscule, un symbole, un chiffre.
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="password_confirmation"
                      rules={[
                        {
                          validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                            value !== passwordForm.getFieldValue('password') ? cb("Les mots de passe que vous avez entrés ne sont pas identiques !") : cb();
                          }
                        },
                      ]}
                      validateTrigger="onBlur"
                      className={"password-input"}
                    >
                      <Input.Password placeholder={"Confirmez le mot de passe"} onChange={e => passwordForm.setFieldsValue({ password_confirmation: e.target.value })} />
                    </Form.Item>
                  </div>
                  <button className={"reset-password-btn-light"} style={{ marginTop: "2rem" }} type="submit">
                    {isSendingRequest ? (<Spin indicator={antIcon} />) : "Confirmer"}
                  </button>
                </Form>
              </>
            ) : (
              <Alert
                message="Lien de réinitialisation invalide ou exipré !"
                description="Le lien de réinitialisation est invalide ou a expiré. Veuillez réessayer à nouveau."
                type="error"
                showIcon
              />
            ) : (
              <>
                <h1 className={"reset-password-title"}>
                  Mot de passe oublié ?
                  <p className={"reset-password-text"}>Entrez votre email et un lien de réinitialisation vous sera envoyé.</p>
                </h1>
                <hr />
                <Form
                  name="reset-form"
                  form={emailForm}
                  className={"reset-form"}
                  onFinish={submitEmail}
                >
                  <div className={"email-input-wrapper"}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Saisir l'adresse email!",
                        },
                        {
                          type: 'email',
                          message: "Saisir une adresse email valide!",
                        },
                      ]}
                      validateTrigger="onBlur"
                      className={"email-input"}
                    >
                      <Input placeholder={"Tapez votre email"} />
                    </Form.Item>
                    <button className={"reset-password-btn-light"} type="submit">
                      {isSendingRequest ? (<Spin indicator={antIcon} />) : "Envoyer"}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div >
  );
};

export default ResetPasswordPage;
