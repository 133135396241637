import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import "./dashboard-page.scss";
import { Row, Col, Skeleton  } from "antd";
import DashboardPageCardsContainer from "../../components/dashboard-page-cards-container/DashboardPageCardsContainer";
import NewMailsDashboardList from "../../components/new-mails-dashboard-list/NewMailsDashboardList";
import MyServicesDashboardCards from "../../components/my-services-dashboard-cards/MyServicesDashboardCards";
import StatsDashboardCardsContainer from "../../components/stats-dashboard-cards-container/StatsDashboardCardsContainer";
import { getLatestBills, getInfos } from "../../actions/dashboard-actions/actions";
import {getNewMails} from "../../actions/mails-actions/actions";
import { getProfile } from "../../actions/profile-actions/actions";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.isLoggedIn);
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const useMountEffect = (fun) => useEffect(fun, [])
  useMountEffect(() => {
    if(isLoggedIn){
      dispatch(getProfile());
      dispatch(getLatestBills());
      dispatch(getInfos());
    }
  })
  useEffect(() => {
    document.title = `Dashboard | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn`);
    }
  })
  return (
    profile ? (
      <div className={"dashboard-page"}>
      <div className="left">
        <DashboardPageCardsContainer />
      </div>
      <div className="right">
        <NewMailsDashboardList />
        <Row gutter={[24, 24]} justify={"space-between"}>
          <Col span={8} xs={24} md={8} lg={8}>
            <MyServicesDashboardCards />
          </Col>
          <Col span={16} xs={24} md={16} lg={16}>
            <StatsDashboardCardsContainer />
          </Col>
        </Row>
      </div>
    </div>
    ) : (
      <div className={"dashboard-page"}>
        <Skeleton active />
      </div>
    )
  );
};

export default DashboardPage;
