/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getMailsRequest(page: number) {
  return axiosInstance({
    method: "get",
    url: "auth/mails",
    params: {page: page},
  });
}

function getMailRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: "auth/mails/get/" + id,
    responseType: 'blob',
  });
}

function getNewMailsRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/new-mails",
    data: null,
  });
}

function filterMailsRequest(body: any) {
  return axiosInstance({
    method: "get",
    url: "auth/filter/mails",
    params: body,
  });
}

function exportMailsRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/mails/export",
    responseType: 'blob',
    data: body,
  });
}

const MailsServices = {
  getMailsRequest,
  getMailRequest,
  getNewMailsRequest,
  filterMailsRequest,
  exportMailsRequest
};

export default MailsServices;
