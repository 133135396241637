import React, { useEffect } from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { MenuIndrawerSider } from "../sidebar/Sidebar";
import SmallLogo from "../../svg-components/SmallLogo";
import { OPEN_DASHBOARD_SIDER, CLOSE_DASHBOARD_SIDER } from "../../actions/ui-actions/types";
import { withRouter, Link } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

const DrawerMenu = ({ history }: any) => {
  const dashboardSiderOpened = useSelector(
    (state: RootState) => state.uiReducer.dashboardSiderOpened
  );
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen(() => {
      setTimeout(() => {
        dispatch({type: CLOSE_DASHBOARD_SIDER});
      }, 400);
    });
  }, []);
  return (
    <Drawer
      className={"drawer-only-mobile"}
      title={
        <button
          onClick={() => dispatch({type: CLOSE_DASHBOARD_SIDER})}
          className={"menu-drawer-icon"}
        >
          <CloseOutlined />
        </button>
      }
      placement="right"
      closable={false}
      visible={dashboardSiderOpened}
      onClose={() => dispatch({type: CLOSE_DASHBOARD_SIDER})}
    >
      <a
        href={process.env.MIX_SITE_URL}
        onClick={() => dispatch({type: CLOSE_DASHBOARD_SIDER})}
        className="sidebar-logo-container"
      >
        <SmallLogo />
      </a>
      <MenuIndrawerSider history={history} />
    </Drawer>
  );
};

export default withRouter(DrawerMenu);
