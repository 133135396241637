import React, {useEffect, useState} from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import { Modal, Spin, Button, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import $ from "jquery";
const lovelyGift = require("../../../assets/png/lovely-gift.png")
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface PackChoosedModalProps {
  visible: boolean,
  abort: any
}

const PackChoosedModal : React.FC<PackChoosedModalProps> = ({visible, abort}:any) => {
  const dispatch = useDispatch();
  const useMountEffect = (fun) => useEffect(fun, [])
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const [VisibleModal, setVisibleModal] = useState(false);
  const [messageTo, setMessageTo] = useState(null);
  useEffect(() => {
    setVisibleModal(visible);
  }, [visible])
  useEffect(() => {
    if (VisibleModal) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [VisibleModal])
  const closeModal = () => {
    abort();
  }
  return (
    <Modal
      centered
      visible={VisibleModal}
      onCancel={closeModal}
      style={{ top: 20, backgroundImage: `url('${lovelyGift}')` }}
      footer={<div></div>}
      wrapClassName={"packchoosed-modal"}
      width={"45%"}
    >
      <div className={"packchoosed-modal-content"} >
        <h2>Félicitations!</h2>
        <p>Nous sommes heureux de vous compter parmi notre clientèle !</p>
        <p>Votre domiciliation a été  effectuée avec succès !</p>
        <p>Vous recevrez un mail récapitulatif de votre commande ainsi que votre facture !</p>
      </div>
    </Modal>
  );
}
export default PackChoosedModal;
