import React from "react";

const FilterValidation = () => {
    return (
        <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.869 9.37584C21.6754 9.01226 21.2993 8.78642 20.8874 8.78642H17.7281C17.4644 7.303 16.1662 6.17271 14.6081 6.17271C13.0501 6.17271 11.7519 7.303 11.4881 8.78642H8.28744C8.53847 8.40226 8.68506 7.94392 8.68506 7.45176C8.68506 6.10253 7.58741 5.00488 6.23818 5.00488C4.88895 5.00488 3.7913 6.10253 3.7913 7.45176C3.7913 7.94392 3.93789 8.40226 4.18892 8.78642H1.57932C1.16741 8.78642 0.791313 9.01226 0.597676 9.37584C0.404094 9.73937 0.426561 10.1775 0.656401 10.5193L8.11916 21.6168V27.9166C8.11916 28.1041 8.21364 28.279 8.37052 28.3817C8.46245 28.4419 8.56861 28.4727 8.67533 28.4727C8.75062 28.4727 8.8262 28.4574 8.89733 28.4264L14.0135 26.1985C14.2164 26.1101 14.3476 25.9099 14.3476 25.6886V21.6168L21.8103 10.5193C22.0401 10.1775 22.0627 9.73937 21.869 9.37584ZM14.6081 7.28493C15.55 7.28493 16.3455 7.92129 16.5888 8.78642H12.6275C12.8708 7.92129 13.6663 7.28493 14.6081 7.28493ZM6.23823 6.1171C6.97413 6.1171 7.5729 6.71586 7.5729 7.45176C7.5729 8.18766 6.97413 8.78642 6.23823 8.78642C5.50234 8.78642 4.90357 8.18766 4.90357 7.45176C4.90357 6.71586 5.50234 6.1171 6.23823 6.1171ZM13.33 21.1368C13.2683 21.2285 13.2353 21.3366 13.2353 21.4471V25.3243L9.23133 27.0678V22.0032H11.2404C11.5475 22.0032 11.7965 21.7543 11.7965 21.4471C11.7965 21.14 11.5475 20.891 11.2404 20.891H8.9714L3.37455 12.568H14.6081C14.9153 12.568 15.1642 12.319 15.1642 12.0119C15.1642 11.7047 14.9153 11.4557 14.6081 11.4557H2.62664L1.57954 9.89864H20.8873L13.33 21.1368Z" fill="#3240FF"/>
        <path d="M13.1312 0C11.782 0 10.6843 1.09765 10.6843 2.44688C10.6843 3.79611 11.782 4.89376 13.1312 4.89376C14.4804 4.89376 15.5781 3.79611 15.5781 2.44688C15.5781 1.09765 14.4804 0 13.1312 0ZM13.1312 3.78154C12.3953 3.78154 11.7965 3.18278 11.7965 2.44688C11.7965 1.71098 12.3953 1.11222 13.1312 1.11222C13.8671 1.11222 14.4659 1.71098 14.4659 2.44688C14.4659 3.18278 13.8671 3.78154 13.1312 3.78154Z" fill="#3240FF"/>
        <path d="M19.0744 2.68799C18.7673 2.68799 18.5183 2.93696 18.5183 3.2441V6.40469C18.5183 6.71188 18.7673 6.96085 19.0744 6.96085C19.3816 6.96085 19.6305 6.71188 19.6305 6.40474V3.2441C19.6305 2.93696 19.3816 2.68799 19.0744 2.68799Z" fill="#3240FF"/>
        <path d="M19.4676 1.02476C19.3642 0.92132 19.2207 0.861816 19.0744 0.861816C18.9282 0.861816 18.7847 0.92132 18.6813 1.02476C18.5778 1.12819 18.5183 1.27167 18.5183 1.41793C18.5183 1.56418 18.5778 1.70766 18.6813 1.81109C18.7847 1.91453 18.9282 1.97403 19.0744 1.97403C19.2207 1.97403 19.3642 1.91453 19.4676 1.81109C19.571 1.70766 19.6305 1.56418 19.6305 1.41793C19.6305 1.27167 19.571 1.12819 19.4676 1.02476Z" fill="#3240FF"/>
        <path d="M8.12911 0.709473C7.82197 0.709473 7.573 0.958443 7.573 1.26558V3.54563C7.573 3.85277 7.82197 4.10174 8.12911 4.10174C8.43625 4.10174 8.68522 3.85277 8.68522 3.54563V1.26558C8.68522 0.958443 8.43625 0.709473 8.12911 0.709473Z" fill="#3240FF"/>
        <path d="M0.556109 0C0.24897 0 0 0.24897 0 0.556109V4.18016C0 4.4873 0.24897 4.73627 0.556109 4.73627C0.863248 4.73627 1.11222 4.4873 1.11222 4.18016V0.556109C1.11222 0.24897 0.863248 0 0.556109 0Z" fill="#3240FF"/>
        <path d="M0.949278 5.87241C0.845842 5.76842 0.702366 5.70947 0.556109 5.70947C0.409852 5.70947 0.266376 5.76842 0.16294 5.87241C0.0595037 5.97585 0 6.11877 0 6.26558C0 6.41184 0.0595037 6.55531 0.16294 6.65875C0.266376 6.76219 0.409852 6.82169 0.556109 6.82169C0.702366 6.82169 0.845842 6.76219 0.949278 6.65875C1.05271 6.55531 1.11222 6.41184 1.11222 6.26558C1.11222 6.11877 1.05271 5.97585 0.949278 5.87241Z" fill="#3240FF"/>
        <path d="M17.4742 11.619C17.3707 11.5156 17.2273 11.4561 17.081 11.4561C16.9342 11.4561 16.7907 11.5156 16.6878 11.619C16.5839 11.7224 16.5249 11.8659 16.5249 12.0122C16.5249 12.1584 16.5839 12.3019 16.6878 12.4053C16.7913 12.5088 16.9342 12.5683 17.081 12.5683C17.2273 12.5683 17.3702 12.5088 17.4742 12.4053C17.5776 12.3019 17.6371 12.1584 17.6371 12.0122C17.6371 11.8659 17.5776 11.7224 17.4742 11.619Z" fill="#3240FF"/>
        </svg>     
    );
};

export default FilterValidation;
