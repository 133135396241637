import React from "react";

const FileSmall = () => {
return (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M10.9025 12.5H4.09686C3.90561 12.5 3.74998 12.36 3.74998 12.1875V2.81248C3.74998 2.63998 3.90561 2.49998 4.09686 2.49998H6.87498V5.09374C6.87498 6.07624 7.63561 6.87499 8.57123 6.87499H11.25V12.1875C11.25 12.36 11.0937 12.5 10.9025 12.5ZM11.0306 5.62498H8.57125C8.325 5.62498 8.125 5.38686 8.125 5.09373V2.49998H8.195L11.0306 5.62498ZM12.3375 5.205L8.935 1.455C8.81688 1.32437 8.64875 1.25 8.47188 1.25H4.09688C3.21625 1.25 2.5 1.95125 2.5 2.8125V12.1875C2.5 13.0487 3.21625 13.75 4.09688 13.75H10.9025C11.7831 13.75 12.5 13.0487 12.5 12.1875V5.625C12.5 5.46937 12.4419 5.32 12.3375 5.205Z"
    fill="#231F20" />
  <mask id="mask0" x="2" y="1" width="11" height="13">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10.9025 12.5H4.09686C3.90561 12.5 3.74998 12.36 3.74998 12.1875V2.81248C3.74998 2.63998 3.90561 2.49998 4.09686 2.49998H6.87498V5.09374C6.87498 6.07624 7.63561 6.87499 8.57123 6.87499H11.25V12.1875C11.25 12.36 11.0937 12.5 10.9025 12.5ZM11.0306 5.62498H8.57125C8.325 5.62498 8.125 5.38686 8.125 5.09373V2.49998H8.195L11.0306 5.62498ZM12.3375 5.205L8.935 1.455C8.81688 1.32437 8.64875 1.25 8.47188 1.25H4.09688C3.21625 1.25 2.5 1.95125 2.5 2.8125V12.1875C2.5 13.0487 3.21625 13.75 4.09688 13.75H10.9025C11.7831 13.75 12.5 13.0487 12.5 12.1875V5.625C12.5 5.46937 12.4419 5.32 12.3375 5.205Z"
      fill="white" />
  </mask>
  <g mask="url(#mask0)">
    <rect width="15" height="15" fill="#3240FF" />
  </g>
</svg>
);
};

export default FileSmall;
