import React, {useEffect, useState} from "react";
import "./card-blue-checkout.scss";
import { Row, Col } from 'antd';

interface IMyProps {
  title: string,
  description: string,
  price: number
}

const CheckoutItem: React.FC<IMyProps> = (props: IMyProps) =>  {
  return (
    <div className={"item-checkout"}>
      <Row gutter={[12, 12]} justify="space-between" className={"ligne"}>
        <Col span={18} className="left">
          <h4>{props.title || null }</h4>
          <p>{props.description || null}
          </p>
        </Col>
        <Col span={6} className="right">
            <h3>{props.price && props.price > 0 ? props.price.toLocaleString('fr-FR', { minimumFractionDigits: 3 }) + " Dt" : null}</h3>
        </Col>
      </Row>
    </div>
  );
};

export default CheckoutItem;
