import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { deleteFile } from "../../../actions/profile-actions/actions";
import { Progress, Button, Upload, message, Modal } from "antd";
import UploadSmallIcon from "../../../svg-components/icons/UploadSmallIcon";
import $ from "jquery";
import CheckSmallGreenIcon from "../../../svg-components/icons/CheckSmallGreeenIcon";
import Note from "../../../svg-components/icons/Note";

const path = process.env.MIX_API_URL + "/auth/upload-file";

// MAIN
const UploadFile = (props) => {
    const dispatch = useDispatch();
    const profile = useSelector((state: RootState) => state.profileReducer.profile);
    const [VisibleModal, setVisibleModal] = useState(false);
    const [filename, setFilename] = useState(null);
    const [originalFileName, setOriginalFileName] = useState(null);
    const [count, setCount] = useState(0);
    const [filePath, setFilePath] = useState(null);
    const token = localStorage.getItem("domicilier_token");

    const properties = {
        name: "file",
        action: path,
        headers: {
            authorization: `Bearer ${token}`,
            contentType: 'multipart/form-data',
        },
        accept: ".pdf, .png, .jpg, .jpeg",
        maxCount: 1,
        onChange(info: any) {
            if (info.file.status == "uploading") {
              setCount(info.file.percent);
            }
            if (info.file.status === "done") {
                setOriginalFileName(info.file.name);
                setCount(100);
                const { response } = info.file;
                setFilename(response.filename);
            } else if (info.file.status === "error") {
              setCount(0);
              const { response } = info.file
              if(response.file && typeof(response.file) == "object"){
                message.error({
                  content: response.file[0],
                  className: 'error-message',
                  duration: 3
                });
              }else{
                message.error({
                  content: 'Echec de chargement de fichier.',
                  className: 'error-message',
                  duration: 3
                });
              }
            }
        },
    };
    useEffect(() => {
      // const subFolder = props.fieldName == "ngsign_contract" ? `contracts/${profile[props.fieldName]}` : profile[props.fieldName];
      setFilePath(process.env.MIX_STORAGE_URL + profile[props.fieldName]);
    })
    useEffect(() => {
      if (VisibleModal) {
        $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
      } else {
        $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
      }
    }, [VisibleModal])
    const apply = () => {
        props.parentSetFileName(filename)
        setVisibleModal(false);
    };
    const onCancel = async () => {
      if(filename){
        await dispatch(deleteFile(filename));
      }
      setCount(0);
      setOriginalFileName(null);
      setVisibleModal(false);
    }
    return (
      <>
        <Button className={"upload-icon-btn"} onClick={() => setVisibleModal(!VisibleModal)}>
            {profile[props.name] && !originalFileName ? (
              <div style={{marginLeft: "0.5rem"}}>
                <CheckSmallGreenIcon />
              </div>
            ) : null}
            <div className={"text"}>
              {originalFileName ? (
                <div>
                  <span style={{display: "block"}}>{props.docName}</span>
                  <small style={{display: "block", textAlign: "left"}}>{originalFileName}</small>
                </div>
              ) : (
                <div>
                  <span style={{display: "block"}}>{props.docName}</span>
                </div>
              )}
            </div>
            <div className="svg-icon-container">
                <UploadSmallIcon />
            </div>
        </Button>
        <Modal
          centered
          destroyOnClose
          visible={VisibleModal}
          onCancel={() => setVisibleModal(false)}
          style={{ top: 20 }}
          footer={
            <div className={"modal-upload-footer"}>
              <Button className={"validation-form-left"} onClick={onCancel}>ANNULER</Button>
              <Button className={"validation-form-right submit"} onClick={apply}>APPLIQUER</Button>
            </div>
          }
          title={
            <div className={"modal-upload-header"}>
              <div className="logo-container">
                <Note/>
              </div>
              <h1 className="modal-upload-title">Télécharger un document</h1>
            </div>
          }
          closable={false}
          wrapClassName={"modal-upload-container"}
          width={"45%"}
        >
          <div className={"modal-upload-content"}>
            <div className={"modal-upload-file-progress"}>
              <p>{originalFileName ? originalFileName : null}</p>
              <Progress percent={count} />
            </div>
            <div className={"modal-upload-file-wrapper"}>
              <p className={"upload-file-label-btn"}>Faites glisser un fichier</p>
              <Upload
                {...properties}
              >
                  <Button className={"btn-upload-custom"} style={{backgroundColor: "rgba(234, 239, 245, 0.5)", fontWeight: "bold" }}>Selectionner un fichier</Button>
              </Upload>
            </div>
          </div>
        </Modal>
      </>
    )
};

export default UploadFile;
