import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import { getContractStatus } from "../../../actions/profile-actions/actions";
import { LoadingOutlined } from '@ant-design/icons';

interface InterfaceCurrentStepTextBlockProps {
  currentStep: number;
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MyInfosProfileBlock: React.FC<InterfaceCurrentStepTextBlockProps> = ({
  currentStep
}) => {
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const contract = useSelector((state: RootState) => state.profileReducer.contract);
  const isCheckingStatus = useSelector((state: RootState) => state.profileReducer.isCheckingStatus);

  return (
    <div className={"my-infos-profile-block"}>
      {currentStep === 1 ? (
        <h1 className={"title"}>MES COORDONNÉES</h1>
      ) : null}
      {currentStep === 2 ? (
        <h1 className={"title"}>MES PIÈCES JUSTIFICATIVES</h1>
      ) : null}
      {currentStep === 3 ? (
        <h1 className={"title"}>MON CONTRAT</h1>
      ) : null}
      {currentStep === 4 ? (
        <h1 className={"title"}>MES DOCUMENTS OFFICIELS</h1>
      ) : null}
      {currentStep === 5 ? (
        <h1 className={"title"}>MA PROCURATION POSTALE</h1>
      ) : null}
      {currentStep === 1 ? (
        <p className={"description"}>
          Vos informations personnelles sont liées à votre contrat de
          domiciliation. Une fois le contrat de domiciliation signé, certaines
          informations ne sont plus modifiables car cela engendrerait une
          régénération du contrat.
        </p>
      ) : null}
      {currentStep === 2 ? (
        <p className={"description"}>
          Retrouvez ici les pièces justificatives fournies lors de la
          constitution de votre dossier.
        </p>
      ) : null}
      {currentStep === 3 ? isCheckingStatus ? (
        null
      ) : profile.ngsign_contract || (contract && contract.status == "SIGNED") ? (
        <p className={"description"}>
          Votre contrat a été sigé par NGSIGN,
          vous pouvez le télécharger en cliquant sur le button ci dessous.
        </p>
      ) : (contract && contract.domi_has_signed) ? (
        <p className={"description"}>
          Après avoir signé votre contrat, vous pouvez le télécharger et le garder précieusement
        </p>
      ) : (
        <p className={"description"}>
          Dés que votre contrat est prêt, vous pouvez le signer en cliquant sur le boutton ci-dessous.
        </p>
      ) : null}
      {currentStep === 4 ? (
        <p className={"description"}>
          Une fois votre entreprise enregistrée au recette des finances et passée
          par la Greffe du Tribunal, vous recevez votre Patente (ou Registre
          National des entreprises) et vos statuts. Afin de mettre à jour la
          domiciliation de votre entreprise n’oubliez pas de nous les faire
          parvenir ci dessous.
        </p>
      ) : null}
      {currentStep === 5 ? (
        <p className={"description"}>
          Vous souhaitez que notre service réceptionne vos courriers recommandés
          ? Finalisez cette dernière étape en signant votre procuration postale
          !
        </p>
      ) : null}
    </div>
  );
};

export default MyInfosProfileBlock;
