import React, { useEffect, useState } from "react";
import "./paymee-card.scss";
import { LoadingOutlined } from '@ant-design/icons';
const paymeeLogo = require('../../assets/png/paymee-logo.png')
import Cookies from 'universal-cookie';

interface PaymeeCardProps {
  paymentToken: string,
  isVisible: boolean,
  height: string
}
const cookies = new Cookies();
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

const PaymeeCard: React.FC<PaymeeCardProps> = ({ paymentToken, height, isVisible }) => {
  useEffect(() => {
  const iframe : any = document.getElementById("paymee-iframe");
  if(paymentToken && iframe){
      const iframeContent = iframe.contentDocument || iframe.contentWindow;
      setTimeout(() => {
        if(iframeContent.location){
          iframeContent.location.reload(true);
        }
      }, 1000);
    }
  }, [paymentToken])
  return (
    <div className={"paymee-iframe-container"}>
      {paymentToken && isVisible ? (
        <iframe id={"paymee-iframe"} key={paymentToken} className={"paymee-iframe"} src={process.env.MIX_PAYMEE_BASE_URL + paymentToken + '/'} width="100%" height={height} />
      ) : (
        <img src={paymeeLogo} alt="paymee-logo" style={{display: 'block', margin: "auto"}}/>
      )}
    </div >
  );
};

export default PaymeeCard;
