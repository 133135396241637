import React, { useState, useEffect } from 'react';
import { RootState } from "../../reducers";
import "../../pages/settings-page/settings.scss";
import { Input, Space, Button, Row, Col, Form } from 'antd';
import EditSmallIcon from "../../svg-components/icons/EditSmallIcon";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updatePars } from "../../actions/auth-actions/actions";

const suffix = (
  <EditSmallIcon
  />
);

const addon = (
  visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)
);
const DisabledUsernameEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);

  return (
    <Space>
      <Input
        placeholder={"Tapez votre nom d'utilisateur"}
        size="large"
        type="text"
        addonAfter={suffix}
        disabled={true}
        value={user && user.name ? user.name : null}
        style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
      >
      </Input>
      <Button htmlType="submit" className={"edit-btn"} onClick={props.onClick}>éditer</Button>
    </Space>
  )
}


const EnabledUsernameEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const dispatch = useDispatch();
  const setData = (data: any) => {
    if(data.name && data.name.length > 0 && data.name !== user.name){
      dispatch(updatePars(data));
    }
    props.onClick();
  }
  return (
    <Form onFinish={setData}>
      <Form.Item
        style={{ width: "100%", marginBottom: "0" }}
        name="name"
        rules={[
          { required: false, message: "Tapez votre nom d'utilisateur" },
        ]}
        initialValue={user && user.name ? user.name : null}
      >
        <Space>
          <Input
            placeholder={"Nouveau nom"}
            size="large"
            type="text"
            className={"edit-input"}
            style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
          >
          </Input>
          <Button htmlType="submit" className={"save-btn"} >Enregistrer</Button>
          <Button htmlType="button" className={"edit-btn"} onClick={() => props.onClick()}>Annuler</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
const DisabledUsersurnameEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);

  return (
    <Space>
      <Input
        placeholder={"Tapez votre prénom"}
        size="large"
        type="text"
        addonAfter={suffix}
        disabled={true}
        value={user && user.surname ? user.surname : null}
        style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
      >
      </Input>
      <Button htmlType="submit" className={"edit-btn"} onClick={props.onClick}>éditer</Button>
    </Space>
  )
}


const EnabledUsersurnameEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const dispatch = useDispatch();
  const setData = (data: any) => {
    if(data.surname && data.surname.length > 0 && data.surname !== user.surname){
      dispatch(updatePars(data));
    }
    props.onClick();
  }
  return (
    <Form onFinish={setData}>
      <Form.Item
        style={{ width: "100%", marginBottom: "0" }}
        name="surname"
        rules={[
          { required: false, message: "Tapez votre prénom" },
        ]}
        initialValue={user && user.surname ? user.surname : null}
      >
        <Space>
          <Input
            placeholder={"Nouveau prénom"}
            size="large"
            type="text"
            className={"edit-input"}
            style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
          >
          </Input>
          <Button htmlType="submit" className={"save-btn"} >Enregistrer</Button>
          <Button htmlType="button" className={"edit-btn"} onClick={() => props.onClick()}>Annuler</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

const DisabledPasswordEdit = (props) => {
  return (
    <Space>
      <Input
        placeholder={"********"}
        size="large"
        type="password"
        addonAfter={suffix}
        disabled={true}
        style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
      >
      </Input>
      <Button htmlType="submit" className={"edit-btn"} onClick={props.onClick}>éditer</Button>
    </Space>
  )
}

const EnabledPasswordEdit = (props) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  const [passwordForm] = Form.useForm();
  const dispatch = useDispatch();
  const setData = (data: any) => {
    if(data.password && data.password.match(passwordPattern)){
      dispatch(updatePars(data));
    }
    props.onClick();
  }
  return (
    <Form onFinish={setData} form={passwordForm}>
      <Form.Item
        style={{ width: "100%", marginBottom: "0" }}
        name="password"
        rules={[
          {
            validator: (rule: any, value: string, cb: (msg?: string) => void) => {
              value && !value.match(passwordPattern) ? cb("Le mot de passe doit contenir 8 caractères minimum, une lettre majuscule, une lettre minuscule, un symbole, un chiffre.") : cb();
            }
          },
        ]}
      >
        <Space>
          <Input.Password
            placeholder={"Nouveau mot de passe"}
            className={"edit-input"}
            size="large"
            onChange={e => passwordForm.setFieldsValue({password: e.target.value})}
            style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
          />
        </Space>
      </Form.Item>
      <Form.Item
        name="password_confirmation"
        rules={[
          {
            validator: (rule: any, value: string, cb: (msg?: string) => void) => {
              value !== passwordForm.getFieldValue('password') ? cb("Les mots de passe que vous avez entrés ne sont pas identiques !") : cb();
            }
          },
        ]}
        className={"edit-input"}
        style={{marginTop: "1rem"}}
      >
        <Space>
          <Input.Password
            placeholder={"Confirmez le mot de passe"}
            className={"edit-input"}
            size="large"
            style={{ fontFamily: "Poppins", fontSize: "15px", lineHeight: "20px", letterSpacing: "0", textAlign: "left" }}
            onChange={e => passwordForm.setFieldsValue({password_confirmation: e.target.value})}
          />
          <Button htmlType="submit" className={"save-btn"} >Enregistrer</Button>
          <Button htmlType="button" className={"edit-btn"} onClick={() => props.onClick()}>Annuler</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

const ConfidentialityPage = () => {
  const [DisabledUsername, setDisabledUsername] = useState(true)
  const [DisabledUsersurname, setDisabledUsersurname] = useState(true)
  const [DisabledPassword, setDisabledPassword] = useState(true)
  const handleUsernameEdit = () => {
    setDisabledUsername(!DisabledUsername);
  }
  const handleUsersurnameEdit = () => {
    setDisabledUsersurname(!DisabledUsersurname);
  }
  const handlePasswordEdit = () => {
    setDisabledPassword(!DisabledPassword);
  }
  return (
    <div>
      <div className={"header-menu"}>
        <h1 className={"menu-title"}>Confidentialité</h1>
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "11px"}}>
        <Col xs={24} md={6} lg={6}>
          <label>Nom d'utilisateur :<span className="redstar">*</span></label>
        </Col>
        <Col xs={24} md={18} lg={18}>
          {DisabledUsername && (<DisabledUsernameEdit onClick={handleUsernameEdit} />)}
          {!DisabledUsername && (<EnabledUsernameEdit onClick={() => {
            handleUsernameEdit();
          }}
        />)}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "11px"}}>
        <Col xs={24} md={6} lg={6}>
          <label>Prénom :<span className="redstar">*</span></label>
        </Col>
        <Col xs={24} md={18} lg={18}>
          {DisabledUsersurname && (<DisabledUsersurnameEdit onClick={handleUsersurnameEdit} />)}
          {!DisabledUsersurname && (<EnabledUsersurnameEdit onClick={() => {
            handleUsersurnameEdit();
          }}
        />)}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: "11px"}}>
        <Col xs={24} md={6} lg={6}>
          <label>Mot de passe :<span className="redstar">*</span></label>
        </Col>
        <Col xs={24} md={18} lg={18}>
          {DisabledPassword && (<DisabledPasswordEdit onClick={handlePasswordEdit} />)}
          {!DisabledPassword && (<EnabledPasswordEdit onClick={() => {
            handlePasswordEdit();
          }} />)}
        </Col>
      </Row>
    </div>
  );
};

export default ConfidentialityPage;
