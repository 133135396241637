import { Reducer, AnyAction } from "redux";
import {
  GET_DISCUSSIONS_REQUEST,
  GET_DISCUSSIONS_SUCCESS,
  GET_DISCUSSIONS_FAILURE,
  GET_FILTRED_DISCUSSIONS_REQUEST,
  GET_FILTRED_DISCUSSIONS_SUCCESS,
  GET_FILTRED_DISCUSSIONS_FAILURE,
  GET_DISCUSSION_REQUEST,
  GET_DISCUSSION_SUCCESS,
  GET_DISCUSSION_FAILURE,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  GET_NEW_UNSEEN_REQUEST,
  GET_NEW_UNSEEN_SUCCESS,
  GET_NEW_UNSEEN_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  START_DISCUSSION_REQUEST,
  START_DISCUSSION_SUCCESS,
  START_DISCUSSION_FAILURE,
  GET_ATTACHMENT_REQUEST,
  GET_ATTACHMENT_SUCCESS,
  GET_ATTACHMENT_FAILURE,
  DELETE_DISCUSSION_REQUEST,
  DELETE_DISCUSSION_SUCCESS,
  DELETE_DISCUSSION_FAILURE,
  EMPTY_DISCUSSIONS,
} from "../actions/discussions-actions/types";

const INITIAL_STATE: any = {
  discussions: null,
  filtredDiscussions: null,
  currentDiscussion: null,
  isLoadingDiscussions: false,
  myTeam: null,
  isLoadingTeam: false,
  isLoadingCurrentDiscussion: false,
  discussionMessages: null,
  newUnseenDiscussions: null,
  totalNew: 0,
  total: 0,
  lastPage: 1,
};

export const discussionsReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): any => {
  switch (action.type) {
    case GET_DISCUSSIONS_REQUEST:
      return {
        ...state,
        isLoadingDiscussions: true
      };
    case GET_DISCUSSIONS_SUCCESS:
      return {
        ...state,
        isLoadingDiscussions: false,
        discussions: state.discussions ? [...state.discussions, ...action.payload.discussions] : action.payload.discussions,
        total: action.payload.total,
        lastPage: action.payload.lastPage,
      };
    case GET_DISCUSSIONS_FAILURE:
      return {
        ...state,
        isLoadingDiscussions: false,
      };
    case EMPTY_DISCUSSIONS:
      return {
        ...state,
        discussions: null,
      };
    case GET_FILTRED_DISCUSSIONS_REQUEST:
      return {
        ...state,
        isLoadingDiscussions: true
      };
    case GET_FILTRED_DISCUSSIONS_SUCCESS:
      return {
        ...state,
        isLoadingDiscussions: false,
        filtredDiscussions: action.payload.discussions,
      };
    case GET_FILTRED_DISCUSSIONS_FAILURE:
      return {
        ...state,
        isLoadingDiscussions: false,
      };

    case GET_TEAM_REQUEST:
      return {
        ...state,
        isLoadingTeam: true
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        isLoadingTeam: false,
        myTeam: action.payload.myTeam,
      };
    case GET_TEAM_FAILURE:
      return {
        ...state,
        isLoadingTeam: false,
      };

    case GET_NEW_UNSEEN_REQUEST:
      return {
        ...state,
      };
    case GET_NEW_UNSEEN_SUCCESS:
      return {
        ...state,
        newUnseenDiscussions: action.payload.newUnseenDiscussions,
        totalNew: action.payload.totalNew,
      };
    case GET_NEW_UNSEEN_FAILURE:
      return {
        ...state,
      };

    case GET_DISCUSSION_REQUEST:
      return {
        ...state,
        isLoadingCurrentDiscussion: true
      };
    case GET_DISCUSSION_SUCCESS:
      return {
        ...state,
        currentDiscussion: action.payload.discussion,
        discussionMessages: action.payload.discussion.messages,
        isLoadingCurrentDiscussion: false
      };
    case GET_DISCUSSION_FAILURE:
      return {
        ...state,
        isLoadingCurrentDiscussion: false
      };

    case START_DISCUSSION_REQUEST:
      return {
        ...state,
        isLoadingCurrentDiscussion: true
      };
    case START_DISCUSSION_SUCCESS:
      return {
        ...state,
        currentDiscussion: action.payload.discussion,
        discussionMessages: action.payload.discussion.messages,
        isLoadingCurrentDiscussion: false
      };
    case START_DISCUSSION_FAILURE:
      return {
        ...state,
        isLoadingCurrentDiscussion: false
      };

    case DELETE_DISCUSSION_REQUEST:
      return {
        ...state,
        isLoadingDiscussions: true
      };
    case DELETE_DISCUSSION_SUCCESS:
      return {
        ...state,
        isLoadingDiscussions: false,
        discussions: action.payload.discussions
      };
    case DELETE_DISCUSSION_FAILURE:
      return {
        ...state,
        isLoadingDiscussions: false,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        discussionMessages: [...state.discussionMessages, action.payload.message]
      };
    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
      };
    case GET_ATTACHMENT_REQUEST:
      return {
        ...state,
      };
    case GET_ATTACHMENT_SUCCESS:
      return {
        ...state,
      };
    case GET_ATTACHMENT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

