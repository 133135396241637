import React from "react";

const Email = () => {
  return (
<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5449 9.43359C10.4302 9.43359 9.52344 10.3404 9.52344 11.4551C9.52344 12.5698 10.4302 13.4766 11.5449 13.4766C12.6596 13.4766 13.5664 12.5698 13.5664 11.4551C13.5664 10.3404 12.6596 9.43359 11.5449 9.43359Z" fill="#FAFCFE"/>
<path d="M11.4551 0C5.13861 0 0 5.13861 0 11.4551C0 17.7715 5.13861 23 11.4551 23C17.7715 23 23 17.7715 23 11.4551C23 5.13861 17.7715 0 11.4551 0ZM16.2617 14.8242C15.351 14.8242 14.5479 14.3678 14.0598 13.6744C13.442 14.3737 12.5491 14.8242 11.5449 14.8242C9.68727 14.8242 8.17578 13.3127 8.17578 11.4551C8.17578 9.59742 9.68727 8.08594 11.5449 8.08594C12.3063 8.08594 13.0016 8.3494 13.5664 8.77697V8.75977C13.5664 8.38732 13.8678 8.08594 14.2402 8.08594C14.6127 8.08594 14.9141 8.38732 14.9141 8.75977C14.9141 10.2584 14.9141 10.6296 14.9141 12.1289C14.9141 12.8718 15.5188 13.4766 16.2617 13.4766C17.0046 13.4766 17.6094 12.8718 17.6094 12.1289C17.6094 7.61915 14.6251 5.39062 11.5449 5.39062C8.2008 5.39062 5.48047 8.11096 5.48047 11.4551C5.48047 14.7992 8.2008 17.5195 11.5449 17.5195C12.8926 17.5195 14.1672 17.0879 15.2313 16.2706C15.942 15.728 16.7559 16.7991 16.0525 17.3392C14.7502 18.3388 13.192 18.8672 11.5449 18.8672C7.45788 18.8672 4.13281 15.5421 4.13281 11.4551C4.13281 7.36804 7.45788 4.04297 11.5449 4.04297C15.2688 4.04297 18.957 6.78927 18.957 12.1289C18.957 13.6154 17.7482 14.8242 16.2617 14.8242Z" fill="#FAFCFE"/>
</svg>
  );
};

export default Email;
