import React from "react";
import "../my-services-components/my-service-card-item.scss";

interface MyServicesCardDashType {
  title: string;
  image: JSX.Element;
  onClick: (e) => void;
}
const MyServicesCardDash: React.FC<MyServicesCardDashType> = ({
  title,
  image,
  onClick,
}) => {
  return (
    <div className={"my-services-card-dash-item"} onClick={onClick}>
      <div className={"svg-logo-container"}>
        {image}
      </div>
      <span className={"title"}>
        {title}
      </span>
    </div>
  );
};

export default MyServicesCardDash;
