import React, {useEffect, useState} from "react";
import { Input, Select, Row, Col, List } from "antd";
import { useDispatch } from "react-redux";
import { search } from "../../actions/dashboard-actions/actions";
import {SearchOutlined} from "@ant-design/icons";

const { Option } = Select;
const { Search } = Input;

interface MessagesSearchBarProps {
  search: any,
}

const MessagesSearchBar : React.FC<MessagesSearchBarProps> = ({search}) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('messages');
    const options = [
      <Option key={1} value={"messages"}>Messages</Option>,
      <Option key={2} value={"persons"}>Support</Option>,
    ];
    const changeFilter = (value) =>{
      setFilter(value);
    }
    // useEffect(() => {
    //   setValues({key: key, filter: filter })
    //   search(values)
    // }, [key || filter])
    const onChangeSearch = (e) => {
      const key = e.target.value.trim();
      search({key: key, filter: filter })
    }
    return (
      <div className={"search-bar"}>
          <Input
            placeholder="Rechercher..."
            onChange={onChangeSearch}
            prefix={<SearchOutlined />}
            addonAfter={
            <Select
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onChange={changeFilter}
              value={filter}
            >
              {options}
            </Select>}
          />
      </div>
    );
};

export default MessagesSearchBar;
