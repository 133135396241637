import * as React from "react";
import "./blue-card-dashboard-welcome.scss";
import { Progress } from "antd";
import TealButton from "../teal-button/TealButton";
interface PropsInterfaceType {
  username: string;
  percentage: number;
  stepsValidated: number;
}
const BlueCardDashboardWelcome = ({
  username,
  percentage,
  stepsValidated,
}: PropsInterfaceType) => {

  return (
    <div className={"blue-card-dashboard-welcome"}>
      <h2 className={"hello-text"}>
        Bonjour <span>{window.capitalize(username) + ' !'} </span>
      </h2>
      <Progress type="circle" width={90} percent={percentage} />
      <h2 className={"steps-counter"}>
        <span>{stepsValidated}</span>/<span>4</span> étapes validées
      </h2>
      <TealButton text={"Compléter Mon Profil"} path={"/profile"} id={"profile"}/>
    </div>
  );
};

export default BlueCardDashboardWelcome;
