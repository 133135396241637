import React, { useEffect, useState } from "react";
import "./service-item-card-big.scss";
import { Row, Col, Form, Select, Input, Button, DatePicker, Spin, TimePicker, InputNumber, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { getAvailableSpaces } from "../../../actions/services-actions/actions";
import { updateCart, emptyCart } from "../../../actions/cart-actions/actions";
import { subscribeMeetingSolution, clearBill } from "../../../actions/profile-actions/actions";
import ArrowDownSmallIcon from "../../../svg-components/icons/ArrowDownSmallIcon";
import TagInput from "./TagInput";
import $ from "jquery";
import { LoadingOutlined } from '@ant-design/icons';
import PaimentModalContent from "../../paiement-modal/PaiementModalContent";
import LocalisationIcon from "../../../svg-components/icons/LocalisationIcon";
import MapContainer from "../../../tools/MapContainer";
import CloseRed from "../../../svg-components/icons/CloseRed";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;
interface ServiceCallRedirectionPropsInterface {
  title: string;
  description: string;
}

const ServiceMeetingRoom: React.FC<ServiceCallRedirectionPropsInterface> = ({
  title,
  description,
}) => {
  const user = useSelector((state: RootState) => state.authReducer.user);
  const [Date, setDate] = useState("Date");
  const [OpenDatePicker, setOpenDatePicker] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const dateFormat = 'DD/MM/YYYY';
  const timeFormat = 'HH:mm';
  const [form] = Form.useForm();
  const spaces = useSelector(
    (state: RootState) => state.serivcesReducer.spaces
  );
  const isLoadingSubscription = useSelector(
    (state: RootState) => state.profileReducer.isLoadingSubscription
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const isLoadingSpaces = useSelector(
    (state: RootState) => state.serivcesReducer.isLoadingSpaces
  );
  const categoryOptions = [{ value: 'Contrat' }, { value: 'Banque' }, { value: 'Fournisseur' }, { value: 'Administratif' }];
  const [VisibleModal, setVisibleModal] = useState(false);
  const [showInMap, setShowInMap] = useState(false);

  const dispatch = useDispatch();
  const handleChange = (value) => {
    const offer = spaces.find((offer, i) => offer.id == value);
    if(offer && offer.service){
      setSelectedSpace(offer.service)
      form.setFieldsValue({service_id: value})
    }
  }
  const getAddressInMap = () => {
    if (selectedSpace) {
      setShowInMap(true);
    }
  }
  const onDateChange = (value) => {
    setDate(value.format(dateFormat))
  }
  const handleOpenDatePicker = () => {
    setOpenDatePicker(!OpenDatePicker);
  }
  const setGuestsValue = (tags) => {
    form.setFieldsValue({ invites: tags })
  }
  const onSubmit = (values) => {
    values.time = values.time.format("HH:mm");
    values.date = values.date.format("YYYY-MM-DD") + ' ' + values.time;
    values.packs = [values.service_id];
    values.billing_type = 0;
    dispatch(emptyCart());
    dispatch(subscribeMeetingSolution(values));
  }
  useEffect((): any => {
    const timePicker = $(".custom-time-picker");
    if (!OpenDatePicker) {
      timePicker.css({ display: 'none' });
    } else {
      timePicker.css({ display: 'inline' })
    }
  });
  useEffect((): any => {
    if (user) {
      dispatch(getAvailableSpaces(user.profile.address_infos.id));
    }
  }, [])
  useEffect(() => {
    if (bill) {
      setVisibleModal(true);
    }
  }, [bill])
  const handleBorderBottomSelect = () => {
    $(document).ready(() => {
      const selected = $(".ant-select-item-option");
      selected.css({ marginLeft: "17px" })
      selected.splice(selected.length - 1, 1);
      selected.css({ borderBottom: "1px solid #72809D", width: "90%" })
    });
  };
  const cancelPayment = async () => {
    await dispatch(emptyCart());
    await dispatch(clearBill());
    form.resetFields();
    setVisibleModal(false);
  }
  const paymentSucceeded = async () => {
    await dispatch(emptyCart());
    await dispatch(clearBill());
    setVisibleModal(false);
  }
  return (
    <div className={"service-item-card-big"}>
      <Row style={{ marginBottom: "20px" }}>
        <h1 className={"services-title"}>{title}</h1>
      </Row>
      <Row>
        <h4 className={"services-description"}>{description}</h4>
      </Row>
      <Form
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} md={8}>
            <div className="marged-top-h2" />
            <Row>
              <p>Nouveau évenement<span className="redstar">*</span></p>
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="event_name"
                rules={[
                  { required: true, message: "Saisissez votre nouvel évenement" },
                ]}
              >
                <Input size={"large"} placeholder={"Nouveau évenement"} />
              </Form.Item>
            </Row>
            <Row style={{ marginTop: "16px" }}>
              <p>Espace à réserver :<span className="redstar">*</span></p>
              <Form.Item
                style={{ width: "100%" }}
                name="service_id"
                rules={[
                  { required: true, message: "Sélectionnez un espace SVP!" },
                ]}
              >
                <div className={"space-selection"}>
                  <Select
                    size={"large"}
                    onChange={handleChange}
                  >
                    {spaces.length > 0 ? (
                      spaces.map((offer, i) => (
                        <Option
                          key={i}
                          value={offer.id}
                          title={`${offer.service.name} ${offer.active_promo ? (offer.price - offer.price * offer.active_promo.value / 100) : offer.price} Dt HT / Mois`}
                        >
                          <div>
                            {offer.service.name} {offer.service.space_capacity} Personnes
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {offer.active_promo ? (offer.price - offer.price * offer.active_promo.value / 100) : offer.price} Dt HT / Heure
                          </div>
                        </Option>
                      ))) : (
                      <Option
                        value={""}
                      >
                        <em>Aucun espace disponible</em>
                      </Option>
                    )
                    }
                  </Select>
                  <Button
                    type="primary"
                    icon={<LocalisationIcon />}
                    onClick={getAddressInMap}
                    title="Voir sur Google Maps"
                  />
                </div>
              </Form.Item>
            </Row>
            <Row style={{ marginTop: "16px" }}>
              <p>Inviter votre intervenant :</p>
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="invites"
              >
                <TagInput
                  setGuests={setGuestsValue}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col xs={24} md={8}>
            <div className="marged-top-h2" />
            <Row style={{ marginBottom: "11px" }}>
              <p>Date<span className="redstar">*</span></p>
              {/* <div className="custom-date-picker"> */}
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="date"
                rules={[
                  { required: true, message: "Saisissez la date SVP!" },
                ]}
              >
                <DatePicker
                  size={"large"}
                  className={"date-picker"}
                  style={{ width: '100%' }}
                  placeholder="Date de réservation"
                  format={dateFormat}
                />
              </Form.Item>
            </Row>
            <Row style={{ marginBottom: "11px" }}>
              <p>Heure<span className="redstar">*</span></p>
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="time"
                rules={[
                  { required: true, message: "Saisissez l'heure SVP!" },
                ]}
              >
                <TimePicker
                  size={"large"}
                  className={"date-picker"}
                  style={{ width: '100%' }}
                  showNow={false}
                  format={timeFormat}
                  placeholder="Heure de réservation"
                />
              </Form.Item>
            </Row>
            <Row style={{ marginBottom: "11px" }}>
              <p>Durée<span className="redstar">*</span></p>
              <Form.Item
                style={{ width: "100%", marginBottom: "0px" }}
                name="periode"
                rules={[
                  { required: true, message: "Saisissez la durée SVP!" },
                ]}
                initialValue={1}
              >
                <InputNumber
                  min={1}
                  max={4}
                  defaultValue={1}
                  onChange={(value) => form.setFieldsValue({periode: value})}
                />
                <span style={{ paddingLeft: "1rem" }}>heures</span>
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                style={{
                  width: "100%", marginBottom: "0px"
                }}
              >
                <Button
                  size={"large"}
                  className={"bordered-blue-button"}
                  type="primary"
                  htmlType="submit"
                >
                  {isLoadingSubscription ? (<Spin indicator={antIcon} />) : "Valider mon service"}
                </Button>
                <p style={{ fontSize: "1.2rem", marginTop: "1rem", fontFamily: "Poppins", lineHeight: "12px", letterSpacing: "0", textAlign: "left" }}>
                  Le paiement est effectif dès la validation de votre service,
                  il s'effectuera chaque mois à la date de souscription du
                  service.
                </p>
              </Form.Item>
            </Row>
            {/* <Row>
              <Form.Item
                style={{
                  width: "100%", marginBottom: "0px"
                }}
              >
                  <TimePicker
                    format={timeFormat}
                    className={"timer"}
                    showNow={false}
                    use12Hours
                    allowClear={false}
                    suffixIcon={<ArrowDownSmallIcon />}
                    open={OpenDatePicker}
                    // onOpenChange={handleOpenDatePicker}
                  />
                  <span className={"time-picker-triangle"} style={{ fontSize: '100px' }}>&#9665;</span>
              </Form.Item>
            </Row> */}
          </Col>
        </Row>
      </Form>
      <PaimentModalContent
        visible={VisibleModal}
        abort={cancelPayment}
        paid={paymentSucceeded}
      />
      <Modal
        title=""
        centered
        visible={showInMap}
        onCancel={() => setShowInMap(false)}
        width={"80%"}
        style={{ top: 20 }}
        footer={[]}
        closeIcon={<CloseRed />}
      >
        <MapContainer
          height={600}
          address={selectedSpace}
        />
      </Modal>
    </div>
  );
};

export default ServiceMeetingRoom;
