import React from "react";

const UploadSmallIcon = () => {
  return (
    <svg viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.1658 9.10328C26.0969 4.07201 21.9827 0 16.9353 0C14.6901 0 12.527 0.816748 10.8446 2.29981C9.34311 3.62335 8.31598 5.39207 7.90922 7.33398C7.87951 7.33366 7.85005 7.3334 7.82066 7.3334C3.50843 7.3334 0 10.8418 0 15.1541C0 19.4664 3.50843 22.9747 7.82073 22.9747H13.2827C13.6713 22.9747 13.9864 22.6597 13.9864 22.271C13.9864 21.8822 13.6713 21.5672 13.2827 21.5672H7.82073C4.28445 21.5672 1.40752 18.6903 1.40752 15.1541C1.40752 11.6178 4.28445 8.74092 7.82073 8.74092C8.00912 8.74092 8.20828 8.75104 8.42968 8.77186C8.80492 8.80699 9.14182 8.54009 9.19293 8.16626C9.44636 6.30872 10.3635 4.60027 11.7754 3.35562C13.2005 2.0993 15.033 1.40746 16.9352 1.40746C21.2494 1.40746 24.7591 4.91725 24.7591 9.23135C24.7591 9.38668 24.7476 9.54678 24.7354 9.71629L24.7302 9.78835C24.7155 9.99537 24.793 10.1983 24.942 10.3429C25.0908 10.4875 25.2957 10.5589 25.5026 10.5382C25.6897 10.5193 25.8786 10.5098 26.0638 10.5098C29.1123 10.5098 31.5925 12.99 31.5925 16.0385C31.5925 19.0869 29.1123 21.5671 26.0638 21.5671H20.3204C19.9318 21.5671 19.6166 21.8821 19.6166 22.2708C19.6166 22.6595 19.9318 22.9746 20.3204 22.9746H26.0638C29.8884 22.9746 33 19.8631 33 16.0385C32.9999 12.2482 29.9436 9.158 26.1658 9.10328Z"
        fill="white"
      />
      <path
        d="M16.9353 3.30768C13.9925 3.30768 11.4734 5.50688 11.0756 8.42312C11.0231 8.80823 11.2928 9.16304 11.6778 9.21557C11.7101 9.21995 11.7421 9.22214 11.7738 9.22214C12.1202 9.22214 12.4221 8.96626 12.4701 8.61345C12.7733 6.39111 14.6929 4.7152 16.9352 4.7152C17.324 4.7152 17.639 4.40016 17.639 4.01144C17.6391 3.62279 17.324 3.30768 16.9353 3.30768Z"
        fill="white"
      />
      <path
        d="M20.1357 17.156L17.5758 14.8692C17.1345 14.4748 16.4687 14.4749 16.0272 14.8692L13.4672 17.1561C13.1773 17.415 13.1523 17.8599 13.4112 18.1498C13.6701 18.4396 14.115 18.4648 14.405 18.2059L16.0978 16.6937V25.3517C16.0978 25.7404 16.4128 26.0555 16.8015 26.0555C17.1902 26.0555 17.5053 25.7404 17.5053 25.3517V16.6935L19.1981 18.2058C19.3323 18.3257 19.4998 18.3847 19.6667 18.3847C19.8602 18.3847 20.0528 18.3055 20.1919 18.1498C20.4507 17.8599 20.4256 17.415 20.1357 17.156Z"
        fill="white"
      />
    </svg>
  );
};

export default UploadSmallIcon;
