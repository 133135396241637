import {
  GET_MAILS_FAILURE,
  GET_MAILS_REQUEST,
  GET_MAILS_SUCCESS,
  GET_MAIL_FAILURE,
  GET_MAIL_REQUEST,
  GET_MAIL_SUCCESS,
  GET_NEW_MAILS_FAILURE,
  GET_NEW_MAILS_REQUEST,
  GET_NEW_MAILS_SUCCESS,
  EXPORT_MAILS_FAILURE,
  EXPORT_MAILS_REQUEST,
  EXPORT_MAILS_SUCCESS
} from "./types";
import MailsServices from "./services";
import {downloadFile} from "../../tools/DownloadFile";
import {message} from "antd";

export function getMails(page: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_MAILS_REQUEST
    });
    try {
      const response = await MailsServices.getMailsRequest(page);
      await dispatch({
        type: GET_MAILS_SUCCESS,
        payload: {
          mails: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_MAILS_FAILURE,
        payload: {
          mails: {}
        },
      });
    }
  };
}

export function getMail(id: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_MAIL_REQUEST
    });
    try {
      const response = await MailsServices.getMailRequest(id);
      await dispatch({
        type: GET_MAIL_SUCCESS,
      });
      downloadFile(response);
    } catch (e) {
      dispatch({
        type: GET_MAIL_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function filterMails(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_MAILS_REQUEST
    });
    try {
      const response = await MailsServices.filterMailsRequest(body);
      await dispatch({
        type: GET_MAILS_SUCCESS,
        payload: {
          mails: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_MAILS_FAILURE,
      });
    }
  };
}

export function getNewMails() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_NEW_MAILS_REQUEST
    });
    try {
      const response = await MailsServices.getNewMailsRequest();
      await dispatch({
        type: GET_NEW_MAILS_SUCCESS,
        payload: {
          newMails: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_NEW_MAILS_FAILURE,
      });
    }
  };
}

export function exportMails(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: EXPORT_MAILS_REQUEST
    });
    try {
      const response = await MailsServices.exportMailsRequest(body);
      await dispatch({
        type: EXPORT_MAILS_SUCCESS,
      });
      downloadFile(response, `Fichier_${(new Date().toJSON().slice(0,16))}.${body.type}`);
    } catch (e) {
      dispatch({
        type: EXPORT_MAILS_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}
