import React from "react";

const MessageSmallIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.815625 0.200195C0.623385 0.200195 0.446982 0.268002 0.309028 0.381004C0.12097 0.527384 0 0.755901 0 1.01267V5.07954C0 5.4434 0.242916 5.75053 0.575423 5.84759L8.62874 9.94259C8.7572 10.0079 8.89537 10.0352 9.03018 10.0285C9.15983 10.0282 9.29119 9.99633 9.41267 9.92964L17.58 5.44521C17.6698 5.39595 17.7462 5.33204 17.8082 5.25812C17.9408 5.11534 18.0219 4.92406 18.0219 4.71383V1.01267C18.0219 0.881564 17.9903 0.757822 17.9344 0.648619C17.8043 0.383048 17.5313 0.200195 17.2156 0.200195H0.815625ZM1.6 4.57614V1.8002H16.4219V4.25622L8.9928 8.33528L1.6 4.57614ZM0.807314 15.8002C0.804879 15.8002 0.80244 15.8002 0.8 15.8002C0.358172 15.8002 0 15.442 0 15.0002V8.40019C0 7.95836 0.358172 7.60019 0.8 7.60019C1.24183 7.60019 1.6 7.95836 1.6 8.40019V14.2002H16.4219V8.40019C16.4219 7.95836 16.78 7.60019 17.2219 7.60019C17.6637 7.60019 18.0219 7.95836 18.0219 8.40019V15.0002C18.0219 15.442 17.6637 15.8002 17.2219 15.8002L17.22 15.8002L17.2156 15.8002H0.815624C0.81285 15.8002 0.81008 15.8002 0.807314 15.8002Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default MessageSmallIcon;
