import {
  GET_DISCUSSIONS_REQUEST,
  GET_DISCUSSIONS_SUCCESS,
  GET_DISCUSSIONS_FAILURE,
  GET_FILTRED_DISCUSSIONS_REQUEST,
  GET_FILTRED_DISCUSSIONS_SUCCESS,
  GET_FILTRED_DISCUSSIONS_FAILURE,
  GET_DISCUSSION_REQUEST,
  GET_DISCUSSION_SUCCESS,
  GET_DISCUSSION_FAILURE,
  GET_ATTACHMENT_REQUEST,
  GET_ATTACHMENT_SUCCESS,
  GET_ATTACHMENT_FAILURE,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  GET_NEW_UNSEEN_REQUEST,
  GET_NEW_UNSEEN_SUCCESS,
  GET_NEW_UNSEEN_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  START_DISCUSSION_REQUEST,
  START_DISCUSSION_SUCCESS,
  START_DISCUSSION_FAILURE,
  DELETE_DISCUSSION_REQUEST,
  DELETE_DISCUSSION_SUCCESS,
  DELETE_DISCUSSION_FAILURE
} from "./types";
import {message} from "antd";
import DiscussionsServices from "./services";
import {downloadFile} from "../../tools/DownloadFile";

export function getDiscussions(page: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DISCUSSIONS_REQUEST,
    });
    try {
      const response = await DiscussionsServices.getDiscussionsRequest(page);
      await dispatch({
        type: GET_DISCUSSIONS_SUCCESS,
        payload: {
          discussions: response.data.data,
          total: response.data.total,
          lastPage: response.data.last_page,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_DISCUSSIONS_FAILURE,
      });
    }
  };
}

export function filterDiscussions(data:any) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_FILTRED_DISCUSSIONS_REQUEST,
    });
    try {
      const response = await DiscussionsServices.filterDiscussionsRequest(data);
      await dispatch({
        type: GET_FILTRED_DISCUSSIONS_SUCCESS,
        payload: {
          discussions: response.data,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_FILTRED_DISCUSSIONS_FAILURE,
      });
    }
  };
}

export function getMyTeam() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_TEAM_REQUEST,
    });
    try {
      const response = await DiscussionsServices.getTeamRequest();
      await dispatch({
        type: GET_TEAM_SUCCESS,
        payload: {
          myTeam: response.data,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_TEAM_FAILURE,
      });
    }
  };
}

export function getNewUnseen() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_NEW_UNSEEN_REQUEST,
    });
    try {
      const response = await DiscussionsServices.getNewUnseenRequest();
      await dispatch({
        type: GET_NEW_UNSEEN_SUCCESS,
        payload: {
          newUnseenDiscussions: response.data.discussions,
          totalNew: response.data.total,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_NEW_UNSEEN_FAILURE,
      });
    }
  };
}


export function getDiscussion(id: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_DISCUSSION_REQUEST,
    });
    try {
      const response = await DiscussionsServices.getDiscussionRequest(id);
      await dispatch({
        type: GET_DISCUSSION_SUCCESS,
        payload: {
          discussion: response.data,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_DISCUSSION_FAILURE,
      });
    }
  };
}

export function startDiscussion (discussionWith: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: START_DISCUSSION_REQUEST,
    });
    try {
      const response = await DiscussionsServices.startDiscussionRequest(discussionWith);
      await dispatch({
        type: START_DISCUSSION_SUCCESS,
        payload: {
          discussion: response.data,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: START_DISCUSSION_FAILURE,
      });
    }
  };
}

export function deleteDiscussion(id: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: DELETE_DISCUSSION_REQUEST,
    });
    try {
      const response = await DiscussionsServices.deleteDiscussionRequest(id);
      await dispatch({
        type: DELETE_DISCUSSION_SUCCESS,
        payload: {
          discussions: response.data,
        },
      });
      message.success({
        content: 'La discussion a été supprimée avec succès.',
        className: 'success-message',
        duration: 3
      });
      return response.data
    } catch (e) {
      dispatch({
        type: DELETE_DISCUSSION_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function sendMessage(data: any, id: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: SEND_MESSAGE_REQUEST,
    });
    try {
      const response = await DiscussionsServices.sendMessageRequest(data, id);
      await dispatch({
        type: SEND_MESSAGE_SUCCESS,
        payload: {
          message: response.data,
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: SEND_MESSAGE_FAILURE,
      });
      if(e.response.data.attachment){
        message.error({
          content: e.response.data.attachment[0],
          className: 'error-message',
          duration: 3
        });
      }else{
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}


export function getAttachment(id: number) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_ATTACHMENT_REQUEST
    });
    try {
      const response = await DiscussionsServices.getAttachmentRequest(id);
      await dispatch({
        type: GET_ATTACHMENT_SUCCESS,
      });
      if(response.headers["content-disposition"]){
        const fileName = response.headers["content-disposition"].split('filename=')[1].split(';')[0]
        downloadFile(response, fileName);
      }else{
        downloadFile(response);
      }
    } catch (e) {
      dispatch({
        type: GET_ATTACHMENT_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}
