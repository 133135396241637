import React from "react";

const HouseBigIcon = () => {
  return (
<svg id="Dashboard" xmlns="http://www.w3.org/2000/svg" width="65.182" height="60.08" viewBox="0 0 65.182 60.08">
  <path id="Path_577" data-name="Path 577" d="M64.225,58.166h-2.1V31.507h2.1a.957.957,0,0,0,.807-1.47L57.889,18.811a.957.957,0,0,0-.807-.443H38.089l-4.542-4.542V10.077H42.8a.957.957,0,0,0,.8-1.487L41.9,6.059l1.687-2.531a.957.957,0,0,0-.8-1.487H33.548V.957a.957.957,0,1,0-1.913,0v12.87l-4.542,4.542H8.1a.957.957,0,0,0-.807.443L.15,30.036a.957.957,0,0,0,.807,1.47h2.1V58.166H.957a.957.957,0,0,0,0,1.914H64.225a.957.957,0,0,0,0-1.914ZM56.557,20.282l5.926,9.312H49.314L40,20.282ZM41.008,3.954,39.958,5.528a.956.956,0,0,0,0,1.061l1.049,1.574h-7.46V3.954ZM8.625,20.282H25.179l-9.312,9.312H2.7ZM4.975,31.507H16.263a.947.947,0,0,0,.095,0l.04-.006.052-.008.046-.011.045-.011.045-.016.044-.016.041-.019.044-.021.038-.023.042-.026.041-.03.034-.025a.928.928,0,0,0,.07-.064L32.591,15.576,48.242,31.226q.033.033.07.064l.033.025.042.031.041.025.039.023.044.021.041.019.043.016.045.016.044.011.047.011.051.008.042.006c.031,0,.063,0,.094,0H60.207V58.166H39.67V44.836a3,3,0,0,0-3-3H28.509a3,3,0,0,0-3,3v13.33H4.975ZM37.757,45.921H27.425V44.836a1.085,1.085,0,0,1,1.084-1.084h8.164a1.085,1.085,0,0,1,1.084,1.084ZM27.425,47.834h4.209V58.166H27.425Zm6.123,0h4.209V58.166H33.548Z" transform="translate(0)" fill="#3240ff"/>
  <path id="Path_578" data-name="Path 578" d="M13.733,20.547a7.08,7.08,0,1,0-7.079-7.08A7.087,7.087,0,0,0,13.733,20.547Zm0-12.246a5.166,5.166,0,1,1-5.166,5.166A5.172,5.172,0,0,1,13.733,8.3Z" transform="translate(18.858 18.104)" fill="#3240ff"/>
  <path id="Path_579" data-name="Path 579" d="M11.47,12.161a.957.957,0,0,0,.957-.957V8.143a.957.957,0,1,0-1.913,0v2.1h-2.1a.957.957,0,1,0,0,1.913Z" transform="translate(21.121 20.367)" fill="#3240ff"/>
  <path id="Path_580" data-name="Path 580" d="M10.317,12.243h-5.1a.957.957,0,0,0-.957.957v6.123a.957.957,0,0,0,.957.957h5.1a.957.957,0,0,0,.957-.957V13.2A.957.957,0,0,0,10.317,12.243Zm-.957,1.913V15.3H6.172V14.156ZM6.172,18.365V17.217H9.361v1.148Z" transform="translate(12.069 34.699)" fill="#3240ff"/>
  <path id="Path_581" data-name="Path 581" d="M7.922,12.243H2.82a.957.957,0,0,0-.957.957v6.123a.957.957,0,0,0,.957.957h5.1a.957.957,0,0,0,.957-.957V13.2A.957.957,0,0,0,7.922,12.243Zm-.957,1.913V15.3H3.776V14.156ZM3.776,18.365V17.217H6.965v1.148Z" transform="translate(5.28 34.699)" fill="#3240ff"/>
  <path id="Path_582" data-name="Path 582" d="M10,9.049H5.534a1.277,1.277,0,0,0-1.276,1.276v6.825a.957.957,0,0,0,.957.957h5.1a.957.957,0,0,0,.957-.957V10.324A1.277,1.277,0,0,0,10,9.049Zm-.638,1.913v2.168H6.172V10.962Zm-3.189,5.23V15.044H9.361v1.148Z" transform="translate(12.069 25.646)" fill="#3240ff"/>
  <path id="Path_583" data-name="Path 583" d="M7.6,9.049H3.139a1.277,1.277,0,0,0-1.276,1.276v6.825a.957.957,0,0,0,.957.957h5.1a.957.957,0,0,0,.957-.957V10.324A1.277,1.277,0,0,0,7.6,9.049Zm-.638,1.913v2.168H3.776V10.962Zm-3.189,5.23V15.044H6.965v1.148Z" transform="translate(5.28 25.646)" fill="#3240ff"/>
  <path id="Path_584" data-name="Path 584" d="M14.264,20.279h5.1a.957.957,0,0,0,.957-.957V13.2a.957.957,0,0,0-.957-.957h-5.1a.957.957,0,0,0-.957.957v6.123A.957.957,0,0,0,14.264,20.279Zm.957-1.914V17.217H18.41v1.148Zm3.189-4.209V15.3H15.221V14.156Z" transform="translate(37.715 34.699)" fill="#3240ff"/>
  <path id="Path_585" data-name="Path 585" d="M11.869,20.279h5.1a.957.957,0,0,0,.957-.957V13.2a.957.957,0,0,0-.957-.957h-5.1a.957.957,0,0,0-.957.957v6.123A.957.957,0,0,0,11.869,20.279Zm.957-1.914V17.217h3.189v1.148Zm3.189-4.209V15.3H12.825V14.156Z" transform="translate(30.927 34.699)" fill="#3240ff"/>
  <path id="Path_586" data-name="Path 586" d="M14.264,18.106h5.1a.957.957,0,0,0,.957-.957V10.324a1.277,1.277,0,0,0-1.276-1.276H14.583a1.277,1.277,0,0,0-1.276,1.276v6.825A.957.957,0,0,0,14.264,18.106Zm.957-1.914V15.044H18.41v1.148Zm3.189-5.23v2.168H15.221V10.962Z" transform="translate(37.715 25.646)" fill="#3240ff"/>
  <path id="Path_587" data-name="Path 587" d="M11.869,18.106h5.1a.957.957,0,0,0,.957-.957V10.324a1.277,1.277,0,0,0-1.276-1.276H12.187a1.277,1.277,0,0,0-1.275,1.276v6.825A.957.957,0,0,0,11.869,18.106Zm.957-1.914V15.044h3.189v1.148Zm3.189-5.23v2.168H12.825V10.962Z" transform="translate(30.927 25.646)" fill="#3240ff"/>
</svg>
  );
};

export default HouseBigIcon;
