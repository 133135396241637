import React from "react";

const ErrorSmallRedIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44504 0.186813C11.5204 0.186813 14.8242 3.49056 14.8242 7.56593C14.8242 11.6413 11.5204 14.9451 7.44504 14.9451C3.36966 14.9451 0.065918 11.6413 0.065918 7.56593C0.065918 3.49056 3.36966 0.186813 7.44504 0.186813ZM10.2451 9.1497C10.5819 9.4865 10.5819 10.0326 10.2451 10.3694C9.9083 10.7062 9.36223 10.7062 9.02543 10.3694L7.4331 8.77703L5.8394 10.3707C5.5026 10.7075 4.95653 10.7075 4.61973 10.3707C4.28293 10.0339 4.28293 9.48786 4.61973 9.15106L6.2168 7.55398L4.61944 5.95662C4.28263 5.61982 4.28263 5.07375 4.61944 4.73695C4.95624 4.40015 5.5023 4.40015 5.83911 4.73695L7.43144 6.32929L9.02514 4.73559C9.36194 4.39879 9.90801 4.39879 10.2448 4.73559C10.5816 5.07239 10.5816 5.61846 10.2448 5.95526L8.64774 7.55233L10.2451 9.1497Z"
        fill="#EF3F26"
      />
    </svg>
  );
};

export default ErrorSmallRedIcon;
