import React, { useEffect, useState } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { changePackChoosed } from "../../actions/auth-actions/actions";
import { RootState } from "../../reducers";
import { updateCart, emptyCart } from "../../actions/cart-actions/actions";
import { getPaymentStatus } from "../../actions/profile-actions/actions";
import { updateBill, clearBill } from "../../actions/profile-actions/actions";
import "./paiement-proccess-page.scss";
import { Link, Redirect } from "react-router-dom";
import CardBlueCheckout from "../../components/card-blue-checkout/CardBlueCheckout";
import { Radio, message, Space, Select } from 'antd';
import PaymeeCard from "../../components/paymee-components/PaymeeCard";
import PackChoosedModal from "../../components/profile-components/packchoosed-done-modal/PackChoosedModal";
const { Option } = Select;

const PaimentProccessPage = ({ history }: any) => {
  useEffect(() => {
    document.title = `Paiement | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute("href", `https://app.domicilier.tn/paiement`);
    }
  })
  const [isVisible, setIsVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("paymee");
  const choosedAPack = useSelector(
    (state: RootState) => state.authReducer.choosedAPack
  );
  useEffect(() => {
    if (choosedAPack) {
      history.push("/dashboard")
    }
  }, [choosedAPack])
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => state.authReducer.user
  );
  const cart = useSelector(
    (state: RootState) => state.cartReducer.cart
  );
  const paymentToken = useSelector(
    (state: RootState) => state.profileReducer.payment_token
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const [isVisibleBtn, setIsVisibleBtn] = useState(false);

  useEffect(() => {
    if (bill && bill.status == "paid") {
      message.success({
        content: 'Paiement effectué avec succès',
        className: 'success-message',
        duration: 3
      });
      setIsModalVisible(true);
    }
  }, [bill])

  const checkStatus = (event) => {
    if (event.data.event_id == 'paymee.complete') {
      if (event.data.payment_token.length > 0) {
        let data = {
          token: event.data.payment_token,
          bill_id: bill.id,
        }
        dispatch(getPaymentStatus(data));
      } else {
        history.push("/dashboard");
      }
    }
  }
  useEffect(() => {
    window.addEventListener('message', checkStatus, false)

    return () => window.removeEventListener("message", checkStatus)
  })
  function onChange(e) {
    setIsVisible(false);
    if (bill) {
      dispatch(updateBill({ bill_id: bill.id, billing_type: e.target.value }));
    }
  };
  useEffect(() => {
    if (paymentToken) {
      if(paymentMethod == "paymee"){
        setIsVisible(true);
        setIsVisibleBtn(false);
      }else{
        redirectToDashboard()
      }
    } else {
      setIsVisibleBtn(true);
    }
  }, [paymentToken])
  const redirectToDashboard = () => {
    dispatch(emptyCart());
    dispatch(clearBill());
    setIsModalVisible(false);
    dispatch(changePackChoosed({ id: user.id }));
  }
  const handleChange = (value) => {
    setPaymentMethod(value);
  }
  return (
    <>
      {cart ? (
        <div className={"paiment-proccess-page"}>
          <div className="left">
            <h1 className="big-title">Veuillez choisir la méthode de paiement</h1>
            <h2 className="silver-sub-title">Tarifs fixes, transparents, sans frais de dossiers</h2>
            <div className="marger-bar-blue" />
            <div className="billing-wrapper">
              <Radio.Group onChange={onChange} value={cart.billing.type}>
                <Space direction="vertical">
                  <Radio value={1} className="billing-type-radio">Facturation 1 an <span className="offer">(1 mois offert)</span></Radio>
                  <Radio value={2} className="billing-type-radio">Facturation 2 ans <span className="offer">(3 mois offert)</span></Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="payment-method-selection">
              <h3>Méthode de paiement</h3>
              <Select
                className="payment-method"
                onChange={handleChange}
                value={paymentMethod}
                placeholder={"Choisissez votre méthode de paiement"}>
                <Option key="paymee" value={"paymee"}>En ligne</Option>
                <Option key="check" value={"check"}>Par chèque</Option>
                <Option key="livraison" value={"livraison"}>A la livraison</Option>
                <Option key="versement" value={"versement"}>Versement espèce</Option>
                <Option key="virement" value={"virement"}>Virement bancaire</Option>
              </Select>
            </div>
            {paymentMethod == "paymee" ? (
              <>
                <h3>Paiement en ligne</h3>
                <PaymeeCard
                  paymentToken={paymentToken}
                  height={"700"}
                  isVisible={isVisible}
                />
              </>
            ) : null}
          </div>
          <div className="right">
            <CardBlueCheckout
              payment={paymentMethod}
              isVisiblePayBtn={isVisibleBtn}
              withCouponField={true}
            />
          </div>
        </div>
      ) : (
        <Redirect to="/dashboard" />
      )}
      <PackChoosedModal
        visible={isModalVisible}
        abort={redirectToDashboard}
      />
    </>
  );
};

export default PaimentProccessPage;
