import React, { useEffect, useState } from "react";
import "./blue-progress-card.scss";
import { Progress } from "antd";

interface propsBlueProgressCardProps {
  step: number;
  username: string;
  percentage: number;
}
const BlueProgressCard: React.FC<propsBlueProgressCardProps> = ({
  step,
  username,
  percentage,
}) => {
  return (
    <div className={"blue-progress-card"}>
      <div className="left">
        <Progress type="circle" width={90} percent={percentage}  />
      </div>
      <div className="right">
        <h2 className={"hello-text"}>
          Bonjour <span>{window.capitalize(username) + ' !'}</span>
        </h2>
        <h2>
          <span>{step}</span>/<span>4</span> étapes validées
        </h2>
      </div>
    </div>
  );
};

export default BlueProgressCard;
