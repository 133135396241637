/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function getInfosRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/dashboard/infos",
    data: null,
  });
}

function getInfosMonthlyRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/dashboard/infos/monthly",
    data: null,
  });
}

function getInfosYearlyRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/dashboard/infos/yearly",
    data: null,
  });
}


function getSubscriptionsRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/dashboard/subscriptions",
  });
}

function getBillsRequest(page: number) {
  return axiosInstance({
    method: "get",
    url: "auth/bills",
    params: {page: page},
  });
}

function getLatestBillsRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/dashboard/bills/latest",
    data: null,
  });
}

function getDirectoryRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/directory",
    data: null,
  });
}

function getContactsRequest() {
  return axiosInstance({
    method: "get",
    url: "auth/user/contacts",
    data: null,
  });
}

function addContact(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/user/add-contact",
    data: body,
  });
}

function removeContactRequest(data: any) {
  return axiosInstance({
    method: "post",
    url: "auth/user/remove-contact",
    data: data,
  });
}

function search(data: any) {
  return axiosInstance({
    method: "post",
    url: "auth/dashboard/search",
    data: data,
  });
}

const DashboardServices = {
  getInfosRequest,
  getInfosMonthlyRequest,
  getInfosYearlyRequest,
  getBillsRequest,
  getLatestBillsRequest,
  getDirectoryRequest,
  getContactsRequest,
  addContact,
  removeContactRequest,
  getSubscriptionsRequest,
  search
};

export default DashboardServices;
