import React, { useState, useEffect } from 'react';
import { Row, Table, Spin, Button } from 'antd';
import { getSubscriptions } from "../../actions/dashboard-actions/actions";
import { getBill } from "../../actions/profile-actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { LoadingOutlined } from '@ant-design/icons';
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

const SubscriptionsPage = () => {
  const dispatch = useDispatch();
  const useMountEffect = (fun) => useEffect(fun, [])
  const subscriptions = useSelector((state: RootState) => state.dashboardReducer.subscriptions);
  const isLoadingSubscriptions = useSelector((state: RootState) => state.dashboardReducer.isLoadingSubscriptions);
  const options : any = { year: 'numeric', month: 'short', day: '2-digit' };
  const options_2 : any = {timeZone: 'Africa/Tunis', year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  const tableLoading = {
    spinning: isLoadingSubscriptions,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  const getPrice = (record) => {
    const item = record.bill.lines.find((item, i) => item.name == record.service.name);
    return item.price_ht.toLocaleString('fr-FR', { minimumFractionDigits: 3 });
  }
  const downloadBill = async (e, bill) => {
    await dispatch(getBill(bill));
  }
  const domiColumns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      render: (text, record) => <span>{record.service.name}</span>
    },
    {
      title: 'Prix Unitaire',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <span>{getPrice(record)} Dt/mois Ht</span>
    },
    {
      title: 'Date d\'abonnement',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => <span>{(new Date(record.created_at)).toLocaleDateString('fr-FR', options)}</span>
    },
    {
      title: 'Période',
      dataIndex: 'period',
      key: 'period',
      render: (text, record) => <span>{record.bill.billing_type == 2 ? 24 : 12} mois</span>
    },
    {
      title: 'Facture',
      dataIndex: 'bill',
      key: 'bill',
      render: (text, record) => (
        <a href={"#"} onClick={(e) => downloadBill(e, record.bill)} className={"table-download-icon-btn"}>
          {record.bill.bill_num}
        </a>
      )
    },
  ];
  const phoneColumns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      render: (text, record) => <span>{record.service.name}</span>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => <span>{record.service.phone_type}</span>
    },
    {
      title: 'Numéro de redirection',
      dataIndex: 'number',
      key: 'number',
      render: (text, record) => <span>{record.phone_number}</span>
    },
    {
      title: 'Prix Unitaire',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <span>{getPrice(record)} Dt/mois Ht</span>
    },
    {
      title: 'Date d\'abonnement',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => <span>{(new Date(record.created_at)).toLocaleDateString('fr-FR', options)}</span>
    },
    {
      title: 'Période',
      dataIndex: 'period',
      key: 'period',
      render: (text, record) => <span>{record.bill.billing_type == 2 ? 24 : 12} mois</span>
    },
    {
      title: 'Facture',
      dataIndex: 'bill',
      key: 'bill',
      render: (text, record) => (
        <a href={"#"} onClick={(e) => downloadBill(e, record.bill)} className={"table-download-icon-btn"}>
          {record.bill.bill_num}
        </a>
      )
    },
  ];
  const meetColumns = [
    {
      title: 'Espace',
      dataIndex: 'service',
      key: 'service',
      render: (text, record) => <span>{record.service.name}</span>
    },
    {
      title: 'Nom de l\'évenement',
      dataIndex: 'event_name',
      key: 'event_name',
      render: (text, record) => <span>{record.event_name}</span>
    },
    {
      title: 'Date de l\'évenement',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => <span>{(new Date(record.date)).toLocaleDateString('fr-FR', options_2)}</span>
    },
    {
      title: 'Invités',
      dataIndex: 'invites',
      key: 'invites',
      render: (text, record) => <div>{record.invites.map((inv, i) => (
        <a href={`mailto:${inv}`} key={i}>{inv}</a>
      ))}</div>
    },
    {
      title: 'Prix Unitaire',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => <span>{getPrice(record)} Dt/heure Ht</span>
    },
    {
      title: 'Facture',
      dataIndex: 'bill',
      key: 'bill',
      render: (text, record) => (
        <a href={"#"} onClick={(e) => downloadBill(e, record.bill)} target={"_blank"} className={"table-download-icon-btn"}>
          {record.bill.bill_num}
        </a>
      )
    },
  ];
  // LOAD Subscriptions
  useMountEffect(() => {
    dispatch(getSubscriptions())
  })
  return (
    <div>
      <div className={"header-menu"}>
        <h1 className={"menu-title"}>Abonnements</h1>
      </div>
      <div className={"body-menu"}>
        <div className="subscription-wrapper">
          <h2>Services de domiciliation</h2>
          <Table
            rowKey="id"
            dataSource={subscriptions.filter((sub, i) => sub.type == "domiciliation")}
            columns={domiColumns}
            loading={tableLoading}
            pagination={false}
            locale={{ emptyText: <em style={{ color: "#bfbfbf" }}>Vide</em> }}
          />
        </div>
        <div className="subscription-wrapper">
          <h2>Solutions téléphoniques</h2>
          <Table
            rowKey="id"
            dataSource={subscriptions.filter((sub, i) => sub.type == "phone")}
            columns={phoneColumns}
            loading={tableLoading}
            pagination={false}
            locale={{ emptyText: <em style={{ color: "#bfbfbf" }}>Vide</em> }}
          />
        </div>
        <div className="subscription-wrapper">
          <h2>Réservations d'espace</h2>
          <Table
            rowKey="id"
            dataSource={subscriptions.filter((sub, i) => sub.type == "meeting")}
            columns={meetColumns}
            loading={tableLoading}
            pagination={false}
            locale={{ emptyText: <em style={{ color: "#bfbfbf" }}>Vide</em> }}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsPage;
