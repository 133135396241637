import React, {Component, useState, useEffect} from 'react'
import { RootState } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from 'antd';
import UrgentNotifIcon from '../../svg-components/icons/notifications/UrgentNotifIcon';
import ReminderNotifIcon from '../../svg-components/icons/notifications/ReminderNotifIcon';
import NormalNotifIcon from '../../svg-components/icons/notifications/NormalNotifIcon';
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
import { Link } from 'react-router-dom';
moment.locale('fr')

interface DropDownNotificationCardProps {
  notification: any;
}

const DropDownNotificationCard : React.FC<DropDownNotificationCardProps> = ({notification}) =>  {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.authReducer.user);
  const isLoading = useSelector((state: RootState) => state.notificationsReducer.isLoadingDiscussions);
  const [isSelected, setIsSelected] = useState(false);
  const [hasUnseen, setHasUnseen] = useState(false);
  const useMountEffect = (fun) => useEffect(fun, [])

  const getPriority = () => {
    let prefix = null;
    switch (notification.priority) {
      case "urgent":
        prefix = "Urgent : "
        break;
      case "reminder":
        prefix = "Rappel : "
        break;
      default:
        prefix = ""
        break;
    }
    return prefix
  }
  const getIcon = () => {
    let icon = null;
    switch (notification.priority) {
      case "urgent":
        icon = <UrgentNotifIcon/>
        break;
      case "reminder":
        icon = <ReminderNotifIcon/>
        break;
      default:
        icon = <NormalNotifIcon/>
        break;
    }
    return icon
  }
  return (
    <Link
      to={{
        pathname: notification.content.includes('courriel') ? "/mails" :"/notifications",
      }}
    >
      <Skeleton avatar title={false} loading={isLoading} active>
          <div className={notification.status === "unseen" ? "dropdown-notification-content-wrapper has-unseen" : "dropdown-notification-content-wrapper"}>
            <div className={"notifiaction-icon"}>
              {getIcon()}
            </div>
            <div className={"notification-content"}>
              <div>
                <span>{getPriority()}</span>
                <div className={"notification-content-body"} dangerouslySetInnerHTML={{ __html: notification.content }} ></div>
              </div>
              <div className={"notification-date"}>
                {moment(notification.created_at).fromNow(true)}
              </div>
            </div>
          </div>
      </Skeleton>
    </Link>
  )
}
export default DropDownNotificationCard;
