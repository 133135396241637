import { AnyAction, Reducer } from "redux";
import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_BILL_REQUEST,
  UPDATE_BILL_SUCCESS,
  UPDATE_BILL_FAILURE,
  CLEAR_BILL,
  SET_BILL,
  INITIATE_PAYMENT_REQUEST,
  INITIATE_PAYMENT_FAILURE,
  INITIATE_PAYMENT_SUCCESS,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_FAILURE,
  APPLY_COUPON_SUCCESS,
  CHECK_PAYMENT_REQUEST,
  CHECK_PAYMENT_FAILURE,
  CHECK_PAYMENT_SUCCESS,
  SUBSCRIBE_DOMICILIATION_REQUEST,
  SUBSCRIBE_DOMICILIATION_SUCCESS,
  SUBSCRIBE_DOMICILIATION_FAILURE,
  SUBSCRIBE_PHONE_SOLUTION_REQUEST,
  SUBSCRIBE_PHONE_SOLUTION_SUCCESS,
  SUBSCRIBE_PHONE_SOLUTION_FAILURE,
  SUBSCRIBE_MEETING_SOLUTION_REQUEST,
  SUBSCRIBE_MEETING_SOLUTION_SUCCESS,
  SUBSCRIBE_MEETING_SOLUTION_FAILURE,
  GET_CONTRACT_STATUS_REQUEST,
  GET_CONTRACT_STATUS_SUCCESS,
  GET_CONTRACT_STATUS_FAILURE,
} from "../actions/profile-actions/types";

const INITIAL_STATE: any = {
  profile: null,
  signURL: null,
  bill: null,
  payment_token: null,
  note: null,
  couponApplied: false,
  isLoadingProfile: false,
  isSendingRequest: false,
  isLoadingSubscription: false,
  isCheckingStatus: false,
  isApplyingCoupon: false,
  contract: null
};

export const profileReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): boolean => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        isLoadingProfile: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
        contract: action.payload.profile.ngsign_contract ? "SIGNED" : false,
        isLoadingProfile: false,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        isLoadingProfile: false,
      };

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoadingProfile: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
        signURL: action.payload.signURL || null,
        isLoadingProfile: false,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isLoadingProfile: false,
      };
    case UPDATE_BILL_REQUEST:
      return {
        ...state,
        isLoadingSubscription: true,
      };
    case UPDATE_BILL_SUCCESS:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: action.payload.bill,
      };
    case UPDATE_BILL_FAILURE:
      return {
        ...state,
        isLoadingSubscription: false,
      };
    case CLEAR_BILL:
      return {
        ...state,
        bill: null,
        payment_token: null,
      };
    case SET_BILL:
      return {
        ...state,
        bill: action.payload.bill,
      };
    // Payment initiate
    case INITIATE_PAYMENT_REQUEST:
      return {
        ...state,
        isSendingRequest: true,
        payment_token: null,
        billNum: null,
      }

    case INITIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payment_token: action.payload.payment_token,
        bill: action.payload.bill,
        isSendingRequest: false
      };
    case INITIATE_PAYMENT_FAILURE:
      return {
        ...state,
        payment_token: null,
        isSendingRequest: false
      };
    case APPLY_COUPON_REQUEST:
      return {
        ...state,
        isApplyingCoupon: true,
      }

    case APPLY_COUPON_SUCCESS:
      return {
        ...state,
        bill: action.payload.bill,
        couponApplied: true,
        isApplyingCoupon: false
      };
    case APPLY_COUPON_FAILURE:
      return {
        ...state,
        isApplyingCoupon: false,
        couponApplied: false,
      };

    case CHECK_PAYMENT_REQUEST:
      return {
        ...state,
        payment_token: null,
      };

    case CHECK_PAYMENT_SUCCESS:
      return {
        ...state,
        bill: action.payload.bill,
      };
    case CHECK_PAYMENT_FAILURE:
      return {
        ...state,
      };
    case SUBSCRIBE_DOMICILIATION_REQUEST:
      return {
        ...state,
        isLoadingSubscription: true,
        bill: null,
      };
    case SUBSCRIBE_DOMICILIATION_SUCCESS:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: action.payload.bill,
      };
    case SUBSCRIBE_DOMICILIATION_FAILURE:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: null,
      };
    case SUBSCRIBE_PHONE_SOLUTION_REQUEST:
      return {
        ...state,
        isLoadingSubscription: true,
      };
    case SUBSCRIBE_PHONE_SOLUTION_SUCCESS:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: action.payload.bill,
      };
    case SUBSCRIBE_PHONE_SOLUTION_FAILURE:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: null,
      };
    case SUBSCRIBE_MEETING_SOLUTION_REQUEST:
      return {
        ...state,
        isLoadingSubscription: true,
      };
    case SUBSCRIBE_MEETING_SOLUTION_SUCCESS:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: action.payload.bill,
      };
    case SUBSCRIBE_MEETING_SOLUTION_FAILURE:
      return {
        ...state,
        isLoadingSubscription: false,
        bill: action.payload.bill,
        note: action.payload.note
      };
    case GET_CONTRACT_STATUS_REQUEST:
      return {
        ...state,
        isCheckingStatus: true,
      };
    case GET_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        contract: action.payload.contract,
        isCheckingStatus: false,
      };
    case GET_CONTRACT_STATUS_FAILURE:
      return {
        ...state,
        isCheckingStatus: false,
      };
    default:
      return state;
  }
};
