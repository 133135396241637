import React, { useEffect } from "react";
import MailsList from "../../components/mails-components/mails-list/MailsList";
import "./mails-page.scss";

const MailsPage = () => {
  useEffect(() => {
    document.title = `Mes courriers | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/mails`);
    }
  })
  return (
    <div className={"mails-page"}>
      <MailsList />
    </div>
  );
};

export default MailsPage;
