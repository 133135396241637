import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { signupUser } from "../../actions/auth-actions/actions";
import { gsap } from "gsap";
import { Tabs, Progress } from "antd";
import { SignupStepOne } from "./signup-steps/SignupStepOne";
import SignupStepTwo from "./signup-steps/SignupStepTwo";
import SignupStepThree from "./signup-steps/SignupStepThree";
import SignupStepFour from "./signup-steps/SignupStepFour";

const { TabPane } = Tabs;

interface Step1InterfacePropsCallbackFn {
  name: string;
  surname: string;
  need_situation_type: string;
}
interface Step2InterfacePropsCallbackFn {
  company_type: string;
}
interface Step3InterfacePropsCallbackFn {
  email: string;
  phone: string;
}
interface Step4InterfacePropsCallbackFn {
  password: string;
  password_confirmation: string;
}

const SignUpForm = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [progress, setProgress] = useState(0);
  const [data, setData] = useState({});
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();

  const onFinishStep1 = ({ name, surname, need_situation_type }: Step1InterfacePropsCallbackFn) => {
    gsap.to('#signup-form-step-1', {
      x: '-100%',
      onComplete: () => {
        setSelectedIndex(2);
        setProgress(25);
        setUsername(name + " " + surname);
        let tempData: any = data;
        tempData.name = name;
        tempData.surname = surname;
        tempData.need_situation_type = need_situation_type;
        setData(tempData);
        gsap.fromTo("#signup-form-step-2", { x: '100%' }, {
          x: 0
        })
      }
    })

  };
  const onFinishStep2 = ({ company_type }: Step2InterfacePropsCallbackFn) => {

    gsap.to("#signup-form-step-2", {
      x: '-100%',
      onComplete: () => {
        setSelectedIndex(3);
        setProgress(50);
        let tempData: any = data;
        tempData.company_type = company_type;
        setData(tempData);
        gsap.fromTo("#signup-form-step-3", { x: '100%' }, {
          x: 0
        })
      }
    })
  };
  const onFinishStep3 = ({ email, phone }: Step3InterfacePropsCallbackFn) => {

    gsap.to("#signup-form-step-3", {
      x: '-100%',
      onComplete: () => {
        setSelectedIndex(4);
        setProgress(75);
        let tempData: any = data;
        tempData.email = email;
        tempData.phone = phone;
        setData(tempData);
        gsap.fromTo("#signup-form-step-4", { x: '100%' }, {
          x: 0
        })
      }
    })
  };
  const onFinish = async ({ password, password_confirmation }: Step4InterfacePropsCallbackFn) => {
    let tempData: any = data;
    tempData.password = password;
    tempData.password_confirmation = password_confirmation;
    await dispatch(signupUser(tempData));
  };
  const backTo = () => {
    const prevStep = selectedIndex - 1;
    const step = selectedIndex;
    gsap.to(`#signup-form-step-${step}`, {
      x: '100%',
      onComplete: () => {
        setSelectedIndex(prevStep);
        gsap.fromTo(`#signup-form-step-${prevStep}`, { x: "-100%" }, {
          x: 0
        })
      }
    })
  }

  return (
    <>
      <Progress type="circle" width={90} percent={progress} className={"signup-progress"} />
      <div
        className={"form-auth-signup"}
      >
        <Tabs
          animated={true}
          activeKey={selectedIndex.toString()}
        >
          <TabPane key={1}>
            <SignupStepOne callBackFn={onFinishStep1} />
          </TabPane>
          <TabPane key={2}>
            <SignupStepTwo username={username} callBackFn={onFinishStep2} goBack={backTo} />
          </TabPane>
          <TabPane key={3}>
            <SignupStepThree callBackFn={onFinishStep3} emailFromHome={props.email} goBack={backTo} />
          </TabPane>
          <TabPane key={4}>
            <SignupStepFour callBackFn={onFinish} goBack={backTo} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default SignUpForm;
