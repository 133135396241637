import {AnyAction, Reducer} from "redux";
import {
    GET_ADDRESSES_REQUEST,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAILURE,
    GET_ADDRESS_REQUEST,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAILURE,
    GET_ADDRESS_REVIEWS_REQUEST,
    GET_ADDRESS_REVIEWS_SUCCESS,
    GET_ADDRESS_REVIEWS_FAILURE,
    GET_REVIEWS_REQUEST,
    GET_REVIEWS_SUCCESS,
    GET_REVIEWS_FAILURE,
    GET_FAQS_REQUEST,
    GET_FAQS_SUCCESS,
    GET_FAQS_FAILURE,
    GET_SERVICE_PACKS_REQUEST,
    GET_SERVICE_PACKS_SUCCESS,
    GET_SERVICE_PACKS_FAILURE,
    GET_SERVICE_SPACES_REQUEST,
    GET_SERVICE_SPACES_SUCCESS,
    GET_SERVICE_SPACES_FAILURE,
    GET_SERVICE_PHONES_REQUEST,
    GET_SERVICE_PHONES_SUCCESS,
    GET_SERVICE_PHONES_FAILURE,
    GET_JOBS_REQUEST,
    GET_JOBS_SUCCESS,
    GET_JOBS_FAILURE,
    GET_JOB_REQUEST,
    GET_JOB_SUCCESS,
    GET_JOB_FAILURE,
    GET_PROMOTIONS_REQUEST,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_FAILURE,
    GET_DEPARTMENTS_REQUEST,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAILURE,
  } from "../actions/services-actions/types";

  const INITIAL_STATE: any = {
    packs: [],
    addresses: [],
    departments: [],
    address: {},
    promotions: null,
    jobOffers: [],
    jobOffer: null,
    testimonials: [],
    reviews: [],
    faqs: [],
    spaces: [],
    phones: [],
    isLoadingPacks: false,
    isLoadingPhones: false,
    isLoadingAddresses: false,
    isLoadingAddress: false,
    isLoadingReviews: false,
    isLoadingFAQs: false,
    isLoadingPromotions: false,
    isLoadingJobs: false,
    isLoadingJob: false,
    isLoadingDepartments: false,
    jobOffersTotal: 0,
    jobOffersLastPage: 1
  };

  export const serivcesReducer: Reducer<any, AnyAction> = (
    state: any = INITIAL_STATE,
    action
  ): boolean => {
    switch (action.type) {
      case GET_SERVICE_PHONES_REQUEST:
        return {
          ...state,
          isLoadingPhones: true,
        };
      case GET_SERVICE_PHONES_SUCCESS:
        return {
          ...state,
          phones: action.payload.phones,
          isLoadingPhones: false,
        };
      case GET_SERVICE_PHONES_FAILURE:
        return {
          ...state,
          phones: action.payload.phones,
          isLoadingPhones: false,
        };
      case GET_SERVICE_PACKS_REQUEST:
        return {
          ...state,
          isLoadingPacks: true,
        };
      case GET_SERVICE_PACKS_SUCCESS:
        return {
          ...state,
          packs: action.payload.packs,
          isLoadingPacks: false,
        };
      case GET_SERVICE_PACKS_FAILURE:
        return {
          ...state,
          packs: action.payload.packs,
          isLoadingPacks: false,
        };
        case GET_ADDRESSES_REQUEST:
          return {
            ...state,
            isLoadingAddresses: true,
          };
        case GET_ADDRESSES_SUCCESS:
          return {
            ...state,
            addresses: action.payload.addresses,
            isLoadingAddresses: false,
          };
        case GET_ADDRESSES_FAILURE:
          return {
            ...state,
            addresses: action.payload.addresses,
            isLoadingAddresses: false,
          };
        case GET_ADDRESS_REQUEST:
          return {
            ...state,
            isLoadingAddress: true,
          };
        case GET_ADDRESS_SUCCESS:
          return {
            ...state,
            address: action.payload.address,
            isLoadingAddress: false,
          };
        case GET_ADDRESS_FAILURE:
          return {
            ...state,
            address: action.payload.address,
            isLoadingAddress: false,
          };
        case GET_PROMOTIONS_REQUEST:
          return {
            ...state,
            isLoadingPromotions: true,
          };
        case GET_PROMOTIONS_SUCCESS:
          return {
            ...state,
            promotions: action.payload.promotions,
            isLoadingPromotions: false,
          };
        case GET_PROMOTIONS_FAILURE:
          return {
            ...state,
            isLoadingPromotions: false,
          };
        case GET_DEPARTMENTS_REQUEST:
          return {
            ...state,
            isLoadingDepartments: true,
          };
        case GET_DEPARTMENTS_SUCCESS:
          return {
            ...state,
            departments: action.payload.departments,
            isLoadingDepartments: false,
          };
        case GET_DEPARTMENTS_FAILURE:
          return {
            ...state,
            isLoadingDepartments: false,
          };
        case GET_JOBS_REQUEST:
          return {
            ...state,
            isLoadingJobs: true,
          };
        case GET_JOBS_SUCCESS:
          return {
            ...state,
            jobOffers: action.payload.jobOffers,
            jobOffersTotal: action.payload.total,
            jobOffersLastPage: action.payload.lastPage,
            isLoadingJobs: false,
          };
        case GET_JOBS_FAILURE:
          return {
            ...state,
            isLoadingJobs: false,
          };
        case GET_JOB_REQUEST:
          return {
            ...state,
            isLoadingJob: true,
          };
        case GET_JOB_SUCCESS:
          return {
            ...state,
            jobOffer: action.payload.jobOffer,
            isLoadingJob: false,
          };
        case GET_JOB_FAILURE:
          return {
            ...state,
            isLoadingJob: false,
          };
        case GET_ADDRESS_REVIEWS_REQUEST:
          return {
            ...state,
            isLoadingReviews: true,
          };
        case GET_ADDRESS_REVIEWS_SUCCESS:
          return {
            ...state,
            reviews: action.payload.reviews,
            isLoadingReviews: false,
          };
        case GET_ADDRESS_REVIEWS_FAILURE:
          return {
            ...state,
            isLoadingReviews: false,
          };
        case GET_REVIEWS_REQUEST:
          return {
            ...state,
            isLoadingReviews: true,
          };
        case GET_REVIEWS_SUCCESS:
          return {
            ...state,
            testimonials: action.payload.reviews,
            isLoadingReviews: false,
          };
        case GET_REVIEWS_FAILURE:
          return {
            ...state,
            isLoadingReviews: false,
          };
        case GET_FAQS_REQUEST:
          return {
            ...state,
            isLoadingFAQs: true,
          };
        case GET_FAQS_SUCCESS:
          return {
            ...state,
            faqs: action.payload.faqs,
            isLoadingFAQs: false,
          };
        case GET_FAQS_FAILURE:
          return {
            ...state,
            isLoadingFAQs: false,
          };
        case GET_SERVICE_SPACES_REQUEST:
          return {
            ...state,
            isLoadingSpaces: true,
          };
        case GET_SERVICE_SPACES_SUCCESS:
          return {
            ...state,
            spaces: action.payload.spaces,
            isLoadingSpaces: false,
          };
        case GET_SERVICE_SPACES_FAILURE:
          return {
            ...state,
            spaces: action.payload.spaces,
            isLoadingSpaces: false,
          };
      default:
        return state;
    }
  };
