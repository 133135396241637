import React from 'react';
import genericFileIcon from '../../assets/file.svg';
import closeIcon from '../../assets/close.svg';

var FileMessage = function FileMessage(props) {
  var meta = props.message.data.meta || null;
  var text = props.message.data.text || '';
  var file = props.message.data.file;
  var author = props.message.author;
  return React.createElement(
    'div', {
      className: 'sc-message--file-wrapper'
    },
    text.length > 0 ?
    React.createElement(
      'div', {
        className: 'sc-message--file-data-text',
      },
      text
    ) : null,
    props.message && file && props.onDownload && React.createElement(
      'button', {
        // href: file.url ? file.url : '#',
        // target: '_blank',
        type: 'button',
        className: 'sc-message--file',
        onClick: function onClick() {
          return props.onDownload(file.id);
        }
      },
      // props.message && author === "me" && props.onDelete && React.createElement(
      //   'button',
      //   { className: 'delete-message', onClick: function onClick() {
      //       return props.onDelete(props.message);
      //     } },
      //   'x'
      // ),
      React.createElement(
        'div', {
          className: 'sc-message--file-icon-wrapper'
        },
        React.createElement('div', {
            className: 'sc-message--file-icon'
          },
          "",
          React.createElement('img', {
            src: file.type && file.type.includes('image') ? file.url : genericFileIcon,
            alt: 'generic file icon',
            className: 'sc-message--file-thumbnail'
          })
        )
      ),
      React.createElement(
        'div', {
          className: 'sc-message--file-data'
        },
        React.createElement(
          'div', {
            className: 'sc-message--file-name'
          },
          file.name,
          React.createElement(
            'small', {
              className: 'sc-message--file-size'
            },
            file.size
          )
        ),
      ),
    ),
  );
};

export default FileMessage;
