import React, { useEffect } from "react";
import "../auth-pages.scss";
import { Row, Form, Input, Spin  } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { loginUser } from "../../../actions/auth-actions/actions";
import { Link } from "react-router-dom";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoginPage = ({history}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.authReducer.isLoading
  );
  const onFinish = async (values: any) => {
    await dispatch(loginUser(values));
  };
  useEffect(() => {
    document.title = `Se connecter | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/login`);
    }
  })

  return (
    <div className={"auth-page"}>
      <div className="max-width-container">
        <div className="container-center">
          <Row>
            <h1 className={"white-title"}>Bienvenue chez domicilier.tn</h1>
          </Row>
          <div className="separtor-small-white" />
          <Row>
            <h2 className={"white-sub-title"}>
              Nous allons vous accompagner dans votre domiciliation
            </h2>
          </Row>
          <Row>
            <h2 className={"white-sub-title"}>
              Pour se connecter quel est votre email et mot de passe?
            </h2>
          </Row>
          <Row>
            <Form
              name="login-form"
              onFinish={onFinish}
              className={"form-auth-login"}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Tapez votre email SVP!",
                  },
                ]}
              >
                <Input placeholder={"Email"} autoComplete={"email"}/>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Tapez votre mot de passe SVP!",
                  },
                ]}
              >
                <Input.Password placeholder={"Mot de passe"} autoComplete={"password"}/>
              </Form.Item>
              <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "2rem"}}>
                <Link to={"/reset-password"} style={{color: "#fff", textDecoration: "underline"}}>Mot de passe oublié ?</Link>
              </div>
              <button className={"auth-submit-btn-white login"} type="submit">
                 {isLoading ? (<Spin indicator={antIcon} />) : "Se connecter"}
              </button>
            </Form>
          </Row>
        </div>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div>
  );
};

export default LoginPage;
