import React, { useState, useEffect } from 'react';
import { Row, Table, Spin, Button } from 'antd';
import { getLatestBills, getBills } from "../../actions/dashboard-actions/actions";
import { setBill, clearBill, getBill } from "../../actions/profile-actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import PaimentModalContent from '../paiement-modal/PaiementModalContent';
import { updateCart, emptyCart } from "../../actions/cart-actions/actions";
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import DownloadSmallIcon from '../../svg-components/icons/DownloadSmallIcon';
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

const FacturePage = () => {
  const dispatch = useDispatch();
  const bills = useSelector((state: RootState) => state.dashboardReducer.bills);
  const user = useSelector(
    (state: RootState) => state.authReducer.user
  );
  const isSendingRequest = useSelector(
    (state: RootState) => state.authReducer.isSendingRequest
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const isLoadingBills = useSelector((state: RootState) => state.dashboardReducer.isLoadingBills);
  const [page, setPage] = useState(1);
  const [VisibleModal, setVisibleModal] = useState(false);
  const [data, setData] = useState([]);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formatDate = (date) => {
    date = new Date(date)
    return date.toLocaleDateString('fr-FR', options)
  }
  const handlePage = (current) => {
    setPage(current);
  }
  const tableLoading = {
    spinning: isLoadingBills,
    indicator: <div className="spinner-container">
      <Spin indicator={LoadingOutlinedIcon} />
    </div>,
  }
  const openPaymentModel = (bill) => {
    dispatch(setBill(bill));
  }
  const downloadBill = async (e, bill) => {
    await dispatch(getBill(bill));
  }
  useEffect(() => {
    if (bill) {
      setVisibleModal(true);
    }
  }, [bill])
  const columns = [
    {
      title: 'Num Facture',
      dataIndex: 'bill_num',
      key: 'bill_num',
      render: (text, record) => <span>{record.bill_num}</span>
    },
    {
      title: 'Service',
      dataIndex: 'object',
      key: 'object',
    },
    {
      title: 'Date d\'échéance',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (text, record) => <span>{formatDate(record.due_date)}</span>
    },
    {
      title: 'Méthode de paiement',
      dataIndex: 'method',
      key: 'method',
      render: (text, record) => {
        switch (record.method) {
          case "livraison":
            return (<span>A la livraison</span>)
            break;
          case "check":
            return (<span>Par chèque</span>)
            break;
          case "versement":
            return (<span>Par versement espèce</span>)
            break;
          case "virement":
            return (<span>Par virement bancaire</span>)
            break;
          default:
            return (<span>En ligne</span>)
            break;
        }
      }
    },
    {
      title: 'Etat',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <span>{record.status == "paid" ? "Soldée" : <Button title="Payer" className={"pay-bill-btn"} onClick={() => openPaymentModel(record)}>Non Soldée</Button>}</span>
    },
    {
      title: '',
      dataIndex: 'file',
      key: 'file',
      render: (text, record) => (
        <button onClick={(e) => downloadBill(e, record)} className={"table-download-icon-btn"}>
          <DownloadSmallIcon/>
        </button>
      )
    },
  ];
  const cancelPayment = async () => {
    await dispatch(emptyCart());
    await dispatch(clearBill());
    setVisibleModal(false);
  }
  const paymentSucceeded = async () => {
    await dispatch(emptyCart());
    await dispatch(clearBill());
    await dispatch(getBills(page))
    setVisibleModal(false);
  }
  // LOAD Bills
  useEffect(() => {
    dispatch(getBills(page))
  }, [page])
  useEffect(() => {
    if (bills.data) {
      setData(bills.data);
    }
  }, [bills.data])
  return (
    <div>
      <div className={"header-menu"}>
        <h1 className={"menu-title"}>Factures</h1>
      </div>
      <div className={"body-menu"}>
        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          pagination={{
            defaultCurrent: page,
            defaultPageSize: 2,
            total: bills.total ? bills.total : 0,
            onChange: handlePage
          }}
          loading={tableLoading}
          locale={{ emptyText: <em style={{ color: "#bfbfbf" }}>Vide</em> }}
        />
      </div>
      <PaimentModalContent
        visible={VisibleModal}
        abort={cancelPayment}
        paid={paymentSucceeded}
      />
    </div>
  );
};

export default FacturePage;
