import React, { useEffect } from 'react';
import "./not-found-page.scss";
import { Link } from 'react-router-dom';
const noFoundImage = require('../../assets/png/404_Illustrations.png')

const NotFoundPage = ({history}) => {
  useEffect(() => {
    document.title = `Page introuvable | Domiciliation et création d'entreprise en Tunisie`
    const description = document.querySelector('meta[name="description"]');
    if(description){
      description.setAttribute("content", "Domiciliation et création d'entreprise en Tunisie 100% en ligne, contrat de domiciliation, transfert de siège social au meilleur prix en Tunisie.");
    }
    const canonical = document.querySelector('link[rel="canonical"]');
    if(canonical){
      canonical.setAttribute("href", `https://app.domicilier.tn/404`);
    }
  })
  return (
    <div className="not-found-page">
      <div className="center">
        <img src={noFoundImage} alt="not found image" />
        <div className="title">Erreur 404</div>
        <div className="text">La page que vous recherchez n'est pas disponible ou n'appartient pas à ce site !</div>
        <Link className="button" to="/">Retour à l'acceuil</Link>
      </div>
      <div className="objects-styling-absolute-container">
        <div className="circle-bottom-styled" />
        <div className="circle-blue-right-top-styled" />
      </div>
    </div>
  );
};

export default NotFoundPage;
