import React from "react";

const EditSmallIcon = () => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6228 2.83708L14.0979 6.31217L5.30142 15.1087L1.82827 11.6336L10.6228 2.83708ZM16.7349 1.99897L15.1851 0.449199C14.5861 -0.149733 13.6136 -0.149733 13.0126 0.449199L11.5281 1.93372L15.0032 5.40884L16.7349 3.67723C17.1994 3.21266 17.1994 2.4635 16.7349 1.99897ZM0.00967044 16.5181C-0.0535723 16.8027 0.203404 17.0577 0.488061 16.9885L4.3605 16.0496L0.887349 12.5745L0.00967044 16.5181Z" fill="white" />
        </svg>
    );
};

export default EditSmallIcon;
