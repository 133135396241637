import React from "react";
import "./radio-simple-btn.scss";

interface RadioSimpleBtnInterfaceTypes {
  label: string;
  name: string;
  text: string;
  defaultChecked: boolean;
}

const RadioSimpleBtn: React.FC<RadioSimpleBtnInterfaceTypes> = ({
                                                                  label,
                                                                  name,
                                                                  text,
                                                                  defaultChecked
                                                                }) => {
  return (
    <label className="radio-simple-btn">
      <input
        defaultChecked={defaultChecked}
        name={name}
        id={"#" + label}
        type="radio"
        value={text}
      />
      <div className="checkmark">
        <div className="text">{text}</div>
      </div>
    </label>
  );
};

export default RadioSimpleBtn;
