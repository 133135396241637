import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_CONTRACT_STATUS_REQUEST,
  GET_CONTRACT_STATUS_SUCCESS,
  GET_CONTRACT_STATUS_FAILURE,
  UPDATE_BILL_REQUEST,
  UPDATE_BILL_SUCCESS,
  UPDATE_BILL_FAILURE,
  DELETE_FILE,
  CLEAR_BILL,
  SET_BILL,
  INITIATE_PAYMENT_REQUEST,
  INITIATE_PAYMENT_FAILURE,
  INITIATE_PAYMENT_SUCCESS,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_FAILURE,
  APPLY_COUPON_SUCCESS,
  CHECK_PAYMENT_REQUEST,
  CHECK_PAYMENT_FAILURE,
  CHECK_PAYMENT_SUCCESS,
  SUBSCRIBE_DOMICILIATION_REQUEST,
  SUBSCRIBE_DOMICILIATION_SUCCESS,
  SUBSCRIBE_DOMICILIATION_FAILURE,
  SUBSCRIBE_PHONE_SOLUTION_REQUEST,
  SUBSCRIBE_PHONE_SOLUTION_SUCCESS,
  SUBSCRIBE_PHONE_SOLUTION_FAILURE,
  SUBSCRIBE_MEETING_SOLUTION_REQUEST,
  SUBSCRIBE_MEETING_SOLUTION_SUCCESS,
  SUBSCRIBE_MEETING_SOLUTION_FAILURE,
  GET_BILL_REQUEST,
  GET_BILL_SUCCESS,
  GET_BILL_FAILURE,
} from "./types";
import ProfileServices from "./services";
import { downloadFile } from "../../tools/DownloadFile";
import { message } from "antd";

export function getProfile() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_PROFILE_REQUEST
    });
    try {
      const response = await ProfileServices.getProfileRequest();
      await dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: {
          profile: response.data
        },
      });
      return response.data
    } catch (e) {
      dispatch({
        type: GET_PROFILE_FAILURE,
      });
    }
  };
}

export function getMyContract() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_CONTRACT_REQUEST
    });
    try {
      const response = await ProfileServices.getMyContractRequest();
      await dispatch({
        type: GET_CONTRACT_SUCCESS,
      });
      downloadFile(response, "Mon contrat de domiciliation.pdf", "application/pdf");
    } catch (e) {
      dispatch({
        type: GET_CONTRACT_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function getBill(bill: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_BILL_REQUEST
    });
    try {
      const response = await ProfileServices.getBillRequest(bill.id);
      await dispatch({
        type: GET_BILL_SUCCESS,
      });
      downloadFile(response, `Domicilier_${bill.bill_num}.pdf`, "application/pdf");
    } catch (e) {
      dispatch({
        type: GET_BILL_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function deleteFile(file: string) {
  return async (dispatch: any) => {
    await dispatch({
      type: DELETE_FILE
    });
    try {
      const response = await ProfileServices.deleteFileRequest(file);
    } catch (e) {
      console.log(e);
    }
  };
}

export function getContractStatus() {
  return async (dispatch: any) => {
    await dispatch({
      type: GET_CONTRACT_STATUS_REQUEST
    });
    try {
      const response = await ProfileServices.getContractStatusRequest();
      await dispatch({
        type: GET_CONTRACT_STATUS_SUCCESS,
        payload: {
          contract: response.data
        },
      });
    } catch (e) {
      dispatch({
        type: GET_CONTRACT_STATUS_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function updateProfile(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: UPDATE_PROFILE_REQUEST
    });
    try {
      const response = await ProfileServices.updateProfile(body);
      if (response.data.signResult) {
        if (response.data.signResult.sign === true) {
          message.info(response.data.signResult.message, 5);
          await dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: {
              profile: response.data.profile,
              signURL: response.data.signResult.signURL
            },
          });
        } else {
          dispatch({
            type: UPDATE_PROFILE_FAILURE,
          });
          message.error({
            content: response.data.signResult.message,
            className: 'error-message',
            duration: 3
          });
        }
      } else {
        await dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: {
            profile: response.data
          },
        })
        return response.data
      }
    } catch (e) {
      dispatch({
        type: UPDATE_PROFILE_FAILURE,
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function updateBill(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: UPDATE_BILL_REQUEST
    });
    try {
      const response = await ProfileServices.updateBillRequest(body);
      await dispatch({
        type: UPDATE_BILL_SUCCESS,
        payload: {
          bill: response.data,
        }
      });
    } catch (e) {
      dispatch({
        type: UPDATE_BILL_FAILURE,
        payload: {
          bill: null,
        }
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function clearBill() {
  return async (dispatch: any) => {
    await dispatch({
      type: CLEAR_BILL
    });
  };
}

export function setBill(bill: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SET_BILL,
      payload: {
        bill: bill,
      }
    });
  };
}

export function getPaymentToken(data: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: INITIATE_PAYMENT_REQUEST,
    });
    try {
      const response = await ProfileServices.getPaymentToken(data);
      await dispatch({
        type: INITIATE_PAYMENT_SUCCESS,
        payload: {
          payment_token: response.data.token,
          bill: response.data.bill
        },
      });
      return response.data
    } catch (e) {
      await dispatch({
        type: INITIATE_PAYMENT_FAILURE
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function applyCoupon(data: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: APPLY_COUPON_REQUEST,
    });
    try {
      const response = await ProfileServices.applyCouponRequest(data);
      await dispatch({
        type: APPLY_COUPON_SUCCESS,
        payload: {
          bill: response.data.bill
        },
      });
      message.success({
        content: 'Coupon appliqué !',
        className: 'success-message',
        duration: 3
      });
    } catch (e) {
      await dispatch({
        type: APPLY_COUPON_FAILURE
      });
      if (e.response.data.errors == "Coupon not found") {
        message.error({
          content: 'Coupon non trouvé !',
          className: 'error-message',
          duration: 3
        });
      }
      else if (e.response.data.errors == "Coupon expired") {
        message.error({
          content: 'Coupon expiré !',
          className: 'error-message',
          duration: 3
        });
      }
      else if (e.response.data.errors == "Coupon Limit Reached") {
        message.error({
          content: 'La limite d\'utilisation du coupon a été atteinte !',
          className: 'error-message',
          duration: 3
        });
      }
      else {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

export function getPaymentStatus(data: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: CHECK_PAYMENT_REQUEST,
    });
    try {
      const response = await ProfileServices.getPaymentStatus(data);
      await dispatch({
        type: CHECK_PAYMENT_SUCCESS,
        payload: {
          bill: response.data
        },
      });
    } catch (e) {
      await dispatch({
        type: CHECK_PAYMENT_FAILURE,
        payload: {
          status: false
        },
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function subscribeDomiciliation(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SUBSCRIBE_DOMICILIATION_REQUEST
    });
    try {
      const response = await ProfileServices.subscribeDomiciliationRequest(body);
      await dispatch({
        type: SUBSCRIBE_DOMICILIATION_SUCCESS,
        payload: {
          bill: response.data,
        }
      });
    } catch (e) {
      dispatch({
        type: SUBSCRIBE_DOMICILIATION_FAILURE,
        payload: {
          bill: null,
        }
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function subscribePhoneSolution(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SUBSCRIBE_PHONE_SOLUTION_REQUEST
    });
    try {
      const response = await ProfileServices.subscribePhoneSolution(body);
      await dispatch({
        type: SUBSCRIBE_PHONE_SOLUTION_SUCCESS,
        payload: {
          bill: response.data,
        }
      });
    } catch (e) {
      dispatch({
        type: SUBSCRIBE_PHONE_SOLUTION_FAILURE,
        payload: {
          bill: null,
        }
      });
      message.error({
        content: 'Un probème est survenu! Veuillez réessayer plus tard.',
        className: 'error-message',
        duration: 3
      });
    }
  };
}

export function subscribeMeetingSolution(body: any) {
  return async (dispatch: any) => {
    await dispatch({
      type: SUBSCRIBE_MEETING_SOLUTION_REQUEST
    });
    try {
      const response = await ProfileServices.subscribeMeetingSolution(body);
      await dispatch({
        type: SUBSCRIBE_MEETING_SOLUTION_SUCCESS,
        payload: {
          bill: response.data,
        }
      });
    } catch (e) {
      dispatch({
        type: SUBSCRIBE_MEETING_SOLUTION_FAILURE,
        payload: {
          bill: null,
        }
      });
      if (e.response.data.date) {
        message.warning({
          content: e.response.data.date,
          duration: 3
        });
      } else {
        message.error({
          content: 'Un probème est survenu! Veuillez réessayer plus tard.',
          className: 'error-message',
          duration: 3
        });
      }
    }
  };
}

