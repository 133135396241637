import React, { useEffect, useState } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../reducers";
import { updateCart, emptyCart } from "../../actions/cart-actions/actions";
import { getPaymentStatus } from "../../actions/profile-actions/actions";
import { updateBill, clearBill } from "../../actions/profile-actions/actions";
import "./paiement-proccess-modal.scss";
import CardBlueCheckout from "../../components/card-blue-checkout/CardBlueCheckout";
import { Modal, message, Radio, Select, Space } from 'antd';
import CloseRed from "../../svg-components/icons/CloseRed";
import $ from "jquery";
import { LoadingOutlined } from '@ant-design/icons';
import PaymeeCard from "../paymee-components/PaymeeCard";

const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#FFFFFF" }} spin />;
const { Option } = Select;

const PaimentModalContent = ({ visible, abort, paid }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleBtn, setIsVisibleBtn] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("paymee");
  const cart = useSelector(
    (state: RootState) => state.cartReducer.cart
  );
  const paymentToken = useSelector(
    (state: RootState) => state.profileReducer.payment_token
  );
  const bill = useSelector(
    (state: RootState) => state.profileReducer.bill
  );
  const checkStatus = (event) => {
    if (event.data.event_id == 'paymee.complete') {
      if (event.data.payment_token.length > 0) {
        let data = {
          token: event.data.payment_token,
          bill_id: bill.id
        }
        dispatch(getPaymentStatus(data));
      } else {
        abort();
      }
    }
  }
  useEffect(() => {
    window.addEventListener('message', checkStatus, false)

    return () => window.removeEventListener("message", checkStatus)
  })
  useEffect(() => {
    if (bill && bill.status == "paid") {
      message.success({
        content: 'Paiement effectué avec succès',
        className: 'success-message',
        duration: 3
      });
      paid();
    }
  }, [bill])
  useEffect(() => {
    if (visible) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [visible])
  function onChange(e) {
    cart.billing.type = e.target.value;
    dispatch(updateCart(cart));
    setIsVisible(false);
    if (bill) {
      dispatch(updateBill({ bill_id: bill.id, billing_type: cart.billing.type }));
    }
  };
  useEffect(() => {
    if (paymentToken) {
      if(paymentMethod == "paymee"){
        setIsVisible(true);
        setIsVisibleBtn(false);
      }else{
        abort();
      }
    } else {
      setIsVisibleBtn(true);
    }
  }, [paymentToken])
  const handleChange = (value) => {
    setPaymentMethod(value);
  }
  useEffect(() => {
    if(bill){
      setPaymentMethod(bill.method)
    }
  }, [bill])
  return (
    <Modal
      title=""
      destroyOnClose
      centered
      visible={visible}
      onCancel={abort}
      width={"80%"}
      style={{ top: 20 }}
      footer={[]}
      closeIcon={<CloseRed />}
    >
      <div className={"paiment-proccess-page"}>
        <div className="left">
          <h1 className="big-title">Veuillez choisir la méthode de paiement</h1>
          <h2 className="silver-sub-title">Tarifs fixes, transparents, sans frais de dossiers</h2>
          <div className="marger-bar-blue" />
          <div className="billing-wrapper">
            {cart.billing.type !== 0 ? (
              <Radio.Group onChange={onChange} value={cart.billing.type}>
                <Space direction="vertical">
                  <Radio value={1} className="billing-type-radio">Facturation 1 an <span className="offer">(1 mois offert)</span></Radio>
                  <Radio value={2} className="billing-type-radio">Facturation 2 ans <span className="offer">(3 mois offert)</span></Radio>
                </Space>
              </Radio.Group>
            ) : null}
          </div>
          <div className="payment-method-selection">
              <h3>Méthode de paiement</h3>
              <Select
                className="payment-method"
                onChange={handleChange}
                value={paymentMethod}
                placeholder={"Choisissez votre méthode de paiement"}>
                <Option key="paymee" value={"paymee"}>En ligne</Option>
                <Option key="check" value={"check"}>Par chèque</Option>
                <Option key="livraison" value={"livraison"}>A la livraison</Option>
                <Option key="versement" value={"versement"}>Versement espèce</Option>
                <Option key="virement" value={"virement"}>Virement bancaire</Option>
              </Select>
            </div>
            {paymentMethod == "paymee" ? (
              <>
                <h3>Paiement en ligne</h3>
                <PaymeeCard
                  paymentToken={paymentToken}
                  height={"350"}
                  isVisible={isVisible}
                />
              </>
            ) : null}
        </div>
        <div className="right">
          <CardBlueCheckout
            payment={paymentMethod}
            isVisiblePayBtn={isVisibleBtn}
            withCouponField={true}
          />
        </div>
      </div>
    </Modal>
  );
}
export default PaimentModalContent;
