import React, { Component, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reducers";
import { Launcher } from './es';
import './messages-components.scss';
import { sendMessage, getAttachment } from "../../actions/discussions-actions/actions";
import moment from 'moment';
import 'moment/locale/fr'  // without this line it didn't work
import { Spin } from 'antd';
moment.locale('fr')
import { LoadingOutlined } from '@ant-design/icons';


interface ChatComponentProps {
  animation: string,
  isOpen: boolean,
  onClickDelete: any,
}
const LoadingOutlinedIcon = <LoadingOutlined style={{ fontSize: 32, color: "#3240FF" }} spin />;

const ChatComponent: React.FC<ChatComponentProps> = ({ animation, isOpen, onClickDelete }) => {
  const dispatch = useDispatch();
  const currentDiscussion = useSelector((state: RootState) => state.discussionsReducer.currentDiscussion);
  const discussionMessages = useSelector((state: RootState) => state.discussionsReducer.discussionMessages);
  const isLoadingCurrentDiscussion = useSelector((state: RootState) => state.discussionsReducer.isLoadingCurrentDiscussion);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const [messageList, setMessageList] = useState([]);
  const onMessageWasSent = async (message) => {
    let data = new FormData();
    if (message.type) {
      if (message.type == "text") {
        data.append("message_content", message.data.text);
      }
      if (message.type == "file") {
        data.append("attachment", message.data.file);
        if (message.data.text) {
          data.append("message_content", message.data.text);
        }
      }
      await dispatch(sendMessage(data, currentDiscussion.id))
    }
  }
  useEffect(() => {
    if (currentDiscussion && discussionMessages) {
      let messageHistory = [];
      for (let i = 0; i < discussionMessages.length; i++) {
        let data = discussionMessages[i];
        let fileData = null;
        if (data.shared_files && data.shared_files.length > 0) {
          fileData = data.shared_files[0];
        }
        let messageData = {
          author: data.user_id === user.id ? "me" : currentDiscussion.support.user.full_name,
          avatar_url: data.user_id === user.id ? "me" : currentDiscussion.support.user.avatar ? process.env.MIX_STORAGE_URL + currentDiscussion.support.user.avatar : "",
          type: fileData ? "file" : "text",
          date: data.updated_at,
          status: data.status,
          data: {
            text: data.content,
            file: fileData ? { id: fileData.id, url: process.env.MIX_STORAGE_URL + fileData.file_url, name: fileData.file_name, size: fileData.file_size, type: fileData.type} : null
          }
        }
        messageHistory.push(messageData);
      }
      setMessageList(messageHistory)
    }
  }, [currentDiscussion, discussionMessages])
  const getFromName = (currentDiscussion) => {
    if (currentDiscussion) {
      if (currentDiscussion.from.id == user.id) {
        return window.capitalize(currentDiscussion.to.name) + ' ' + window.capitalize(currentDiscussion.to.surname)
      } else {
        return window.capitalize(currentDiscussion.from.name) + ' ' + window.capitalize(currentDiscussion.from.surname)
      }
    }
    return ''
  }
  const getFromAvatar = (currentDiscussion) => {
    if (currentDiscussion) {
      if (currentDiscussion.from.id == user.id) {
        return currentDiscussion && currentDiscussion.to.avatar ? process.env.MIX_STORAGE_URL + currentDiscussion.to.avatar : ""
      } else {
        return currentDiscussion && currentDiscussion.from.avatar ? process.env.MIX_STORAGE_URL + currentDiscussion.from.avatar : ""
      }
    }
    return ''
  }
  const getLastActivity = (currentDiscussion) => {
    if (currentDiscussion) {
      if (currentDiscussion.from.id == user.id) {
        return "il y a " + moment(currentDiscussion.to.last_active_at).fromNow(true)
      } else {
        return "il y a " + moment(currentDiscussion.from.last_active_at).fromNow(true)
      }
    }
    return ''
  }
  const downloadFile = async (fileId) => {
    await dispatch(getAttachment(fileId));
  }
  return (
    <div className={"chat-box " + animation}>
      {isLoadingCurrentDiscussion ? (
        <div className="spinner-container">
          <Spin indicator={LoadingOutlinedIcon} />
        </div>
      ) : (
        currentDiscussion ? (
          <Launcher
            agentProfile={{
              teamName: currentDiscussion ? window.capitalize(currentDiscussion.support.user.full_name) : null,
              imageUrl: currentDiscussion && currentDiscussion.support.user.avatar ? process.env.MIX_STORAGE_URL + currentDiscussion.support.user.avatar : "",
              lastActivity: currentDiscussion && currentDiscussion.support && currentDiscussion.support.user.last_active_at ? 'il y a ' + moment(currentDiscussion.support.user.last_active_at).fromNow(true) : null
            }}
            downloadAttachment={downloadFile}
            onMessageWasSent={onMessageWasSent}
            messageList={messageList}
            showEmoji
            isOpen={isOpen}
            onClickDelete={onClickDelete}
          />
        ) : null

      )}
    </div>
  )
}
export default ChatComponent;
