import React from "react";
import "./transparent-dashboard-card.scss";
interface TransparentDashCardProps {
  title: string;
}
const TransparentDashboardCard: React.FC<TransparentDashCardProps> = ({
  title,
  children,
}) => {
  return (
    <div className={"transparent-dashboard-card"}>
      <div className="title">{title}</div>
      {children}
    </div>
  );
};

export default TransparentDashboardCard;
