import React from "react";

const RingtoneSmallIcon = () => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8097 3.585C11.8097 2.03329 10.5518 0.775391 9.0001 0.775391C7.4484 0.775391 6.1905 2.03329 6.1905 3.585C6.1905 4.21523 6.398 4.79699 6.74842 5.2657C4.40816 6.1833 2.74844 8.48627 2.74844 11.1822V13.214L0.683791 16.7869C0.535412 17.0428 0.533696 17.3596 0.679294 17.6172C0.824892 17.8747 1.09548 18.0335 1.38833 18.0331H4.92796C5.31867 19.9241 6.99338 21.3456 9.0001 21.3456C11.0068 21.3456 12.6815 19.9241 13.0722 18.0331H16.6216C16.9121 18.0299 17.1791 17.8704 17.3226 17.6143C17.466 17.3582 17.4642 17.0441 17.318 16.7896L15.2615 13.1419V11.1822C15.2615 8.48338 13.5982 6.17836 11.254 5.26275C11.6031 4.79455 11.8097 4.21391 11.8097 3.585ZM13.6294 13.2056C13.5988 13.3887 13.6286 13.5833 13.7273 13.7568L15.2125 16.3788H2.81101L4.32345 13.754C4.4868 13.5084 4.50767 13.1941 4.38058 12.9296V11.1822C4.38058 8.59351 6.45099 6.495 9.00496 6.495C11.5589 6.495 13.6293 8.59351 13.6294 11.1822V13.2056ZM9.0001 2.34877C8.31735 2.34877 7.76388 2.90225 7.76388 3.585C7.76388 4.26774 8.31735 4.82122 9.0001 4.82122C9.68285 4.82122 10.2363 4.26774 10.2363 3.585C10.2363 2.90225 9.68285 2.34877 9.0001 2.34877ZM9.0001 19.7722C7.86896 19.7722 6.90752 19.0456 6.55702 18.0337C7.15827 18.0384 10.4609 18.0363 11.328 18.0357L11.4425 18.0357C11.0915 19.0465 10.1305 19.7722 9.0001 19.7722Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default RingtoneSmallIcon;
