import React, {useEffect, useState} from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import { Modal, Spin, Button, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import $ from "jquery";
const profileDoneIllustr = require("../../../assets/png/profileDoneIllustr.png")
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface ProfileDoneModalProps {
  visible: boolean,
  abort: any
}

const ProfileDoneModal : React.FC<ProfileDoneModalProps> = ({visible, abort}:any) => {
  const dispatch = useDispatch();
  const useMountEffect = (fun) => useEffect(fun, [])
  const profile = useSelector((state: RootState) => state.profileReducer.profile);
  const [VisibleModal, setVisibleModal] = useState(false);
  const [messageTo, setMessageTo] = useState(null);
  useEffect(() => {
    setVisibleModal(visible);
  }, [visible])
  useEffect(() => {
    if (VisibleModal) {
      $(".app-container").css({ 'filter': 'brightness(50%) blur(2px)' });
    } else {
      $(".app-container").css({ 'filter': 'brightness(100%) blur(0)' });
    }
  }, [VisibleModal])
  const closeModal = () => {
    abort();
  }
  return (
    <Modal
      centered
      visible={VisibleModal}
      onCancel={closeModal}
      style={{ top: 20 }}
      footer={<div></div>}
      wrapClassName={"profile-done-modal"}
      width={"45%"}
    >
      <div className={"profile-done-modal-content"}>
        <h2>Félicitations, <br /> <span>votre profil est 100% complet !</span></h2>
        <img src={profileDoneIllustr} alt="sally illustrarion" />
        <div className={"buttons-wrapper"}>
          <Button onClick={closeModal} className={"back-btn"}>Retour au dashboard</Button>
        </div>
      </div>
    </Modal>
  );
}
export default ProfileDoneModal;
