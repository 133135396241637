import {AnyAction, Reducer} from "redux";
import {
  GET_MAILS_FAILURE,
  GET_MAILS_REQUEST,
  GET_MAILS_SUCCESS,
  GET_MAIL_FAILURE,
  GET_MAIL_REQUEST,
  GET_MAIL_SUCCESS,
  GET_NEW_MAILS_FAILURE,
  GET_NEW_MAILS_REQUEST,
  GET_NEW_MAILS_SUCCESS
} from "../actions/mails-actions/types";

const INITIAL_STATE: any = {
  mails: {},
  newMails: [],
  isLoadingMails: false,
  isLoadingNewMails: false
};

export const mailsReducer: Reducer<any, AnyAction> = (
  state: any = INITIAL_STATE,
  action
): boolean => {
  switch (action.type) {
    case GET_MAILS_REQUEST:
      return {
        ...state,
        isLoadingMails: true,
      };
    case GET_MAILS_SUCCESS:
      return {
        ...state,
        mails: action.payload.mails,
        isLoadingMails: false,
      };
    case GET_MAILS_FAILURE:
      return {
        ...state,
        mails: action.payload.mails,
        isLoadingMails: false,
      };
    case GET_MAIL_REQUEST:
      return {
        ...state,
      };
    case GET_MAIL_SUCCESS:
      return {
        ...state,
      };
    case GET_MAIL_FAILURE:
      return {
        ...state,
      };

    //new mails
    case GET_NEW_MAILS_REQUEST:
      return {
        ...state,
        isLoadingNewMails: true,
      };
    case GET_NEW_MAILS_SUCCESS:
      return {
        ...state,
        newMails: action.payload.newMails,
        isLoadingNewMails: false,
      };
    case GET_NEW_MAILS_FAILURE:
      return {
        ...state,
        isLoadingNewMails: false,
      };
    default:
      return state;
  }
};
