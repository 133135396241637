/*
@
 This file contains the requests ( services )
@
*/

import axiosInstance from "../../config/axios-instance";

function addNewServicePhoneRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/services/phone",
    data: body,
  });
}

function addNewServiceMeetingRoomRequest(body: any) {
  return axiosInstance({
    method: "post",
    url: "auth/services/meeting-room",
    data: body,
  });
}

function getAvailablePacks(addressId) {
  return axiosInstance({
    method: "get",
    url: `auth/services/packs/${addressId}`,
  });
}

function getAddresses(filter) {
  return axiosInstance({
    method: "get",
    url: `services/addresses`,
    params: {filter:  filter}
  });
}

function getAddressRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: `services/addresses/${id}`,
  });
}

function getDepartmentsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/departments`,
  });
}

function getJobOffersRequest(params: any) {
  return axiosInstance({
    method: "get",
    url: `services/jobs`,
    params: params
  });
}

function getJobOfferRequest(id: number) {
  return axiosInstance({
    method: "get",
    url: `services/jobs/${id}`,
  });
}

function getPromotionsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/promotions`,
  });
}

function getAddressReviewsRequest(addressId: number) {
  return axiosInstance({
    method: "get",
    url: `services/reviews/address/${addressId}`,
  });
}

function getHomeReviewsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/reviews/home`,
  });
}

function getFAQsRequest() {
  return axiosInstance({
    method: "get",
    url: `services/faqs`,
  });
}

function getAvailableSpaces(addressId) {
  return axiosInstance({
    method: "get",
    url: `auth/services/spaces/${addressId}`,
  });
}

function getAvailablePhoneSolutions(addressId) {
  return axiosInstance({
    method: "get",
    url: `auth/services/phones/${addressId}`,
  });
}
const Services = {
    addNewServicePhoneRequest,
    addNewServiceMeetingRoomRequest,
    getAddresses,
    getAddressReviewsRequest,
    getHomeReviewsRequest,
    getFAQsRequest,
    getAddressRequest,
    getAvailablePacks,
    getAvailableSpaces,
    getAvailablePhoneSolutions,
    getJobOffersRequest,
    getJobOfferRequest,
    getPromotionsRequest,
    getDepartmentsRequest
};

export default Services;
