import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, withRouter, Switch, Redirect, useLocation } from "react-router-dom";
import DashboardPage from "./pages/dashboard-page/DashboardPage";
import ProfilePage from "./pages/profile-page/ProfilePage";
import "../sass/ui-elements.scss";
import { RootState } from "./reducers/index";
import LoginPage from "./pages/auth/login-page/LoginPage";
import SignupPage from "./pages/auth/signup-page/SignupPage";
import MailsPage from "./pages/mails-page/MailsPage";
import MyServicesPage from "./pages/my-services-page/MyServicesPage";
import SettingsPage from "./pages/settings-page/SettingsPage";
import PackChoicePage from "./pages/pack-choice/PackChoicePage";
import AccountVerficationPage from "./pages/auth/account-verification-pages/account-verification-page/AccountVerficationPage";
import EmailVerficationPage from "./pages/auth/account-verification-pages/email-verification-page/EmailVerficationPage";
import PhoneVerficationPage from "./pages/auth/account-verification-pages/phone-verification-page/PhoneVerficationPage";
import ResetPasswordPage from "./pages/auth/reset-password-page/ResetPasswordPage";
import PaiementProccessPage from "./pages/paiement-proccess/PaiementProccessPage";
import NotFoundPage from "./pages/not-found-page/NotFoundPage";
import MessagesPage from "./pages/messages-page/MessagesPage";
import NotificationsPage from "./pages/notifications-page/NotificationsPage";
import DashboardLayout from "./layouts/DashboardLayout";
import AuthLayout from "./layouts/AuthLayout";
import PackLayout from "./layouts/PackLayout";
import NotFoundRoute from "./layouts/WideLayout";

const App = ({ history }: any) => {
  const reactLocation = useLocation();
  const [redirectTo, setRedirectTo] = useState(null);
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  );
  const choosedAPack = useSelector(
    (state: RootState) => state.authReducer.choosedAPack
  );
  const isVerified = useSelector(
    (state: RootState) => state.authReducer.isVerified
  );
  useEffect(() => {
    if (reactLocation.pathname == "/pack-choice") {
      if (reactLocation.state && reactLocation.state.from) {
        setRedirectTo(reactLocation.state.from);
      }
    }
  })
  return (
    <Route
      render={({ location }) => (
        <div>
          <Switch location={location}>
            <Route
              render={() => <Redirect to={"/dashboard"} />}
              path={"/"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={DashboardPage}
              path={"/dashboard"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={ProfilePage}
              path={"/profile"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={ProfilePage}
              path={"/profile/contract"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={MailsPage}
              path={"/mails"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={MyServicesPage}
              path={"/services"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              name="settings"
              component={SettingsPage}
              path={"/settings/:settingName"}
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={MessagesPage}
              path={"/messages"}
              exact
            />
            <AuthPackChoosenRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={NotificationsPage}
              path={"/notifications"}
              exact
            />
            <AuthIsVerifiedRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={PackChoicePage}
              path={"/pack-choice"}
              exact
            />
            <AuthIsVerifiedRoute
              hasChoosedAPack={choosedAPack}
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={PaiementProccessPage}
              path={"/paiement"}
              exact
            />
            <AuthRoute
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={AccountVerficationPage}
              path={"/verify"}
              exact
            />
            <EmailVerificationRoute
              component={EmailVerficationPage}
              path={"/verify/email/:token"}
              exact
            />
            <AuthRoute
              authenticated={isLoggedIn}
              isVerifiedAcc={isVerified}
              component={PhoneVerficationPage}
              path={"/verify/phone"}
              exact
            />
            <GuestRoute
              redirectTo={redirectTo}
              authenticated={isLoggedIn}
              component={LoginPage}
              path={"/login"}
              exact
            />
            <GuestRoute
              authenticated={isLoggedIn}
              component={ResetPasswordPage}
              path="/reset-password/:code"
            />
            <GuestRoute
              authenticated={isLoggedIn}
              component={ResetPasswordPage}
              path={"/reset-password"}
            />
            <GuestRoute
              authenticated={isLoggedIn}
              component={SignupPage}
              path={"/signup"}
              exact
            />
            <NotFoundRoute
              component={NotFoundPage}
              path='/404'
            />
            <Redirect
              from='*'
              to='/404'
            />
          </Switch>
        </div>
      )}
    />
  );
};

export default withRouter(App);

const AuthRoute = ({ component: Component, authenticated, ...rest }: any) => {

  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        authenticated ? (
          <AuthLayout location={props.location}>
            <Component {...props} />
          </AuthLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AuthIsVerifiedRoute = ({ component: Component, authenticated, isVerifiedAcc, hasChoosedAPack, ...rest }: any) => {
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        authenticated && isVerifiedAcc ? (
          <PackLayout location={props.location}>
            <Component {...props} />
          </PackLayout>
        ) :
          (
            <Redirect
              to={{
                pathname: "/verify/phone",
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
};

const AuthPackChoosenRoute = ({ component: Component, authenticated, hasChoosedAPack, isVerifiedAcc, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated && isVerifiedAcc && hasChoosedAPack ? (
          <DashboardLayout location={props.location} >
            <Component {...props} />
          </DashboardLayout>
        ) :
          (
            <Redirect
              to={{
                pathname: "/pack-choice",
                state: { from: props.location },
              }}
            />
          )
      }
    />
  );
};

const GuestRoute = ({ component: Component, authenticated, redirectTo, ...rest }: any) => {
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        authenticated ? (
          <Redirect to={redirectTo ? {
            pathname: redirectTo.pathname,
            search: redirectTo.search,
            state: redirectTo.state
          } : "/dashboard"} />
        ) : (
          <AuthLayout location={props.location}>
            <Component {...props} />
          </AuthLayout>
        )
      }
    />
  );
};

const EmailVerificationRoute = ({ component: Component, authenticated, redirectTo, ...rest }: any) => {
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        <AuthLayout location={props.location}>
          <Component {...props} />
        </AuthLayout>
      }
    />
  );
};
