import React from "react";

const DownloadSmallIcon = (props) => {
  return (
    <svg
      width="21"
      height="22"
      cursor="pointer"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M15.7297 9.52188V5.3582C15.7297 5.23789 15.6711 5.13047 15.59 5.04023L10.6939 0.141797C10.6037 0.0515625 10.473 0 10.3422 0H2.57879C1.14513 0 0 1.11289 0 2.4793V16.4742C0 17.8406 1.14513 18.9363 2.57879 18.9363H8.71018C9.86883 20.7711 11.9787 22 14.3772 22C18.0245 22 21 19.177 21 15.6965C21.0045 12.6543 18.7143 10.1148 15.7297 9.52188ZM10.8246 1.61133L14.1112 4.90703H11.9787C11.3431 4.90703 10.8246 4.40859 10.8246 3.80273V1.61133ZM2.57879 18.0211C1.67711 18.0211 0.960283 17.3336 0.960283 16.4742V2.4793C0.960283 1.61563 1.67711 0.915234 2.57879 0.915234H9.86432V3.79844C9.86432 4.91133 10.8111 5.82227 11.9833 5.82227H14.7739V9.40156C14.6297 9.39727 14.5125 9.38437 14.3907 9.38437C12.7136 9.38437 11.1672 9.99883 10.0041 10.9613H3.88622C3.62022 10.9613 3.40382 11.1676 3.40382 11.4211C3.40382 11.6746 3.62022 11.8809 3.88622 11.8809H9.10241C8.75977 12.3406 8.47574 12.7961 8.25483 13.3031H3.88622C3.62022 13.3031 3.40382 13.5094 3.40382 13.7629C3.40382 14.0164 3.62022 14.2227 3.88622 14.2227H7.94375C7.82203 14.6824 7.75891 15.1895 7.75891 15.7008C7.75891 16.5258 7.92572 17.3422 8.23229 18.0297H2.57879V18.0211ZM14.3817 21.0891C11.2664 21.0891 8.72821 18.6742 8.72821 15.7008C8.72821 12.7273 11.2574 10.3125 14.3817 10.3125C17.506 10.3125 20.0352 12.7273 20.0352 15.7008C20.0352 18.6742 17.497 21.0891 14.3817 21.0891Z"
        fill="#3240FF"
      />
      <path
        d="M3.8862 9.58213H8.75976C9.02575 9.58213 9.24215 9.37588 9.24215 9.12236C9.24215 8.86885 9.02575 8.6626 8.75976 8.6626H3.8862C3.62021 8.6626 3.40381 8.86885 3.40381 9.12236C3.40832 9.37588 3.62472 9.58213 3.8862 9.58213Z"
        fill="#3240FF"
      />
      <path
        d="M16.8432 15.6021L14.864 17.6346V12.6287C14.864 12.3752 14.6476 12.1689 14.3816 12.1689C14.1156 12.1689 13.8992 12.3752 13.8992 12.6287V17.6346L11.9065 15.6021C11.7217 15.4174 11.4151 15.4045 11.2212 15.5807C11.0274 15.7568 11.0138 16.0447 11.1987 16.2252L14.0119 19.1084C14.1021 19.1986 14.2283 19.2545 14.3636 19.2545C14.4988 19.2545 14.6251 19.1986 14.7152 19.1084L17.533 16.2252C17.7178 16.0404 17.7043 15.7482 17.5149 15.5807C17.3256 15.4088 17.028 15.4217 16.8432 15.6021Z"
        fill="#3240FF"
      />
    </svg>
  );
};

export default DownloadSmallIcon;
